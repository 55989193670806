import { useEffect, useState, useRef } from 'react';
import { PhotoType } from '..';
import { CloseIcon } from '../../../../../assets/icons';
import { ChangeType, SetStateType } from '../../../../../types/common';
import { IOffice } from '../../../../../types/interfaces/office';
import { COLORS } from '../../../../../utils/constants/colors';
import { PhotoWrapper, Photo, Close } from '../style';
import { Label } from '../../../../../components/common/FormikElements/Input/style';
import { Flex } from '../../../../../components/common/Styled/Flex';
import { Typography } from '../../../../../components/common/Styled/Typography';

import FileInput from '../../../../../components/common/FormElements/FileInput';
import Modal from '../../../../../components/Modal';
import SliderModalComponent from '../../../../../components/SliderModal';

interface IProps {
    photoCabinet: Array<PhotoType>
    photoRoad: Array<PhotoType>
    validationRoad: boolean
    validationCabinet: boolean
    office: IOffice | null
    setPhotoCabinet: SetStateType<Array<PhotoType>>
    setPhotoRoad: SetStateType<Array<PhotoType>>
    setPhotoDelete: SetStateType<Array<{ type: string, id: number }>>
    photoDelete: Array<{ type: string, id: number }>
    setActivePhoto: SetStateType<PhotoType | null>
    isRequired: boolean
}

const FotoInputs = ({
    photoCabinet,
    photoRoad,
    validationRoad,
    validationCabinet,
    office,
    setPhotoRoad,
    setPhotoCabinet,
    setPhotoDelete,
    photoDelete,
    setActivePhoto,
    isRequired,
}: IProps) => {
    const cadinetFileInput = useRef<HTMLInputElement | null>(null)
    const roadFileInput = useRef<HTMLInputElement | null>(null)

    const [showRoadPhoto, setShowRoadPhoto] = useState(false)
    const [showPhoto, setShowPhoto] = useState(false)

    useEffect(() => {
        if (office) {
            setPhotoCabinet(office.photos.map(item => ({ id: item.id, url: item.photo })))
            setPhotoRoad(office.photos_get.map(item => ({ id: item.id, url: item.photo })))
        }
    }, [office, setPhotoCabinet, setPhotoRoad])

    const addPhoto = (e: ChangeType) => {
        const reader = new FileReader()
        const file = e.target.files
        if (!file || !file[0]) return
        reader.onloadend = () => {
            setPhotoCabinet([...photoCabinet, { file: file[0], url: reader.result, id: Date.now() }])
        }

        reader.readAsDataURL(file[0])
    }

    const addPhotoRoad = (e: ChangeType) => {
        const reader = new FileReader()
        const file = e.target.files
        if (!file || !file[0]) return
        reader.onloadend = () => {
            setPhotoRoad([...photoRoad, { file: file[0], url: reader.result, id: Date.now() }])
        }

        reader.readAsDataURL(file[0])
    }


    const deletePhoto = (id: number, section: 'cabinet' | 'road') => {
        const photo = section === 'cabinet' ? photoCabinet.find(item => item.id === id) : photoRoad.find(item => item.id === id)
        const newArr = section === 'cabinet' ? photoCabinet.filter(item => item.id !== id) : photoRoad.filter(item => item.id !== id)

        if (photo && !photo.file) {
            setPhotoDelete([...photoDelete, { type: section === 'cabinet' ? 'photosoffice' : 'photosgetoffice', id }])
        }
        section === 'cabinet' ? setPhotoCabinet(newArr) : setPhotoRoad(newArr)
        section === 'cabinet' ? cadinetFileInput!.current!.value = '' : roadFileInput!.current!.value = ''
    }

    return (
        <>
            <Flex width="100%" margin="20px 0 0 0" align="flex-start" direction="column">
                <Label>
                    {isRequired ? 'Фото кабинета*' : 'Фото кабинета'}
                </Label>
                <PhotoWrapper justify="flex-start" align="flex-start" gap="7px">
                    {!!photoCabinet.length && photoCabinet.map(item => item.url &&
                        <Photo key={item.id}>
                            <img onClick={() => setShowPhoto(true)} alt="Фото" src={item.url as string} />
                            <Close onClick={() => deletePhoto(item.id, 'cabinet')}><CloseIcon /></Close>
                        </Photo>
                    )}
                    <FileInput inputRef={cadinetFileInput} fieldId="fileCabinet" changeFunction={addPhoto} />
                </PhotoWrapper>
                {validationCabinet && <Typography color={COLORS.red} margin="5px 0" size="12px">Обязательное поле</Typography>}
            </Flex>
            <Flex justify="flex-start" width="100%" margin="20px 0 0 0" align="flex-start" direction="column">
                <Label>
                    {isRequired ? 'Фото как добраться*' : 'Фото как добраться'}
                </Label>
                <PhotoWrapper justify="flex-start" align="flex-start" gap="7px">
                    {!!photoRoad.length && photoRoad.map(item => item.url &&
                        <Photo key={item.id}>
                            <img onClick={() => setShowRoadPhoto(true)} alt="Фото" src={item.url as string} />
                            <Close onClick={() => deletePhoto(item.id, 'road')}><CloseIcon /></Close>
                        </Photo>
                    )}
                    <FileInput inputRef={roadFileInput} fieldId="fileRoad" changeFunction={addPhotoRoad} />
                </PhotoWrapper>
                {validationRoad && <Typography color={COLORS.red} margin="5px 0" size="12px">Обязательное поле</Typography>}
            </Flex>
            {(showPhoto || showRoadPhoto) &&
                <Modal
                    component={() =>
                        <SliderModalComponent
                            alt={showRoadPhoto ? 'Фото пути' : 'Фото кабинета'}
                            arr={(showRoadPhoto ? photoRoad : photoCabinet).map(item => ({ id: item.id, photo: item.url as string }))}
                        />
                    }
                    closeModal={() => { setShowPhoto(false); setShowRoadPhoto(false) }}
                    sliderModal
                />
            }
        </>
    );
};

export default FotoInputs;