import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import Slider, { Settings } from 'react-slick'
import { NextIcon, PrevIcon } from '../../assets/icons'
import { Flex } from '../../components/common/Styled/Flex'
import { Tab } from '../../components/common/Styled/Tab'
import { Typography } from '../../components/common/Styled/Typography'
import Filters from '../../components/Filters'
import useResolution from '../../hooks/useResolution'
import { useWrapperSize } from '../../hooks/useWrapperSize'
import { IProduct } from '../../types/interfaces/product'
import { getProducts } from '../../utils/api/routes'
import PurchaseHistory from './History'
import { SubscriptionsWrapper, HeaderText, TextWrapper } from './style'
import SubscriptionsItem from './SubscriptionsItem'

function Subscriptions() {
    const [page, setPage] = useState(1 as 1 | 2 | 3)
    const [product, setProduct] = useState<Array<IProduct>>([])
    const isMobile = useResolution(1000)
    // const isMobile750 = useResolution(750)
    const isMobile500 = useResolution(500)
    const ref = useRef<HTMLDivElement>(null)
    const [textActive, setTextActive] = useState(false)
    const {size} = useWrapperSize(ref, isMobile)
    const [city, setCity] = useState('')
    const [sliderCount, setSliderCount] = useState(1)
    const history = useHistory()

    useEffect(() => {
       page !== 3 && getProducts(page === 2, city).then(res => setProduct(res.data))
    }, [city, page])

    useEffect(() => {
        const resize = () => {            
            setSliderCount(Math.floor(((window.innerWidth - 210 - (((window.innerWidth - 210) / 10) - 1)) / 290)))
        }
        resize()
        window.addEventListener('resize', resize)


        return () => window.removeEventListener('resize', resize)
    }, [])

    const sliderSettings: Settings = {
        infinite: false,
        variableWidth: true,
        className: 'slider',
        slidesToShow: sliderCount,
        speed: 300,
        focusOnSelect: true,
        prevArrow: <PrevIcon />,
        nextArrow: <NextIcon />,
    }
    
    return (
        <SubscriptionsWrapper direction="column" padding="50px 60px 0 60px">
            <Flex direction="column" align="flex-start" gap="10px" width="100%">
                <HeaderText
                    weight="800"
                    size="24px"
                    lineHeight="29px"
                    active={page === 1}
                    onClick={() => setPage(1)}
                >
                    Покупки
                </HeaderText>
                {page !== 3 &&
                    <Filters 
                        show={{
                            city: true
                        }}
                        setQueryString={setCity}
                    />
                }
            </Flex>
            <TextWrapper active={textActive}>
                <Typography fontFamily="Montserrat" weight="500">
                    Перед началом использования сервиса и совершением своей первой покупки, ознакомьтесь с правилами. Здесь мы собрали для вас все самое необходимое: 
                    <a onClick={() => history.push('/rules')} href="#">Правила</a>
                </Typography>
            </TextWrapper>
            <Flex margin="20px 0 0 0" gap="10px" width="100%" justify="flex-start">
                <Tab onClick={() => setPage(1)} active={page === 1} weight="600" size="16px">Общие</Tab>
                <Tab onClick={() => setPage(2)} active={page === 2} weight="600" size="16px">Групповые</Tab>
                <Tab onClick={() => setPage(3)} active={page === 3} weight="600" size="16px">История списаний</Tab>
            </Flex>
            <Flex justify="flex-start" margin="20px 0 0 0" width="100%" gap={isMobile ? '0' : '10%'}>
                {page !== 3 ?
                    isMobile500 ? 
                        <Flex width="100%" ref={ref} maxHeight={size} overflow="auto" column="10px" direction="column" justify="flex-start">
                            {product.map((item, index) => 
                                <SubscriptionsItem key={index} data={item} />
                            )}
                        </Flex>
                        :
                        <Slider {...sliderSettings}>
                            {product.map((item, index) => 
                                <SubscriptionsItem key={index} data={item} />
                            )}
                        </Slider>
                    :
                        <PurchaseHistory />
                }
            </Flex>
        </SubscriptionsWrapper>
    )
}

export default Subscriptions
