import React, { useEffect, useState, useRef } from 'react'
import Input from '../../../components/common/FormElements/Input'
import { Flex } from '../../../components/common/Styled/Flex'
import loupeIcon from '../../../assets/icons/admin/loupeIcon.svg'
import { ChangeType } from '../../../types/common'
import { Button } from '../../../components/common/Styled/Button'
import { CabinetsWrapper, OfficesWrapper } from './style'
import { useSelector, useDispatch } from 'react-redux'
import { getOffices } from '../../../store/reducers/officesReducer'
import { RootState } from '../../../store/reducers/rootReducer'
import { usePagination } from '../../../hooks/usePagination'
import { Grid } from '../../../components/common/Styled/Grid'
import CbinetCard from './CabinetCard'
import { useHistory } from 'react-router-dom'
import CabinetInfo from './CbinetsInfo'
import { IOffice } from '../../../types/interfaces/office'
import { useWrapperSize } from '../../../hooks/useWrapperSize'
import Filters from '../../../components/Filters'
import { debounce } from '../../../utils/debounce'
import useResolution from '../../../hooks/useResolution'

const AdminCabinets = () => {
    const { offices, count } = useSelector((state: RootState) => state.offices)
    const { profile } = useSelector((state: RootState) => state.user)
    const { finalyCallback, successCallback, currentPage, fetching, personalChenging, scroll } = usePagination(count === offices.length)
    const [search, setSearch] = useState('')
    const [queryString, setQueryString] = useState('')
    const [activeOffice, setActiveOffice] = useState<IOffice | null>(null)
    const ref = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const history = useHistory()

    const { size } = useWrapperSize(ref)
    const isMobile = useResolution(1000)

    const filtretingSuccess = () => personalChenging(2)

    const debouncedGetOficces = useRef(debounce((searchString: string, queryString: string) =>
        dispatch(
            getOffices(
                {
                    filtration: true,
                    page: 1,
                    successCallback: filtretingSuccess,
                    finalyCallback,
                    pageSize: 15,
                    queryString: queryString + (!profile.is_superuser && !profile.cabinets_all ? `&user=${profile.id}` : '') + `&name=${searchString}`
                }
            )
        ), 400))

    const updateComponent = () => {
        setActiveOffice(null)
        dispatch(getOffices({ filtration: true, page: 1, successCallback: filtretingSuccess, finalyCallback, pageSize: 15, queryString }))
    }

    useEffect(() => {
        queryString && debouncedGetOficces.current(search, queryString)
    }, [queryString, dispatch, search])

    useEffect(() => {
        fetching && dispatch(getOffices(
            {
                page: currentPage,
                successCallback,
                finalyCallback,
                pageSize: 15,
                queryString: queryString + (!profile.is_superuser && !profile.cabinets_all ? `&user=${profile.id}` : '')
            }
        ))
    }, [fetching, dispatch])

    return (
        <CabinetsWrapper width="100%" align="flex-start" direction="column" padding="100px 60px 20px 60px">
            <Flex width="100%" justify="space-between">
                <Flex justify="flex-start" width="100%">
                    <Input
                        placeholder="Поиск"
                        icon={loupeIcon}
                        onChange={(e: ChangeType) => setSearch(e.target.value)}
                        value={search}
                    />
                    <Filters
                        show={{
                            metro: true,
                            city: true
                        }}
                        setQueryString={setQueryString}
                    />
                </Flex>
                <Button
                    theme="grey"
                    width="217px"
                    height="36px"
                    radius="6px"
                    weight="600"
                    lineHeight="17px"
                    onClick={() => history.push('/offices/add')}
                >
                    Добавить кабинет
                </Button>
            </Flex>
            <Grid padding="25px 0 0 0" columns="45% 45%" gap="10%" margin={isMobile ? '0 0 80px 0' : ''}>
                <OfficesWrapper height={size} ref={ref} justify="flex-start" onScroll={scroll} direction="column" width="100%">
                    {offices.map(item => <CbinetCard activeOffice={activeOffice?.id} setActiveOffice={setActiveOffice} key={item.id} office={item} />)}
                </OfficesWrapper>
                {activeOffice && <CabinetInfo updateComponent={updateComponent} office={activeOffice} />}
            </Grid>
        </CabinetsWrapper>
    )
}

export default AdminCabinets