import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { COLORS } from '../../../../utils/constants/colors'

const UserInfoWrapper = styled(Flex)`
    position: relative;
    width: 100%;
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 12px;
    background: ${COLORS.cream};
`
const Status = styled(Flex)<{status: boolean}>`
    content: '';
    position: absolute;
    top: 25px;
    right: 25px;
    width: 118px;
    height: 30px;
    background: ${({status}) => status ? COLORS.green : COLORS.red};
    border-radius: 9px;
`

export {UserInfoWrapper, Status}