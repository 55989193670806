import React, { useEffect, useState, useRef } from 'react';
import Input from '../../FormElements/Input';
import { Flex } from '../../Styled/Flex';
import { Typography } from '../../Styled/Typography';
import { Label, InputDateFilter } from './style';
import { COLORS } from '../../../../utils/constants/colors';
import { FieldProps } from 'formik';
import { CSSObject } from 'styled-components';
import { getNestedValue } from '../../../../utils/formik/getNestedValue';
import DateFilter from '../../FormElements/DateFilter';
import moment from 'moment';
import { ChangeType, SetStateType } from '../../../../types/common';
import { useClickOutside } from '../../../../hooks/useClickOutside';

interface IProps extends FieldProps {
    label: string
    labelWidth: string
    direction: CSSObject['flexDirection']
    excludeDates?: Array<Date>
    setCurrentMonth?: SetStateType<Date>
}


const DateFormikFilter = ({                   
    label,
    labelWidth,
    field: {value, onChange, ...field},
    form: {setFieldValue, errors, touched},
    direction = 'row',
    excludeDates,
    setCurrentMonth,
    ...props
    } : IProps) => {
    
    const [show, setShow] = useState(false)
    const [dates, setDates] = useState<{startDate: Date | null}>({startDate: null})
    const [title, setTitle] = useState('')
    const [month, setMonth] = useState<Date | null>(null)
    const fieldError = getNestedValue(errors, field.name)
    const fieldTouched = getNestedValue(touched, field.name)
    const ref = useRef<HTMLDivElement>(null)
    useClickOutside(ref, () => setShow(false))
    useEffect(() => {
        if(setCurrentMonth) {
            month ? setCurrentMonth(month) : setCurrentMonth(new Date())
        }
    }, [month])
    
    useEffect(() => {
        if(title) {
            const dateValue = dates.startDate ? moment(dates.startDate).format('DD.MM.YYYY') : ''
            setFieldValue('date', dateValue)
        }
    }, [title])
    
    return (
        <Flex ref={ref} width="100%" direction={direction} align="flex-start" justify="flex-start">
            {label && <Label width={labelWidth}>{label}</Label>}
            <InputDateFilter width="100%" direction="column">
                <Input 
                    onFocus={() => {
                        setDates({startDate: null})
                        setTitle('')
                        setShow(true)
                    }} 
                    value={value} 
                    onChange={(e: ChangeType) => {}}
                    autoComplete="off"
                    {...field} 
                    {...props}
                />
                {fieldError && fieldTouched && <Typography color={COLORS.red} margin="5px 0" size="12px">{fieldError}</Typography>}
                {show && 
                    <DateFilter
                        setShow={setShow}
                        select={false}
                        showInputs={false}
                        dates={dates}
                        setDates={setDates}
                        setTitle={setTitle}
                        excludeDates={excludeDates}
                        setGlobalMonth={setMonth}
                        dontShowPrevDays
                    />
                }
            </InputDateFilter>
        </Flex>
    );
};

export default DateFormikFilter;