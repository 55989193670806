import React, { ChangeEvent, HTMLAttributes } from 'react';
import { StyledTextArea } from './style';

interface IProps extends HTMLAttributes<HTMLTextAreaElement>{
    value: string,
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void,
    classname?: string
    name?: string
    maxLength?: number
    height: string
    placeholder?: string
}

const TextArea = ({value, onChange, classname, name, maxLength, placeholder, height, ...props} : IProps) => {
    return (
        <StyledTextArea 
            value={value}
            onChange={onChange}
            className={classname}
            name={name}
            maxLength={maxLength}
            height={height}
            placeholder={placeholder}
            {...props}
        />
    );
};

export default TextArea;