import { FieldProps } from 'formik';
import React from 'react';
import { COLORS } from '../../../../utils/constants/colors';
import { getCorrectFieldLabel } from '../../../../utils/formik/getCorrectFieldLabel';
import { getNestedValue } from '../../../../utils/formik/getNestedValue';
import TextArea from '../../FormElements/TextArea';
import { Flex } from '../../Styled/Flex';
import { Typography } from '../../Styled/Typography';
import { Label } from '../Input/style';

interface FormikTextAreaProps extends FieldProps{
    schema: Record<string, any>
}

const FormikTextArea = ({
    field: {value, onChange, ...field},
    form: {errors, touched},
    height,
    label,
    placeholder,
    schema,
    ...props
} : FormikTextAreaProps & {height: string, label?: string, placeholder?: string}) => {
    const fieldError = getNestedValue(errors, field.name)
    const fieldTouched = getNestedValue(touched, field.name)

    return (
        <Flex width="100%" direction="column" align="flex-start">
            {label && <Label>{getCorrectFieldLabel(label, schema, field.name)}</Label>}
            <TextArea 
                value={value}
                onChange={onChange}
                height={height}
                placeholder={placeholder}
                {...field}
                {...props}
            />
            {fieldError && fieldTouched && <Typography color={COLORS.red} margin="5px 0" size="12px">{fieldError}</Typography>}
        </Flex>
    );
};

export default FormikTextArea;