import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {
    PeopleIcon,
    CarIcon,
    AlaramIcon,
    BoundingIcon,
    ArrowBackIcon,
    CalendarIcon,
    CalendarNumberIcon,
    PrevIcon,
    NextIcon,
} from '../../../../assets/icons';
import Calendar from '../../../../components/Calendar';
import {Button} from '../../../../components/common/Styled/Button';
import {CalendarWrapper} from '../../../../components/common/Styled/CalendarWrapper';
import {Flex} from '../../../../components/common/Styled/Flex';
import {Typography} from '../../../../components/common/Styled/Typography';
import useResolution from '../../../../hooks/useResolution';
import {bookingsGet, setActiveCabinetId, setActiveDateRange} from '../../../../store/reducers/bookingReducer';
import {RootState} from '../../../../store/reducers/rootReducer';
import {IOffice} from '../../../../types/interfaces/office';
import {getOffice, getRoadImg} from '../../../../utils/api/routes';
import {COLORS} from '../../../../utils/constants/colors';
import {LoaderIcon} from '../../../../assets/icons';
import {
    Info,
    Description,
    Way,
    ImgWrapper,
    RoadImg,
    PageWrapper,
    Road,
    DescriptionItem,
} from './style';
import {Grid} from '../../../../components/common/Styled/Grid';
import Slider, {Settings} from 'react-slick';
import {SliderWrapper} from '../../../../components/common/Styled/SliderWrapper';
import Modal from '../../../../components/Modal';
import SliderModalComponent from '../../../../components/SliderModal';

const CabinetPage = () => {
    const {bookings, loading} = useSelector(
        (state: RootState) => state.bookings
    );
    const [office, setOffice] = useState({} as IOffice);
    const [roadImgs, setRoadImgs] = useState<Array<{ id: number; photo: string; user: number }>>([]);
    const [viewCalendar, setViewCalendar] = useState(7 as 1 | 7);
    const [modalOpen, setModalOpen] = useState(false);
    const [dates, setDates] = useState({dateAfter: '', dateBefore: ''});
    const [buttonText, setButtonText] = useState('Забронировать');
    const [toNow, setToNow] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [officeLoading, setOfficeLoading] = useState(false);
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showRoadModal, setShowRoadModal] = useState(false)
    const {id} = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const isMobile = useResolution(1000);
    const history = useHistory();

    useEffect(() => {
        setOfficeLoading(true);
        getOffice(id)
            .then(res => {
                setOffice(res.data);
                setNotFound(false);
                dispatch(setActiveCabinetId(+id))
            })
            .catch(() => setNotFound(true))
            .finally(() => setOfficeLoading(false));
        getRoadImg(id).then(res => setRoadImgs(res.data));
    }, [id]);

    const bookingsRequest = () => {
        const queryString = `&date_after=${dates.dateAfter}&date_before=${dates.dateBefore}&office=${office.id}&canceled=False`;
        dispatch(bookingsGet({queryString, pageSize: 250, all: true}));
    };

    useEffect(() => {
        dispatch(setActiveDateRange(dates))
        dates.dateAfter && office.id && bookingsRequest();
    }, [dates, office]);

    const intial = {
        '1': '08:00-22:00',
        '2': '08:00-22:00',
        '3': '08:00-22:00',
        '4': '08:00-22:00',
        '5': '08:00-22:00',
        '6': '08:00-22:00',
        '7': '08:00-22:00',
    };

    const sliderSettings: Settings = {
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <PrevIcon/>,
        nextArrow: <NextIcon/>,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        dots: true,
        draggable: false
    };

    if (notFound) {
        return (
            <Flex height="100vh" width="100%">
                <Typography weight="800" size="20px">
                    Страница не найдена
                </Typography>
            </Flex>
        );
    }

    return (
        <PageWrapper
            justify="flex-start"
            padding="20px 17% 100px 17%"
            direction="column"
        >
            {officeLoading ? (
                <Flex height="100vh" width="100%">
                    <LoaderIcon/>
                </Flex>
            ) : (
                <>
                    <Flex margin="0 0 20px 0" width="100%" justify="flex-start">
                        <ArrowBackIcon onClick={() => history.goBack()}/>
                    </Flex>
                    <SliderWrapper>
                        <Slider {...sliderSettings}>
                            {office.photos &&
                                office.photos.map(item => (
                                    <ImgWrapper onClick={() => setShowPhotoModal(true)} key={item.id}>
                                        <img
                                            src={item.photo}
                                            alt="Фото кабинета"
                                        />
                                    </ImgWrapper>
                                ))}
                        </Slider>
                    </SliderWrapper>
                    <Info>
                        <Flex width="100%" justify="space-between">
                            <Typography
                                weight="700"
                                size="22px"
                                lineHeight="26px"
                            >
                                {office.name}
                            </Typography>
                            <Typography
                                align="end"
                                fontFamily="Montserrat"
                                weight="500"
                                lineHeight="17px"
                                color={COLORS.steel}
                            >
                                {office?.price_category?.name}
                            </Typography>
                        </Flex>
                        <Flex width="100%" justify="space-between">
                            <Typography
                                fontFamily="Montserrat"
                                weight="500"
                                size="16px"
                                lineHeight="20px"
                            >
                                {office.address}
                            </Typography>
                            <Typography
                                align="end"
                                weight="500"
                                size="22px"
                                lineHeight="26px"
                            >
                                {office?.price_category?.price}
                                ₽&nbsp;/&nbsp;час
                            </Typography>
                        </Flex>
                    </Info>
                    <Description>
                        <Typography weight="700" size="22px" lineHeight="26px">
                            О кабинете
                        </Typography>
                        <Grid
                            gap="20px"
                            margin="20px 0 25px 0"
                            columns="1fr 1fr"
                            rows="50px 50px"
                            w={isMobile ? '100%' : '60%'}
                        >
                            <DescriptionItem>
                                <PeopleIcon/>
                                <Typography
                                    weight="500"
                                    size="18px"
                                    lineHeight="21px"
                                >
                                    {office.max_peoples} чел.
                                </Typography>
                            </DescriptionItem>
                            <DescriptionItem>
                                <CarIcon/>
                                <Typography
                                    weight="500"
                                    size="18px"
                                    lineHeight="21px"
                                >
                                    {office.parking} парковка
                                </Typography>
                            </DescriptionItem>
                            <DescriptionItem>
                                <BoundingIcon/>
                                <Typography
                                    weight="500"
                                    size="18px"
                                    lineHeight="21px"
                                >
                                    {office.metric_area} м<sup>2</sup>
                                </Typography>
                            </DescriptionItem>
                            <DescriptionItem>
                                <AlaramIcon/>
                                <Typography
                                    weight="500"
                                    size="18px"
                                    lineHeight="21px"
                                >
                                    Мин{' '}
                                    {office.occupation_step === 1
                                        ? '1 час'
                                        : '30 минут'}
                                </Typography>
                            </DescriptionItem>
                        </Grid>
                        <Typography
                            fontFamily="Montserrat"
                            size="16px"
                            lineHeight="20px"
                            whiteSpace="pre-line"
                        >
                            {office.description}
                        </Typography>
                    </Description>
                    <Way>
                        <Typography weight="700" size="22px" lineHeight="26px">
                            Как добраться
                        </Typography>
                        <Road>
                            {roadImgs && roadImgs.length ? (
                                roadImgs.map(item => (
                                    <RoadImg onClick={() => setShowRoadModal(true)} key={item.id}>
                                        <img
                                            src={item.photo}
                                            alt="Как добраться"
                                        />
                                    </RoadImg>
                                ))
                            ) : (
                                <Typography
                                    weight="700"
                                    size="22px"
                                    lineHeight="26px"
                                >
                                    Фото отсутствуют
                                </Typography>
                            )}
                        </Road>
                    </Way>
                    <Flex
                        width="100%"
                        margin="30px"
                        direction="column"
                        align="flex-start"
                    >
                        <Flex
                            width="100%"
                            align="flex-start"
                            justify="space-between"
                        >
                            <Typography
                                margin="0 0 20px 0"
                                weight="700"
                                size="22px"
                                lineHeight="26px"
                            >
                                Календарь
                            </Typography>
                            <Flex>
                                <Button
                                    width="120px"
                                    height="25px"
                                    radius="21px"
                                    margin="0 10px 0 0"
                                    onClick={() => setToNow(true)}
                                    color={COLORS.black}
                                    weight="700"
                                >
                                    Сегодня
                                </Button>
                                {viewCalendar === 1 ? (
                                    <CalendarIcon
                                        onClick={() => setViewCalendar(7)}
                                    />
                                ) : (
                                    <CalendarNumberIcon
                                        onClick={() => setViewCalendar(1)}
                                    />
                                )}
                            </Flex>
                        </Flex>
                        <CalendarWrapper notScroll padding="30px 50px 0 25px">
                            <Calendar
                                data={bookings}
                                view={viewCalendar}
                                step={office.occupation_step || 1}
                                schedule={office.schedule || intial}
                                calendarPrams={office}
                                setBookings={bookingsRequest}
                                setDates={setDates}
                                modalOpen={modalOpen}
                                setButtonText={setButtonText}
                                setModalOpen={setModalOpen}
                                toNow={toNow}
                                setToNow={setToNow}
                                notAdaptive
                                loading={loading}
                                noQueue={false}
                                showMyBooking
                            />
                            <Flex padding="20px 0" justify="flex-end">
                                <Button
                                    theme="green"
                                    radius="21px"
                                    width="155px"
                                    height="40px"
                                    lineHeight="17px"
                                    weight="600"
                                    onClick={() => setModalOpen(true)}
                                >
                                    {buttonText}
                                </Button>
                            </Flex>
                        </CalendarWrapper>
                    </Flex>
                    {(showRoadModal || showPhotoModal) &&
                        <Modal
                            component={() =>
                                <SliderModalComponent
                                    alt={showRoadModal ? 'Как добраться' : 'Фото кабинета'}
                                    arr={(showRoadModal ? office.photos_get : office.photos).map(item => ({
                                        id: item.id,
                                        photo: item.photo
                                    }))}
                                />}
                            closeModal={() => {
                                setShowPhotoModal(false);
                                setShowRoadModal(false)
                            }}
                            sliderModal
                        />
                    }
                </>
            )}
        </PageWrapper>
    );
};

export default CabinetPage;
