import styled from "styled-components";
import {COLORS} from "../../../../utils/constants/colors";

interface IButtonProps {
    color?: string
    weight?: string | number
    fontSize?: string
    lineHeight?: string
    fontFamily?: string
    margin?: string
    padding?: string
    background?: string
    radius?: string
    rounded?: boolean
    shadow?: boolean
    width?: string
    height?: string
    theme?: 'red' | 'grey' | 'green' | 'beige' | 'darkRed' | 'brown'
    hover?: {background?: string, color?: string} | string
}

const getTheme = (theme: IButtonProps['theme']) => {
    switch (theme) {
        case "red":
            return `
                background: ${COLORS.red};
                &:hover {
                    opacity: 0.8
                };
            `
        case "darkRed":
            return `
                background: ${COLORS.darkRed};
                &:hover {
                    opacity: 0.8
                };
            `
        case "green":
            return `
                background: ${COLORS.darkGreen};
                &:hover {
                    opacity: 0.8
                };
            `
        case "grey":
            return `
                background: ${COLORS.steel};
                &:hover {
                    opacity: 0.8
                };
            `
        case "beige":
            return `
                background: ${COLORS.brown};
                &:hover {
                    opacity: 0.8
                };
            `
        case "brown":
            return `
                background: ${COLORS.lightBrown};
                &:hover {
                    opacity: 0.8
                };
            `
        default:
            return ''
    }
}

export const Button = styled.button<IButtonProps>`
    font-size: ${p => p.fontSize || '14px'};
	line-height: ${p => p.lineHeight || '120%'};
	font-weight: ${p => p.weight || 'normal'};
	font-family: ${p => p.fontFamily || 'SF Pro Display'}, sans-serif;
	color: ${p => p.color ? p.color : p.theme ? COLORS.white : COLORS.black};
	transition: all .25s linear;
	cursor: pointer;
	padding: ${p => p.padding || 0};
	margin: ${p => p.margin || 0};
	width: ${p => p.width || 'auto'};
	height: ${p => p.height || 'auto'};
	background: ${p => p.background || 'none'};
	border-radius: ${p => p.radius || 0};
	box-shadow: ${p => p.shadow ? '0 4px 4px rgba(0, 0, 0, 0.25)' : 'none'};
	${p => p.hover && typeof p.hover !== 'string' ?
	    `&:hover {
	        background: ${p.hover.background || 'initial'};
	        color: ${p.hover.color || 'initial'};
	    };`
        : p.hover
	};
	${p => p.theme && getTheme(p.theme)};

    &:disabled {
        pointer-events: none;
        background:  #BCC0C3;
    }
`;