import React from "react";
import {Route, Redirect, RouteComponentProps} from "react-router-dom";
import {ComponentType} from "../../types/common";

interface IProps {
    component: ComponentType<RouteComponentProps>,
    path: string,
    exact?: boolean,
    redirect: string,
    access?: boolean
}


const PrivateRoute = ({component: Component, access, redirect, ...rest}: IProps) => {
    return (
        <Route {...rest} render={props => access
            ? <Component {...props}/>
            : <Redirect to={redirect}/>}
        />
    )
}

export default PrivateRoute;