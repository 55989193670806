import styled from 'styled-components'
import { DropDown } from '../../../../components/common/Styled/DropDown'
import { COLORS } from '../../../../utils/constants/colors'

const SearchDropdown = styled.div`
    position: relative;
    width: 100%;
`
const List = styled.ul`
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: ${COLORS.white};
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    top: 100%;
    left: 0;
    z-index: 99;

    &::-webkit-scrollbar {
        width: 6px;
    }
`

const DateSelectWrapper = styled.div`
    position: relative;
    width: 100%;

    input {
        background: ${COLORS.white}
    }

    ${DropDown} {
        width: 340px;
    }
`

const ListItem = styled.li`
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    border-bottom: 1px solid ${COLORS.brown};
    padding: 0 10px;
    cursor: pointer;
    /* border-radius: 10px; */
    
    &:hover {
        background: ${COLORS.beige};
    }
`
export {SearchDropdown, List, ListItem, DateSelectWrapper}