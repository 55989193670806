import styled from 'styled-components'
import { Flex } from '../../../components/common/Styled/Flex'

export const NotificationWrapper = styled(Flex)`
    max-height: calc(100vh - 170px);
    overflow-y: auto;

    & > div {
        margin-bottom: 15px;
    }
    & > div:last-child {
        margin-bottom: 0px;
    }
`