import React from 'react'
import { Button } from '../../../../../components/common/Styled/Button'
import { Flex } from '../../../../../components/common/Styled/Flex'
import { Typography } from '../../../../../components/common/Styled/Typography'
import { useErrorHandler } from '../../../../../hooks/useErrorHandler'
import { VoidFuncType } from '../../../../../types/common'
import { deleteSubscriptions } from '../../../../../utils/api/routes'
import { showAlert } from '../../../../../utils/showAlert'
import { DeleteSubscriptionPopupWrapper, SubscriptionName } from './style'
import { ISubscription } from '../../../../../types/interfaces/subscription'
import ErrorHandler from '../../../../../components/Error'

interface IProps {
  id: number,
  updateComponent: (arg: string) => void,
  closeModal: VoidFuncType
  subscription: ISubscription
}

function DeleteSubscriptionPopup({ id, updateComponent, closeModal, subscription }: IProps) {
  const { show, active, hidden, text } = useErrorHandler()

  const deletePriceCAtegoryById = async () => {
    deleteSubscriptions(subscription.id).then(() => {
      updateComponent('')
      closeModal()
      showAlert('success', 'Абонемент успешно удален')
    }).catch((e) => {
      closeModal()
      showAlert('error', 'Не удалось удалить абонемент')
    })
  }

  return (
    <DeleteSubscriptionPopupWrapper>
      <ErrorHandler active={active} hidden={hidden} text={text} />
      <Flex padding="10px 0" border="1px solid #DECEB7">
        <Typography weight="700" size="18px" lineHeight="21px">Удалить абонемент</Typography>
      </Flex>
      <Flex padding="10px" direction="column">
        <Typography size="18px" lineHeight="21px" align="center">
          <SubscriptionName>
            {subscription?.name}<br />
          </SubscriptionName>
          Вы уверены, что хотите удалить данный абонемент?
        </Typography>
        <Button
          margin="15px"
          onClick={deletePriceCAtegoryById}
          theme="grey"
          width="155px"
          height="40px"
          radius="21px"
          weight="600"
          lineHeight="17px"
        >
          Да, я уверен(a)
        </Button>
      </Flex>
    </DeleteSubscriptionPopupWrapper>
  )
}

export default DeleteSubscriptionPopup
