import React, { useEffect, useState, useRef } from 'react';
import Input from '../../../../components/common/FormElements/Input';
import { Flex } from '../../../../components/common/Styled/Flex';
import { List, ListItem, SearchDropdown, DateSelectWrapper } from './style';
import loupeIcon from '../../../../assets/icons/admin/loupeIcon.svg'
import { ChangeType } from '../../../../types/common';
import Select from '../../../../components/common/FormElements/Select';
import { IUser } from '../../../../types/interfaces/user';
import { getStatisticsSummary, getUsers, getPsyhoStatistics } from '../../../../utils/api/routes';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { Typography } from '../../../../components/common/Styled/Typography';
import { Ceil, CeilHeader, Table } from '../../../../components/common/Styled/Table';
import { Grid } from '../../../../components/common/Styled/Grid';
import { Card } from '../style';
import { IPsyhoStatistics } from '../../../../types/interfaces/statistics';
import moment from 'moment';
import DateFilter from '../../../../components/common/FormElements/DateFilter';
import paginationHoc, { PaginationComponentProps } from '../../../../hocs/Pagination.hoc';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducer';
import { debounce } from '../../../../utils/debounce';

const PsyhoStatistics = ({ currentPage, setCurrentPage, paginationComponet }: PaginationComponentProps) => {
    const { profile } = useSelector((state: RootState) => state.user)
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState<number | null>(null)
    const [users, setUsers] = useState<Array<IUser>>([])
    const [user, setUser] = useState<number | null>(null)
    const [showUsers, setShowUsers] = useState(false)
    const [statisticsSummary, setStatisticsSummary] = useState({ hours: 0, count: 0 })
    const [statistics, setStatistics] = useState<Array<IPsyhoStatistics>>([])
    const [dates, setDates] = useState<{ startDate: Date | null, endDate?: Date | null }>({ startDate: null, endDate: null })
    const [showDateSelect, setShowDateSelect] = useState(false)
    const [dateInputValue, setDateInputValue] = useState('')
    const [filterString, setFilterString] = useState('')
    const [pageSize, setPageSize] = useState(0)
    const [totalStatisticsCount, setTotalStatisticsCount] = useState(0)
    const ref = useRef<HTMLUListElement>(null)
    const dateFilterRef = useRef<HTMLDivElement>(null)
    const table = useRef<HTMLDivElement>(null)
    useClickOutside(ref, () => setShowUsers(false))
    useClickOutside(dateFilterRef, () => setShowDateSelect(false))

    const tableHeader = [
        { id: 1, text: 'Кабинет', borderRadius: 'first' as 'first' },
        { id: 2, text: 'Дата брони' },
        { id: 3, text: 'Время начала' },
        { id: 4, text: 'Время конца' },
        { id: 5, text: 'Статус брони', borderRadius: 'last' as 'last' },
    ]

    const debouncedSearch = useRef(debounce((search: string, profile: IUser) => getUsers(`search=${search}&is_psychologist=true&is_superuser=false${!profile.is_superuser && !profile.psychologist_all ? `&staff=${profile.id}` : ''}`).then(res => setUsers(res.data.results)), 400))

    useEffect(() => {
        if (!search) setUser(null)
        debouncedSearch.current(search, profile)
    }, [search])

    useEffect(() => {
        if (table.current) {
            setPageSize(Math.floor((window.innerHeight - table.current.getBoundingClientRect().top - 100) / 40))
        }
    }, [table])

    useEffect(() => {
        let queryString = ''
        if (dates.startDate) {
            queryString += `&date_after=${moment(dates.startDate).format('YYYY-MM-DD')}`
        }
        if (dates.endDate) {
            queryString += `&date_before=${moment(dates.endDate).format('YYYY-MM-DD')}`
        }
        if (status && status > 0) {
            queryString += `${status === 1 ? '&visited=true&canceled=false' : '&visited=false&canceled=true'}`
        }
        if ((dates.endDate && dates.startDate) || (dates.endDate === null && dates.startDate === null)) {
            setCurrentPage(1)
            setFilterString(queryString)
        }
    }, [dates, status])

    useEffect(() => {
        if (user) {
            getPsyhoStatistics(`?user=${user}${filterString}&page=${currentPage}&page_size=${pageSize}`).then(res => {
                setStatistics(res.data.results)
                setTotalStatisticsCount(res.data.count)
            })
            getStatisticsSummary(`?user=${user}${filterString}&page=${currentPage}&page_size=${pageSize}`, 'psychologists_summary')
                .then(res => setStatisticsSummary({ hours: res.data.hours || 0, count: res.data.count }))
            return
        }
        setStatistics([])
        setStatisticsSummary({ hours: 0, count: 0 })
    }, [user, filterString, currentPage, pageSize])

    const selectUser = (selectUser: IUser) => {
        setShowUsers(false)
        setUser(selectUser.id)
        setSearch(`${selectUser.last_name} ${selectUser.first_name} ${selectUser.middle_name}`)
    }

    return (
        <Flex margin="40px 0 0 0" align="flex-start" direction="column" width="100%">
            <Flex align="flex-start" direction="column" width="35%">
                <SearchDropdown>
                    <Input
                        value={search}
                        onChange={(e: ChangeType) => setSearch(e.target.value)}
                        icon={loupeIcon}
                        placeholder="ФИО / Email / Телефон"
                        onFocus={() => setShowUsers(true)}
                    />
                    {showUsers &&
                        <List ref={ref}>
                            {users.map(item =>
                                <ListItem onClick={() => selectUser(item)} key={item.id}>
                                    <Typography size="16px" lineHeight="20px">{item.last_name} {item.first_name} {item.middle_name}</Typography>
                                </ListItem>
                            )}
                        </List>
                    }
                </SearchDropdown>
                <Flex margin="15px 0 0 0" gap="15px" width="100%">
                    <Select
                        options={[
                            { value: -1, name: 'Все' },
                            { value: 1, name: 'Завершена' },
                            { value: 2, name: 'Отменена' }
                        ]}
                        value={status || ''}
                        onChange={(value) => setStatus(value as number)}
                        defaultValue="Статус брони"
                    />
                    <DateSelectWrapper ref={dateFilterRef}>
                        <Input
                            value={dateInputValue}
                            onChange={() => { }}
                            onFocus={() => setShowDateSelect(true)}
                            placeholder="Период бронирования"
                        />
                        {showDateSelect &&
                            <DateFilter
                                setShow={setShowDateSelect}
                                dates={dates}
                                setDates={setDates}
                                select
                                showInputs
                                setTitle={setDateInputValue}
                            />
                        }
                    </DateSelectWrapper>
                </Flex>
            </Flex>
            <Grid margin="25px 0 0 0" columns="60% 35%" gap="5%">
                <Table ref={table} columns="repeat(5, 20%)">
                    {tableHeader.map(item =>
                        <CeilHeader borderRadius={item.borderRadius && item.borderRadius} key={item.id}>
                            <Typography weight="700" lineHeight="18px">{item.text}</Typography>
                        </CeilHeader>
                    )}
                    {pageSize > 0 && statistics.map((item, index) =>
                        <React.Fragment key={item.id}>
                            <Ceil borderRadius="first" isLastRow={index === statistics.length - 1}>
                                <Typography lineHeight="18px">{item.office.name}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{moment(item.date).format('DD.MM.YYYY')}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{item.before_hours.slice(0, 5)}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{item.after_hours.slice(0, 5)}</Typography>
                            </Ceil>
                            <Ceil borderRadius="last" isLastRow={index === statistics.length - 1}>
                                <Typography lineHeight="18px">{item.visited ? 'Завершена' : item.canceled ? 'Отменена' : 'Активна'}</Typography>
                            </Ceil>
                        </React.Fragment>
                    )}
                </Table>
                <Flex direction="column" width="100%">
                    <Card>
                        <Typography fontFamily="Montserrat" weight="600" size="18px" lineHeight="22px">Итого часов</Typography>
                        <Typography weight="800" size="72px" lineHeight="86px">{statisticsSummary.hours}</Typography>
                    </Card>
                    <Card>
                        <Typography fontFamily="Montserrat" weight="600" size="18px" lineHeight="22px">Выбрано броней</Typography>
                        <Typography weight="800" size="72px" lineHeight="86px">{statisticsSummary.count}</Typography>
                    </Card>
                </Flex>
            </Grid>
            <Flex width="100%" margin="25px 0 0 0">
                {paginationComponet(totalStatisticsCount, pageSize)}
            </Flex>
        </Flex>
    );
};

export default paginationHoc(PsyhoStatistics);