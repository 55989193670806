import React, {useCallback} from "react";
import {Flex} from "../../Styled/Flex";
import {FieldProps} from "formik";
import {BaseLabel, Typography} from "../../Styled/Typography";
import Select from "../../FormElements/Select";
import {getNestedValue} from "../../../../utils/formik/getNestedValue";
import {COLORS} from "../../../../utils/constants/colors";
import { getCorrectFieldLabel } from "../../../../utils/formik/getCorrectFieldLabel";

type ValueType = string | number | boolean

interface IProps extends FieldProps {
    label?: string
    margin?: string
    options: Array<{value: ValueType, name: string}> | string[],
    defaultValue?: string
    schema: Record<string, any>
}

const FormikSelect = ({field, form: {setFieldValue, errors, touched}, options, margin, label, defaultValue, schema}: IProps) => {
    const fieldError = getNestedValue(errors, field.name)
    const fieldTouched = getNestedValue(touched, field.name)

    const changeValue = useCallback((value: string | number | boolean) => {
        setFieldValue(field.name, value);
    }, [])

    return (
        <Flex margin={margin} width="100%" direction="column" align="flex-start">
            {label && <BaseLabel>{getCorrectFieldLabel(label, schema, field.name)}</BaseLabel>}
            <Select
                options={options}
                value={field.value}
                onChange={changeValue}
                defaultValue={defaultValue}
            />
            {fieldError && fieldTouched && <Typography color={COLORS.red} margin="5px 0" size="12px">{fieldError}</Typography>}
        </Flex>
    )
}

export default FormikSelect;