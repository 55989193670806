import { useState } from 'react';

export const useErrorHandler = () => {
    const [active, setActive] = useState(false)
    const [hidden, setHidden] = useState(true)
    const [text, setText] = useState('')

    const show = (err: any, time = 3000) => {
        if (!hidden) return
        setHidden(false)
        setTimeout(() => setActive(true), 500)

        let message = ''
        if (err && err.data && err.status !== 500 && err.status !== 404) {
            if (err.data.code === 'user_inactive') {
                message += 'Ваша учетная запись заблокирована, обратитесь к администратору'
            }
            else {
                message += Object.keys(err.data).reduce((acum, item) => acum + err.data[item] + '\n', '')
            }
        } else if (err?.status === 404) {
            message += 'Объект не найден...'
        }
        else {
            message += 'Что-то пошло не так...'
        }

        setText(message[0].toUpperCase() + message.slice(1, message.length))
        setTimeout(() => {
            setActive(false)
            setTimeout(() => {
                setText('')
                setHidden(true)
            }, 500)
        }, time)
    }
    return { active, hidden, text, show }
}