import React, {useEffect, useState} from "react";
import {Container, ErrorMessage, FormWrapper, LoginContainer} from "./style";
import loginBanner from "../../assets/images/loginBanner.png";
import {Typography} from "../../components/common/Styled/Typography";
import {COLORS} from "../../utils/constants/colors";
import Auth from "./Steps/Auth";
import Modal from "../../components/Modal";
import RecoveryPopup from "./RecoveryPopup";
import qs from "query-string";
import {RouteComponentProps} from "react-router-dom";
import PasswordRecovery from "./Steps/PasswordRecovery";
import PasswordCreation from "./Steps/PasswordCreation";
import Agreement from "./Steps/Agreement";

const Login = ({location}: RouteComponentProps) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [step, setStep] = useState<'login' | 'recovery' | 'create' | 'agreement'>('login');
    const [isRecoveryOpen, toggleRecovery] = useState(false)

    const {uid, token} = qs.parse(location.search)

    useEffect(() => {
        setErrorMessage('');
    }, [step])

    useEffect(() => {
        if (uid && token) {
            setStep('recovery')
        }
    }, [uid, token])


    const getForm = () => {
        switch (step) {
            case "login":
            default:
                return <Auth
                    changeStep={setStep}
                    openRecovery={() => toggleRecovery(true)}
                    setError={setErrorMessage}
                />
            case "recovery":
                return <PasswordRecovery changeStep={setStep} uid={uid as string} token={token as string}  />
            case "create":
                return <PasswordCreation changeStep={setStep} />
        }
    }

    return (
        <>
            {
                step === 'agreement'
                    ? <Agreement />
                    : (
                        <Container>
                            <div>
                                <img src={loginBanner} alt="banner" />
                            </div>
                            <LoginContainer padding="20px" direction="column">
                                {errorMessage && (
                                    <ErrorMessage>
                                        <Typography
                                            color={COLORS.red}
                                            size="24px"
                                            fontFamily="Montserrat"
                                        >
                                            {errorMessage}
                                        </Typography>
                                    </ErrorMessage>
                                )}
                                <FormWrapper>
                                    {getForm()}
                                </FormWrapper>
                            </LoginContainer>
                        </Container>
                    )
            }
            {isRecoveryOpen &&
                <Modal
                    haveCloseBtn={true}
                    component={RecoveryPopup}
                    closeModal={() => toggleRecovery(false)}
                    parameters={{changeStep: setStep}}
                />
            }
        </>
    )
}

export default Login;