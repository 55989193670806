import React from 'react';

export const useAdaptive = <T extends HTMLElement = HTMLElement>(ref: React.RefObject<T>) : (coefficient? : number) => void => {
    const giveStyle = (width: string, position: string) => {
        if(ref.current) {
            if(window.innerWidth > 350) {
                ref.current.style.width = width
            }
            ref.current.style.left = position
        }
    }
    const givePosition = (position: string) => {
        if(ref.current) {
            ref.current.style.left = position
        }
    }
    const resize = (coefficient = 0) => {
        if(ref.current) {
            ref.current.getBoundingClientRect().x < 0 && givePosition(`${Math.abs(ref.current.getBoundingClientRect().x)}px`)
            ref.current.getBoundingClientRect().right - ref.current.getBoundingClientRect().left >= window.innerWidth && giveStyle(`${window.innerWidth - 10}px`, `${Math.abs(ref.current.getBoundingClientRect().x)}`)
            ref.current.getBoundingClientRect().right >= window.innerWidth && givePosition(`-${ref.current.getBoundingClientRect().right - window.innerWidth + 20 + coefficient}px`)
        }
    }

    return resize
};
