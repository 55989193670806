import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { BodyWrapper } from '../../../../components/Modal/style'
import { COLORS } from '../../../../utils/constants/colors'

const PageWrapper = styled(Flex)`
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
    &::-webkit-scrollbar {
        width: 0;
    }
    @media (max-width: 1000px) {
        padding: 20px 17% 100px 17%;
        height: calc(100vh - 100px);
    }
    @media (max-width: 800px) {
        padding: 20px 15px 100px 15px;
    }
    @media (max-width: 500px) {
        ${BodyWrapper} {
            width: auto!important;
        }
    }
`

const Info = styled.div`
    width: 100%;
    padding: 25px 0 35px 0;
    border-bottom: 1px solid ${COLORS.brown};
    & div > p {
            word-wrap: break-word;
            width: 50%;
    }
`

const Description = styled.div`
    width: 100%;
    padding: 30px 0 35px 0;
    border-bottom: 1px solid ${COLORS.brown};

    svg {
        width: 30px;
        height: 20px;
        user-select: none;
    }
`

const DescriptionItem = styled(Flex)`
    position: relative;
    svg {
        cursor: default;
    }
    @media (max-width: 516px) {
        p{
            width: 100px;
            white-space: nowrap;
        }
    }
    @media (max-width: 460px) {
        margin: 0 50px 0 0;
        p{
            width: 50px;
            white-space: nowrap;
        }
    }
    @media (max-width: 367px) {
        margin: 0 50px 0 0;
        p{
            width: 50px;
            white-space: normal;
        }
    }

    sup {
        position: absolute;
        top: -4px;
    }
`

const Way = styled.div`
    width: 100%;
    padding: 30px 0 35px 0;
    border-bottom: 1px solid ${COLORS.brown};
`

const Road = styled.div`
    margin: 20px 0 0 0;
    white-space: nowrap;
    overflow-x: auto;
`

const RoadImg = styled.div`
    display: inline-block;
    width: 140px;
    height: 100px;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 6px;
    }
`

const ImgWrapper = styled(Flex)`
    width: 100%;
    height: 520px;
    border-radius: 12px;
    background: transparent;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 12px;
    }

    @media (max-width: 800px) {
        height: 400px;
    }
`


export { Info, Description, Way, ImgWrapper, RoadImg, PageWrapper, Road, DescriptionItem }