import React, { useState } from 'react'
import { Flex } from '../../../components/common/Styled/Flex'
import { Tab } from '../../../components/common/Styled/Tab'
import Cities from './Cities'
import Metros from './Metros'
import PriceCategory from './PriceCategory'
import { SettingWrapper } from './style'
import Subscriptions from './Subscriptions'

function Settings() {
    const [page, setPage] = useState(1 as 1 | 2 | 3 | 4)
    const tabs = [
        { id: 1, text: 'Города', active: page === 1, callback: () => setPage(1) },
        { id: 2, text: 'Метро', active: page === 2, callback: () => setPage(2) },
        { id: 3, text: 'Ценовые категории', active: page === 3, callback: () => setPage(3) },
        { id: 4, text: 'Абонементы', active: page === 4, callback: () => setPage(4) },
    ]
    return (
        <SettingWrapper align="flex-start" padding="40px 60px 40px 60px" direction="column">
            <Flex>
                {tabs.map(item =>
                    <Tab
                        margin="0 35px 0 0"
                        weight="600"
                        size="15px"
                        lineHeight="24px"
                        key={item.id}
                        active={item.active}
                        onClick={item.callback}
                    >
                        {item.text}
                    </Tab>
                )}
            </Flex>
            {page === 1 && <Cities />}
            {page === 2 && <Metros />}
            {page === 3 && <PriceCategory />}
            {page === 4 && <Subscriptions />}
        </SettingWrapper>
    )
}

export default Settings
