import styled from 'styled-components'
import { Typography } from '../../../../components/common/Styled/Typography'
import { COLORS } from '../../../../utils/constants/colors'

const List = styled.div`
    padding-left: 12px;
    ${Typography} {
        position: relative;

        span {
            position: relative;
            sup {
                position: absolute;
                top: -2px;
                right: -6px;
            }
        }
    }

    ${Typography}:before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: ${COLORS.steel};
        left: -12px;
        top: 5px;

    }

`

const CabinetPreviewWrapper = styled.div<{show : boolean}>`
    width: 100%;
    min-height: 50px;
    max-height: ${p => p.show ? 'auto' : '50px'};
    background: ${p => p.show ? COLORS.cream : COLORS.logout};
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    transition: max-height .2s linear,
                background .1s linear;
    padding: 13px 10px 13px 15px;
    overflow: hidden;
    cursor: pointer;
    margin-top: 5px;

    &:hover {
        background: ${p => p.show ? COLORS.cream : COLORS.cream}
    }
`

const Title = styled(Typography)<{show : boolean}>`
    text-overflow: ${p => p.show ? 'none' : 'ellipsis'};
    width: calc(100% - 20px);
    white-space: ${p => p.show ? 'wrap' : 'nowrap'};
    overflow: hidden; 
`

const PhotoWrapper = styled.div`
    min-width: 125px;
    min-height: 115px;
    width: 125px;
    height: 115px;
    border-radius: 9px;
    background: #C4C4C4;
    margin: 0 30px 0 0;

    img {
        width: 100%;
        height: 100%;
        border-radius: 9px;
        object-fit: fill;
    }
    @media (max-width: 1350px) {
        min-width: 110px;
        min-height: 100px;
        width: 110px;
        height: 100px;
        margin: 0 15px 0 0;
    }

    @media (max-width: 1200px) {
        min-width: 90px;
        min-height: 85px;
        width: 90px;
        height: 85px;
    }

    @media (max-width: 1000px) {
        width: 125px;
        height: 115px;
    }
`

export { CabinetPreviewWrapper, List, PhotoWrapper, Title }