import { Formik, Form, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormikInput from '../../../../../components/common/FormikElements/Input';
import FormikSelect from '../../../../../components/common/FormikElements/Select';
import { Button } from '../../../../../components/common/Styled/Button';
import { Flex } from '../../../../../components/common/Styled/Flex';
import { Typography } from '../../../../../components/common/Styled/Typography';
import ErrorHandler from '../../../../../components/Error';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler';
import { OptionType, VoidFuncType } from '../../../../../types/common';
import { addMetro, getSelectParams } from '../../../../../utils/api/routes';
import { REQUIRED_STRING } from '../../../../../utils/formik/validation';
import { showAlert } from '../../../../../utils/showAlert';
import { AddMetroPopupWrapper } from './style';

interface IProps {
    closeModal: VoidFuncType
    updateComponent: (city: number | null, search: string) => void
}

const AddMetroPopup = ({ closeModal, updateComponent }: IProps) => {
    const [cities, setCities] = useState<Array<OptionType>>([])
    const { show, active, hidden, text } = useErrorHandler()

    useEffect(() => {
        getSelectParams('cities').then(res => setCities(res.data.map(item => ({ value: item.id, name: item.name }))))
    }, [])

    const initial = {
        name: '',
        city: ''
    }

    const schema = Yup.object({
        name: REQUIRED_STRING,
        city: REQUIRED_STRING
    })

    return (
        <AddMetroPopupWrapper>
            <ErrorHandler active={active} hidden={hidden} text={text} />
            <Flex padding="10px 0" border="1px solid #DECEB7">
                <Typography weight="700" size="18px" lineHeight="21px">Добавить метро</Typography>
            </Flex>
            <Flex padding="20px">
                <Formik
                    initialValues={initial}
                    onSubmit={(values) =>
                        addMetro({ ...values, city: +values.city })
                            .then(() => {
                                closeModal()
                                updateComponent(null, '')
                                showAlert('success', 'Метро успешно добавлено')
                            })
                            .catch((e) => {
                                updateComponent(null, '')
                                showAlert('error', 'Не удалось добавить метро')
                            })
                    }
                    validationSchema={schema}
                >
                    <Form>
                        <Flex direction="column">
                            <Field name="name" label="Метро" direction="column" placeholder="Метро" component={FormikInput} />
                            <Field name="city" margin="10px 0 0 0" label="Город" options={cities} component={FormikSelect} />
                        </Flex>
                        <Flex margin="20px 0 0 0" justify="flex-end">
                            <Button
                                type="submit"
                                theme="grey"
                                width="155px"
                                height="40px"
                                radius="21px"
                                weight="600"
                                lineHeight="17px"
                            >
                                Добавить
                            </Button>
                        </Flex>
                    </Form>
                </Formik>
            </Flex>
        </AddMetroPopupWrapper>
    );
};

export default AddMetroPopup;