import styled from 'styled-components'
import { Typography } from '../../../../components/common/Styled/Typography'
import { COLORS } from '../../../../utils/constants/colors'

const BookingItem = styled.div<{active: boolean, status: 1 | 2 | 3}>`
    position: relative;
    width: 100%;
    /* min-height: 60px; */
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    background: ${p => p.active ? COLORS.cream : COLORS.logout};
    transition: background .2s linear;
    cursor: pointer;
    padding: 10px 15px;
    margin-bottom: 5px;

    &:hover {
        background: ${COLORS.cream};
    }

    &::before {
        content: '';
        position: absolute;
        right: 5px;
        top: 5px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: ${p => p.status === 1 ? COLORS.green : p.status === 2 ? COLORS.orange : COLORS.red}
    }
`

const OfficeInfo = styled(Typography)`
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow:hidden; 
`

export { BookingItem, OfficeInfo }