import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'
import { Grid } from '../Grid'
import { Typography } from '../Typography'

const CeilHeader = styled.div<{borderRadius?: 'first' | 'last'}>`
    background: ${COLORS.brown};
    border: 1px solid #C6B8A6;
    ${({borderRadius}) => borderRadius !== 'first' && 'border-left: none;'};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius === 'first' ? '4px 0px 0px 0px' : '0px 4px 0px 0px' : '0'};
    height: 40px;
    width: 100%;
    padding: 10px;
`

const Ceil = styled.div<{borderRadius?: 'first' | 'last', isLastRow?: boolean}>`
    border: 1px solid #C6B8A6;
    border-top: none;
    ${({borderRadius}) => borderRadius !== 'first' && 'border-left: none;'};
    border-radius: ${({borderRadius, isLastRow}) => isLastRow && borderRadius ? borderRadius === 'first' ? '0px 0px 0px 4px' : '0px 0px 4px 0px' : '0'};
    height: 40px;
    width: 100%;
    padding: 10px;
    background: ${COLORS.white};
`

const Table = styled(Grid)`
    ${Typography} {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow:hidden; 
    }
`

export {CeilHeader, Ceil, Table}