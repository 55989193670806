import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { COLORS } from '../../../../utils/constants/colors'

const AddNotificationPopupWrapper = styled.div`
    width: 500px;
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    background: ${COLORS.cream};

    textarea, input {
        background: ${COLORS.white};
    }
`

const PhotoWrapper = styled(Flex)`
    padding: 5px 0;
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden!important;
    

    ::-webkit-scrollbar {
        height: 8px;
    }
`

const Photo = styled.div`
    position: relative;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    border: 1px solid #DECEB7;
    border-radius: 6px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }
`

const Close = styled(Flex)`
    position: absolute;
    width: 16px;
    height: 16px;
    background: ${COLORS.red};
    border-radius: 50%;
    top: -5px;
    right: -5px;
    cursor: pointer;
    svg {
        width: 8px;
        height: 8px;
        transition: transform .2s linear;
    }

    path {
        fill: ${COLORS.white}
    }

    &:hover {
        svg {
            transform: rotate(180deg);
        }
    }
`

export {AddNotificationPopupWrapper, PhotoWrapper, Photo, Close}