import moment from "moment"

export const searchAllWeekDaysOnMonth = (startDate: string, weekDayNumber: number) => {

    const firstDay = moment(startDate, 'DD.MM.YYYY').startOf('month').weekday(weekDayNumber)
    if (!firstDay.isSame(moment(startDate, 'DD.MM.YYYY'), 'month')) {
        firstDay.add(1, 'week')
    }
    const disabledDate = Array.from({ length: 5 }).map((_, index) => {
        return firstDay.clone().add(7 * index, 'days').toDate()
    }).filter(item => moment(item).isSame(moment(startDate, 'DD.MM.YYYY'), 'month'))
    return disabledDate
}

export const momentWeekDayToWeekDay = (weekDay: number) => {
    return weekDay + 1
}
export const weekDayToMomentWeekDay = (weekDay: number) => {
    return weekDay - 1 === -1 ? 6 : weekDay - 1
}

export const timeToNumber = (time: string) => +time.split(':')[0] + (+time.split(':')[1] === 30 ? 0.5 : 0)

const convert24 = (num: number) => num == 24 ? '00' : num
const addZero = (num: number) => num < 10 ? `0${num}` : convert24(num)
export const numberToTime = (time: number) => Number.isInteger(+time) ? `${addZero(time)}:00:00` : `${addZero(Math.floor(time))}:30:00`