import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from '../../../components/common/FormElements/Select'
import { Button } from '../../../components/common/Styled/Button'
import { Flex } from '../../../components/common/Styled/Flex'
import { Tab } from '../../../components/common/Styled/Tab'
import Modal from '../../../components/Modal'
import { usePagination } from '../../../hooks/usePagination'
import { RootState } from '../../../store/reducers/rootReducer'
import { OptionType, VoidFuncType } from '../../../types/common'
import { INotification } from '../../../types/interfaces/notification'
import { IOffice } from '../../../types/interfaces/office'
import { getAllOffices, getNotifications, getReports } from '../../../utils/api/routes'
import { COLORS } from '../../../utils/constants/colors'
import AddNotificationPopup from './AddNotificationPopup'
import NotificationCard from './NotificationCard'
import ReportCard from './ReportsCard'
import { NotificationWrapper } from './style'


function AdminNotifications() {
    const {profile} = useSelector((state: RootState) => state.user)
    const [showModal, setShowModal] = useState(false)
    const [notifications, setNotifications] = useState<Array<INotification>>([])
    const [reports, setReports] = useState<Array<any>>([])
    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState(1 as 1 | 2)
    const {scroll, fetching, successCallback, finalyCallback, currentPage, resetCallback} = usePagination(totalCount === (page === 1 ? notifications.length : reports.length))
    const [offices, setOffices] = useState<Array<IOffice>>([])
    const [office, setOffice] = useState<number | null>(null)
    const [totalOfficesCount, setTotalOfficesCount] = useState(0)
    const [currentOfficesPage, setCurrentOfficesPage] = useState(2)

    useEffect(() => {
        if(page === 2) {
            setCurrentOfficesPage(2)
            getAllOffices(1, 15, `${!profile.is_superuser && !profile.cabinets_all ? `&user=${profile.id}` : ''}`)
                .then(res => {
                    setOffices(res.data.results)
                    setTotalOfficesCount(res.data.count)
                })
        }
    }, [page])


    const request = (finalyCallback: VoidFuncType) => {
        getAllOffices(currentOfficesPage, 15, `${!profile.is_superuser && !profile.cabinets_all ? `&user=${profile.id}` : ''}`)
            .then(res => {
                setOffices([...offices, ...res.data.results])
                setCurrentOfficesPage(prevState => prevState + 1)
            })
            .finally(() => finalyCallback())
    }
    const getOptions = () => {
        let optionsArr = [{value: -1, name: 'Все'}] as Array<OptionType>
        if(offices.length) {
            optionsArr = [...optionsArr,...offices.map(item => ({value: item.id, name: `${item.name} кабинет, ${item.address}`}))]
        }

        return optionsArr
    }

    const updateComponent = () => {
        resetCallback()
        page === 1 ? 
        getNotifications(`?page=1&page_size=10`).then(res => {
            setNotifications(res.data.results)
            setTotalCount(res.data.count)
            successCallback()
        })
        :
        getReports(`?page=1&page_size=10&office=${office && office > 0 ? office : ''}`).then(res => {
            setReports(res.data.results)
            setTotalCount(res.data.count)
            successCallback()
        })
    }

    useEffect(() => {
        updateComponent()
    }, [page, office])

    useEffect(() => {
        if(fetching) {
            page === 1 ? 
            getNotifications(`?page=${currentPage}&page_size=10`).then(res => {
                setNotifications([...notifications, ...res.data.results])
                successCallback()
            }).finally(finalyCallback)
            :
            getReports(`?page=${currentPage}&page_size=10&office=${office && office > 0 ? office : ''}`).then(res => {
                setReports([...reports, ...res.data.results])
                successCallback()
            }).finally(finalyCallback)
        }
    }, [fetching])

    return (
        <Flex width="100%" direction="column" padding="100px 0 0 0">
            <Flex padding="0 60px" width="100%" justify="space-between" >
                <Flex>
                    <Tab 
                        onClick={() => setPage(1)} 
                        active={page === 1}
                        color={COLORS.steel}
                        weight="600"
                        size="16px"
                        lineHeight="24px"
                        margin="0 35px 0 0"
                        fontFamily="Montserrat"
                    >
                        Уведомления
                    </Tab>
                    <Tab 
                        onClick={() => setPage(2)} 
                        active={page === 2}
                        color={COLORS.steel}
                        weight="600"
                        size="16px"
                        lineHeight="24px"
                        margin="0 35px 0 0"
                        fontFamily="Montserrat"
                    >
                        Жалобы
                    </Tab>
                </Flex>
                {page === 1 ? 
                    <Button
                        theme="grey"
                        width="217px"
                        height="36px"
                        radius="6px"
                        weight="600"
                        lineHeight="17px"
                        onClick={() => setShowModal(true)}
                    >
                        Создать уведомление
                    </Button>
                    :
                    <Flex width="300px">
                        <Select 
                            options={getOptions()}
                            onChange={(value) => setOffice(value as number)}
                            value={office || ''}
                            totalCount={totalOfficesCount}
                            request={request}
                            currentLength={offices.length}
                            defaultValue="Выберите"
                        />  
                    </Flex>
                }
            </Flex>
            <NotificationWrapper onScroll={scroll} justify="flex-start" width="100%" direction="column" margin="25px 0 0 0" padding="0 220px">
                {page === 1 && notifications.map(item => 
                    <NotificationCard key={item.id} data={item} />
                )}
                {page === 2 && reports.map(item => 
                    <ReportCard key={item.id} updateComponent={updateComponent} data={item}/>      
                )}
            </NotificationWrapper>
            {showModal && 
                <Modal 
                    haveCloseBtn={false}
                    component={AddNotificationPopup}
                    closeModal={() => setShowModal(false)}
                    parameters={{
                        updateComponent
                    }}
                />
            }
        </Flex>
    )
}

export default AdminNotifications
