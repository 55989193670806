import { formatPhoneNumber } from './formatPhoneNumber';
import moment from 'moment'
import * as Yup from 'yup'
import { IBooking } from '../../types/interfaces/booking'

export const validationMessages = {
    required: 'Обязательное поле',
    numeric: 'Введите число',
    email: 'Неверный email',
    phone: 'Неверный номер телефона',
    min6: 'Минимальная длина 6 символов',
    max128: 'Максимальная длина 128 символов',
    max1500: 'Максимальная длина 1500 символов',
    initials: 'Неверный формат имени',
    link: 'Ссылка не соответсвует формату',
}

export const REQUIRED_STRING = Yup.string().required(validationMessages.required)
export const RQUIRED_NUMBER = Yup.number().required(validationMessages.required)
export const REQUIRED_POSITIVE_NUMBER = Yup.number().required(validationMessages.required).min(0, 'Значение не может быть отрицательным')

export const EMAIL = Yup.string()
    .required(validationMessages.required)
    .test('email', validationMessages.email, value =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value as string)
    )

export const REQUIRED_CHECKBOX = (name: string) =>
    Yup.boolean().test(name, validationMessages.required, value => !!value)

export const REGEXP_TEST = (name: string, regexp: RegExp, message: string) =>
    Yup.string()
        .required(validationMessages.required)
        .test(name, message, value => regexp.test(value as string))

export const ONLY_NUMBERS = Yup.number()
    .required(validationMessages.required)
    .typeError(validationMessages.numeric)

export const PHONE = Yup.string()
    .required(validationMessages.required)
    .test('mobile', validationMessages.phone, value =>
        /^(\+7)\(\d{3}\)(\d{3})(-\d{2}){2}$/.test(formatPhoneNumber(value as string))
    )

export const INITIALS = Yup.string()
    .required(validationMessages.required)
    .test('initials', validationMessages.initials, value =>
        /^([а-яА-Я]{1,}|[a-zA-Z]{1,})\s([а-яА-Я]{1,}|[a-zA-Z]{1,})(\s(\D){0,}|\D)$/.test(value as string),
    )

export const linkValidation = (domen: string) => Yup.string()
    .test('link', validationMessages.link, (value) =>
        !value ? true : new RegExp(`^(https|http):\/\/(${domen})\/.{1,}$`).test(value as string)
    )

export const CONFIRM = Yup.string()
    .required('Повторный пароль не введен')
    .oneOf([Yup.ref('new_password'), Yup.ref('password'), null], 'Пароли не совпадают')

export const PASSWORD = Yup.string()
    .required(validationMessages.required)
    .min(6, validationMessages.min6)
    .max(128, validationMessages.max128)

export const bookingStarted = (booking: IBooking) => {
    if (moment(booking.date, 'YYYY-MM-DD').isSame(moment(), 'day') &&
        (
            moment(booking.before_hours, 'HH:mm:ss').isSame(moment(), 'minutes') ||
            moment(booking.before_hours, 'HH:mm:ss').isBefore(moment(), 'minutes')
        )
    ) return false
    return false
}
