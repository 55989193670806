import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/common/Styled/Button'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Typography } from '../../../../components/common/Styled/Typography'
import Modal from '../../../../components/Modal'
import SliderModalComponent from '../../../../components/SliderModal'
import { INotification } from '../../../../types/interfaces/notification'
import { getUsersOfNotification } from '../../../../utils/api/routes'
import { COLORS } from '../../../../utils/constants/colors'
import { NotificationCardWrapper, Photo, PhotoWrapper } from './style'

function NotificationCard({data} : {data: INotification}) {
    const [listOpen, setListOpen] = useState(false)
    const [users, setUsers] = useState<Array<{get_full_name: string}>>([])
    const [showModal, setShowModal] = useState(false)
    useEffect(() => {
        getUsersOfNotification({recipient_list: data.recipient_list}).then(res => setUsers(res.data.users))
    }, [data])
    
    return (
        <NotificationCardWrapper>
            <Flex justify="flex-end" width="100%">
                <Typography color={COLORS.steel} fontFamily="Montserrat" size="12px" weight="500" lineHeight="15px">
                    {moment(data.date).format('DD.MM.YYYY HH:mm')}
                </Typography>
            </Flex>
            <Flex align="flex-start" margin="16px 0 0 0" padding="0 10px 16px 10px " width="100%"  direction="column">
                {!!data.images.length &&
                    <PhotoWrapper justify="flex-start" gap="10px">
                            {data.images.map(item => 
                                <Photo onClick={() => setShowModal(true)} key={item.id}>
                                    <img src={item.image} alt="Фото уведомления" />
                                </Photo>
                            )}
                    </PhotoWrapper>
                }
                <Typography margin="16px 0 0 0" fontFamily="Montserrat" weight="700" size="16px" lineHeight="20px">
                    {data.title}
                </Typography>
                <Typography margin="16px 0 0 0" fontFamily="Montserrat" size="16px" lineHeight="20px">
                    {data.text}
                </Typography>
                <Flex margin="22px 0 0 0" width="100%" justify="space-between">
                    <Typography
                        weight="700"
                        size="18px"
                        lineHeight="21px"
                    >
                        Список получателей
                    </Typography>
                    <Button onClick={() => setListOpen(!listOpen)}>
                        <Typography size="16px" lineHeight="19px" decoration="underline">{listOpen ? 'Свернуть' : 'Развернуть'}</Typography>
                    </Button>
                </Flex>
                {listOpen && 
                    <Flex align="flex-start" direction="column">
                        {users.map((item, index) => 
                            <Typography key={index} margin="16px 0 0 0" fontFamily="Montserrat" size="16px" lineHeight="20px">
                                {item.get_full_name}
                            </Typography>
                        )}
                    </Flex>         
                }
            </Flex>
            {showModal && 
                    <Modal 
                        component={() => <SliderModalComponent alt="Фото уведомления" arr={data.images.map(item => ({id: item.id, photo: item.image}))}/>}
                        closeModal={() => setShowModal(false)}
                        sliderModal
                    />
            }
        </NotificationCardWrapper>
    )
}

export default NotificationCard
