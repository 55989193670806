import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'

export const StyledTextArea = styled.textarea<{height?: string}>`
    font-family: Montserrat, sans-serif;
    width: 100%;
    height: ${p => p.height ? p.height : 'auto'};
    font-size: 16px;
    background: none;
    border: 1px solid ${COLORS.brown};
    border-radius: 6px;
    line-height: 20px;
    padding: 8px 10px;
    resize: none;
`