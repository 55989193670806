import styled from 'styled-components'
import { Flex } from '../../../components/common/Styled/Flex'
import { COLORS } from '../../../utils/constants/colors'

const Header = styled(Flex)`
    input {
        width: 300px;
        background: ${COLORS.white};
    }
`

const BookingsWrapper = styled.div`
    position: relative;
    border-radius: 4px;
    border: 1px solid #C6B8A6;
    background: ${COLORS.white};
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
        height: 8px;
    }
`

const CalendarWrapper = styled.div<{show: boolean}>`
    position: fixed;
    top: 0;
    max-height: 100vh;
    height: 100vh;
    overflow-y: auto;
    left: 100px;
    right: 0;
    z-index: 9999;
    box-shadow: 0px 0px 30px rgb(198 184 166 / 70%);
    background: ${COLORS.cream};
    border-radius: 40px 0 0 40px;
    transition: transform .2s linear;
    transform: ${({show}) => show ? 'translateY(0)' : 'translateY(100%)'};
    padding: 0 0 20px 0;
`

export {Header, BookingsWrapper, CalendarWrapper}