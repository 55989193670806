import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'

const CabinetsPreviewWrapper = styled.div`
    width: 100%;
    height: 100%;
    min-height: 250px;
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 10px;
    background: ${COLORS.cream};
    transition: background .2s linear;
    cursor: pointer;

    &:hover {
        background: ${COLORS.beige};
    }

    & .slick-dots {
        bottom: 0;
    }

`

const ArrowWrapper = styled.div<{isPrev?: boolean}>`
    height: 100%;
    display: flex!important;
    align-items: center;
    padding: 0 2px;
    width: auto!important;
    border-radius: ${({isPrev}) => isPrev ? '10px 0 0 0' : '0 10px 0 0 '};
    z-index: 9;
    transition: background .1s linear;
    &.slick-arrow {
        ${({isPrev}) => isPrev ? 'left: 0;' : 'right: 0;'};

        &:hover {
            background: ${COLORS.steel}!important;
        }

        &::before {
            content: '';
        }
    }

    svg {
        width: 20px;
        height: 20px;
    }


`

const PhotoWrapper = styled.div`
    width: 100%;
    height: 190px;
    border-radius: 10px 10px 0px 0px;
    background: #C4C4C4;
    img{
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0px 0px;
        object-fit: cover;
    }
`

export { CabinetsPreviewWrapper, PhotoWrapper, ArrowWrapper }