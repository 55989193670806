import styled from 'styled-components';
import { Flex } from '../../components/common/Styled/Flex';
import { Tab } from '../../components/common/Styled/Tab';
import { PaginationWrapper } from '../../hocs/Pagination.hoc';
import { COLORS } from '../../utils/constants/colors';

const TabWrapper = styled(Flex)`
    width: 100%;
    min-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        height: 5px;
    }

    @media (max-height: 950px) and (min-width: 1000px) {
        margin: 0 0 20px 0;
    }

    ${Tab} {   
        @media (max-width: 489px) {
            margin: 0 15px 0 0;
        }
    }
`

const Container = styled.div<{width: string}>`
    position: relative;
    width: ${p => p.width};
    max-height: 800px;
    background: ${COLORS.cream};
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
`

const MainWrapper = styled(Flex)<{showCalendar: boolean}>`
    height: 100vh;
    justify-content: flex-start;
    ${({showCalendar}) => showCalendar && 'padding: 0;'};
    @media (max-width: 550px) {
        padding: 35px 30px 0 30px;
        ${({showCalendar}) => showCalendar && 'padding: 0;'}
    }
    @media (max-width: 519px) {
        padding: 20px 15px 0 15px;
        ${({showCalendar}) => showCalendar && 'padding: 0;'}
    }
    @media (max-height: 950px) and (min-width: 1000px) {
        padding: 35px 60px 0 60px;
    }

    ${PaginationWrapper} {
        @media (max-width: 1000px) {
            bottom: 120px;
            right: 40px;
        }
    }
`

const ArrowBack = styled(Flex)`
    position: fixed;
    top: 0;
    left: 0;
`

export {Container, MainWrapper, TabWrapper, ArrowBack}