import { Formik, Form, Field } from 'formik'
import React from 'react'
import FormikInput from '../../../../../components/common/FormikElements/Input'
import { Button } from '../../../../../components/common/Styled/Button'
import { Flex } from '../../../../../components/common/Styled/Flex'
import { Typography } from '../../../../../components/common/Styled/Typography'
import { VoidFuncType } from '../../../../../types/common'
import { addCity } from '../../../../../utils/api/routes'
import { CityPopup } from './style'
import * as Yup from 'yup'
import { REQUIRED_STRING } from '../../../../../utils/formik/validation'
import ErrorHandler from '../../../../../components/Error'
import { useErrorHandler } from '../../../../../hooks/useErrorHandler'

interface IProps {
    closeModal: VoidFuncType,
    updateComponent: (arg: string) => void
}

function AddCityPopup({closeModal, updateComponent}: IProps) {
    const {show, active, hidden, text} = useErrorHandler()

    const initial = {
        name: ''
    }

    const schema = Yup.object({
        name: REQUIRED_STRING,

    })

    return (
        <CityPopup>
            <ErrorHandler active={active} hidden={hidden} text={text} />
            <Flex padding="10px 0" border="1px solid #DECEB7">
                <Typography weight="700" size="18px" lineHeight="21px">Добавить город</Typography>
            </Flex>
            <Flex padding="20px">
                <Formik
                    initialValues={initial}
                    onSubmit={(values) => addCity(values).then(() => {
                            closeModal()
                            updateComponent('')
                        }).catch((e) => show(e))
                    }
                    validationSchema={schema}
                >
                    <Form>
                        <Field name="name" label="Город" direction="column"  placeholder="Город" component={FormikInput} schema={schema}/>
                        <Flex margin="20px 0 0 0" justify="flex-end">
                            <Button
                                type="submit"
                                theme="grey"
                                width="155px"
                                height="40px"
                                radius="21px"
                                weight="600"
                                lineHeight="17px"
                            >
                                Добавить
                            </Button>
                        </Flex>
                    </Form>
                </Formik>
            </Flex>
        </CityPopup>
    )
}

export default AddCityPopup
