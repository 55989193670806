import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'

interface IProps {
    active?: boolean
}

export const FilterWrapper = styled.div<IProps>`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 3px 0;
    border-radius: 17px;
    background: ${p => p.active ? COLORS.lightBrown :'rgba(36, 36, 36, 0.06)'};
    border: 1px solid ${COLORS.lightBrown};
    transition: background .2s linear;
    margin: 0 0 10px 5px;
    cursor: pointer;
    white-space: nowrap;
    width: auto;

    &:hover {
        background: ${p => p.active ? '#93846F' :'rgba(25, 25, 25, 0.1)'};
    }

    @media(max-width: 350px) {
        padding: 0;
    }
`