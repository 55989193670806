import styled, {keyframes} from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'
import { FilterWrapper } from '../../../common/Styled/Filters';
const open = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const MetroWrapper = styled(FilterWrapper)`
    width: 150px;
`

const List = styled.ul<{active: boolean}>`
    position: absolute;
    background: ${COLORS.white};
    min-width: 100%;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 99;
    top: 120%;
    /* left: -100px; */
    transform: translateX(50%);
    animation: ${open} .2s linear;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 6px;
    }
`

const ListItem = styled.li`
    display: flex;
    align-items: center;
    padding: 5px 10px;
    z-index: 100;
    width: 100%;
    min-height: 35px;
    border-bottom: 1px solid ${COLORS.brown};
    .metros-checkbox{
        margin-right: 10px;
    }

    & > * {
        display: block;
        pointer-events: none;
    }

    &:hover {
        background: ${COLORS.beige};
    }
`

export {List, ListItem, MetroWrapper}

