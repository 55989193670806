import React from 'react'
import { HouseIcon, CityIcon, MapIcon, SubwayIcon } from '../../../../assets/icons'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Typography } from '../../../../components/common/Styled/Typography'
import { SetStateType } from '../../../../types/common'
import { IOffice } from '../../../../types/interfaces/office'
import { CabinetCardWrapper } from './style'

interface IProps {
    office: IOffice,
    setActiveOffice: SetStateType<IOffice | null>,
    activeOffice?: number | null
}

const CbinetCard = ({ office, setActiveOffice, activeOffice }: IProps) => {
    return (
        <CabinetCardWrapper onClick={() => setActiveOffice(office)} active={office.id === activeOffice} status={office.status} padding="15px" align="flex-start" direction="column">
            <Flex margin="0 0 5px 0">
                <HouseIcon />
                <Typography size="16px" margin="0 0 0 10px" lineHeight="19px">{office.name}</Typography>
            </Flex>
            <Flex margin="0 0 5px 0">
                <CityIcon />
                <Typography size="16px" margin="0 0 0 10px" lineHeight="19px">{office.city.name}</Typography>
            </Flex>
            <Flex margin="0 0 5px 0" justify='flex-start' align='center'>
                <div style={{ width: '15px', marginRight: '9px' }}>
                    <SubwayIcon />
                </div>
                <div style={{ fontSize: '16px', lineHeight: "19px", width: '100%' }}>
                    {
                        office.metro.map((metro, idx) => `${metro.name}${(office.metro.length > 1 && idx < office.metro.length - 1) ? ', ' : ''}`)
                    }
                </div>
            </Flex>
            <Flex margin="0 0 0 0" justify='flex-start' align='center'>
                <div style={{ width: '15px', marginRight: '7px' }}>
                    <MapIcon />
                </div>
                <Typography size="16px" lineHeight="19px">{office.address}</Typography>
            </Flex>
        </CabinetCardWrapper >
    )
}
export default CbinetCard