import { Field, Formik } from 'formik'
import { useState } from 'react'
import FormikInput from '../../../../components/common/FormikElements/Input'
import { Button } from '../../../../components/common/Styled/Button'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Typography } from '../../../../components/common/Styled/Typography'
import { SetStateType, VoidFuncType } from '../../../../types/common'
import { IUser } from '../../../../types/interfaces/user'
import { COLORS } from '../../../../utils/constants/colors'
import { PopupWrapper, StyledForm, Tab } from '../AddPsyhoPopup/style'
import * as Yup from 'yup'
import { PHONE, EMAIL, INITIALS, PASSWORD, CONFIRM } from '../../../../utils/formik/validation'
import { createUser, updateUser } from '../../../../utils/api/routes'
import ErrorHandler from '../../../../components/Error'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import CloseModalPopup from '../ClosedModalPopup'
import Modal from '../../../../components/Modal'
import { showAlert } from '../../../../utils/showAlert'

interface IProps {
    version: boolean
    closeModal: VoidFuncType
    user?: IUser
    updateUserComponent: (search?: string, page?: number) => void
    showWarraningModal: boolean
    setShowWarrningModal: SetStateType<boolean>
    closeParentModal: VoidFuncType
}

const AddStaffPopup = ({ version, closeModal, user, updateUserComponent, showWarraningModal, setShowWarrningModal, closeParentModal }: IProps) => {
    const { show, active, hidden, text } = useErrorHandler()
    const [rights, setRights] = useState<{ [key: string]: boolean }>({
        cabinets: user ? user.cabinets_all : false,
        psyhologist: user ? user.psychologist_all : false,
        notifications: user ? user.notifications_all : false
    })

    const changeRights = (right: string, value: boolean) => {
        setRights({ ...rights, [right]: value })
    }

    const initial = {
        name: !version && user ? `${user.last_name} ${user.first_name} ${user.middle_name}` : '',
        phone: !version && user ? user.phone : '',
        email: !version && user ? user.email : '',
        password: '',
        ...version && { passwordConfirm: '' }
    }

    const tabs = [
        { id: 1, label: 'Кабинеты', name: 'cabinets' },
        { id: 2, label: 'Психологи', name: 'psyhologist' },
        { id: 3, label: 'Уведомления', name: 'notifications' }
    ] as const

    const schema = Yup.object({
        name: INITIALS,
        phone: PHONE,
        email: EMAIL,
        password: version ? PASSWORD : Yup.string(),
        ...version && { passwordConfirm: CONFIRM }
    })

    const createBody = (values: typeof initial) => {
        const name = values.name.split(' ')
        const { cabinets, psyhologist, notifications } = rights
        return {
            ...values,
            email: values.email.trim().toLowerCase(),
            last_name: name[0].trim(),
            first_name: name[1].trim(),
            middle_name: name.slice(2).join(' ')?.trim() ?? '',
            role: 'staff',
            notifications_all: notifications,
            psychologist_all: psyhologist,
            cabinets_all: cabinets
        }
    }

    const changeStaff = (values: typeof initial) => {
        if (!user) return
        updateUser(createBody(values), `${user.id}`).then(() => {
            closeParentModal()
            updateUserComponent('', 2)
            showAlert('success', 'Информация о модераторе успешно обновлена')
        }).catch((e) => show(e))
    }

    const addStaff = (values: typeof initial) => {
        createUser(createBody(values)).then(() => {
            closeParentModal()
            updateUserComponent('', 2)
            showAlert('success', 'Модератор успешно добавлен')
        }).catch((e) => show(e))
    }

    return (
        <PopupWrapper direction="column">
            <ErrorHandler active={active} hidden={hidden} text={text} />
            <Flex width="100%" padding="10px 0" margin="0 0 25px 0" border="1px solid #DECEB7">
                <Typography weight="700" size="18px" lineHeight="21px">{version ? 'Создание модератора' : 'Редактирование модератора'}</Typography>
            </Flex>
            <Formik
                initialValues={initial}
                onSubmit={(values) => version ? addStaff(values) : changeStaff(values)}
                validationSchema={schema}
            >
                <StyledForm>
                    <Field disabled={!version} direction="column" name="name" component={FormikInput} placeholder="ФИО" label="ФИО" schema={schema} />
                    <Field direction="column" name="phone" component={FormikInput} placeholder="Номер телефона" label="Номер телефона" schema={schema} />
                    <Field direction="column" name="email" component={FormikInput} placeholder="Email" label="Email" schema={schema} />
                    {version && <Field direction="column" name="password" component={FormikInput} placeholder="Пароль" label="Пароль" schema={schema} />}
                    {version && <Field direction="column" name="passwordConfirm" component={FormikInput} placeholder="Подтвердите пароль" label="Подтвердите пароль" schema={schema} />}
                    {tabs.map(item =>
                        <Flex key={item.id} width="100%" justify="space-between">
                            <Typography
                                weight="600"
                                lineHeight="17px"
                                fontFamily="Montserrat"
                            >
                                {item.label}
                            </Typography>
                            <Flex>
                                <Tab onClick={() => changeRights(item.name, false)} active={!rights[item.name]}>
                                    <Typography
                                        weight={!rights[item.name] ? '600' : '400'}
                                        color={!rights[item.name] ? COLORS.white : COLORS.black}
                                        lineHeight="17px"
                                        fontFamily="Montserrat"
                                    >
                                        Только свои
                                    </Typography>
                                </Tab>
                                <Tab onClick={() => changeRights(item.name, true)} isNext active={rights[item.name]}>
                                    <Typography
                                        weight={rights[item.name] ? '600' : '400'}
                                        color={rights[item.name] ? COLORS.white : COLORS.black}
                                        lineHeight="17px"
                                        fontFamily="Montserrat"
                                    >
                                        Все
                                    </Typography>
                                </Tab>
                            </Flex>
                        </Flex>
                    )}
                    <Flex margin="30px 0 0 0" justify="space-between">
                        <Button
                            type="button"
                            theme="beige"
                            width="155px"
                            height="40px"
                            radius="21px"
                            weight="600"
                            lineHeight="17px"
                            onClick={closeModal}
                        >
                            Отменить
                        </Button>
                        <Button
                            type="submit"
                            theme={version ? 'grey' : 'green'}
                            width="155px"
                            height="40px"
                            radius="21px"
                            weight="600"
                            lineHeight="17px"
                        >
                            {version ? 'Создать' : 'Сохранить'}
                        </Button>
                    </Flex>
                </StyledForm>
            </Formik>
            {showWarraningModal &&
                <Modal
                    component={CloseModalPopup}
                    haveCloseBtn
                    closeModal={() => setShowWarrningModal(false)}
                    parameters={{
                        closeParentModal,
                    }}
                />
            }
        </PopupWrapper>
    )
}

export default AddStaffPopup