import styled from 'styled-components'
import { Flex } from '../../../components/common/Styled/Flex'
import { COLORS } from '../../../utils/constants/colors'

export const Wrapper = styled.div<{height: string}>`
    width: 100%;
    height: ${({height}) => height};
`

export const PurchaseHistoryWrapper = styled.div<{active: boolean, isMobile: boolean}>`
    background: ${COLORS.cream};
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: ${({isMobile}) => isMobile ? '12px' : '12px 12px 0 0'};
    width: 100%;
    max-height: ${({active, isMobile}) => active || isMobile ? `calc(100% - ${isMobile ? '0px' : '33px'})` : '96px'};
    min-height: ${({active, isMobile}) => active || isMobile ? 'calc(100vh - 289px)' : '96px'};
    transition: max-height .2s linear,
                min-height .2s linear;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 1px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
  }
    @media(max-width: 1000px) {
        min-height: auto;
    } 
`

export const ShowMore = styled(Flex)<{active: boolean}>`
    background: ${COLORS.cream};
    transition: background .2s linear;
    border-radius: 0 0 12px 12px;
    cursor: pointer;
    border-top: 1px solid ${COLORS.beige};
    &:hover {
        background: ${COLORS.logout};
    }

    svg {
        transition: transform .2s linear;
        transform: ${({active}) => active ? 'rotate(180deg);' : 'rotate(0);'};
    }
`