import React from 'react';
import styled from 'styled-components'
import { COLORS } from '../../utils/constants/colors';
import { showAlert } from '../../utils/showAlert';

const ErrorBlock = styled.div<{ active: boolean, hidden: boolean }>`
    position: fixed;
    top: 50px;
    right: 50px;
    background: rgba(217, 92, 92, 0.95);
    z-index: 9999991;
    transition: transform .1s linear,
                opacity .2s linear;
    transform: ${({ active }) => active ? 'translateY(0)' : 'translateY(calc(-100% - 50px))'};
    opacity: ${({ active }) => active ? '1' : '0'};
    visibility: ${({ hidden }) => hidden ? 'hidden' : 'visible'};
    color: ${COLORS.white};
    padding: 15px;
    border-radius: 12px;
    width: 40%;

    @media(max-width: 1000px) {
        width: 90%;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
    }
`

interface IProps {
    active: boolean
    hidden: boolean
    text: string
}

const ErrorHandler = ({ active, hidden, text }: IProps) => {
    return (
        <ErrorBlock active={active} hidden={hidden}>
            {text}
        </ErrorBlock>
    );
};

export default ErrorHandler;