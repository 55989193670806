import styled from 'styled-components'
import { Button } from '../../../components/common/Styled/Button'
import { CalendarWrapper } from '../../../components/common/Styled/CalendarWrapper'
import { Flex } from '../../../components/common/Styled/Flex'
import { Grid } from '../../../components/common/Styled/Grid'
import { BodyWrapper } from '../../../components/Modal/style'

const Container = styled(Grid)`
    @media (max-width: 1000px) {
        grid-template-columns: 100%;
        ${CalendarWrapper} {
            position: fixed;
            top: 44px;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 100;
            border-radius: 12px 12px 0 0;
        } 
    }

    @media (max-width: 500px) {
        ${CalendarWrapper} {
            padding: 10px;
        } 
    }
    @media (max-width: 340px) {
        ${CalendarWrapper} {
            ${Button} {
                width: 130px;
            }
        } 
    }

    @media (max-height: 950px) and (min-width: 1000px) {
        ${CalendarWrapper} {
            padding: 10px 20px 30px 20px;
        } 
    }

    ${BodyWrapper} {
        @media (max-width: 500px) {
            width: auto;
        }
    }

`
const BookingsWrapper = styled(Flex)`
    overflow-y: auto;

    @media (max-width: 550px) {
        &::-webkit-scrollbar {
            width: 10px;
        }
     }
`

const Message = styled(Flex)`
    height: calc(100vh - 150px);
    @media (max-width: 1000px) {
        height: calc(100vh - 240px);
    }
    @media (max-width: 550px) {
        height: calc(100vh - 205px);
     }
     @media (max-width: 450px) {
        height: calc(100vh - 170px);
     }
`

const CalendarTitle = styled(Flex)`
    @media (max-height: 800px){
        margin: 10px 0 5px 0;
    }
`

export { Container, BookingsWrapper, Message, CalendarTitle }