import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { SetStateType } from '../../../../types/common';
import { COLORS } from '../../../../utils/constants/colors';
import { Button } from '../../Styled/Button';
import { Flex } from '../../Styled/Flex';
import { Typography } from '../../Styled/Typography';
import { SelectWrapper, TimeWrapper } from './style';
import { useAdaptive } from '../../../../hooks/useAdaptive';
import DatePicker from "react-datepicker";
import ru from 'date-fns/locale/ru'
import moment from 'moment';

type TimesType = {startTime?: Date | null, endTime?: Date | null}

interface IProps {
    setShow: SetStateType<boolean>
    setTitle?: SetStateType<string>
    setActive?: SetStateType<boolean>
    times: TimesType
    setTimes: React.Dispatch<React.SetStateAction<TimesType>>
}

const TimeFilter = ({ setShow, setTitle, setActive, times, setTimes }: IProps) => {
    const [showTimesSelects, setShowTimesSelect] = useState<{showEndTime: boolean, showStartTime: boolean}>({
        showEndTime: false,
        showStartTime: false
    })
    const ref = useRef<HTMLDivElement>(null);

    const resize = useAdaptive(ref)

    useEffect(() => {
        resize()
    }, [resize])

    const clear = () => {
        setTimes({ startTime: null, endTime: null})
        setShow(false)
        setActive && setActive(false)
        setTitle && setTitle('Время')
    }

    const save = () => {
        if(!times.startTime) return clear()
        setTitle && setTitle(`${moment(times.startTime).format('H:mm')} – ${moment(times.endTime).format('H:mm')}`)
        setActive && setActive(true)
        setShow(false)
    }

    return (
        <TimeWrapper ref={ref} padding="20px">
            <Flex>
                <SelectWrapper direction="column">
                    <span onClick={() => setShowTimesSelect({showStartTime: true, showEndTime: false})}>
                        {times.startTime ? moment(times.startTime).format('HH:mm') : ''}
                    </span>
                    {showTimesSelects.showStartTime &&
                        <DatePicker
                            selected={times.startTime}
                            onChange={(date) => {
                                date && setTimes({...times, startTime: moment(date as Date).toDate()})
                                setShowTimesSelect({showEndTime: false, showStartTime: false})
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Время"
                            dateFormat="H:mm"
                            locale={ru}
                            minTime={moment('08:00', 'H:mm').toDate()}
                            maxTime={times.endTime || moment('22:00', 'H:mm').toDate()}
                            inline
                            onClickOutside={() => setShowTimesSelect({showEndTime: false, showStartTime: false})}
                        />
                    }
                </SelectWrapper>
                <Typography color={COLORS.brown} margin="0 20px" weight="800" size="18px"> — </Typography>
                <SelectWrapper direction="column">
                    <span onClick={() => setShowTimesSelect({showStartTime: false, showEndTime: true})}>
                        {times.endTime ? moment(times.endTime).format('HH:mm') : ''}
                    </span>
                    {showTimesSelects.showEndTime && 
                            <DatePicker
                                locale={ru}
                                selected={times.endTime}
                                onChange={(date) => {
                                    date && setTimes({...times, endTime: moment(date as Date).toDate()})
                                    setShowTimesSelect({showEndTime: false, showStartTime: false})
                                }}
                                inline
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Время"
                                dateFormat="H:mm"
                                minTime={times.startTime || moment('08:00', 'H:mm').toDate()}
                                maxTime={moment('22:00', 'H:mm').toDate()}
                                onClickOutside={() => setShowTimesSelect({showEndTime: false, showStartTime: false})}
                            />
                    }
                </SelectWrapper>
            </Flex>
            <Flex margin="15px 0 0" justify="space-between">           
                <Button onClick={clear}>
                    <Typography decoration="underline" color={COLORS.steel} lineHeight="17px" weight="600" size="14px">Очистить</Typography>
                </Button>
                <Button
                    theme="green"
                    width="120px"
                    height="35px"
                    radius="27px"
                    weight="600"
                    fontSize="14px"
                    lineHeight="17px"
                    onClick={save}
                >
                    Сохранить
                </Button>
            </Flex>
        </TimeWrapper>
    );
};

export default TimeFilter;