import styled, {keyframes} from 'styled-components'
import { Flex } from '../../../components/common/Styled/Flex'
import { BodyWrapper } from '../../../components/Modal/style'
import { COLORS } from '../../../utils/constants/colors'


const SubscriptionsItemWrapper = styled.div<{isMobile: boolean}>`
    position: relative;
    background: ${COLORS.cream};
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 12px;
    padding: 25px 0 0 0;
    /* min-width: 290px; */
    width: 290px;
    width: ${({isMobile}) => isMobile && '100%'};
    margin-right: 20px;
    ${BodyWrapper} {
        @media (max-width: 500px) {
            width: auto;
        }
    }
    @media (max-width: 500px) {
        margin-right: 0;
    }
`
const ProductItems = styled(Flex)<{size: string, isMobile: boolean}>`
    max-height: ${({size, isMobile}) => !isMobile && size};
    overflow-y: auto;
    width: 100%;

    &::-webkit-scrollbar {
        width: 0;
    }

`
const ProductItem = styled(Flex)`
    border-top: 2px solid ${COLORS.beige};
    padding: 15px 20px;
    /* min-height: 120px; */
`

const Title = styled(Flex)``

const ArrowDown = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    right: 10px;
    width: 35px;
    height: 35px;
    z-index: 99;
    border-radius: 50%;
    background: ${COLORS.lightBrown};
    cursor: pointer;
    box-shadow: 0px 0px 19px 0px rgba(34, 60, 80, 0.2);
    & svg {
        width: 20px;
        height: 20px;
        transform: rotate(-90deg);

        & path {
            fill: ${COLORS.white};
        }
    }
`

export {Title, ProductItems, SubscriptionsItemWrapper, ProductItem, ArrowDown}