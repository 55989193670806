import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom';
import { BellIcon, CartIcon, MeassegeIcon, OfficeIcon, PeopleIcon, ProfileIcon, SettingsIcon, SofaIcon, StaticIcon } from '../../assets/icons';
import { logout } from "../../store/reducers/userReducer";
import { COLORS } from "../../utils/constants/colors";
import { Typography } from '../common/Styled/Typography';
import { List, ListItem, MenuWrapper, UnReadedCount } from './style';

const Menu = ({isPsyho, unReadedCount}: {isPsyho: boolean, unReadedCount: number}) => {
    const [manyIcons, setManyIcons] = useState(false)
    const refList = useRef<HTMLUListElement>(null)
    const refWrapper = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if(refList.current && refWrapper.current) {
            if(refList.current.clientHeight > refWrapper.current.clientHeight) {
                setManyIcons(true)
            } 
        }
    }, [])

    const links = [
        {id: 1, path: '/booking', text: 'Бронирования', icon: <SofaIcon />, access: isPsyho},
        {id: 2, path: '/offices', text: 'Кабинеты', icon: <OfficeIcon />, access: isPsyho},
        {id: 3, path: '/purchases', text: 'Покупки', icon: <CartIcon />, access: isPsyho},
        {id: 4, path: '/notifications', text: 'Уведомления', icon: <BellIcon />, access: isPsyho},
        {id: 5, path: '/profile', text: 'Профиль', icon: <ProfileIcon />, access: isPsyho},
        {id: 6, path: '/users', text: 'Пользователи', icon: <PeopleIcon />, access: !isPsyho},
        {id: 7, path: '/offices', text: 'Кабинеты', icon:  <OfficeIcon />, access: !isPsyho},
        {id: 8, path: '/booking', text: 'Бронирования', icon: <SofaIcon />, access: !isPsyho},
        {id: 9, path: '/statistics', text: 'Статистика', icon: <StaticIcon />, access: !isPsyho},
        {id: 10, path: '/notifications', text: 'Уведомления', icon: <BellIcon />, access: !isPsyho},
        {id: 11, path: '/settings', text: 'Настройки', icon: <SettingsIcon />, access: !isPsyho},
        {id: 12, path: '/history', text: 'История изменений', icon: <MeassegeIcon />, access: !isPsyho},
    ]

    const singOut = () => {
        Cookies.remove('access')
        Cookies.remove('refresh')
        dispatch(logout())
    }

    return (
        <MenuWrapper manyIcons={manyIcons} ref={refWrapper}>
                <List ref={refList}>
                    {
                        links.map(link => link.access ? (
                            <NavLink to={link.path} key={link.id} activeClassName="active_menu_link">
                                <ListItem>
                                    {link.icon}
                                    <Typography
                                        color={'#3D322161'}
                                        size="12px"
                                        lineHeight="18px"
                                        align="center"
                                    >
                                        {link.text}
                                    </Typography>
                                    {link.path === '/notifications' && !!unReadedCount && isPsyho && 
                                        <UnReadedCount>
                                            <Typography color={COLORS.white} weight="700" size="10px" lineHeight="12px">{unReadedCount}</Typography>
                                        </UnReadedCount>
                                    }
                                </ListItem>
                            </NavLink>
                        ) : <React.Fragment key={link.id}></React.Fragment>)
                    }
                    {!isPsyho && 
                        <ListItem
                            onClick={singOut}
                        >
                            <ProfileIcon />
                            <Typography
                                color={'#3D322161'}
                                size="12px"
                                lineHeight="18px"
                                align="center"
                            >
                                Выход
                            </Typography>
                        </ListItem>
                    }
                </List>
        </MenuWrapper>
    );
};

export default Menu;