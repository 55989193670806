import React, { useEffect, useState } from 'react';
import { CalendarChevron } from '../assets/icons';
import { Flex } from '../components/common/Styled/Flex';
import { Typography } from '../components/common/Styled/Typography';
import styled from 'styled-components'

interface IProps {
    [key: string]: unknown
}

export interface PaginationComponentProps {
    currentPage: number
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    paginationComponet: (count: number, pageSize: number) => JSX.Element | null
    [key: string]: unknown
}
type ComponentType = (props: PaginationComponentProps) => JSX.Element


export const PaginationWrapper = styled(Flex)`
    width: 150px;
    ${Typography} {
        cursor: pointer;
        transition: font-weight .2s linear;
        user-select: none;
        &:hover {
            font-weight: 600;
        }
    }

`

const Chevron = styled.button<{ isPrev?: boolean }>`
    display: flex;
    align-items: center;
    transform: ${({ isPrev }) => !isPrev && 'rotate(180deg)'};
`;

const paginationHoc = (Component: ComponentType) => {
    return (props: IProps) => {
        const [currentPage, setCurrentPage] = useState(1)
        const [pageCount, setPageCount] = useState(1)


        const prev = () => currentPage > 1 && setCurrentPage(prevState => prevState - 1)

        const next = () => currentPage < pageCount && setCurrentPage(prevState => prevState + 1)

        const resetPage = () => setCurrentPage(1)

        const Pagination = (count: number, pageSize: number) => {
            const [paginationNumbers, setPaginationNumbers] = useState<Array<number | null>>([])

            useEffect(() => {
                setPageCount(Math.ceil(count / pageSize))
            }, [count, pageSize])

            useEffect(() => {
                const end = currentPage === pageCount
                const newArr = [
                    currentPage === 1 ? null : end ? currentPage - 2 : currentPage - 1,
                    currentPage < pageCount ? currentPage : currentPage - 1,
                    currentPage < pageCount ? currentPage + 1 : currentPage
                ]
                setPaginationNumbers(newArr)
            }, [currentPage, pageCount])

            useEffect(() => {
                !count && resetPage()
            }, [count])

            if (paginationNumbers[1] && count) {
                return (
                    <PaginationWrapper>
                        <Chevron isPrev onClick={() => prev()}><CalendarChevron /></Chevron>
                        <Flex width="50%" gap="5px">
                            {paginationNumbers.map((item, index) =>
                                <Typography
                                    onClick={() => item && setCurrentPage(item)}
                                    weight={item === currentPage ? '700' : '400'}
                                    key={index}
                                >
                                    {item ? item : ' '}
                                </Typography>
                            )}
                        </Flex>
                        <Chevron onClick={() => next()}><CalendarChevron /></Chevron>
                    </PaginationWrapper>
                )
            }
            return null
        }

        return (
            <Component currentPage={currentPage} setCurrentPage={setCurrentPage} paginationComponet={Pagination} {...props} />
        );
    }
}

export default paginationHoc