import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DateFilter from '../../../../components/common/FormElements/DateFilter';
import Input from '../../../../components/common/FormElements/Input';
import { Flex } from '../../../../components/common/Styled/Flex';
import { Grid } from '../../../../components/common/Styled/Grid';
import { Ceil, CeilHeader, Table } from '../../../../components/common/Styled/Table';
import { Typography } from '../../../../components/common/Styled/Typography';
import paginationHoc, { PaginationComponentProps } from '../../../../hocs/Pagination.hoc';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { IBookingStatistics } from '../../../../types/interfaces/statistics';
import { getBookingStatistics } from '../../../../utils/api/routes';
import { DateSelectWrapper } from '../PsyhoStatistics/style';
import { Card } from '../style';

const CancelingStatistics = ({ currentPage, setCurrentPage, paginationComponet }: PaginationComponentProps) => {
    const [dates, setDates] = useState<{ startDate: Date | null, endDate?: Date | null }>({ startDate: null, endDate: null })
    const [showDateSelect, setShowDateSelect] = useState(false)
    const [dateInputValue, setDateInputValue] = useState('')
    const [filterString, setFilterString] = useState('')
    const [statistics, setStatistics] = useState<Array<IBookingStatistics>>([])
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(0)
    const table = useRef<HTMLDivElement>(null)
    const ref = useRef<HTMLDivElement>(null)
    useClickOutside(ref, () => setShowDateSelect(false))
    const tebleHeader = [
        { id: 1, text: 'Психолог', borderRadius: 'first' as 'first' },
        { id: 2, text: 'Завершенные брони' },
        { id: 3, text: 'Отмененные брони', borderRadius: 'last' as 'last' },
    ]

    useEffect(() => {
        if (table.current) {
            setPageSize(Math.floor((window.innerHeight - table.current.getBoundingClientRect().top - 100) / 40))
        }
    }, [table])

    useEffect(() => {
        let queryString = ''
        if (dates.startDate) {
            queryString += `&date_after=${moment(dates.startDate).format('YYYY-MM-DD')}`
        }
        if (dates.endDate) {
            queryString += `&date_before=${moment(dates.endDate).format('YYYY-MM-DD')}`
        }
        if ((dates.endDate && dates.startDate) || (dates.endDate === null && dates.startDate === null)) {
            setCurrentPage(1)
            setFilterString(queryString)
        }
    }, [dates])

    useEffect(() => {
        getBookingStatistics(`?page=${currentPage}&page_size=${pageSize}${filterString}`).then(res => {
            setStatistics(res.data.results)
            setTotalCount(res.data.count)
        })
    }, [filterString, currentPage, pageSize])

    return (
        <Flex align="flex-start" margin="40px 0 0 0" direction="column" width="100%">
            <Flex width="30%">
                <DateSelectWrapper ref={ref}>
                    <Input
                        value={dateInputValue}
                        onChange={() => { }}
                        onFocus={() => setShowDateSelect(true)}
                        placeholder="Период брони/отмен"
                    />
                    {showDateSelect &&
                        <DateFilter
                            setShow={setShowDateSelect}
                            dates={dates}
                            setDates={setDates}
                            select
                            showInputs
                            setTitle={setDateInputValue}
                        />
                    }
                </DateSelectWrapper>
            </Flex>
            <Grid margin="25px 0 0 0" columns="60% 30%" gap="10%">
                <Table ref={table} columns="40% 30% 30%">
                    {tebleHeader.map(item =>
                        <CeilHeader borderRadius={item.borderRadius && item.borderRadius} key={item.id}>
                            <Typography weight="700" lineHeight="18px">{item.text}</Typography>
                        </CeilHeader>
                    )}
                    {pageSize > 0 && statistics.map((item, index) =>
                        <React.Fragment key={item.id}>
                            <Ceil borderRadius="first" isLastRow={index === statistics.length - 1}>
                                <Typography lineHeight="18px">{item.get_full_name.length ? item.get_full_name : `[неизвестный клиент]`}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{item.finished_booking}</Typography>
                            </Ceil>
                            <Ceil borderRadius="last" isLastRow={index === statistics.length - 1}>
                                <Typography lineHeight="18px">{item.canceled_booking}</Typography>
                            </Ceil>
                        </React.Fragment>
                    )}
                </Table>
                <Card>
                    <Typography fontFamily="Montserrat" weight="600" size="18px" lineHeight="22px">Выбрано</Typography>
                    <Typography weight="800" size="72px" lineHeight="86px">{totalCount}</Typography>
                </Card>
            </Grid>
            <Flex margin="25px 0 0 0" width="100%">
                {paginationComponet(totalCount, pageSize)}
            </Flex>
        </Flex>
    );
};

export default paginationHoc(CancelingStatistics);