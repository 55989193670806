import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NotificationChevron } from '../../../assets/icons'
import { Button } from '../../../components/common/Styled/Button'
import { Flex } from '../../../components/common/Styled/Flex'
import { Typography } from '../../../components/common/Styled/Typography'
import Modal from '../../../components/Modal'
import SliderModalComponent from '../../../components/SliderModal'
import useResolution from '../../../hooks/useResolution'
import { INotification } from '../../../types/interfaces/notification'
import { COLORS } from '../../../utils/constants/colors'
import { Address, NotificationCardWrapper, Photo, PhotoWrapper, ShowMore } from './style'

function NotificationCard({ data }: { data: INotification }) {
    const [showMore, setShowMore] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const history = useHistory()
    const isMobile = useResolution(850)

    return (
        <NotificationCardWrapper>
            <Flex align="flex-start" direction="column" padding={showMore ? '0 20px' : '0 20px 20px 20px'}>
                <Flex width="100%" justify="space-between">
                    <Typography weight="700" size="18px" lineHeight="18px">{data.title}</Typography>
                    <Typography color={COLORS.steel} size="12px" lineHeight="18px" margin="5px 0 0 0">{moment(data.date).format('DD.MM.YYYY HH:mm')}</Typography>
                </Flex>
                {!!data.images.length &&
                    <PhotoWrapper showMore={showMore} margin="10px 0 0 0" justify="flex-start" gap="10px">
                        {data.images.map(item =>
                            <Photo onClick={() => setShowModal(true)} showMore={showMore} key={item.id}>
                                <img src={item.image} alt="Фото уведомления" />
                            </Photo>
                        )}
                    </PhotoWrapper>
                }
            </Flex>
            {showMore &&
                <Flex padding="0 20px 12px 20px" margin="8px 0 0 0" direction={isMobile ? 'column' : 'row'} align="flex-start" justify={isMobile ? 'flex-start' : "space-between"}>
                    <Flex margin="0 0 10px 0" align="flex-start" direction="column">
                        <Address size="16px">{data.text}</Address>
                        {data.booking &&
                            <>
                                <Typography margin="10px 0 0 0" weight="600" size="16px" lineHeight="18px">Дата бронирования</Typography>
                                <Typography size="14px" weight="500" lineHeight="18px" margin="5px 0 0 0">
                                    {moment(data.booking.date).format('DD.MM.YYYY')} с&nbsp;
                                    {data.booking.before_hours.slice(0, 5)} до&nbsp;
                                    {data.booking.after_hours.slice(0, 5)}
                                </Typography>
                            </>
                        }
                    </Flex>

                    {data.booking && data.booking.office &&
                        <Button
                            width="122px"
                            height="34px"
                            radius="6px"
                            theme="green"
                            weight="700"
                            lineHeight="17px"
                            onClick={() => history.push(`/booking/${data.booking.id}`)}
                        >
                            Открыть
                        </Button>
                    }
                    {/* ${data.booking.office} */}
                </Flex>
            }
            <ShowMore onClick={() => setShowMore(!showMore)} active={showMore} width="100%" padding="7.5px 0">
                <NotificationChevron />
            </ShowMore>
            {showModal &&
                <Modal
                    component={() => <SliderModalComponent alt="Фото уведомления" arr={data.images.map(item => ({ id: item.id, photo: item.image }))} />}
                    closeModal={() => setShowModal(false)}
                    sliderModal
                />
            }
        </NotificationCardWrapper>
    )
}

export default NotificationCard
