import styled from 'styled-components'
import { FilterWrapper } from '../../../components/common/Styled/Filters'
import { Flex } from '../../../components/common/Styled/Flex'
import { Grid } from '../../../components/common/Styled/Grid'
import { Typography } from '../../../components/common/Styled/Typography'
import { COLORS } from '../../../utils/constants/colors'

const CatalogWrapper = styled.div`
    width: 100%;
    ${FilterWrapper}.active {
        background: ${COLORS.lightBrown};
        ${Typography} {
            color: ${COLORS.white};
        }
    }

    ${FilterWrapper}{
        cursor: pointer;
        margin-top: 10px;
    }
`

const CatalogOffices = styled(Grid)<{height: string}>`
    width: 100%;
    max-height: ${({height}) => height};
    overflow-y: auto;
    padding: 0 0 30px 0;
    
    @media (max-width: 1200px) {
        grid-template-columns: repeat(auto-fill, 30%);
    }

    @media (max-width: 1000px) {
        grid-template-columns: repeat(auto-fill, 45%);
        justify-content: center;
        &::-webkit-scrollbar {
            width: 10px;
        }
    }
    @media (max-width: 550px) {
        grid-template-columns: repeat(auto-fill, 90%);

    }
`

const Metros = styled(Flex)`
    width: 100%;
    max-width: 100%;
    height: 55px;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: ${COLORS.brown};
    }

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
` 

export { CatalogWrapper, CatalogOffices, Metros }