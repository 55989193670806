import React, { useEffect, useMemo } from 'react';
import { SelectWorkDayType } from '..';
import { CloseIcon, PlusIcon } from '../../../../../assets/icons';
import Select from '../../../../../components/common/FormElements/Select';
import { Flex } from '../../../../../components/common/Styled/Flex';
import { Grid } from '../../../../../components/common/Styled/Grid';
import { Typography } from '../../../../../components/common/Styled/Typography';
import { OptionType, SetStateType } from '../../../../../types/common';
import { IOffice } from '../../../../../types/interfaces/office';
import { COLORS } from '../../../../../utils/constants/colors';
import { DaySlect, Close, Plus } from '../style';

interface IProps {
    timesSelect: Array<SelectWorkDayType>
    setTimesSelect: SetStateType<Array<SelectWorkDayType>>
    validation: boolean
    office: IOffice | null
    step: 1 | 2
}

const DaySelects = ({ timesSelect, setTimesSelect, validation, office, step }: IProps) => {

    const weekDays = useMemo(() => {
        return [
            { value: 1, name: 'Понедельник' },
            { value: 2, name: 'Вторник' },
            { value: 3, name: 'Среда' },
            { value: 4, name: 'Четверг' },
            { value: 5, name: 'Пятница' },
            { value: 6, name: 'Суббота' },
            { value: 7, name: 'Воскресенье' }
        ] as Array<OptionType>
    }, [])

    useEffect(() => {
        if (office) {
            const timeToNumber = (time: string) => +time.split(':')[0] + (+time.split(':')[1] === 30 ? 0.5 : 0)

            const timesArr = Object.keys(office.schedule).map(item => {
                if (office.schedule[+item] === '24:00-24:00') return null
                const timesRange = office.schedule[+item].split('-')
                return { id: +item, day: +item, timeAfter: timeToNumber(timesRange[1]), timeBefore: timeToNumber(timesRange[0]), options: weekDays }
            }).filter(item => item)

            setTimesSelect([...timesArr] as [])
        }
    }, [office, setTimesSelect, weekDays])

    const addDay = () => {
        if (timesSelect.length !== 0 && timesSelect[timesSelect.length - 1].day === 7) return
        if (!timesSelect.length) {
            return setTimesSelect([{ id: 1, timeBefore: 8, timeAfter: 24, day: 1, options: weekDays }])
        }
        setTimesSelect(prevState => {
            const newOptions = weekDays.filter(day => !(!!prevState.find(item => item.day === day.value)) && day.value > prevState[prevState.length - 1].day)
            return [...prevState, { id: prevState.length + 1, timeBefore: 8, timeAfter: 24, day: newOptions[0].value, options: newOptions }]
        })
    }

    const changeDay = (value: number | string | boolean, elem: SelectWorkDayType) => {
        let newArr = [...timesSelect]
        const currentElem = newArr.find(item => item.day === value)
        if (currentElem) {
            newArr = newArr.filter(item => item.day !== value as number)
        }

        const idx = newArr.indexOf(elem)
        if (idx < 0) return
        const newSelect = [...newArr.slice(0, idx), { ...newArr[idx], day: value as number }, ...newArr.slice(idx + 1)]
        setTimesSelect(newSelect)
    }

    const timeToOptions = (timeBefore = 8) => {
        const stepTime = step
        return Array.from({ length: ((24 - timeBefore) * stepTime) + 1 }).reduce((acum: Array<{ value: number, name: string }>, _, index) => {
            const time = timeBefore + (index / stepTime)
            acum.push({ value: time, name: Number.isInteger(time) ? `${time}:00` : `${Math.floor(time)}:30` })
            return acum
        }, [])
    }

    const changeTime = (elem: SelectWorkDayType, time: number, timeAfterOrBefore: boolean) => {
        const idx = timesSelect.indexOf(elem)
        if (idx < 0) return

        const timeAfter = { ...timesSelect[idx], timeAfter: time }
        const timeBefore = { ...timesSelect[idx], timeBefore: time }
        const newSelect = timeAfterOrBefore ?
            [...timesSelect.slice(0, idx), timeAfter, ...timesSelect.slice(idx + 1)]
            :
            [...timesSelect.slice(0, idx), timeBefore, ...timesSelect.slice(idx + 1)]

        setTimesSelect(newSelect)
    }

    const deleteDay = (id: number) => {
        const newArr = timesSelect.filter(item => item.id !== id)
        setTimesSelect(newArr)
    }
    //{id: number, timeBefore: number, timeAfter: number, day: number, options: Array<OptionType>}
    useEffect(() => {
        if (step === 1) {
            const rebuildArr = timesSelect.map(item => {
                const timeBefore = !Number.isInteger(item.timeBefore) ? Math.floor(item.timeBefore) : item.timeBefore
                const timeAfter = !Number.isInteger(item.timeAfter) ? Math.floor(item.timeAfter) : item.timeAfter

                return { ...item, timeAfter, timeBefore }
            })

            setTimesSelect(rebuildArr)
        }
    }, [step])

    return (
        <Flex margin="20px 0 0 0" direction="column" align="flex-start">
            <Typography weight="700" size="18px" lineHeight="21px">Рабочее время кабинета*</Typography>
            <Grid margin="20px 0 0 0" columns="25% repeat(2, 10%)" gap="2%">
                <Typography fontFamily="Montserrat" weight="600" lineHeight="17px">День</Typography>
                <Typography fontFamily="Montserrat" weight="600" lineHeight="17px">Время с</Typography>
                <Typography fontFamily="Montserrat" weight="600" lineHeight="17px">Время до</Typography>
            </Grid>
            {timesSelect.map((item, idx) =>
                <DaySlect margin="7px 0 0 0" columns="25% repeat(2, 10%)" gap="2%" key={item.id}>
                    <Select value={item.day} onChange={(value) => changeDay(value, item)} options={item.options} />
                    <Select
                        value={item.timeBefore}
                        onChange={(value) => changeTime(item, value as number, false)}
                        options={timeToOptions()}
                    />
                    <Select
                        value={item.timeAfter}
                        onChange={(value) => changeTime(item, value as number, true)}
                        options={timeToOptions(item.timeBefore)}
                    />
                    <Close onClick={() => deleteDay(item.id)}>
                        <CloseIcon />
                    </Close>
                </DaySlect>
            )}
            {validation && <Typography color={COLORS.red} margin="5px 0" size="12px">Обязательное поле</Typography>}
            <Flex onClick={addDay} cursor="pointer" margin="13px 0 0 0">
                <Plus margin="0 10px 0 0">
                    <PlusIcon />
                </Plus>
                <Typography weight="500" size="16px" lineHeight="19px">Добавить рабочий день</Typography>
            </Flex>
        </Flex>
    );
};

export default DaySelects;