import React, { useEffect, useState, useRef } from 'react';
import Input from '../../../../components/common/FormElements/Input';
import { Flex } from '../../../../components/common/Styled/Flex';
import { ChangeType, OptionType } from '../../../../types/common';
import { getSelectParams } from '../../../../utils/api/routes';
import loupeIcon from '../../../../assets/icons/admin/loupeIcon.svg'
import Select from '../../../../components/common/FormElements/Select';
import { Table, CeilHeader, Ceil } from '../../../../components/common/Styled/Table';
import { Typography } from '../../../../components/common/Styled/Typography';
import { DeleteIcon } from '../../../../assets/icons';
import AddMetroPopup from './AddMetroPopup';
import Modal from '../../../../components/Modal';
import { Button } from '../../../../components/common/Styled/Button';
import DeleteMetroConfirmPopup from './DeleteMetroConfirmPopup';
import { debounce } from '../../../../utils/debounce';

const Metros = () => {
    const [search, setSearch] = useState('')
    const [city, setCity] = useState<number | null>(null)
    const [cities, setCities] = useState<Array<OptionType>>([])
    const [metros, setMetros] = useState<Array<{ id: number, name: string, city?: number }>>([])
    const [showModal, setShowModal] = useState(false)
    const [activeMetro, setActiveMetro] = useState<number | null>(null)
    const [showDeleteMetro, setShowDeleteMetro] = useState(false)

    const debouncedSearch = useRef(debounce((city, search) => updateComponent(city, search), 400))

    const updateComponent = (city: number, search: string) => {
        let queryString = ''
        if (city && city > 0) queryString += `&city=${city}`
        getSelectParams('metros', `?search=${search ?? ''}${queryString}`).then(res => setMetros(res.data))
    }

    useEffect(() => {
        getSelectParams('cities').then(res => setCities([{ value: -1, name: 'Все' }, ...res.data.map(item => ({ value: item.id, name: item.name }))]))
    }, [])

    useEffect(() => {
        debouncedSearch.current(city, search)
    }, [city, search])

    return (
        <Flex margin="40px 0 0 0" width="100%" align="flex-start" direction="column">
            <Flex width="100%" justify="space-between">
                <Flex margin="0 0 15px 0" width="40%">
                    <Input
                        value={search}
                        onChange={(e: ChangeType) => setSearch(e.target.value)}
                        placeholder={'Поиск'}
                        icon={loupeIcon}
                    />
                </Flex>
                <Button
                    radius="6px"
                    theme="grey"
                    width="217px"
                    height="36px"
                    weight="600"
                    lineHeight="17px"
                    onClick={() => setShowModal(true)}
                >
                    Добавить метро
                </Button>
            </Flex>
            <Flex width="30%">
                <Select
                    value={city || ''}
                    options={cities}
                    defaultValue="Город"
                    onChange={(value) => setCity(value as number)}
                />
            </Flex>
            <Table margin="25px 0 0 0" w="50%" columns="repeat(3, calc(100% / 3))">
                <CeilHeader borderRadius="first">
                    <Typography weight="700" lineHeight="18px">Метро</Typography>
                </CeilHeader>
                <CeilHeader>
                    <Typography weight="700" lineHeight="18px">Город</Typography>
                </CeilHeader>
                <CeilHeader borderRadius="last">
                    <Typography weight="700" lineHeight="18px">Удалить</Typography>
                </CeilHeader>
                {metros.map((item, index) =>
                    <React.Fragment key={item.id}>
                        <Ceil borderRadius="first" isLastRow={index === cities.length - 1}>
                            <Typography lineHeight="18px">{item.name}</Typography>
                        </Ceil>
                        <Ceil>
                            <Typography lineHeight="18px">{cities.find(city => city.value === item.city)?.name}</Typography>
                        </Ceil>
                        <Ceil borderRadius="last" isLastRow={index === cities.length - 1}>
                            <DeleteIcon onClick={() => {
                                setShowDeleteMetro(true)
                                setActiveMetro(item.id)
                            }} />
                        </Ceil>
                    </React.Fragment>
                )}
            </Table>
            {showModal &&
                <Modal
                    haveCloseBtn
                    component={AddMetroPopup}
                    closeModal={() => setShowModal(false)}
                    parameters={{
                        updateComponent
                    }}
                />
            }
            {showDeleteMetro &&
                <Modal
                    haveCloseBtn
                    closeModal={() => {
                        setShowDeleteMetro(false)
                        setActiveMetro(null)
                    }}
                    component={DeleteMetroConfirmPopup}
                    parameters={{
                        id: activeMetro,
                        updateComponent
                    }}
                />
            }
        </Flex>
    );
};

export default Metros;