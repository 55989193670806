import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { VoidFuncType } from "../../../types/common";
import { IBooking, IOptimizedBookingBooking } from "../../../types/interfaces/booking";
import { cancelBooking, deleteBooking, getBookings, сonfirmBooking } from "../../../utils/api/routes";
import { showAlert } from "../../../utils/showAlert";

interface GetPayloadType {
    pageSize: number,
    queryString: string,
    all: boolean,
    pagination?: boolean,
    successCallback?: VoidFuncType,
    finalyCallback?: VoidFuncType,
}

interface IdPayloadType {
    group: number
    successCallback?: VoidFuncType
}

type DateRange = { dateAfter: string, dateBefore: string }

const initialState = {
    bookings: [] as IBooking[],
    myBookings: [] as IBooking[],
    activeCabinetId: null as number | null,
    activeDateRange: null as DateRange | null,
    count: 0,
    myCount: 0,
    loading: false
}

export const bookingsGet = createAsyncThunk<object, GetPayloadType>(
    'bookings/getBokings',
    async (payload, { dispatch }) => {
        const { pageSize, queryString, all, pagination, successCallback, finalyCallback } = payload
        try {
            all && dispatch(setLoading(true))
            const response = await getBookings(queryString, pageSize)
            if (response.status === 200) {
                dispatch(
                    all
                        ? setBookings(response.data.results as Array<IBooking>)
                        : !!pagination
                            ? addMyBookings(response.data.results as Array<IBooking>)
                            : setMyBookings(response.data.results as Array<IBooking>))

                all && dispatch(setCount(response.data.count))
                !all && dispatch(setMyCount(response.data.count))
                successCallback && response.data.results.length && successCallback()
                dispatch(setLoading(false))
            }
            finalyCallback && finalyCallback()
        } catch (error) {
            finalyCallback && finalyCallback()
            dispatch(setLoading(false))
        }
    }
)

export const bookingsDelete = createAsyncThunk<object, IdPayloadType>(
    'bookings/deleteBokings',
    async (payload) => {
        const { group } = payload
        try {
            await deleteBooking(group)
            showAlert('success', 'Бронирование успешно отменено')
        } catch (error) {
            showAlert('error', 'Что-то пошло не так...')
        }
    }
)

export const bookingsConfirm = createAsyncThunk<object, IdPayloadType>(
    'bookings/confirmBokings',
    async (payload, { dispatch }) => {
        const { group } = payload
        try {
            const response = await сonfirmBooking({ group })
            if (response.status === 200) {
                showAlert('success', 'Бронирование успешно подтверждено')
            }
        } catch (error) {
            showAlert('error', 'Что-то пошло не так...')
        }
    }
)

export const bookingCancel = createAsyncThunk<object, IdPayloadType>(
    'bookings/canselBoking',
    async (payload, { dispatch }) => {
        const { group, successCallback } = payload
        try {
            const response = await cancelBooking({ group })
            if (response.status === 200) {
                showAlert('success', 'Бронирование успешно отменено')
                dispatch(cancel(group))
                successCallback && successCallback()
            }
        } catch (error) {
            showAlert('error', 'Что-то пошло не так...')
        }
    }
)

// export const bookingCreate = createAsyncThunk<object, IdPayloadType>(
//     'bookings/confirmBokings',
//     async (payload, {dispatch}) => {
//         const {group} = payload
//         try {
//             const response = await сonfirmBooking({group})
//             if(response.status === 200) {
//                 showAlert('success', 'Бронь успешно подтверждена')
//                 dispatch(confirmBooking(group))
//             }
//         } catch (error) {
//             showAlert('error', 'Что-то пошло не так...')
//         }
//     }
// )

// export const bookingsChange = createAsyncThunk<object, IdPayloadType>(
//     'bookings/confirmBokings',
//     async (payload, {dispatch}) => {
//         const {group} = payload
//         try {
//             const response = await сonfirmBooking({group})
//             if(response.status === 200) {
//                 showAlert('success', 'Бронь успешно подтверждена')
//                 dispatch(confirmBooking(group))
//             }
//         } catch (error) {
//             showAlert('error', 'Что-то пошло не так...')
//         }
//     }
// )


const bookingsSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
        setBookings(state, action: PayloadAction<IBooking[]>) {

            if (!action.payload.length) {
                state.bookings = action.payload
                return
            }
            // if (!action.payload.every(item =>
            //     item.office.id === state.activeCabinetId
            // )) return
            const currentBookings = action.payload.filter(item =>
                moment(item.date).isSameOrBefore(moment(state.activeDateRange?.dateBefore)) && moment(item.date).isSameOrAfter(moment(state.activeDateRange?.dateAfter)))
            if (currentBookings.length === 0) return

            state.bookings = action.payload
        },
        setMyBookings(state, action: PayloadAction<IBooking[]>) {
            // if(!action.payload.every(item => item.office.id === state.activeCabinetId)) return
            state.myBookings = action.payload
        },
        addMyBookings(state, action: PayloadAction<IBooking[]>) {
            state.myBookings.push(...action.payload)
        },
        setCount(state, action: PayloadAction<number>) {
            state.count = action.payload
        },
        setMyCount(state, action: PayloadAction<number>) {
            state.myCount = action.payload
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        cancel(state, action: PayloadAction<number>) {
            state.myBookings = state.myBookings.filter(item => item.id !== action.payload)
            state.bookings = state.bookings.filter(item => item.id !== action.payload)
        },
        setActiveCabinetId(state, action: PayloadAction<number | null>) {
            state.activeCabinetId = action.payload
        },
        setActiveDateRange(state, action: PayloadAction<DateRange | null>) {
            state.activeDateRange = action.payload
        }
    }
})

const {
    setBookings,
    setCount,
    setMyBookings,
    addMyBookings,
    setMyCount,
    setLoading,
    cancel,
    setActiveCabinetId,
    setActiveDateRange
} = bookingsSlice.actions

export {
    setBookings,
    setCount,
    setMyBookings,
    addMyBookings,
    setMyCount,
    setLoading,
    cancel,
    setActiveCabinetId,
    setActiveDateRange
}

export default bookingsSlice.reducer