import React, { useEffect, useMemo, useRef, useState } from "react";
import { OptionsWrapper, SelectWrapper, Option, Container, OptionsSelectWrapper } from "./style";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { usePagination } from "../../../../hooks/usePagination";
import { SetStateType, VoidFuncType } from "../../../../types/common";
import Checkbox from "../Checkbox";

type ValueType = string | number | boolean

interface ISelectProps {
    options: Array<{ value: ValueType, name: string }> | ValueType[]
    value: ValueType
    onChange: (value: ValueType) => void,
    defaultValue?: string
    totalCount?: number
    currentLength?: number
    request?: (finalyCallback: VoidFuncType) => void
    many?: boolean
    optionsMany?: Array<{ id: number, active: boolean, name: string }>
    onChangeAll?: (id: number) => void
    manySelected?: string
    selectAll?: boolean
    all?: boolean
    setAll?: SetStateType<boolean>
}

const Select = ({ value, options, onChange, defaultValue, totalCount, currentLength, request, many, optionsMany, onChangeAll, manySelected, selectAll, all, setAll }: ISelectProps) => {
    const [isShow, toggleOptions] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const refOptions = useRef<HTMLDivElement>(null);

    const getOption = (option: ValueType | { value: ValueType, name: string }, prop: 'value' | 'name'): ValueType =>
        typeof (option as { value: ValueType, name: string }).value ? (option as { value: ValueType, name: string })[prop] : option as ValueType

    useClickOutside(ref, () => toggleOptions(false))

    const { scroll, fetching, finalyCallback } = usePagination(totalCount === currentLength)

    useEffect(() => {
        if (fetching) {
            request && request(finalyCallback)
        }
    }, [fetching])

    const currentValue = useMemo(() => {
        if (!value || options.length === 0) return ''
        else {
            return (options[0] as { value: ValueType, name: string }).value
                ? (options as { value: ValueType, name: string }[]).find(item => item.value === +value)?.name
                : (options as ValueType[]).find(item => item === value)
        }
    }, [value, options.length])

    const toTop = useMemo(() => {
        if (ref.current) {

            const bottomPoint = ref.current.getBoundingClientRect().bottom
            if (window.innerHeight - bottomPoint <= 200) return true
        }
        return false
    }, [ref, isShow])

    return (
        <Container ref={ref}>
            <SelectWrapper isShow={isShow} onClick={() => toggleOptions(!isShow)}>
                {(many && manySelected) ?
                    <span>{manySelected}</span>
                    :
                    <span>{currentValue || defaultValue || ''}</span>
                }
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.24701 7.14L0.451011 1.658C-0.114989 1.013 0.345011 3.67706e-07 1.20401 3.67706e-07H10.796C10.9883 -0.000164459 11.1765 0.0550878 11.3381 0.159141C11.4998 0.263194 11.628 0.411637 11.7075 0.586693C11.7869 0.761749 11.8142 0.955998 11.7861 1.14618C11.758 1.33636 11.6757 1.51441 11.549 1.659L6.75301 7.139C6.65915 7.24641 6.5434 7.3325 6.41352 7.39148C6.28364 7.45046 6.14265 7.48098 6.00001 7.48098C5.85737 7.48098 5.71638 7.45046 5.5865 7.39148C5.45663 7.3325 5.34087 7.24641 5.24701 7.139V7.14Z" fill="#DECEB7" />
                </svg>
            </SelectWrapper>
            {isShow && (
                <OptionsWrapper ref={refOptions} toTop={toTop} onScroll={totalCount ? scroll : () => { }} isShow={isShow}>
                    {(many && optionsMany && onChangeAll) ?
                        <>
                            {selectAll && setAll &&
                                <OptionsSelectWrapper onClick={() => setAll(!all)} padding="5px" gap="5px">
                                    <Checkbox onChange={() => setAll(!all)} value={!!all} />
                                    <Option>
                                        Все
                                    </Option>
                                </OptionsSelectWrapper>
                            }
                            {optionsMany.map((option, index) => (
                                <OptionsSelectWrapper onClick={() => onChangeAll(option.id)} padding="5px" gap="5px" key={option.id}>
                                    <Checkbox onChange={() => onChangeAll(option.id)} value={option.active} />
                                    <Option key={index} >
                                        {option.name}
                                    </Option>
                                </OptionsSelectWrapper>
                            ))}
                        </>
                        :
                        options.map((option, index) => (
                            <Option key={index} onClick={() => {
                                onChange(getOption(option, 'value'));
                                toggleOptions(false);
                            }}>
                                {getOption(option, 'name')}
                            </Option>
                        ))
                    }
                </OptionsWrapper>
            )}
        </Container>
    )
}

export default Select;