import React, { useEffect, useRef, useState } from 'react';
import DateFilter from '../common/FormElements/DateFilter';
import { Flex } from '../common/Styled/Flex';
import TimeFilter from '../common/FormElements/TimeFilter';
import { Typography } from '../common/Styled/Typography';
import { COLORS } from '../../utils/constants/colors';
import { DateWrapper, TimeWrapper } from './style';
import CitiesComplite from './AutoComplite/Cities';
import MetrosComplite from './AutoComplite/Metros';
import NumberPeople from './NumbersPeople';
import { SetStateType } from '../../types/common';
import moment from 'moment';
import { useClickOutside } from '../../hooks/useClickOutside';

export type DateType = Date | null
type TotalStateType = {metro: string, city: string, numberPepole: string}

export interface ITotalProps {
    setTotalState: SetStateType<TotalStateType>
}

interface IProps {
    setQueryString: SetStateType<string>,
    show: {
        city?: boolean,
        metro?: boolean,
        date?: boolean,
        time?: boolean,
        countPepole?: boolean
    }
}

const Filters = ({setQueryString, show}: IProps) => {
    const [cityId, setCityId] = useState<number | null>(null)
    const [showDate, setShowDate] = useState(false)
    const [activeDate, setActiveDate] = useState(false)
    const [titleDate, setTitleDate] = useState('Дата')
    const [dates, setDates] = useState<{startDate: DateType, endDate?: DateType}>({startDate: null, endDate: null})
    const [disabledDays, setDisabledDays] = useState(false)
    const [showTime, setShowTime] = useState(false)
    const [activeTime, setActiveTime] = useState(false)
    const [titleTime, setTitleTime] = useState('Время')
    const [times, setTimes] = useState<{startTime?: Date | null, endTime?: Date | null}>({startTime: null, endTime: null })
    const [totalState, setTotalState] = useState({metro: '', city: '', numberPepole: '' })
    
    const dateRef = useRef<HTMLDivElement>(null)
    const timeRef = useRef<HTMLDivElement>(null)
    useClickOutside(dateRef, () => {
        setShowDate(false)
        setShowTime(false)
    })
    
    useEffect(() => {
        let totalString = ''

        if(dates.startDate && show.date) {
            totalString += `&dates=${moment(dates.startDate).format('DD.MM.YYYY')},${dates.endDate ? moment(dates.endDate).format('DD.MM.YYYY') : moment(dates.startDate).format('DD.MM.YYYY')}`
        }

        if(times.startTime && show.time) {
            totalString += `&times=${moment(times.startTime).format('H:mm')}:00,${times.endTime ? `${moment(times.endTime).format('H:mm')}:00` : `${moment(times.startTime).format('H:mm')}:00`}`
        } 

        totalState.metro && show.metro ? totalString += `&metro__in=${encodeURIComponent(totalState.metro)}`: totalString += ''
        if(show.city) {
            !totalState.city ? totalString += '&city=Санкт-Петербург' : totalState.city === 'Город' ? totalString += '&city=' : totalString += `&city=${totalState.city}`
        }
        totalState.numberPepole && show.countPepole ? totalString += `&max_peoples=${totalState.numberPepole}`: totalString += ''
        setQueryString(totalString)

    }, [dates, times, totalState])
    
    return (
        <Flex wraping={true} justify="flex-start" gap="10px"> 
            {show.city && <CitiesComplite setCityId={setCityId} setTotalState={setTotalState} />}
            {show.metro && <MetrosComplite cityId={cityId} setTotalState={setTotalState} />}
            {(show.date || show.time) && 
                <Flex gap="10px" ref={dateRef}>
                    {show.date && 
                        <DateWrapper active={activeDate}>
                            <Typography 
                                color={activeDate ? COLORS.white : COLORS.black}   
                                fontFamily="Montserrat" 
                                lineHeight="24px" 
                                weight="700" 
                                size="12px"
                                align="center"
                                padding="0 25px"
                                onClick={() => {
                                    setShowDate(!showDate)
                                    setShowTime(false)
                                }}
                            >
                                    {titleDate}
                            </Typography>
                            {showDate && 
                                <DateFilter 
                                    dates={dates} 
                                    setDates={setDates} 
                                    showInputs
                                    select
                                    setTitle={setTitleDate}  
                                    setShow={setShowDate} 
                                    setActive={setActiveDate}
                                    disabledDays={disabledDays}
                                    setDisabledDays={setDisabledDays}
                                />
                            }
                        </DateWrapper>
                    }
                    {show.time && 
                        <TimeWrapper ref={timeRef} active={activeTime}>
                            <Typography 
                                color={activeTime ? COLORS.white : COLORS.black} 
                                align="center" 
                                fontFamily="Montserrat" 
                                lineHeight="24px" 
                                weight="700" 
                                size="12px"
                                padding="0 25px"
                                onClick={() => {
                                    setShowTime(!showTime)
                                    setShowDate(false)
                                }}
                            >
                                {titleTime}
                            </Typography>
                            {showTime && 
                                <TimeFilter 
                                    setShow={setShowTime} 
                                    times={times}
                                    setTitle={setTitleTime}
                                    setActive={setActiveTime}
                                    setTimes={setTimes}
                                />
                            }
                        </TimeWrapper>
                    }
                </Flex>
            }
            {show.countPepole && <NumberPeople setTotalState={setTotalState} />}
        </Flex>
    );
};

export default Filters;