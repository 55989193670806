import { ReactComponent as CloseIcon } from "./closeIcon.svg";
import { ReactComponent as BellIcon } from './menu/bellIcon.svg'
import { ReactComponent as CartIcon } from './menu/cartIcon.svg'
import { ReactComponent as OfficeIcon } from './menu/officeBuildingIcon.svg'
import { ReactComponent as SofaIcon } from './menu/sofaIcon.svg'
import { ReactComponent as ProfileIcon } from './menu/profileIcon.svg'
import { ReactComponent as CalendarChevron } from './calendarChevron.svg'
import { ReactComponent as AlaramIcon } from './cabinet/alarmIcon.svg'
import { ReactComponent as PeopleIcon } from './cabinet/peopleIcon.svg'
import { ReactComponent as CarIcon } from './cabinet/carIcon.svg'
import { ReactComponent as BoundingIcon } from './cabinet/boundingIcon.svg'
import { ReactComponent as ArrowBackIcon } from './cabinet/arrowBackIcon.svg'
import { ReactComponent as CalendarIcon } from './cabinet/calendarIcon.svg'
import { ReactComponent as CalendarNumberIcon } from './cabinet/calendarNumberIcon.svg'
import { ReactComponent as MapIcon } from './bookings/mapIcon.svg'
import { ReactComponent as StaticIcon } from './admin/staticIcon.svg'
import { ReactComponent as SettingsIcon } from './admin/settingsIcon.svg'
import { ReactComponent as MeassegeIcon } from './admin/meassegeIcon.svg'
import { ReactComponent as EmailIcon } from './admin/emailIcon.svg'
import { ReactComponent as VkIcon } from './admin/vkIcon.svg'
import { ReactComponent as TelegramIcon } from './admin/telegramIcon.svg'
import { ReactComponent as InstIcon } from './admin/instIcon.svg'
import { ReactComponent as PenIcon } from './admin/penIcon.svg'
import { ReactComponent as PhoneIcon } from './admin/phoneIcon.svg'
import { ReactComponent as UserIcon } from './admin/userIcon.svg'
import { ReactComponent as LoupeIcon } from './admin/loupeIcon.svg'
import { ReactComponent as BankIcon } from './admin/bankIcon.svg'
import { ReactComponent as CityIcon } from './admin/cityIcon.svg'
import { ReactComponent as CardIcon } from './admin/cardIcon.svg'
import { ReactComponent as HouseIcon } from './admin/houseIcon.svg'
import { ReactComponent as PlusIcon } from './admin/plusIcon.svg'
import { ReactComponent as DeleteIcon } from './admin/deleteIcon.svg'
import { ReactComponent as LoaderIcon } from './loaderIcon.svg'
import { ReactComponent as NextIcon } from './slider/nextArrowIcon.svg'
import { ReactComponent as PrevIcon } from './slider/prevArrowIcon.svg'
import { ReactComponent as CloseSliderIcon } from './slider/closeSliderIcon.svg'
import { ReactComponent as NotificationChevron } from './notificationChevron.svg'
import { ReactComponent as SubwayIcon } from './cabinet/subway.svg'


export {
    CloseIcon,
    BellIcon,
    CartIcon,
    OfficeIcon,
    SofaIcon,
    ProfileIcon,
    CalendarChevron,
    AlaramIcon,
    BoundingIcon,
    CarIcon,
    PeopleIcon,
    ArrowBackIcon,
    CalendarIcon,
    CalendarNumberIcon,
    MapIcon,
    StaticIcon,
    SettingsIcon,
    MeassegeIcon,
    EmailIcon,
    VkIcon,
    TelegramIcon,
    InstIcon,
    PenIcon,
    PhoneIcon,
    UserIcon,
    LoupeIcon,
    BankIcon,
    CityIcon,
    CardIcon,
    HouseIcon,
    PlusIcon,
    DeleteIcon,
    LoaderIcon,
    NextIcon,
    PrevIcon,
    CloseSliderIcon,
    NotificationChevron,
    SubwayIcon,
}