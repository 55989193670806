import styled from "styled-components";

const Label = styled.label<{ width?: string, marginLeft?: boolean, size?: string }>`
    width: ${({ width }) => width || 'auto'};
    font-weight: 600;
    font-size: ${({ size }) => size ? size : '16px'};
    line-height: 19px;
    margin-left: ${({ marginLeft }) => marginLeft ? '15px' : '0'};
    margin-bottom: ${({ width }) => width ? 0 : '7px'};   
`;

const SwitchingLabel = styled.label<{ width?: string, marginLeft?: boolean, size?: string, isActive: boolean }>`
    width: ${({ width }) => width || 'auto'};
    font-weight: 600;
    font-size: ${({ size }) => size ? size : '16px'};
    line-height: 19px;
    margin-left: ${({ marginLeft }) => marginLeft ? '15px' : '0'};
    margin-bottom: ${({ width }) => width ? 0 : '7px'};   
    color: ${({ isActive }) => isActive ? '#B2DD91' : '#242424'};
    cursor: pointer;
`;

const InputWrapper = styled.div`
    flex: 1;
    width: 100%;
`

export { Label, InputWrapper, SwitchingLabel }