import moment from 'moment'
import React from 'react'
import { Typography } from '../../../../components/common/Styled/Typography'
import { IOrder } from '../../../../types/interfaces/order'
import { COLORS } from '../../../../utils/constants/colors'
import { fixString } from '../../utils'
import { PurchaseHistoryItemWrapper, InfoWrapper } from './style'

function PurchaseHistoryItem({data}: {data: IOrder}) {

    return (
        <PurchaseHistoryItemWrapper>
            <Typography fontFamily="Montserrat" weight="500" size="12px" lineHeight="24px" color={COLORS.steel}>{moment(data.date).format('DD.MM.YYYY HH:mm')}</Typography>
            <InfoWrapper margin="1px 0 0 0" width="100%" justify="space-between">
                <Typography weight="600" size="16px" lineHeight="18px">{data.subscription ? data.subscription.price_category.name : data.price_category.name }</Typography>
                <Typography weight="600" size="16px" lineHeight="18px">{data.subscription ? data.subscription.hours_all : data.price_category.price * data.count}₽</Typography>
            </InfoWrapper>
            <Typography margin="4px 0 0 0" lineHeight="18px">{data.subscription ? `Абонемент ${data.subscription.name}` : fixString(data.count)}</Typography>
        </PurchaseHistoryItemWrapper>
    )
}

export default PurchaseHistoryItem
