import { debounce } from '../../../../utils/debounce';
import { ChangeType } from '../../../../types/common/index';
import React, { useRef, useState } from "react";
import Input, { IInputProps } from "../Input";

interface DebouncedInputProps extends Omit<IInputProps, 'onChange' | 'value'> {
  searchHandler: Function
  timeOut?: number
}

export const DebouncedInput = ({
  searchHandler,
  timeOut = 400,
  ...props
}: DebouncedInputProps) => {
  const [search, setSearch] = useState<string>('')

  const debouncedSearch = useRef(debounce((callback) => callback(), timeOut))

  const onSearch = (e: ChangeType) => {
    const searchString = e.target.value
    setSearch(searchString)
    debouncedSearch.current(() => searchHandler(searchString))
  }

  return (
    <Input
      onChange={onSearch}
      value={search}
      {...props}
    />
  )
}