import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'

const CabinetStatisticsWrapper = styled.div`
    width: 100%;
    margin-top: 40px;
`

const TimeSelectWrapper = styled.div`
    position: relative;
    width: 100%;

    input {
        background: ${COLORS.white};
    }
`

export { CabinetStatisticsWrapper, TimeSelectWrapper }