import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MapIcon } from '../../../../assets/icons';
import Input from '../../../../components/common/FormElements/Input';
import Select from '../../../../components/common/FormElements/Select';
import { Label } from '../../../../components/common/FormikElements/Input/style';
import { Flex } from '../../../../components/common/Styled/Flex';
import { Typography } from '../../../../components/common/Styled/Typography';
import { OptionType, SetStateType, VoidFuncType } from '../../../../types/common';
import { IBooking } from '../../../../types/interfaces/booking';
import { IOffice } from '../../../../types/interfaces/office';
import { getAllOffices } from '../../../../utils/api/routes';
import { COLORS } from '../../../../utils/constants/colors';
import { HeaderWrapper, ImgWrapper, Status, HeaderInfo, Date, Address, Name } from './style';

interface IProps {
    localTime: {
        date: string,
        time: string
    } | null,
    changed: boolean,
    setCalendarParams: SetStateType<IOffice | null>,
    office: IOffice,
    booking: IBooking,
    showContent: boolean,
    setLocalTime: SetStateType<{ date: string, time: string }>,
    calendarPrams: IOffice | null
}

const CalendarHeader = ({ office, booking, showContent, localTime, changed, setCalendarParams, setLocalTime, calendarPrams }: IProps) => {
    const [offices, setOffices] = useState<IOffice[] | []>([])
    const [currentPage, setCurrentPage] = useState(2)
    const [totalCount, setTotalCount] = useState(0)
    useEffect(() => {
        showContent && setLocalTime({ date: booking?.date.split('-').reverse().join('.'), time: `${booking.before_hours.slice(0, 5)} - ${booking.after_hours.slice(0, 5)}` })
    }, [changed])

    const request = (finalyCallback: VoidFuncType) => {
        changed && getAllOffices(currentPage, 15, `&city=${office.city.name}`)
            .then(res => {
                setOffices([...offices, ...res.data.results])
                setCurrentPage(prevState => prevState + 1)
            })
            .finally(finalyCallback)
    }

    useEffect(() => {
        changed && getAllOffices(1, 15, `&city=${office.city.name}`)
            .then(res => {
                setOffices(res.data.results)
                setTotalCount(res.data.count)
            })
    }, [changed])

    const getOptions = () => {
        let optionsArr = [] as Array<OptionType>
        if (offices.length) {
            optionsArr = [...optionsArr, ...offices.map(item => ({ value: item.id, name: `${item.name} кабинет, ${item.address}` }))]
        }

        if (office && !offices.find(item => item.id === office.id)) {
            optionsArr = [...optionsArr, { value: office.id, name: `${office.name} кабинет, ${office.address}` }]
        }

        return optionsArr
    }

    const selectOffice = (id: number) => {
        const elem = offices.find(item => item.id === id)
        elem && setCalendarParams(elem)
    }

    return (
        <HeaderWrapper margin="0 0 30px 0" justify="flex-start">
            {showContent &&
                <>
                    <ImgWrapper>
                        <img src={office.photos[0].photo} alt="Фото кабинета" />
                    </ImgWrapper>
                    <HeaderInfo width="100%" align="flex-start" direction="column" margin="0 0 0 30px">
                        <Flex width={changed ? 'calc(70% - 2px)' : '100%'} align="flex-start" direction="column">
                            {!changed &&
                                <>
                                    <Name
                                        weight="700"
                                        size="22px"
                                        lineHeight="26px"
                                    >
                                        {office.name} кабинет
                                    </Name>
                                    <Address
                                        weight="500"
                                        fontFamily="Montserrat"
                                        color={COLORS.steel}
                                        lineHeight="24px"
                                        margin="10px 0 0 0"
                                    >
                                        <MapIcon />
                                        &nbsp;
                                        {office.address}
                                    </Address>
                                    {booking!.status! === 1 &&
                                        <Flex justify="flex-start">
                                            <Typography
                                                weight="500"
                                                fontFamily="Montserrat"
                                                color={COLORS.steel}
                                                lineHeight="24px"
                                            >
                                                Пароль:
                                            </Typography>
                                            <Typography
                                                weight="700"
                                                lineHeight="18px"
                                                size="16px"
                                                margin="0 0 0 10px"
                                            >
                                                {office.password}
                                            </Typography>
                                        </Flex>
                                    }
                                </>
                            }
                            {changed &&
                                <Flex align="flex-start" width="100%" direction="column">
                                    <Label marginLeft size="14px">Выберите кабинет</Label>
                                    <Select
                                        options={
                                            getOptions()
                                        }
                                        onChange={(value) => selectOffice(+value)}
                                        value={calendarPrams!.id}
                                        totalCount={totalCount}
                                        request={request}
                                        currentLength={offices.length}
                                    />
                                </Flex>
                            }
                        </Flex>
                        <Date width="70%" justify="flex-start" margin="30px 0 0 0">
                            {!changed &&
                                <Typography
                                    weight="700"
                                    size="22px"
                                    lineHeight="26px"
                                >
                                    {moment(booking.date).format('DD MMMM')},
                                    &nbsp;
                                    {booking.before_hours.slice(0, 5)} - {booking.after_hours.slice(0, 5)}
                                </Typography>
                            }
                            {changed &&
                                <Flex align="flex-start" width="100%" direction="column">
                                    <Label marginLeft size="14px">Выберите день и время на календаре</Label>
                                    <Input
                                        onChange={() => { }}
                                        value={`${localTime!.date}, ${localTime?.time}`}
                                        disabled
                                    />
                                </Flex>
                            }
                        </Date>
                    </HeaderInfo>
                    <Status
                        status={booking!.status!}
                    >
                        <Typography
                            weight="600"
                            size="13px"
                            lineHeight="16px"
                            color={COLORS.steel}
                        >
                            {booking.status === 1 ? 'Подтверждено' : booking.status === 2 ? 'Ожидает подтверждения' : 'В очереди'}
                        </Typography>
                    </Status>
                </>
            }
        </HeaderWrapper>
    );
};

export default CalendarHeader;