import { Formik, Form, Field, FormikProps, FormikValues } from 'formik';
import React, { useState } from 'react';
import { FormCard, Logout } from './style';
import FormikInput from '../../../components/common/FormikElements/Input';
import { Button } from '../../../components/common/Styled/Button';
import { useDispatch, useSelector } from 'react-redux';
import { logout, updateProfile } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/reducers/rootReducer';
import { Flex } from '../../../components/common/Styled/Flex';
import Modal from '../../../components/Modal';
import ChangePopup from '../ChangePopup';
import { Typography } from '../../../components/common/Styled/Typography';
import { COLORS } from '../../../utils/constants/colors';
import Cookies from 'js-cookie';
import ErrorHandler from '../../../components/Error';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { EMAIL, linkValidation, PHONE } from '../../../utils/formik/validation';
import * as Yup from 'yup'

const ProfileForm = ({ isMobile }: { isMobile: boolean }) => {
	const [change, setChange] = useState(false);
	const [togglePasswordModal, setTogglePasswordModal] = useState(false)
	const dispatch = useDispatch();
	const { show, active, hidden, text } = useErrorHandler()
	const { profile } = useSelector((state: RootState) => state.user);

	const initial = {
		name: `${profile?.last_name} ${profile.first_name} ${profile.middle_name}` || '',
		phone: profile?.phone || '',
		email: profile?.email || '',
		vk: profile?.vk || '',
		telegram: profile?.telegram || '',
		instagram: profile?.instagram || ''
	};

	const successCallback = () => {
		setChange(false)
	}

	const errorCallback = (err: unknown) => {
		show(err)
	}

	const submitProfileData = (value: typeof initial) => {
		const body = {
			...value,
			first_name: value.name.split(' ')[1],
			last_name: value.name.split(' ')[0],
			middle_name: value.name.split(' ')[2] ?? ''
		}
		dispatch(
			updateProfile({
				profile: body,
				successCallback,
				errorCallback
			})
		)
	}

	const inputs = [
		{ id: 1, name: 'name', label: 'ФИО', disable: true },
		{ id: 2, name: 'email', label: 'Email', disable: false },
		{ id: 3, name: 'phone', label: 'Телефон', disable: false },
		{ id: 4, name: 'vk', label: 'Вконтакте', disable: false, placeholder: 'https://vk.com/id' },
		{ id: 5, name: 'instagram', label: 'Instagram', disable: false, placeholder: 'https://www.instagram.com/ник' },
		{ id: 6, name: 'telegram', label: 'Telegram', disable: false, placeholder: 'https://t.me/ник' },
	]

	const schema = Yup.object({
		email: EMAIL,
		phone: PHONE,
		vk: linkValidation('vk.com'),
		instagram: linkValidation('www.instagram.com'),
		telegram: linkValidation('t.me'),
	})

	return (
		<FormCard>
			<ErrorHandler active={active} hidden={hidden} text={text} />
			<Flex className="wrapper" padding={isMobile ? '25px 25px 0 25px' : '50px 50px 0 50px'}>
				<Formik
					initialValues={initial}
					onSubmit={(value, { resetForm }) => {
						submitProfileData(value)
						resetForm({ values: value })
					}}
					validationSchema={schema}
				>
					{({ handleReset }: FormikProps<FormikValues>) => (
						<Form>
							{inputs.map(item =>
								<Flex key={item.id} className="field" justify="flex-start" margin="0 0 40px 0" width="100%">
									<Field
										name={item.name}
										component={FormikInput}
										label={item.label}
										theme={'underline'}
										placeholder={item.placeholder ?? item.label}
										key={item.id}
										disabled={!change || item.disable}
										labelWidth="160px"
									/>
								</Flex>
							)}

							{!change &&
								<Flex margin="0 0 10px 0" justify="space-between" align="flex-start">
									<Button
										type="button"
										theme="grey"
										fontSize="14px"
										lineHeight="17px"
										radius="21px"
										weight={600}
										width={isMobile ? '150px' : '200px'}
										height="40px"
										onClick={() => setChange(!change)}
									>
										Редактировать&nbsp;данные
									</Button>
									<Button
										type="button"
										theme="grey"
										fontSize="14px"
										lineHeight="17px"
										radius="21px"
										weight={600}
										width={isMobile ? '150px' : '200px'}
										height="40px"
										onClick={() => setTogglePasswordModal(true)}
									>
										Сменить пароль
									</Button>
								</Flex>
							}
							{change &&
								<Flex margin="0 0 10px 0" justify="space-between">
									<Button
										type="button"
										theme="beige"
										fontSize="14px"
										lineHeight="17px"
										radius="21px"
										weight={600}
										width={isMobile ? '100px' : '150px'}
										height="40px"
										onClick={() => {
											setChange(false)
											handleReset()
										}}
									>
										Отменить
									</Button>
									<Button
										type="submit"
										theme="green"
										fontSize="14px"
										lineHeight="17px"
										radius="21px"
										weight={600}
										width={isMobile ? '100px' : '150px'}
										height="40px"

									>
										Сохранить
									</Button>
								</Flex>
							}
						</Form>
					)}
				</Formik>
			</Flex>
			{togglePasswordModal &&
				<Modal
					component={ChangePopup}
					closeModal={() => setTogglePasswordModal(false)}
				/>
			}
			<Logout
				onClick={() => {
					dispatch(logout())
					Cookies.remove('access')
					Cookies.remove('refresh')
				}}
				padding='13px 0'
			>
				<Typography weight="600" size="16px" lineHeight="19px" color={COLORS.red}>Выйти из аккаунта</Typography>
			</Logout>
		</FormCard>
	);
};

export default ProfileForm;
