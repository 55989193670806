import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Grid } from '../../../../components/common/Styled/Grid'
import { COLORS } from '../../../../utils/constants/colors'

const AddCabinetWrapper = styled(Flex)`
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
    min-height: 100vh;
    input, textarea {
        background: ${COLORS.white};
    }

    & button svg {
        width: 30px;
        height: 30px;

        & path {
            fill: ${COLORS.white};
        }
    }

`

const Photo = styled(Flex)`
    position: relative;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    border: 1px solid #DECEB7;
    border-radius: 6px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }
`
const ModalPhoto = styled(Flex)`
    width: 500px;
    height: 400px;
    border-radius: 6px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }
`

const Close = styled(Flex)`
    position: absolute;
    width: 16px;
    height: 16px;
    background: ${COLORS.red};
    border-radius: 50%;
    top: -5px;
    right: -5px;
    cursor: pointer;
    svg {
        width: 8px;
        height: 8px;
        transition: transform .2s linear;
    }

    path {
        fill: ${COLORS.white}
    }

    &:hover {
        svg {
            transform: rotate(180deg);
        }
    }
`

const Plus = styled(Flex)`
    width: 16px;
    height: 16px;
    background: ${COLORS.black};
    border-radius: 50%;

    svg {
        width: 12px;
        height: 12px;
    }

    path {
        fill: ${COLORS.white}
    }
`

const DaySlect = styled(Grid)`
    position: relative;
    ${Close} {
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
    }    
`

const PhotoWrapper = styled(Flex)`
    padding: 10px 0;
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden!important;
    

    ::-webkit-scrollbar {
        height: 8px;
    }
`

export {AddCabinetWrapper, Photo, Close, Plus, DaySlect, PhotoWrapper, ModalPhoto}
