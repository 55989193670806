import React from 'react';
import { Button } from '../../../../components/common/Styled/Button';
import { Flex } from '../../../../components/common/Styled/Flex';
import { Typography } from '../../../../components/common/Styled/Typography';
import ErrorHandler from '../../../../components/Error';
import { VoidFuncType } from '../../../../types/common';
import { DeleteMetroConfirmPopupWrapper } from './style';

interface IProps {
    confirmFunction: VoidFuncType
}

const DeleteCabinetPopup = ({confirmFunction}: IProps) => {
    return (
        <DeleteMetroConfirmPopupWrapper>
            <Flex padding="10px 0" border="1px solid #DECEB7">
                <Typography weight="700" size="18px" lineHeight="21px">Удалить кабинет</Typography>
            </Flex>
            <Flex padding="10px" direction="column">
                <Typography size="18px" lineHeight="21px" align="center">Вместе с кабинетом удалятся все бронирования, связанные с ним. <br /> Вы уверены?</Typography>
                <Button
                    margin="15px"
                    onClick={confirmFunction}
                    theme="grey"
                    width="155px"
                    height="40px"
                    radius="21px"
                    weight="600"
                    lineHeight="17px"
                >
                    Да, я уверен(a)
                </Button>
            </Flex>
        </DeleteMetroConfirmPopupWrapper>
    );
};

export default DeleteCabinetPopup;