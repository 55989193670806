import { id } from 'date-fns/locale';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarIcon, CalendarNumberIcon, LoaderIcon } from '../../../assets/icons';
import Calendar from '../../../components/Calendar';
import { Button } from '../../../components/common/Styled/Button';
import { CalendarWrapper } from '../../../components/common/Styled/CalendarWrapper';
import { Flex } from '../../../components/common/Styled/Flex';
import { Grid } from '../../../components/common/Styled/Grid';
import { Typography } from '../../../components/common/Styled/Typography';
import ErrorHandler from '../../../components/Error';
import Filters from '../../../components/Filters';
import Modal from '../../../components/Modal';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { usePagination } from '../../../hooks/usePagination';
import { useWrapperSize } from '../../../hooks/useWrapperSize';
import { bookingsGet, setActiveCabinetId, setActiveDateRange } from '../../../store/reducers/bookingReducer';
import { getOffices } from '../../../store/reducers/officesReducer';
import { RootState } from '../../../store/reducers/rootReducer';
import { SetStateType } from '../../../types/common';
import { IBooking } from '../../../types/interfaces/booking';
import { IOffice } from '../../../types/interfaces/office';
import { cancelBooking, changeBooking, deleteBooking, createBooking, сonfirmBooking } from '../../../utils/api/routes';
import { COLORS } from '../../../utils/constants/colors';
import { bookingStarted } from '../../../utils/formik/validation';
import { showAlert } from '../../../utils/showAlert';
import { timeToNumber } from '../../adminPages/Bookings/BookingsPopup/utils';
import { debounce } from '../../../utils/debounce'
import CancelPopup from '../../Bookings/BookingSchedule/CancelPopup';
import CabinetPreview from './CbinetPreview';
import { ButtonsList, CabinetWrapper, CalendarTitle, List, PaddingWrapper } from './style';
import { isActiveBookingStarted } from '../../../utils/isBookingStarted'

interface IProps {
    offices: IOffice[]
    count: number
    isMobile: boolean
    setShowCalendar: SetStateType<boolean>
    showCalendar: boolean
}

const CabinetsSchedule = ({ offices, count, isMobile, setShowCalendar, showCalendar }: IProps) => {
    const { bookings, loading } = useSelector((state: RootState) => state.bookings)
    const { id } = useSelector((state: RootState) => state.user.profile)
    const [calendarPrams, setCalendarParams] = useState<IOffice | null>(null)
    const [queryString, setQueryString] = useState('')
    const { fetching, scroll, finalyCallback, successCallback, currentPage, personalChenging } = usePagination(count === offices.length)
    const [localTime, setLocalTime] = useState({ date: '', time: '' })
    const [viewCalendar, setViewCalendar] = useState(7 as 7 | 1)
    const [dates, setDates] = useState({ dateAfter: '', dateBefore: '' })
    const [modalOpen, setModalOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [buttonText, setButtonText] = useState('Забронировать')
    const [toNow, setToNow] = useState(false)
    const { show, active, hidden, text } = useErrorHandler()
    const [activeBooking, setActiveBooking] = useState<IBooking | null>(null)
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)

    const { size } = useWrapperSize(ref, isMobile, [])

    const showCabinet = (id: number) => {
        const office = offices.find(item => item.id === id)
        office && setCalendarParams(office)
    }

    const filtretingSuccess = () => personalChenging(2)

    useEffect(() => {
        offices.length && setCalendarParams(offices[0])
        offices.length && dispatch(setActiveCabinetId(offices[0].id))
    }, [offices])

    const bookingRequest = () => {
        if (calendarPrams) {
            const queryString = `&date_after=${dates.dateAfter}&date_before=${dates.dateBefore}&office=${calendarPrams!.id}&canceled=False&is_active=true&visited=false`
            dispatch(bookingsGet({ queryString, pageSize: 250, all: true }))
        }
    }

    useEffect(() => {
        dates.dateAfter && dates.dateBefore && bookingRequest()
        dispatch(setActiveDateRange(dates))
    }, [calendarPrams, dates])

    useEffect(() => {
        queryString && dispatch(getOffices({ filtration: true, page: 1, successCallback: filtretingSuccess, finalyCallback, pageSize: 15, queryString: queryString + '&status=true' }))
    }, [queryString, dispatch])

    useEffect(() => {
        fetching && dispatch(getOffices({ page: currentPage, successCallback, finalyCallback, pageSize: 15, queryString: queryString + '&status=true' }))
    }, [fetching, dispatch])

    useEffect(() => {
        setActiveBooking(null)
    }, [calendarPrams])

    const intial = {
        "1": "08:00-22:00",
        "2": "08:00-22:00",
        "3": "08:00-22:00",
        "4": "08:00-22:00",
        "5": "08:00-22:00",
        "6": "08:00-22:00",
        "7": "08:00-22:00"
    }

    const bookingClickHandler = debounce((booking: IBooking) => {
        if (booking && isActiveBookingStarted(booking)) {
            showAlert('error', 'Вы не можете редактировать бронирование, которое уже началось')
            return
        }
        setActiveBooking(booking)
    }, 200)

    const cancel = async () => {
        if (activeBooking && isActiveBookingStarted(activeBooking)) {
            showAlert('error', 'Вы не можете отменить бронирование, которое уже началось')
            setActiveBooking(null)
            return
        }

        if (activeBooking) {
            if (activeBooking.status !== 3 && !confirmCancel && !moment(activeBooking.created).isSame(moment(), 'day')
                && (
                    (moment.duration(moment(`${activeBooking!.date} ${activeBooking!.before_hours}`).diff(moment())).asHours() <= 24)
                    ||
                    (timeToNumber(activeBooking.after_hours) >= 17
                        &&
                        moment.duration(moment(`${activeBooking!.date} ${activeBooking!.before_hours}`).diff(moment())).asHours() <= 48))
            ) {
                return false
            }
            await cancelBooking({ group: activeBooking.id })
                .then(() => showAlert('success', 'Бронирование успешно отменено'))
                .catch((e) => show(e))
            bookingRequest()
            setActiveBooking(null)
            setShowCalendar(false)
            return true
        }
    }
    const save = async () => {
        if (activeBooking && isActiveBookingStarted(activeBooking)) {
            showAlert('error', 'Вы не можете перенести бронирование, которое уже началось')
            setActiveBooking(null)
            return
        }

        if (activeBooking && calendarPrams) {
            const newDate = localTime.date.split('.').reverse().join('-')
            const timeToZero = (time: string) => +time.split(':')[0] === 24 ? `00:${time.split(':')[1] + ':' + time.split(':')[2]}` : time
            // if(moment(`${newDate} ${localTime.time.split('-')[1].trim()}:00`, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(), 'minutes')) return show({data: 'Бронирование прошло'})
            const body = {
                peoples: activeBooking.peoples,
                date: newDate,
                office: calendarPrams.id,
                user: id,
                after_hours: timeToZero(`${localTime.time.split('-')[1].trim()}:00`),
                before_hours: `${localTime.time.split('-')[0].trim()}:00`
            }

            if (activeBooking.office.id === calendarPrams.id) {
                await changeBooking(activeBooking.id, { after_hours: body.after_hours, before_hours: body.before_hours, date: newDate })
                    .then(() => showAlert('success', 'Редактирование бронирования прошло успешно'))
                    .catch((e) => show(e))
                setActiveBooking(null)
                bookingRequest()
                return
            }

            await deleteBooking(activeBooking.id).catch((e) => show(e))
            await createBooking(body).catch((e) => show(e))

            setActiveBooking(null)
        }
    }

    const confirm = async () => {
        if (activeBooking) {
            await сonfirmBooking({ group: activeBooking.id })

                .catch((e) => show(e))
            bookingRequest()
            setActiveBooking(null)
        }
    }

    const customsButtons = useMemo(() => {
        if (!activeBooking) return null

        const arrButtons: Array<{ text: string, theme: string, callback: () => void, access: boolean }> = activeBooking.status === 1 ?

            [
                { text: 'Отменить', theme: 'grey', callback: () => setShowModal(true), access: !bookingStarted(activeBooking) },
                { text: 'Отменить редактирование', theme: 'red', callback: () => setActiveBooking(null), access: true },
                { text: 'Сохранить', theme: 'green', callback: save, access: true }
            ]

            : activeBooking.status === 2 ?

                [
                    { text: 'Отменить', theme: 'grey', callback: cancel, access: !bookingStarted(activeBooking) },
                    { text: 'Отменить редактирование', theme: 'red', callback: () => setActiveBooking(null), access: true },
                    { text: 'Подтвердить', theme: 'green', callback: confirm, access: true }
                ]

                :

                [
                    { text: 'Отменить редактирование', theme: 'red', callback: () => setActiveBooking(null), access: true },
                    { text: 'Покинуть очередь', theme: 'red', callback: cancel, access: true }
                ]

        return (
            <ButtonsList width="100%" justify={arrButtons.length > 1 ? 'space-between' : 'flex-end'} padding="0 20px 20px 20px">
                {arrButtons.map((item, index) =>
                    <Button
                        key={index}
                        onClick={item.access ? item.callback : () => { }}
                        type="button"
                        theme={item.theme}
                        fontSize="14px"
                        lineHeight="17px"
                        radius="21px"
                        weight={600}
                        width="150px"
                        height="40px"
                        disabled={!item.access}
                    >
                        {item.text}
                    </Button>
                )}
            </ButtonsList>
        )

    }, [activeBooking, localTime])

    return (
        <CabinetWrapper width="100%" align="flex-start" direction="column">
            <ErrorHandler active={active} hidden={hidden} text={text} />
            {!showCalendar &&
                <Filters
                    show={{
                        time: true,
                        date: true,
                        countPepole: true,
                        city: true,
                        metro: true
                    }}
                    setQueryString={setQueryString}
                />
            }
            <Grid w="100%" columns="25% 72%" gap="40px" margin={isMobile ? '15px 0 0' : '30px 0 0'}>
                {!showCalendar &&
                    <List height={size} ref={ref} onScroll={scroll}>
                        {offices.map(item =>
                            <CabinetPreview
                                showCalendar={setShowCalendar}
                                isMobile={isMobile}
                                showCabinet={showCabinet}
                                show={item.id === (calendarPrams && calendarPrams.id)}
                                key={item.id}
                                data={item}
                            />
                        )}
                    </List>
                }
                {(showCalendar || (calendarPrams && !isMobile)) &&
                    <CalendarWrapper notScroll>
                        <CalendarTitle justify="space-between" padding="20px">
                            <Typography weight="800" size="24px" lineHeight="29px">{calendarPrams?.name}, {calendarPrams?.address}</Typography>
                            <Flex>
                                <Button
                                    width="120px"
                                    height="25px"
                                    radius="21px"
                                    margin="0 10px 0 0"
                                    onClick={() => setToNow(true)}
                                    color={COLORS.black}
                                    weight="700"
                                >
                                    Сегодня
                                </Button>
                                {viewCalendar === 1 ? <CalendarIcon onClick={() => setViewCalendar(7)} /> : <CalendarNumberIcon onClick={() => setViewCalendar(1)} />}
                            </Flex>
                        </CalendarTitle>
                        <PaddingWrapper padding="10px 20px 30px 20px">
                            <Calendar
                                data={bookings}
                                view={viewCalendar}
                                step={calendarPrams ? calendarPrams.occupation_step : 1}
                                schedule={(calendarPrams && calendarPrams!.schedule) || intial}
                                calendarPrams={calendarPrams!}
                                setBookings={bookingRequest}
                                setDates={setDates}
                                modalOpen={modalOpen}
                                setButtonText={setButtonText}
                                setModalOpen={setModalOpen}
                                toNow={toNow}
                                setToNow={setToNow}
                                loading={loading}
                                noQueue={false}
                                showMyBooking
                                setLocalTime={setLocalTime}
                                bookingClickHandler={(booking: IBooking) => bookingClickHandler(booking)}
                            />
                        </PaddingWrapper>
                        {activeBooking ?
                            { ...customsButtons }
                            :
                            <Flex justify="flex-end" padding="0 20px 20px 0">
                                <Button
                                    onClick={() => setModalOpen(true)}
                                    type="button"
                                    theme="green"
                                    fontSize="14px"
                                    lineHeight="17px"
                                    radius="21px"
                                    weight={600}
                                    width="150px"
                                    height="40px"
                                >
                                    {buttonText}
                                </Button>
                            </Flex>
                        }
                    </CalendarWrapper>
                }
            </Grid>
            {showModal &&
                <Modal
                    component={CancelPopup}
                    closeModal={() => { setShowModal(false); setConfirmCancel(false) }}
                    parameters={{
                        cancel,
                        setConfirmCancel
                    }}
                    haveCloseBtn
                />
            }
        </CabinetWrapper>
    );
};

export default CabinetsSchedule;