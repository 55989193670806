import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'
import { Flex } from '../../Styled/Flex'

const FileInputWrapper = styled(Flex)`
    width: 100px;
    height: 100px;
    input {
        height: 0px;
        width: 0px;
        visibility: hidden;
        opacity: 0;
    }
`

const StyledInput = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background: ${COLORS.white};
    border: 1px solid #DECEB7;
    border-radius: 6px;
    cursor: pointer;
    transition: background .2s linear;

    path {
        transition: fill .2s linear;
    }

    &:hover {
        background: ${COLORS.lightBrown};
        path {
            fill: ${COLORS.white};
        }
    }
    
`
export { FileInputWrapper, StyledInput }