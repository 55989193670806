import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'


const List = styled.ul`
    position: absolute;
    background: ${COLORS.white};
    min-width: 100%;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    top: 100%;
    z-index: 99;
`

const ListItem = styled.li`
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid ${COLORS.brown};
    width: 100%;
    height: 34px;
    padding: 0 0 0 10px;
    transition: background .2s linear;
    &:hover {
        background: ${COLORS.beige};
    }
`

export {List, ListItem}