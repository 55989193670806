import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarIcon, CalendarNumberIcon } from '../../../assets/icons';
import Calendar from '../../../components/Calendar';
import { CalendarWrapper } from '../../../components/common/Styled/CalendarWrapper';
import { Flex } from '../../../components/common/Styled/Flex';
import { Typography } from '../../../components/common/Styled/Typography';
import { RootState } from '../../../store/reducers/rootReducer';
import { IBooking } from '../../../types/interfaces/booking';
import { IOffice } from '../../../types/interfaces/office';
import { cancelBooking, changeBooking, createBooking, deleteBooking, сonfirmBooking } from '../../../utils/api/routes';
import { Container, BookingsWrapper, Message, CalendarTitle } from './style';
import CalendarHeader from './CalendarHeader';
import ScheduleItem from './ScheduleItem';
import {
    bookingCancel,
    bookingsConfirm,
    bookingsGet,
    setActiveCabinetId,
    setActiveDateRange
} from '../../../store/reducers/bookingReducer';
import { Button } from '../../../components/common/Styled/Button';
import { usePagination } from '../../../hooks/usePagination';
import { SetStateType, VoidFuncType } from '../../../types/common';
import { useWrapperSize } from '../../../hooks/useWrapperSize';
import Modal from '../../../components/Modal';
import CancelPopup from './CancelPopup';
import moment from 'moment';
import ErrorHandler from '../../../components/Error';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { COLORS } from '../../../utils/constants/colors';
import { bookingStarted } from '../../../utils/formik/validation';
import { useParams } from 'react-router-dom';
import { isActiveBookingStarted } from '../../../utils/isBookingStarted'
import { showAlert } from '../../../utils/showAlert';

interface IProps {
    isMobile: boolean
    showCalendar: boolean
    setShowCalendar: SetStateType<boolean>
}

const BookingSchedule = ({ isMobile, showCalendar, setShowCalendar }: IProps) => {
    const { id, calendar_blocked } = useSelector((state: RootState) => state.user.profile ? state.user.profile : {
        id: 0,
        calendar_blocked: false
    })
    const { bookings, myBookings, myCount, loading } = useSelector((state: RootState) => state.bookings)
    const [viewCalendar, setViewCalendar] = useState(7 as 1 | 7)
    const [calendarPrams, setCalendarParams] = useState<IOffice | null>(null)
    const [dates, setDates] = useState({ dateAfter: '', dateBefore: '' })
    const [activeBooking, setActiveBooking] = useState<IBooking | null>(null)
    const [changed, setChanged] = useState(false)
    const [localTime, setLocalTime] = useState({ date: '', time: '' })
    const [chengedGroup, setChengedGroup] = useState<number | null>(null)
    const [toNow, setToNow] = useState(false)
    const { show, active, hidden, text } = useErrorHandler()
    const [showModal, setShowModal] = useState(false)
    const dispatch = useDispatch()
    const { id: bookingId } = useParams<{ id: string }>();
    const {
        fetching,
        scroll,
        currentPage,
        finalyCallback,
        successCallback,
        resetCallback
    } = usePagination(myCount === myBookings.length)
    const ref = useRef<HTMLDivElement>(null)

    const { size } = useWrapperSize(ref, isMobile, [myBookings])

    const requestForBooking = (pageSize: number, queryString: string, all: boolean, pagination = false, successCallback?: VoidFuncType, finalyCallback?: VoidFuncType) => {
        const newQueryString = `&canceled=False` + queryString
        dispatch(bookingsGet({ queryString: newQueryString, pageSize, all, pagination, successCallback, finalyCallback }))
    }

    const forceUpdate = () => {
        resetCallback()
        requestForBooking(15, `&user=${id}&page=1&visited=False`, false, false, successCallback)
    }

    useEffect(() => {
        if (!changed) {
            forceUpdate()
        }
    }, [viewCalendar, changed])

    useEffect(() => {
        fetching && requestForBooking(15, `&page=${currentPage}&user=${id}&visited=False`, false, true, successCallback, finalyCallback)
    }, [fetching])

    useEffect(() => {
        activeBooking && setCalendarParams(activeBooking.office)
    }, [activeBooking])

    useEffect(() => {
        if ((dates.dateBefore || dates.dateAfter) && calendarPrams) {
            dispatch(setActiveDateRange(dates))
            requestForBooking(250, `&date_after=${dates.dateAfter}&date_before=${dates.dateBefore}&office=${calendarPrams!.id}`, true)
        }
    }, [calendarPrams, dates])

    useEffect(() => {
        if (!myBookings[0]) return
        const selectedBooking = myBookings.find(booking => booking.id === +bookingId)
        if (selectedBooking) {
            setActiveBooking(selectedBooking)
            dispatch(setActiveCabinetId(selectedBooking.office.id))
            return
        }
        if (!!myBookings.length && !activeBooking) {
            setActiveBooking(myBookings[0])
            dispatch(setActiveCabinetId(myBookings[0].office.id))
        }
    }, [myBookings])

    useEffect(() => {
        changed && activeBooking && setChengedGroup(activeBooking.id)
    }, [changed])

    const onSelectBooking = (item: IBooking) => {
        if (activeBooking && activeBooking.id === item.id) return
        setActiveBooking(item)
    }

    const intial = {
        "1": "08:00-22:00",
        "2": "08:00-22:00",
        "3": "08:00-22:00",
        "4": "08:00-22:00",
        "5": "08:00-22:00",
        "6": "08:00-22:00",
        "7": "08:00-22:00"
    }

    const cancel = async () => {
        if (activeBooking) {
            setActiveBooking(myBookings[0] ?? null)
            dispatch(bookingCancel({
                group: activeBooking.id,
                successCallback: () => {
                    forceUpdate()
                    setActiveBooking(myBookings[1] || null)
                }
            }))
            setShowCalendar(false)
            return true
        }
    }

    const save = async () => {
        if (activeBooking && calendarPrams) {
            const newDate = localTime.date.split('.').reverse().join('-')

            const timeToZero = (time: string) => +time.split(':')[0] === 24 ? `00:${time.split(':')[1] + ':' + time.split(':')[2]}` : time

            if (moment(`${newDate} ${localTime.time.split('-')[1].trim()}:00`, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(), 'minutes')) return show({ data: 'Бронирование прошло' })
            const body = {
                peoples: activeBooking.peoples,
                date: newDate,
                office: calendarPrams.id,
                user: id,
                after_hours: timeToZero(`${localTime.time.split('-')[1].trim()}:00`),
                before_hours: `${localTime.time.split('-')[0].trim()}:00`
            }

            if (activeBooking.office.id === calendarPrams.id) {
                await changeBooking(activeBooking.id, {
                    after_hours: body.after_hours,
                    before_hours: body.before_hours,
                    date: newDate
                }).then(res => setActiveBooking(res.data)).catch((e) => show(e))
                setChanged(false)
                return
            }

            await deleteBooking(activeBooking.id).catch((e) => show(e))
            await createBooking(body).then(res => setActiveBooking(res.data)).catch((e) => show(e))

            setChanged(false)
        }
    }

    const confirm = async () => {
        if (activeBooking) {
            await сonfirmBooking({ group: activeBooking.id }).then(res => setActiveBooking(res.data))
            forceUpdate()

        }
    }

    if (myBookings.length === 0) {
        return (
            <Message align="center" width="100%">
                <Typography weight="700" size="18px" align="center">Забронируйте кабинет для сеанса, чтобы увидеть Ваши
                    бронирования в этом разделе</Typography>
            </Message>
        )
    }
    return (
        <Container columns="26% 1fr" gap="40px">
            <ErrorHandler active={active} hidden={hidden} text={text} />
            {!showCalendar &&
                <BookingsWrapper ref={ref} height={size} onScroll={scroll} justify="flex-start" width="100%"
                    direction="column">
                    {myBookings.map(item =>
                        <ScheduleItem
                            setShowCalendar={setShowCalendar}
                            isMobile={isMobile}
                            active={item.id === activeBooking?.id}
                            setActiveBooking={onSelectBooking}
                            key={item.id}
                            item={item}
                        />
                    )}
                </BookingsWrapper>
            }
            {(showCalendar || (activeBooking && !isMobile)) &&
                <CalendarWrapper padding="20px">
                    <CalendarHeader
                        showContent={!!activeBooking && !!calendarPrams}
                        booking={activeBooking!}
                        office={calendarPrams!}
                        localTime={localTime}
                        changed={changed}
                        setCalendarParams={setCalendarParams}
                        setLocalTime={setLocalTime}
                        calendarPrams={calendarPrams}
                    />
                    <CalendarTitle margin="0 0 20px 0" justify="space-between">
                        <Typography
                            weight="700"
                            size="22px"
                            lineHeight="18px"
                        >
                            Календарь
                        </Typography>
                        <Flex>
                            <Button
                                width="120px"
                                height="25px"
                                color={COLORS.black}
                                weight="700"
                                radius="21px"
                                margin="0 10px 0 0"
                                onClick={() => {
                                    setToNow(true)
                                }}
                            >
                                Сегодня
                            </Button>
                            {viewCalendar === 1 ? <CalendarIcon onClick={() => setViewCalendar(7)} /> :
                                <CalendarNumberIcon onClick={() => setViewCalendar(1)} />}
                        </Flex>
                    </CalendarTitle>
                    <Calendar
                        view={viewCalendar}
                        data={bookings}
                        setBookings={() => { }}
                        setDates={setDates}
                        schedule={calendarPrams ? calendarPrams!.schedule : intial}
                        step={calendarPrams ? calendarPrams!.occupation_step : 1}
                        notDraw={!changed}
                        selectedBooking={activeBooking?.office.id === calendarPrams?.id ? activeBooking : null}
                        setLocalTime={setLocalTime}
                        chengedGroup={chengedGroup}
                        toNow={toNow}
                        setToNow={setToNow}
                        loading={loading}
                        noQueue={false}
                    />
                    {!calendar_blocked && activeBooking && activeBooking.status !== 3 &&
                        <Flex width="100%" justify="space-between" margin="25px 0 0 0">
                            {activeBooking && activeBooking.status === 2 &&
                                <>

                                    <Button
                                        theme="darkRed"
                                        radius="21px"
                                        width="155px"
                                        height="40px"
                                        weight="600"
                                        lineHeight="17px"
                                        onClick={!bookingStarted(activeBooking) ? cancel : () => {
                                        }}
                                        disabled={bookingStarted(activeBooking)}
                                    >
                                        Отменить
                                    </Button>

                                    <Button
                                        theme="green"
                                        radius="21px"
                                        width="155px"
                                        height="40px"
                                        weight="600"
                                        lineHeight="17px"
                                        onClick={confirm}
                                    >
                                        Подтвердить
                                    </Button>
                                </>
                            }
                            {!calendar_blocked && activeBooking && activeBooking.status === 1 &&
                                <>
                                    <Button
                                        theme={isActiveBookingStarted(activeBooking) ? '' : 'beige'}
                                        background={isActiveBookingStarted(activeBooking) ? '#BCC0C3 !important' : '#EDE8DA'}
                                        radius="21px"
                                        width="155px"
                                        height="40px"
                                        weight="600"
                                        lineHeight="17px"
                                        onClick={!isActiveBookingStarted(activeBooking)
                                            ?
                                            () => {
                                                if (changed) {
                                                    setChanged(false)
                                                    setCalendarParams(activeBooking.office)
                                                    return
                                                }
                                                setShowModal(true)
                                            }
                                            :
                                            () => { showAlert('error', 'Вы не можете отменить бронирование, которое уже началось') }

                                        }
                                    >
                                        Отменить
                                    </Button>
                                    {activeBooking && moment(`${activeBooking.date} ${activeBooking.before_hours}:00`, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(), 'second') &&
                                        <Button
                                            theme={changed ? 'green' : 'grey'}
                                            radius="21px"
                                            width="155px"
                                            height="40px"
                                            weight="600"
                                            lineHeight="17px"
                                            onClick={() => changed ? save() : setChanged(true)}
                                        >
                                            {changed ? 'Сохранить' : 'Редактировать'}
                                        </Button>
                                    }
                                </>
                            }
                        </Flex>
                    }
                    {!calendar_blocked && activeBooking && activeBooking.status === 3 &&
                        <Flex justify="flex-end" margin="25px 0 0 0">
                            <Button
                                theme="grey"
                                radius="21px"
                                width="155px"
                                height="40px"
                                weight="600"
                                lineHeight="17px"
                                onClick={cancel}
                            >
                                Покинуть очередь
                            </Button>
                        </Flex>
                    }
                </CalendarWrapper>
            }
            {showModal &&
                <Modal
                    component={CancelPopup}
                    closeModal={() => setShowModal(false)}
                    parameters={{
                        cancel
                    }}
                    haveCloseBtn
                />
            }
        </Container>
    );
};

export default BookingSchedule;