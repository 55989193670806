import styled from "styled-components";

const ModalWrapper = styled.div`
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(36, 36, 36, 0.5);
    transition: opacity .15s linear;
    z-index: 5000;
    width: 100%;
    height: 100vh;
    outline: 0;
`;

const ModalWrapperContent = styled.div`
    transition: transform .3s ease-out;
    min-height: calc(100vh - 50px);
    display: flex;
    width: calc(100vw - 50px);
    margin: 25px;
    justify-content: center;
    position: relative;
    align-items: center;

    @media (max-width: 500px) {
        margin: 25px 25px 0 25px;
    }
`;

const BodyWrapper = styled.div`
    display: flex;
    position: relative;
    width: auto;
    z-index: 1000;
    flex-direction: column;
    max-height: calc(100vh - 50px);
    @media (max-width: 500px) {
        width: 100%;
    }

    &::-webkit-scrollbar-track {
      background: #EDE8DA;
      border-radius: 12px;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }
`;

const CloseButton = styled.button<{sliderModal?: boolean}>`
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 5px;
    transition: transform .1s linear;
    z-index: 99;
    ${({sliderModal}) => sliderModal && 'top: -30px; right: -30px;'};
    &:hover {
        transform: rotate(90deg);
    };

    @media(max-width: 750px) {
        ${({sliderModal}) => sliderModal && 'top: -35px; right: 0px;'};
    }
`;

export {ModalWrapperContent, BodyWrapper, CloseButton, ModalWrapper}