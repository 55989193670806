import React, { useState } from 'react'
import { Flex } from '../../../components/common/Styled/Flex'
import { Tab } from '../../../components/common/Styled/Tab'
import CabinetStatistics from './CabinetStatistics'
import CancelingStatistics from './CancelingStatistics'
import PsyhoStatistics from './PsyhoStatistics'


function Statistics() {
    const [page, setPage] = useState(1 as 1 | 2 | 3)

    const tabs = [
        {id: 1, active: page === 1, callback: () => setPage(1), text: 'Кабинеты'},
        {id: 2, active: page === 2, callback: () => setPage(2), text: 'Психологи'},
        {id: 3, active: page === 3, callback: () => setPage(3), text: 'Брони/Отмены'},
    ]
    return (
        <Flex align="flex-start" direction="column" padding="40px 60px 0 60px">
            <Flex>
                {tabs.map(item => 
                    <Tab 
                        margin="0 35px 0 0" 
                        weight="600" 
                        size="15px" 
                        lineHeight="24px" 
                        key={item.id} 
                        active={item.active} 
                        onClick={item.callback} 
                    >
                        {item.text}
                    </Tab>
                )}
            </Flex>
            {page === 1 && <CabinetStatistics />}
            {page === 2 && <PsyhoStatistics />}
            {page === 3 && <CancelingStatistics />}
        </Flex>
    )
}

export default Statistics
