
import React from 'react'
import { Button } from '../../../../components/common/Styled/Button'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Typography } from '../../../../components/common/Styled/Typography'
import { SetStateType, VoidFuncType } from '../../../../types/common'
import { COLORS } from '../../../../utils/constants/colors'
import { CancelPopupWrapper } from './style'

function CancelPopup({cancel, closeModal}: {cancel: () => Promise<boolean>, closeModal: VoidFuncType, setConfirmCancel: SetStateType<boolean>}) {
    const ready = async () => {
        await cancel()
        closeModal()
    }

    return (
        <CancelPopupWrapper>
            <Typography color={COLORS.black} align="center" weight="500" size="16px" lineHeight="21px">
                Если условия для бесплатной отмены не соблюдены, то отмена будет платной. С условиями отмены Вы можете ознакомиться в разделе "Покупки"
            </Typography>
            <Flex>
                <Button
                    theme="grey"
                    width="155px"
                    height="40px"
                    radius="21px"
                    weight="600"
                    lineHeight="17px"
                    margin="15px 0 0 0"
                    onClick={ready}
                >
                    Да, я уверен(а)
                </Button>
            </Flex>
        </CancelPopupWrapper>
    )
}

export default CancelPopup
