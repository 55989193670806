import { useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { useState, useEffect, useRef } from 'react'
import { CloseIcon, LoaderIcon } from '../../../../assets/icons'
import { COLORS } from '../../../../utils/constants/colors'
import { Flex } from '../../../../components/common/Styled/Flex'
import { ChangeType, VoidFuncType } from '../../../../types/common'
import { RootState } from '../../../../store/reducers/rootReducer'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import { Button } from '../../../../components/common/Styled/Button'
import { REQUIRED_STRING } from '../../../../utils/formik/validation'
import { Typography } from '../../../../components/common/Styled/Typography'
import { Label } from '../../../../components/common/FormikElements/Input/style'
import { OptionType } from '../../../../components/common/FormElements/SelectWithSearch'
import { addPhotoToNotification, createNotifications, getUsers } from '../../../../utils/api/routes'
import {
  AddNotificationPopupWrapper,
  Close,
  Photo,
  PhotoWrapper
} from './style'

import Modal from '../../../../components/Modal'
import FormikInput from '../../../../components/common/FormikElements/Input'
import ErrorHandler from '../../../../components/Error'
import SliderModalComponent from '../../../../components/SliderModal'
import FileInput from '../../../../components/common/FormElements/FileInput'
import FormikTextArea from '../../../../components/common/FormikElements/TextArea'
import * as Yup from 'yup'
import SelectWithSearch from '../../../../components/common/FormElements/SelectWithSearch'

type PhotoType = { file: File, url: string | ArrayBuffer | null, id: number }

function AddNotificationPopup({ closeModal, updateComponent }: { closeModal: VoidFuncType, updateComponent: VoidFuncType }) {

  const inputRef = useRef<HTMLInputElement | null>(null)
  const { show, active, hidden, text } = useErrorHandler()
  const { profile } = useSelector((state: RootState) => state.user)
  const initial = {
    text: '',
    title: 'Сообщение от администратора'
  }

  const [users, setUsers] = useState<OptionType[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(2)
  const [photo, setPhoto] = useState<Array<PhotoType>>([])
  const [error, setError] = useState(false)
  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([])
  const [showModal, setShowModal] = useState(false)
  const [isPhotoPending, setIsPhotoPending] = useState(false)

  const request = (pageSize: number, finalyCallback: VoidFuncType) => {
    getUsers(`page=${currentPage}&page_size=${pageSize}&is_psychologist=true&is_superuser=false${!profile.is_superuser && !profile.notifications_all ? `&staff=${profile.id}` : ''}`)
      .then(res => {
        const newData = res.data.results.map(item => ({ id: item.id, name: `${item.first_name} ${item.last_name} ${item.middle_name}`, active: isUserActive(item.id) }))
        setUsers([...users, ...newData])
        setCurrentPage(prevState => ++prevState)
      })
      .finally(() => {
        finalyCallback()
      })
  }

  const getAllUsers = (pageSize: number, finalyCallback: VoidFuncType, setAllActive: (arr: OptionType[], setArray: Function) => void) => {
    getUsers(`page=1&page_size=${pageSize}&is_psychologist=true&is_superuser=false${!profile.is_superuser && !profile.notifications_all ? `&staff=${profile.id}` : ''}`)
      .then(res => {
        const newData = res.data.results.map(item => ({ id: item.id, name: `${item.first_name} ${item.last_name} ${item.middle_name}`, active: isUserActive(item.id) }))
        setAllActive([...newData], setUsers)
      })
      .finally(() => {
        finalyCallback()
      })
  }

  const isUserActive = (id: number) => {
    if (selectedUsersIds.includes(id)) {
      setSelectedUsersIds(state => [...state.filter(selectedId => selectedId !== id)])
      return true
    }
    return false
  }

  const selectUser = (id: number, isActive?: boolean) => {
    if (users.some(user => user.id === id)) {
      setUsers(users.map(item => {
        if (item.id === id) {
          return { ...item, active: isActive ?? !item.active }
        }
        return item
      }))
      return
    }
    if (selectedUsersIds.includes(id)) {
      setSelectedUsersIds(state => [...state.filter(selectedId => selectedId !== id)])
      return
    }
    setSelectedUsersIds(state => [...state, id])
  }

  const addPhoto = (e: ChangeType) => {
    const reader = new FileReader()
    const file = e.target.files
    if (!file || !file[0]) return
    reader.onloadend = () => {
      setPhoto([...photo, { file: file[0], url: reader.result, id: Date.now() }])
    }
    reader.readAsDataURL(file[0])
  }

  const deletePhoto = (id: number) => {
    const newArr = photo.filter(item => item.id !== id)
    setPhoto(newArr)
    if (inputRef.current)
      inputRef.current.value = ''
  }

  const send = (values: typeof initial) => {
    const recipientList = users.reduce((acum: Array<number>, item) => {
      if (item.active) acum.push(item.id)
      return acum
    }, [])

    const body = {
      ...values,
      recipient_list: setSelectedUsersIds.length ? [...recipientList, ...selectedUsersIds] : recipientList
    }

    createNotifications(body)
      .then(res => {
        Promise
          .all(photo.map(item => {
            setIsPhotoPending(true)
            const formData = new FormData()
            formData.append('notification', `${res.data.id}`)
            formData.append('image', item.file)
            return addPhotoToNotification(formData)
          }))
          .then(() => {
            closeModal()
            updateComponent()
          })
          .catch((e) => show(e))
          .finally(() => setIsPhotoPending(false))
      })
      .catch((e) => show(e))
  }

  const verify = () => {
    if (!(users.filter(item => item.active).length)) setError(true)
  }

  const schema = Yup.object({
    text: REQUIRED_STRING
  })

  useEffect(() => {
    getUsers(`page=1&page_size=15&is_psychologist=true&is_superuser=false${!profile.is_superuser && !profile.notifications_all ? `&staff=${profile.id}` : ''}`)
      .then(res => {
        setUsers(res.data.results.map(item => ({ id: item.id, name: `${item.first_name} ${item.last_name} ${item.middle_name}`, active: false })))
        setTotalCount(res.data.count)
      })
  }, [])

  useEffect(() => {
    setError(false)
  }, [users])

  return (
    <AddNotificationPopupWrapper>
      <ErrorHandler active={active} hidden={hidden} text={text} />
      <Flex padding="10px 0" border="1px solid #DECEB7">
        <Typography weight="700" size="18px" lineHeight="21px">Создание  уведомления</Typography>
      </Flex>
      <Flex margin="24px 0" padding="0 20px">
        <Formik
          initialValues={initial}
          onSubmit={(values) => send(values)}
          validationSchema={schema}
        >
          <Form>
            <Flex margin="0 0 10px 0">
              <Field name="title" label="Заголовок уведомления" direction="column" placeholder="Введите текст" height="180px" component={FormikInput} schema={schema} />
            </Flex>
            <Field name="text" label="Текст уведомления" direction="column" placeholder="Введите текст" height="180px" component={FormikTextArea} schema={schema} />
            <Flex margin="15px 0 0 0" align="flex-start" direction="column">
              <Label>Психолог*</Label>
              <SelectWithSearch
                onChange={() => { }}
                options={[]}
                value=""
                many
                optionsMany={users}
                setOptionsMany={setUsers}
                onChangeAll={(id: number) => selectUser(id)}
                manySelected={`Выбрано ${users.filter(item => item.active).length + selectedUsersIds.length}`}
                totalCount={totalCount}
                request={request}
                currentLength={users.length}
                getAllUsers={getAllUsers}
                defaultValue="Выберите"
                selectAll
              />
              {error && <Typography color={COLORS.red} margin="5px 0" size="12px">Обязателное поле</Typography>}
            </Flex>
            <Flex width="100%" margin="20px 0 0 0" align="flex-start" direction="column">
              <Label>Фото</Label>
              <PhotoWrapper justify="flex-start" align="flex-start" gap="7px">
                {!!photo.length && photo.map((item) => item.url &&
                  <Photo
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      e.nativeEvent.stopImmediatePropagation()
                      setShowModal(true)
                    }}
                    key={item.id}
                  >
                    <img alt="Фото" src={item.url as string} />
                    <Close
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        e.nativeEvent.stopImmediatePropagation()
                        deletePhoto(item.id)
                      }}
                    >
                      <CloseIcon />
                    </Close>
                  </Photo>
                )}
                <FileInput inputRef={inputRef} fieldId="notification" changeFunction={addPhoto} />
              </PhotoWrapper>
            </Flex>
            <Flex margin="20px 0 0 0" justify="space-between">
              <Button
                type="button"
                theme="beige"
                width="155px"
                height="40px"
                radius="21px"
                weight="600"
                lineHeight="17px"
                disabled={isPhotoPending}
                onClick={() => closeModal()}
              >
                Отменить
              </Button>
              <Button
                type="submit"
                theme="grey"
                width="155px"
                height="40px"
                radius="21px"
                weight="600"
                lineHeight="17px"
                disabled={isPhotoPending}
                onClick={verify}
              >
                {
                  isPhotoPending ? <LoaderIcon height='30px' color='#FFFFFF' /> : 'Отправить'
                }
              </Button>
            </Flex>
          </Form>
        </Formik>
        {showModal &&
          <Modal
            component={() => <SliderModalComponent alt="Фото уведомления" arr={photo.map(item => ({ id: item.id, photo: item.url as string }))} />}
            closeModal={() => setShowModal(false)}
            sliderModal
          />
        }
      </Flex>
    </AddNotificationPopupWrapper>
  )
}

export default AddNotificationPopup
