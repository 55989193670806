import React from 'react'
import { EmailIcon, InstIcon, PhoneIcon, TelegramIcon, UserIcon, VkIcon } from '../../../../assets/icons'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Typography } from '../../../../components/common/Styled/Typography'
import { SetStateType } from '../../../../types/common'
import { IUser } from '../../../../types/interfaces/user'
import { CardWrapper } from './style'
import {formatPhoneNumber} from '../../../../utils/formik/formatPhoneNumber'

interface IProps {
    user: IUser, 
    setActiveUser: SetStateType<IUser | null>, 
    activeUser: number | null
}

const UsersCard = ({user, setActiveUser, activeUser}: IProps) => {

    return (
        <CardWrapper active={user.id === activeUser} onClick={() => setActiveUser(user)} padding="20px" align="flex-start" direction="column" status={user.is_active}>
            <Flex margin="0 0 3px 0">
                <UserIcon />
                <Typography
                    size="16px"
                    lineHeight="19px"
                    margin="0 0 0 15px"
                >
                    {`${user.last_name} ${user.first_name} ${user.middle_name}`}
                </Typography>
            </Flex>
            <Flex margin="0 0 3px 0">
                <EmailIcon />
                <Typography
                    size="16px"
                    lineHeight="19px"
                    margin="0 0 0 15px"
                >
                    {user.email}
                </Typography>
            </Flex>
            <Flex width="100%" justify="space-between" margin="0 0 3px 0">
                <Flex>
                    <PhoneIcon />
                    <Typography
                        size="16px"
                        lineHeight="19px"
                        margin="0 0 0 15px"
                    >
                        {formatPhoneNumber(user.phone)}
                    </Typography>
                </Flex>
                <Flex gap="10px">
                    {user.instagram && <a target="__blank" href={user.instagram}><InstIcon /></a>}
                    {user.telegram && <a target="__blank" href={user.telegram}><TelegramIcon /></a>}
                    {user.vk && <a target="__blank" href={user.vk}><VkIcon /></a>}
                </Flex>
            </Flex>
        </CardWrapper>
    )
}

export default UsersCard