import moment from 'moment';
import React from 'react';
import { Button } from '../../../../components/common/Styled/Button';
import { Flex } from '../../../../components/common/Styled/Flex';
import { Typography } from '../../../../components/common/Styled/Typography';
import { IReport } from '../../../../types/interfaces/reports';
import { deleteComplains } from '../../../../utils/api/routes';
import { NotificationCardWrapper } from '../NotificationCard/style';

const ReportCard = ({data, updateComponent}: {data: IReport, updateComponent: () => void}) => {
    const fields = [
        {id: 1, text: 'Мусор', name: 'garbage' as 'garbage'},
        {id: 2, text: 'Отсутствие расходников', name: 'consumables' as 'consumables'},
        {id: 3, text: 'Поздно освободили кабинет', name: 'vacated_late' as 'vacated_late'},
    ]
    return (
        <NotificationCardWrapper>
            {fields.map(item => 
                <Flex margin="5px 0 0 0" key={item.id   } justify="space-between" width="100%">
                    <Typography>{item.text}</Typography>
                    <Typography weight="700">{data[item.name] ? 'Да' : 'Нет'}</Typography>
                </Flex>
            )}
            <Flex align="flex-start" margin="5px 0 0 0" direction="column"   width="100%">
                <Typography weight="800">Подробнее</Typography>
                <Typography>{data.other || '-'}</Typography>
            </Flex>
            <Flex align="flex-start" margin="5px 0 0 0"  direction="column" width="100%">
                <Typography weight="800">Комментарий</Typography>
                <Typography>{data.comment || '-'}</Typography>
            </Flex>
            <Flex direction="column" margin="10px 0 0 0">
                <Typography weight="800">Данные о бронировании</Typography>
                <Flex margin="10px 0 0 0"  justify="space-between" width="100%">
                    <Typography>Кабинет</Typography>
                    <Typography>{data.office.name}, {data.office.address}</Typography>
                </Flex>
                <Flex margin="5px 0 0 0"  justify="space-between" width="100%">
                    <Typography>Психолог</Typography>
                    <Typography>{`${data.psychologist.last_name} ${data.psychologist.first_name} ${data.psychologist.middle_name}`}, {data.psychologist.email}</Typography>
                </Flex>
                <Flex margin="5px 0 0 0"  justify="space-between" width="100%">
                    <Typography>Время брони</Typography>
                    <Typography>{moment(data.groupbooking.date).format('DD.MM.YYYY')}, {data.groupbooking.before_hours.slice(0, 5)} - {data.groupbooking.after_hours.slice(0, 5)}</Typography>
                </Flex>
            </Flex>
            <Button
                theme="grey"
                width="100%"
                height="30px"
                radius="6px"
                margin="30px 0 0 0"
                onClick={async () => {await deleteComplains(data.id); updateComponent()}}
            >
                Удалить жалобу
            </Button>
        </NotificationCardWrapper>
    );
};

export default ReportCard;