import { showAlert } from './../../../utils/showAlert';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserProfile, loginUser, updateUser } from "../../../utils/api/routes";
import Cookies from "js-cookie";
import { IUser } from "../../../types/interfaces/user";
import { VoidFuncType } from "../../../types/common";
import { UpdateType } from "../../../types/interfaces/user";

const initialState = {
    isLogged: false,
    profile: {} as IUser
}

type LoginPayloadType = { email: string, password: string, successCallback?: (isChanged: boolean) => void, errorCallback?: (error: string) => void }
type UpdateProfileType = { profile: UpdateType, successCallback?: VoidFuncType, errorCallback?: (err: unknown) => void }

export const login = createAsyncThunk<object, LoginPayloadType>(
    'user/login',
    async (payload, { dispatch, rejectWithValue }) => {
        const { password, email, successCallback, errorCallback } = payload
        try {
            const response = await loginUser({ password, email })
            if (response.status === 200) {
                Cookies.set('access', response.data.access, { expires: 1 })
                Cookies.set('refresh', response.data.refresh, { expires: 365 })
                dispatch(getProfile({ successCallback }))
            }
            return response.data
        } catch (err) {
            if (errorCallback) {
                switch (err.status) {
                    case 401:
                        errorCallback('Неверный логин или пароль!')
                        break;
                    case 403:
                        errorCallback('Ваша учетная запись заблокирована!')
                        break;
                    default:
                        errorCallback('Ошибка сервера!')
                        break;
                }
            }
            return rejectWithValue(err.response.data)
        }
    },
)

export const getProfile = createAsyncThunk<IUser, { successCallback?: (isChanged: boolean) => void }>(
    'user/getProfile',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await getUserProfile()
            if (response?.status === 200) {
                dispatch(setUserProfile(response.data))
                payload.successCallback && payload.successCallback(response.data.password_changed)
            }
            return response.data as IUser
        } catch (err) {
            if (err.data.code === 'user_inactive')
                showAlert('error', 'Ваша учетная запись заблокирована, обратитесь к администратору')
            return rejectWithValue(err.response.data)
        }
    },
)

export const updateProfile = createAsyncThunk<object, UpdateProfileType>(
    'user/updateProfile',
    async (payload, { dispatch, rejectWithValue }) => {
        const { profile, successCallback, errorCallback } = payload
        try {
            const response = await updateUser(profile)
            if (response?.status === 200) {
                dispatch(setUserProfile(response.data))
                successCallback && successCallback()
            }
        } catch (err) {
            if (errorCallback) {
                errorCallback(err)
            }
            return rejectWithValue(err.response.data)
        }
    }
)
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLogged: (state, action: PayloadAction<boolean>) => {
            state.isLogged = action.payload
        },
        setUserProfile: (state, action: PayloadAction<IUser>) => {
            state.profile = action.payload
        },
        logout: (state) => {
            state.isLogged = false
            state.profile = {} as IUser
        },
    },
    extraReducers: builder => {
        builder.addCase(getProfile.fulfilled, (state, action: PayloadAction<IUser>) => {
            state.profile = action.payload
        })
    }
})

const { setUserProfile, logout, setLogged } = userSlice.actions;

export { setUserProfile, logout, setLogged }

export default userSlice.reducer
