import React, { useEffect, useRef, useState } from 'react'
import { NotificationChevron } from '../../../assets/icons'
import { Flex } from '../../../components/common/Styled/Flex'
import { usePagination } from '../../../hooks/usePagination'
import useResolution from '../../../hooks/useResolution'
import { useWrapperSize } from '../../../hooks/useWrapperSize'
import { IOrder } from '../../../types/interfaces/order'
import { getOrders } from '../../../utils/api/routes'
import PurchaseHistoryItem from './PurchaseHistoryItem'
import { PurchaseHistoryWrapper, ShowMore, Wrapper } from './style'

function PurchaseHistory() {
    const [history, setHistory] = useState<Array<IOrder>>([])
    const [count, setCount] = useState(0)
    const {scroll, currentPage, finalyCallback, successCallback, resetCallback, fetching} = usePagination(count <= history.length)
    const [active, setActive] = useState(false)
    const isMobile = useResolution(1000)
    const ref = useRef<HTMLDivElement>(null)
    const {size} = useWrapperSize(ref, isMobile)
    useEffect(() => {
        resetCallback()
        getOrders('?page=1&page_size=15').then(res => {
            setHistory(res.data.results)
            setCount(res.data.count)
            successCallback()
        })
    }, [])

    useEffect(() => {
        fetching && getOrders(`?page=${currentPage}&page_size=15`).then(res => {
            setHistory([...history, ...res.data.results])
            setCount(res.data.count)
            successCallback()
        }).finally(finalyCallback)
    }, [fetching])

    // if(!history.length) {
    //     return null
    // }

    return (
        <Wrapper ref={ref} height={size}>
            {!!history.length &&
                <PurchaseHistoryWrapper isMobile={isMobile} active={active} onScroll={scroll}>
                    {history.map(item => 
                        <PurchaseHistoryItem key={item.id} data={item} />
                    )}
                </PurchaseHistoryWrapper>    
            }
            {!isMobile && !!history.length &&
                <ShowMore onClick={() => setActive(!active)} active={active} padding="10px">
                    <NotificationChevron />
                </ShowMore>
            }
        </Wrapper>    
    )
}

export default PurchaseHistory
