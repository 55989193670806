import styled from "styled-components";
import {Flex} from "../../../components/common/Styled/Flex";
import {Typography} from "../../../components/common/Styled/Typography";

const Container = styled(Flex)`
    box-shadow: 0 0 4px #E1D7C9;
    border-radius: 6px;
    min-width: 520px;
    
    ${Typography} {
        max-width: 280px;
    };

    @media (max-width: 500px) {
        min-width: auto;
    }
`;

export {Container};