import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '../../../../components/common/Styled/Typography';
import { setActiveCabinetId } from '../../../../store/reducers/bookingReducer';
import { SetStateType } from '../../../../types/common';
import { IBooking } from '../../../../types/interfaces/booking';
import { COLORS } from '../../../../utils/constants/colors';
import { BookingItem, OfficeInfo } from './style';

interface IProps {
    item: IBooking,
    active?: boolean, 
    setActiveBooking?: (item: IBooking) => void
    setShowCalendar?: SetStateType<boolean>
    isMobile?: boolean 
}

const ScheduleItem = ({item, active, setActiveBooking, isMobile, setShowCalendar} : IProps) => {
    const { date, after_hours, before_hours, status, office} = item
    const dispatch = useDispatch()
    const selectBooking = () => {
        setActiveBooking && setActiveBooking(item)
        setActiveBooking && dispatch(setActiveCabinetId(item.office.id))
        isMobile && setShowCalendar && setShowCalendar(true)
    }

    return (
        <BookingItem onClick={selectBooking} active={!!active} status={status!}>
            <Typography weight="700" size="15px" lineHeight="18px">
                {moment(date).format('DD MMMM')},
                &nbsp;
                {before_hours.slice(0, 5)} – {after_hours.slice(0, 5)}
            </Typography>
            <OfficeInfo color={COLORS.steel} fontFamily="Montserrat" weight="500" size="12px" lineHeight="24px">
                {office.name} кабинет • {office.address}
            </OfficeInfo>
        </BookingItem>
    );
};

export default ScheduleItem;