import styled from 'styled-components'
import { Flex } from '../../components/common/Styled/Flex'
import { Typography } from '../../components/common/Styled/Typography'
import { AutoCompliteWrapper } from '../../components/Filters/AutoComplite/style'

const SubscriptionsWrapper = styled(Flex)`
    @media(max-width: 1000px) {
        padding: 50px 60px 0 60px;
        align-items: center;
    } 
    @media(max-width: 800px) {
        padding: 50px 15px 0 15px;
        align-items: center;
    }
    
    ${AutoCompliteWrapper} {
        margin: 20px 0 0 0;
    }

    .slider {
        width: 100%;
    }

    .slick-track {
        display: flex;
        justify-content: flex-start;
        width: 100%!important;

        &::before, &::after {
            display: none;
        }
    }
`

const TextWrapper = styled.div<{active: boolean}>`
    margin-top: 25px;
    display: flex;
    align-items: center;
    width: 100%;
    & p{
        
        & a {
            text-decoration: underline!important;
            cursor: pointer;
            margin-left: 5px;
        }
    }
`

const HeaderText = styled(Typography)<{active: boolean}>`
    @media(max-width: 800px) {
        font-size: 18px;
    } 
    @media(max-width: 343px) {
        font-size: 15px;
    } 
`

const SubscriptionItemsWrapper = styled(Flex)<{size: string}>`
    max-height: ${({size}) => size};
    max-width: 100%;
    overflow: auto;
/* 
    @media(max-width: 1550px) {
        grid-template-columns: repeat(2, 1fr);
    } 

    @media(max-width: 1100px) {
        grid-template-columns: repeat(1, 1fr);
    } 

    @media(max-width: 1000px) {
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
    } 

    @media(max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
    }  */
`

export {SubscriptionsWrapper, SubscriptionItemsWrapper, HeaderText, TextWrapper}