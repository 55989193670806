import { Formik, Form, Field } from 'formik'
import React, { useEffect, useState } from 'react'
import FormikInput from '../../../../../components/common/FormikElements/Input'
import FormikSelect from '../../../../../components/common/FormikElements/Select'
import { Button } from '../../../../../components/common/Styled/Button'
import { Flex } from '../../../../../components/common/Styled/Flex'
import { Typography } from '../../../../../components/common/Styled/Typography'
import { VoidFuncType } from '../../../../../types/common'
import { addPriceCategories, changePriceCategories, getPaymentSystems, getSelectParams } from '../../../../../utils/api/routes'
import { PriceCategoryPopup, ColorWrapper } from './style'
import * as Yup from 'yup'
import { REQUIRED_POSITIVE_NUMBER, REQUIRED_STRING } from '../../../../../utils/formik/validation'
import { COLORS } from '../../../../../utils/constants/colors'
import ErrorHandler from '../../../../../components/Error'
import { useErrorHandler } from '../../../../../hooks/useErrorHandler'
import { IPriceCategory } from '../../../../../types/interfaces/priceCategories'
import { ObjectShape } from 'yup/lib/object'
import { showAlert } from '../../../../../utils/showAlert'

type OptionType = { value: number, name: string }

interface IProps {
    closeModal: VoidFuncType,
    updateComponent: (arg?: string) => void,
    activePriceCategory: IPriceCategory | null
}

function AddPriceCategoryPopup({ closeModal, updateComponent, activePriceCategory }: IProps) {
    const [paymentSystems, setPaymentSystems] = useState<Array<OptionType>>([])
    const [cities, setCities] = useState<Array<OptionType>>([])
    const { show, active, hidden, text } = useErrorHandler()
    const initialChnage = activePriceCategory && {
        name: activePriceCategory?.name,
        city: activePriceCategory?.city.id,
        payment_system: activePriceCategory?.payment_system.id,
        price: activePriceCategory?.price,
        color: activePriceCategory?.color
    }

    const initialCreate = {
        name: '',
        city: '',
        payment_system: '',
        individ_price: '',
        group_price: '',
        color: COLORS.green
    }

    useEffect(() => {
        getPaymentSystems().then(res => setPaymentSystems(res.data.map(item => ({ value: item.id, name: item.name }))))
        getSelectParams('cities').then(res => setCities(res.data.map(item => ({ value: item.id, name: item.name }))))
    }, [])

    const create = (values: typeof initialCreate) => {
        const body = {
            ...values,
            city: +values.city,
            payment_system: +values.payment_system,
            individ_price: +values.individ_price,
            group_price: +values.group_price,
        }

        addPriceCategories(body).then(() => {
            closeModal()
            updateComponent()
            showAlert('success', 'Ценовая категория успешно создана')
        }).catch((e) => showAlert('error', 'Не удалось создать ценовую категорию'))
    }
    const change = (values: typeof initialChnage) => {
        if (activePriceCategory && values) {

            const body = {
                ...values,
                city: +values.city,
                payment_system: +values.payment_system,
                price: +values!.price
            }

            changePriceCategories(activePriceCategory.id, body).then(() => {
                closeModal()
                updateComponent()
                showAlert('success', 'Ценовая категория успешно отредактирована')
            }).catch((e) => showAlert('error', 'Не удалось отредактировать ценовую категорию'))
        }
    }

    const getValidationField = (): ObjectShape => {
        if (activePriceCategory) {
            return { price: REQUIRED_STRING }
        }

        return {
            individ_price: REQUIRED_POSITIVE_NUMBER,
            group_price: REQUIRED_POSITIVE_NUMBER,
        }
    }

    const schema = Yup.object({
        name: REQUIRED_STRING,
        city: REQUIRED_STRING,
        payment_system: REQUIRED_STRING,
        color: REQUIRED_STRING,
        ...getValidationField()
    })

    return (
        <PriceCategoryPopup>
            <ErrorHandler active={active} hidden={hidden} text={text} />
            <Flex padding="10px 0" border="1px solid #DECEB7">
                <Typography weight="700" size="18px" lineHeight="21px">{activePriceCategory ? 'Редактирование ценовой категории' : 'Создание ценовой категории'}</Typography>
            </Flex>
            <Flex align="flex-start" padding="20px" width="100%" direction="column">
                <Formik
                    initialValues={initialChnage ? initialChnage : initialCreate}
                    onSubmit={(values) => activePriceCategory ? change(values as typeof initialChnage) : create(values as typeof initialCreate)}
                    validationSchema={schema}
                >
                    <Form>
                        <Flex gap="40px" justify="space-between">
                            <Field component={FormikInput} name="name" placeholder="Название" label="Название" direction="column" schema={schema} />
                            <Field component={FormikSelect} options={cities} name="city" label="Город" direction="column" schema={schema} />
                        </Flex>
                        <Flex margin="15px 0 0 0" width="calc(50% - 20px)" justify="flex-start">
                            <Field component={FormikSelect} options={paymentSystems} name="payment_system" label="Платежная система" direction="column" schema={schema} />
                        </Flex>
                        <Flex margin="15px 0 0 0" gap="40px" justify="space-between">
                            {activePriceCategory &&
                                <Field type="number" component={FormikInput} name="price" placeholder="Час приема, ₽" label="Час приема, ₽" direction="column" schema={schema} />
                            }
                            {!activePriceCategory &&
                                <>
                                    <Field type="number" component={FormikInput} name="individ_price" placeholder="Час индивидуального приема, ₽" label="Час индивидуального приема, ₽" direction="column" schema={schema} />
                                    <Field type="number" component={FormikInput} name="group_price" placeholder="Час группового приема, ₽" label="Час группового приема, ₽" direction="column" schema={schema} />

                                </>
                            }
                        </Flex>
                        <ColorWrapper margin="15px 0 0 0" width="50%">
                            <Field name="color" direction="column" type="color" label="Цвет ценовой категории" component={FormikInput} />
                        </ColorWrapper>
                        <Flex margin="20px 0 0 0" width="100%" justify="space-between">
                            <Button
                                theme="beige"
                                radius="21px"
                                width="155px"
                                height="40px"
                                type="button"
                                weight="600"
                                lineHeight="17px"
                                onClick={() => closeModal()}
                            >
                                Отменить
                            </Button>
                            <Button
                                theme="grey"
                                radius="21px"
                                width="155px"
                                height="40px"
                                type="submit"
                                weight="600"
                                lineHeight="17px"
                            >
                                {activePriceCategory ? 'Сохранить' : 'Создать'}
                            </Button>
                        </Flex>
                    </Form>
                </Formik>
            </Flex>
        </PriceCategoryPopup>
    )
}

export default AddPriceCategoryPopup
