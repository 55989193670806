import styled, { keyframes } from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'

const open = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const DropDown = styled.div<{padding?: string}>`
    position: absolute;
    max-width: 340px;
    min-height: 120px;
    background: ${COLORS.white};
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    top: 115%;
    z-index: 1005;
    padding: ${p => p.padding ? p.padding : '0'};
    animation: ${open} .2s linear;
    input {
        text-align: center;
    }


    /* @media (max-width: 760px) {
        left: -80%;
    }
    @media (max-width: 475px) {
        left: -130%;
    }

    @media (max-width: 424px) {
        left: -80%;
    }
    @media (max-width: 335px) {
        left: -110%;
    } */
`