import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PenIcon } from '../../../assets/icons';
import Calendar from '../../../components/Calendar';
import Input from '../../../components/common/FormElements/Input';
import { Button } from '../../../components/common/Styled/Button';
import { Flex } from '../../../components/common/Styled/Flex';
import { Ceil, CeilHeader, Table } from '../../../components/common/Styled/Table';
import { Typography } from '../../../components/common/Styled/Typography';
import Modal from '../../../components/Modal';
import paginationHoc, { PaginationComponentProps } from '../../../hocs/Pagination.hoc';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { bookingsGet, setActiveCabinetId, setActiveDateRange } from '../../../store/reducers/bookingReducer';
import { RootState } from '../../../store/reducers/rootReducer';
import { ChangeType } from '../../../types/common';
import { IBooking, IOptimizedBookingBooking } from '../../../types/interfaces/booking';
import { IOffice } from '../../../types/interfaces/office';
import { getBookings } from '../../../utils/api/routes';
import { debounce } from '../../../utils/debounce';
import BookingsPopup from './BookingsPopup';
import { Header, BookingsWrapper, CalendarWrapper } from './style';

const AdminBookings = ({ paginationComponet, setCurrentPage, currentPage }: PaginationComponentProps) => {
    const state = useSelector((state: RootState) => state.bookings)
    const [bookings, setBookings] = useState<Array<IBooking>>([])
    const [showModal, setShowModal] = useState(false)
    const [booking, setBooking] = useState<IBooking | null>(null)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(0)
    const [office, setOffice] = useState<IOffice | null>(null)
    const [dates, setDates] = useState({ dateAfter: '', dateBefore: '' })
    const [search, setSearch] = useState('')
    const [toNow, setToNow] = useState(false)
    const [localTime, setLocalTime] = useState<{ time: string, date: string }>({ time: '', date: '' })
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)
    const place = useRef<HTMLDivElement>(null)

    const debouncedSearch = useRef(debounce((callback) => callback(), 400))

    const updateComponent = () => {
        getBookings(`&page=${currentPage}&canceled=False&visited=False&search=${search}`, pageSize).then(res => {
            setTotalCount(res.data.count)
            setBookings(res.data.results)
        })
    }

    const upadateReservations = () => {
        const queryString = `&canceled=False&visited=False&date_after=${dates.dateAfter}&date_before=${dates.dateBefore}&office=${office!.id}`
        dispatch(bookingsGet({ pageSize: 250, queryString, all: true }))
    }

    useEffect(() => {
        booking && setLocalTime(prev => ({ ...prev, date: moment(booking.date).format('DD.MM.YYYY') }))
        booking && dispatch(setActiveCabinetId(booking.office.id))
    }, [booking, setLocalTime])

    useEffect(() => {
        !showModal && setBooking(null)
    }, [showModal, setBooking])

    useEffect(() => {
        if (ref.current) {
            setPageSize(Math.floor((window.innerHeight - ref.current.getBoundingClientRect().top - 100) / 40))
        }
    }, [ref])

    useEffect(() => {
        if (!showModal) {
            setBooking(null)
        }
    }, [showModal])

    useEffect(() => {
        office && dispatch(setActiveCabinetId(office.id))
        dispatch(setActiveDateRange(dates))
        if (office && dates.dateAfter && dates.dateBefore)
            upadateReservations()
    }, [dates, office])

    useEffect(() => {
        debouncedSearch.current(updateComponent)
    }, [currentPage, pageSize, search])

    useEffect(() => {
        debouncedSearch.current(() => {
            setCurrentPage(1)
            updateComponent()
        })
    }, [search])


    const ceilHeader = [
        { id: 1, text: 'Город', borderRadius: 'first' as 'first' },
        { id: 2, text: 'Кабинет' },
        { id: 3, text: 'Психолог' },
        { id: 4, text: 'Дата брони' },
        { id: 5, text: 'Время начала' },
        { id: 6, text: 'Время конца' },
        { id: 7, text: 'Ценовая категория' },
        { id: 8, text: 'Статус' },
        { id: 9, text: 'Кол-во человек' },
        { id: 10, text: 'Редактировать', borderRadius: 'last' as 'last' },
    ]

    const close = () => {
        setShowModal(false);
        setBooking(null);
        setOffice(null);
        setDates({ dateAfter: '', dateBefore: '' })
    }

    return (
        <Flex padding="100px 60px 0 60px" width="100%" align="flex-start" direction="column">
            <Header margin="0 0 25px 0" width="100%" justify="space-between">
                <Input value={search} onChange={(e: ChangeType) => setSearch(e.target.value)}
                    placeholder="ФИО / Email / Телефон" />
                <Button
                    theme="grey"
                    radius="6px"
                    width="217px"
                    height="36px"
                    weight="600"
                    lineHeight="17px"
                    onClick={() => setShowModal(true)}
                >
                    Создать бронь
                </Button>
            </Header>
            <BookingsWrapper>
                <Table ref={ref} columns="10% 7% 20% repeat(3, 8%) repeat(2, 13%) 8% 5%">
                    {ceilHeader.map(item =>
                        <CeilHeader borderRadius={item.borderRadius && item.borderRadius} key={item.id}>
                            <Typography weight="700" lineHeight="18px">{item.text}</Typography>
                        </CeilHeader>
                    )}
                    {bookings.map((item, index) =>
                        <React.Fragment key={item.id}>
                            <Ceil borderRadius="first" isLastRow={index === bookings.length - 1}>
                                <Typography lineHeight="18px">{item.office.city.name}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{item.office.name}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography
                                    lineHeight="18px">
                                    {
                                        item.user
                                            ? `${item.user?.last_name} ${item.user?.first_name} ${item.user?.middle_name}`
                                            : item.unknown_user
                                    }
                                </Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{moment(item.date).format('DD.MM.YYYY')}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{item.before_hours}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{item.after_hours}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{item.office?.price_category.name}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography
                                    lineHeight="18px">{item.status === 1 ? 'Подтверждено' : item.status === 2 ? 'Не подтверждено' : 'В очереди'}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{item.peoples}</Typography>
                            </Ceil>
                            <Ceil borderRadius="last" isLastRow={index === bookings.length - 1}>
                                <Typography lineHeight="18px">
                                    <PenIcon
                                        onClick={() => {
                                            setBooking(item)
                                            setShowModal(true)
                                        }}
                                    />
                                </Typography>
                            </Ceil>
                        </React.Fragment>
                    )}
                </Table>
                <CalendarWrapper show={showModal} ref={place}>
                    {showModal && <BookingsPopup
                        close={close}
                        update={updateComponent}
                        localTime={localTime}
                        booking={booking}
                        setOfficeGlobal={setOffice}
                        setBooking={setBooking}
                        upadateReservations={upadateReservations}
                    />}

                    {office &&
                        <Flex padding="0 25px" direction="column">
                            <Calendar
                                view={7}
                                step={office.occupation_step}
                                schedule={office.schedule}
                                setBookings={() => {
                                }}
                                toNow={toNow}
                                setToNow={setToNow}
                                data={state.bookings}
                                setDates={setDates}
                                setLocalTime={setLocalTime}
                                loading={state.loading}
                                noQueue
                                selectedBooking={booking}
                                showNameUser
                                bookingClickHandler={(item: IBooking) => setBooking(item)}
                            />
                        </Flex>
                    }
                </CalendarWrapper>
            </BookingsWrapper>
            <Flex margin="25px 0 0 0" width="100%">
                {paginationComponet(totalCount, pageSize)}
            </Flex>
            {/* {showModal &&
                <Modal 
                    haveCloseBtn={false}
                    component={BookingsPopup}
                    closeModal={() => {
                        setShowModal(false)
                        setBooking(null)
                    }}
                    parameters={{
                        booking,
                        updateComponent
                    }}
                />
            } */}
        </Flex>
    );
};

export default paginationHoc(AdminBookings);