import styled from 'styled-components'
import { Flex } from '../../../components/common/Styled/Flex'
import { COLORS } from '../../../utils/constants/colors'

export const Wrapper = styled.div<{height: string}>`
    width: 100%;
    height: ${({height}) => height};
`

export const PurchaseHistoryWrapper = styled.div`
    background: ${COLORS.cream};
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 12px;
    width: 100%;
    max-height: 100%;
    transition: max-height .2s linear,
                min-height .2s linear;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 1px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
  }
    @media(max-width: 1000px) {
        min-height: auto;
    } 
`