import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'

export const SliderWrapper = styled.div<{width?: string, height?: string}>`
    width: ${({width}) => width || '100%'}!important;
    /* height: 600px; */
    border-radius: 12px;

    .dots-modal-preview {
        display: flex!important;
        max-width: 100%;
        overflow-x: auto;
        width: 100%;
        height: 90px;
        margin-top: 40px;
        &::-webkit-scrollbar {
            height: 0px;
        }
        li {
            min-width: 140px;
            width: 140px;
            height: 100%;
            border-radius: 6px;
            border: 1px solid transparent;
        }
        img {
            cursor: pointer;
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 6px;
        }

        li.slick-active {
            border: 1px solid ${COLORS.brown};
        }

        & > * {
            margin-right: 10px;
        }
    }
`

export const ModalSliderWrapper = styled(SliderWrapper)`
    @media(max-width: 990px) {
        width: 700px!important;
    }

    @media(max-width: 770px) {
        width: 500px!important;
    }

    @media(max-width: 549px) {
        width: 400px!important;
    }

    @media(max-width: 465px) {
        width: 370px!important;
    }

    @media(max-width: 437px) {
        width: 350px!important;
    }

    @media(max-width: 407px) {
        width: 330px!important;
    }

    @media(max-width: 344px) {
        width: 300px!important;
    }

    @media(max-width: 308px) {
        width: 250px!important;
    }

    .slick-arrow {
        transform: translate(0, -200%)!important;
    }
`

export const ImgModalWrapper = styled.div`
    width: 100%;
    height: 600px;
    border-radius: 12px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 12px;
    }

    @media(max-width: 990px) {
        height: 500px;
    }

    @media(max-width: 770px) {
        height: 400px;
    }

    @media(max-width: 549px) {
        height: 350px;
    }

    @media(max-width: 465px) {
        height: 300px;
    }

    @media(max-width: 344px) {
        height: 250px;
    }

    @media(max-width: 308px) {
        height: 200px;
    }

`