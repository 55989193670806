import styled from 'styled-components';
import { COLORS } from '../../../../utils/constants/colors';

export const Background = styled.div`
    flex: none;
    width: calc(100% - 100px);
    min-height: 100vh;
    background: ${COLORS.beige};
    box-shadow: 0px 0px 30px rgba(198, 184, 166, 0.7);
    border-radius: 40px 0px 0px 40px;
    @media (max-width: 1000px) {
        width: 100%;
        min-height: calc(100vh - 90px);
        border-radius: 0;
        box-shadow: none;
    }
`;