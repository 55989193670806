import React, { useState, useRef } from 'react'
import Input from '../../../components/common/FormElements/Input'
import { Button } from '../../../components/common/Styled/Button'
import { Flex } from '../../../components/common/Styled/Flex'
import { Grid } from '../../../components/common/Styled/Grid'
import Modal from '../../../components/Modal'
import { ChangeType, SetStateType } from '../../../types/common'
import { COLORS } from '../../../utils/constants/colors'
import AddPsyhoPopup from './AddPsyhoPopup'
import UserInfo from './UserInfo'
import { Tab, UsersWrapper, UsersCardWrapper } from './style'
import UserCard from './UserCard'
import loupeIcon from '../../../assets/icons/admin/loupeIcon.svg'
import AddStaffPopup from './AddStaffPopup'
import { useEffect } from 'react'
import { IUser } from '../../../types/interfaces/user'
import { getUsers } from '../../../utils/api/routes'
import { usePagination } from '../../../hooks/usePagination'
import { RootState } from '../../../store/reducers/rootReducer'
import { useSelector } from 'react-redux'
import { useWrapperSize } from '../../../hooks/useWrapperSize'
import { debounce } from '../../../utils/debounce'
import useResolution from '../../../hooks/useResolution'


export interface IAdminModalProps {
    setShowModal: SetStateType<boolean>
    setVersion: SetStateType<boolean>
    page: 1 | 2
}

const Users = () => {
    const { profile } = useSelector((state: RootState) => state.user)
    const [page, setPage] = useState(1 as 1 | 2)
    const [search, setSearch] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [version, setVersion] = useState(true)
    const [users, setUsers] = useState<Array<IUser>>([])
    const [countUsers, setCountUsers] = useState(0)
    const { scroll, fetching, successCallback, currentPage, finalyCallback, resetCallback } = usePagination(countUsers === users.length)
    const [activeUser, setActiveUser] = useState<IUser | null>(null)
    const [showWarraningModal, setShowWarrningModal] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    const { size } = useWrapperSize(ref)
    const isMobile = useResolution(1000)

    const debouncedUpdateUsers = useRef(debounce((search, page) => updateUserComponent(search, page), 400))

    const updateUserComponent = (search?: string, page?: number) => {
        resetCallback()
        getUsers(`page=1&page_size=15&search=${search}&is_psychologist=${page === 1 ? 'true' : 'false'}&is_staff=${page === 2 ? 'true' : 'false'}&is_superuser=false${!profile.is_superuser && !profile.psychologist_all ? `&staff=${profile.id}` : ''}`)
            .then(res => {
                setUsers(res.data.results)
                setCountUsers(res.data.count)
                successCallback()
            })
        setActiveUser(null)
    }

    useEffect(() => {
        debouncedUpdateUsers.current(search, page)
    }, [page, search])

    useEffect(() => {
        if (fetching) {
            getUsers(`page=${currentPage}&page_size=15&search=${search}&is_psychologist=${page === 1 ? 'true' : 'false'}&is_staff=${page === 2 ? 'true' : 'false'}&is_superuser=false${!profile.is_superuser && !profile.psychologist_all ? `&staff=${profile.id}` : ''}`).then(res => {
                setUsers([...users, ...res.data.results])
                successCallback()
            }).finally(finalyCallback)
        }
    }, [fetching])

    return (
        <UsersWrapper width="100%" padding="40px 60px 20px 60px" justify="flex-start" direction="column">
            <Flex width="100%" justify="flex-start">
                <Tab
                    active={page === 1}
                    onClick={() => setPage(1)}
                    color={COLORS.steel}
                    weight="600"
                    size="16px"
                    lineHeight="24px"
                    margin="0 35px 0 0"
                    fontFamily="Montserrat"
                >
                    Психологи
                </Tab>
                {profile.is_superuser &&
                    <Tab
                        active={page === 2}
                        onClick={() => setPage(2)}
                        color={COLORS.steel}
                        weight="600"
                        size="16px"
                        lineHeight="24px"
                        fontFamily="Montserrat"
                    >
                        Модераторы
                    </Tab>
                }
            </Flex>
            <Flex margin="40px" width="100%" justify="space-between">
                <Input
                    value={search}
                    onChange={(e: ChangeType) => setSearch(e.target.value)}
                    placeholder="ФИО / Email / Телефон"
                    icon={loupeIcon}
                />
                <Button
                    theme="grey"
                    radius="6px"
                    width="217px"
                    height="36px"
                    weight="600"
                    lineHeight="17px"
                    onClick={() => {
                        setVersion(true)
                        setShowModal(true)
                    }}
                >
                    {page === 1 ? 'Добавить психолога' : 'Добавить модератора'}
                </Button>
            </Flex>
            <Grid w="100%" columns="40% 50%" gap="10%" margin={isMobile ? '0 0 80px 0' : ''}>
                <UsersCardWrapper ref={ref} height={size} justify="flex-start" direction="column" onScroll={scroll} width="100%">
                    {users.map(item => <UserCard activeUser={activeUser?.id || null} setActiveUser={setActiveUser} key={item.id} user={item} />)}
                </UsersCardWrapper>
                <Flex width="100%">
                    {activeUser && <UserInfo updateUserComponent={updateUserComponent} page={page} user={activeUser} setShowModal={setShowModal} setVersion={setVersion} />}
                </Flex>
            </Grid>
            {showModal && page === 1 &&
                <Modal
                    component={AddPsyhoPopup}
                    closeModal={() => setShowWarrningModal(true)}
                    parameters={{
                        version,
                        user: activeUser,
                        updateUserComponent,
                        showWarraningModal,
                        setShowWarrningModal,
                        closeParentModal: () => setShowModal(false)
                    }}
                />
            }
            {showModal && page === 2 &&
                <Modal
                    component={AddStaffPopup}
                    closeModal={() => setShowWarrningModal(true)}
                    parameters={{
                        version,
                        user: activeUser,
                        updateUserComponent,
                        showWarraningModal,
                        setShowWarrningModal,
                        closeParentModal: () => setShowModal(false)
                    }}
                />
            }
        </UsersWrapper>
    )
}

export default Users