import React, { useState, useEffect } from 'react'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Table, CeilHeader, Ceil } from '../../../../components/common/Styled/Table'
import { Typography } from '../../../../components/common/Styled/Typography'
import loupeIcon from '../../../../assets/icons/admin/loupeIcon.svg'
import { Button } from '../../../../components/common/Styled/Button'
import { deleteSubscriptions, getSubscriptions } from '../../../../utils/api/routes'
import { ISubscription } from '../../../../types/interfaces/subscription'
import { PenIcon, DeleteIcon } from '../../../../assets/icons'
import Modal from '../../../../components/Modal'
import AddSubscriptionsPopup from './AddSubscriptionsPopup'
import DeleteSubscriptionPopup from './DeleteSubscriptionPopup'
import ErrorHandler from '../../../../components/Error'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import { DebouncedInput } from '../../../../components/common/FormElements/DebouncedInput/DebouncedInput'

function Subscriptions() {
    const { show, active, hidden, text } = useErrorHandler()
    const [addSubscriptionModal, setAddSubscriptionModal] = useState(false)
    const [deleteSubscriptionModal, setDeleteSubscriptionModal] = useState(false)
    const [subscriptions, setSubscriptions] = useState<Array<ISubscription>>([])
    const [subscription, setSubscription] = useState<ISubscription | null>(null)

    const tableHeader = [
        { id: 1, text: 'Название абонемента', borderRadius: 'first' as 'first' },
        { id: 2, text: 'Количество часов' },
        { id: 3, text: 'Стоимость часа в абонементе, ₽' },
        { id: 4, text: 'Стоимость абонемента, ₽' },
        { id: 5, text: 'Ценовая категория' },
        { id: 6, text: 'Редактировать' },
        { id: 7, text: 'Удалить', borderRadius: 'last' as 'last' },
    ]
    const updateComponent = (seachString?: string) => {
        getSubscriptions(seachString && `?search=${seachString}`).then(res => setSubscriptions(res.data))
    }
    useEffect(() => {
        updateComponent()
    }, [])

    return (
        <Flex margin="40px 0 0 0" align="flex-start" direction="column" width="100%">
            <ErrorHandler active={active} hidden={hidden} text={text} />
            <Flex justify="space-between" width="100%">
                <Flex width="30%">
                    <DebouncedInput
                        searchHandler={(seachString: string) => updateComponent(seachString)}
                        placeholder="Поиск"
                        icon={loupeIcon}
                    />
                </Flex>
                <Button
                    theme="grey"
                    width="220px"
                    height="36px"
                    radius="6px"
                    weight="600"
                    lineHeight="17px"
                    onClick={() => setAddSubscriptionModal(true)}
                >
                    Добавить абонемент
                </Button>
            </Flex>
            <Table margin="25px 0 0 0" w="100%" columns="repeat(7, calc(100% / 7))">
                {tableHeader.map(item =>
                    <CeilHeader key={item.id} borderRadius={item.borderRadius && item.borderRadius}>
                        <Typography weight="700" lineHeight="18px">{item.text}</Typography>
                    </CeilHeader>

                )}
                {subscriptions.map((item, index) =>
                    <React.Fragment key={item.id}>
                        <Ceil borderRadius="first" isLastRow={index === subscriptions.length - 1}>
                            <Typography lineHeight="18px">{item.name}</Typography>
                        </Ceil>
                        <Ceil>
                            <Typography lineHeight="18px">{item.count}</Typography>
                        </Ceil>
                        <Ceil>
                            <Typography lineHeight="18px">{item.hour_one}</Typography>
                        </Ceil>
                        <Ceil>
                            <Typography lineHeight="18px">{item.hours_all}</Typography>
                        </Ceil>
                        <Ceil>
                            <Typography lineHeight="18px">{item?.price_category?.name}</Typography>
                        </Ceil>
                        <Ceil>
                            <PenIcon onClick={() => {
                                setSubscription(item)
                                setAddSubscriptionModal(true)
                            }} />
                        </Ceil>
                        <Ceil borderRadius="last" isLastRow={index === subscriptions.length - 1}>
                            <DeleteIcon onClick={() => {
                                setSubscription(item)
                                setDeleteSubscriptionModal(true)
                            }} />
                        </Ceil>
                    </React.Fragment>
                )}
            </Table>
            {addSubscriptionModal &&
                <Modal
                    component={AddSubscriptionsPopup}
                    haveCloseBtn={false}
                    closeModal={() => {
                        setAddSubscriptionModal(false)
                        setSubscription(null)
                    }}
                    parameters={{
                        subscription,
                        updateComponent
                    }}
                />
            }
            {deleteSubscriptionModal &&
                <Modal
                    component={DeleteSubscriptionPopup}
                    haveCloseBtn={true}
                    closeModal={() => {
                        setDeleteSubscriptionModal(false)
                        setSubscription(null)
                    }}
                    parameters={{
                        subscription,
                        updateComponent
                    }}
                />
            }
        </Flex>
    )
}

export default Subscriptions
