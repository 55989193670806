import React from "react";
import {Container, Banner, Wrapper, Line, Handshake} from "./style";
import {Typography} from "../../../../components/common/Styled/Typography";
import banner from "../../../../assets/images/agreementBanner.png";
import handshake from "../../../../assets/images/handshake.png";
import {Button} from "../../../../components/common/Styled/Button";
import {useDispatch} from "react-redux";
import {setLogged} from "../../../../store/reducers/userReducer";
import {Flex} from "../../../../components/common/Styled/Flex";


const Agreement = () => {
    const dispatch = useDispatch();

    const agree = () => {
        dispatch(setLogged(true))
    }

    return (
        <Wrapper>
            <Banner src={banner} alt="agreement" />
            <Container direction="column" align="flex-start">
                <Typography size="36px" fontFamily="Montserrat" weight={700}>Договор</Typography>
                <Typography fontFamily="Montserrat" margin="20px 0 50px" size="16px">
                    Вы можете оплатить бронирование кабинетов как единоразовым платежом, так и с помощью абонемента.
                    <br />
                    Правила пользования абонементами:
                    <br />
                    <br />
                    1. Есть несколько категорий абонементов, в которые входят определённые кабинеты (см. список Категории абонементов).
                    <br />
                    2. Абонемент действует в течение года с момента покупки. Вы можете тратить часы в любой момент действия абонемента.
                    <br />
                    3. Абонементом могут быть оплачены только следующие после покупки абонемента часы, им нельзя оплатить уже прошедшие брони.
                    <br />
                    <br />
                    Категории абонементов:
                    <br />
                    1. Абонемент «Стандарт». В данный абонемент входят кабинеты на станциях метро Чернышевская, Невский проспект, Сенная площадь, Петроградская (кроме центра на Петропавловской).
                    <br />
                    2. Абонемент «Петропавловская». В данный абонемент входят 3 кабинета психологического центра на Петропавловской 6.
                    <br />
                    3. Абонемент «Плюс». В данный абонемент входят кабинеты на станциях метро Адмиралтейская и Технологический Институт.
                    <br />
                    4. Абонемент «Групповой». В данный абонемент входят кабинеты 1,3,6 на станции метро Сенная площадь (ул. Ефимова ?). Чтобы забронировать групповой кабинет необходимо выбрать количество 
                    посетителей от 5 человек.
                    <br />
                    <br />
                    Правила бронирования:
                    <br />
                    1. Средства за бронь списываются только по окончанию бронирования.
                    <br />
                    2. Оплата прошедшего бронирования должна быть совершена в течение суток. Если оплата не поступила, доступ к бронированию ограничивается.
                    <br />
                    3. Бесплатная отмена бронирования дневного времени до 17:00 — за 24 часа.
                    <br />
                    4. Бесплатная отмена бронирования вечернего времени с 17:00 — за 48 часов.
                    <br />
                    5. В случае если вы создали бронь до 17:00 менее, чем за 24 часа или после 17:00 часов менее, чем за 48 часов отмена брони осуществляется бесплатно.
                    <br />
                    6. Бесплатная отмена 4 и более часов в день — за 4 суток (96 часов).
                    <br />
                    7. Перенос брони в течение одного дня осуществляется бесплатно. Вы можете бесплатно перенести свою бронь в день приема на другое время и в любой другой свободный кабинет.
                    <br />
                    8. Есть возможность встать в очередь. Для этого на уже забронированное время нужно нажать и забронировать. Бронь будет отображаться красным цветом. Если время освободится — вам придёт уведомление о том, что что вы можете забронировать освободившееся время. Чтобы закрепить время за собой, нужно подтвердить бронирование. На подтверждение отводится 3 часа с момента освобождения (за исключением ночного времени, в этот период таймер не включён). Если за 3 часа вы не подтвердили бронь, то она либо освобождается, либо переходит к следующему за вами в очереди.
                    <br />
                    9. Если вы несвоевременно отменили бронь, то списание произойдёт только в момент начала брони. До этого изменения в балансе не будет. Если другой психолог забронирует на освобождённое вами время — с вас не будут списываться средства. Если время останется пустым — средства спишутся с баланса.
                </Typography>
                <Flex width="100%">
                    <Line />
                    <Handshake src={handshake} alt="handshake" />
                    <Line />
                </Flex>
                <Button
                    theme="grey"
                    fontSize="16px"
                    radius="6px"
                    padding="8px 65px"
                    weight={600}
                    width="100%"
                    margin="80px auto 60px"
                    onClick={agree}
                >
                    С ДОГОВОРОМ ОЗНАКОМИЛСЯ(ЛАСЬ), ПРИНИМАЮ УСЛОВИЯ СОТРУДНИЧЕСТВА
                </Button>
            </Container>
        </Wrapper>
    )
}

export default Agreement;