import styled from 'styled-components'
import { DropDown } from '../../../components/common/Styled/DropDown'
import { COLORS } from '../../../utils/constants/colors'

export const DateSelectWrapper = styled.div`
    position: relative;
    width: 100%;

    input {
        background: ${COLORS.white}
    }

    ${DropDown} {
        width: 340px;
    }
`
