import React, { useEffect, useState, useRef } from 'react';
import { ITotalProps } from '../../';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { ChangeType, SetStateType } from '../../../../types/common';
import { getSelectParams } from '../../../../utils/api/routes';
import { COLORS } from '../../../../utils/constants/colors';
import Input from '../../../common/FormElements/Input';
import { Typography } from '../../../common/Styled/Typography';
import { AutoCompliteWrapper } from '../style';
import { List, ListItem } from './style';

type ListType = Array<{ id: number, name: string, city?: number }>

const CitiesComplite = ({ setTotalState, setCityId }: ITotalProps & { setCityId: SetStateType<number | null> }) => {
    const [list, setList] = useState<ListType>([])
    const [show, setShow] = useState(false)
    const [active, setActive] = useState(false)
    const [selectedCity, setSelectedCity] = useState('')
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        getSelectParams('cities')
            .then(res => {
                setList(res.data)
                const city = res.data.find(item => item.name === 'Санкт-Петербург')
                city && setCityId(city.id)
                city && setSelectedCity(city.name)
            })
    }, [setList, setCityId, setSelectedCity])

    useEffect(() => {
        selectedCity && selectedCity !== 'Город' && setActive(true)
        setTotalState(prevState => ({ ...prevState, city: selectedCity }))
    }, [selectedCity, setTotalState, setActive])

    const reset = () => {
        setSelectedCity('Город')
        setActive(false)
        setCityId(null)
        setShow(false)
    }

    const selectItem = (id: number) => {
        const record = id && list.find(item => item.id === +id)
        record && setSelectedCity(record.name)
        setShow(false)
        setCityId(id)
    }

    useClickOutside(ref, () => setShow(false))

    return (
        <AutoCompliteWrapper width="150px" ref={ref} active={active}>
            <Typography onClick={() => setShow(true)} color={active ? COLORS.white : COLORS.black} padding="0 10px" weight="700" lineHeight="24px">{selectedCity}</Typography>
            {show &&
                <List>
                    <ListItem
                        onClick={() => reset()}
                    >
                        <Typography size="16px" lineHeight="20px">Все</Typography>
                    </ListItem>
                    {list.map(item =>
                        <ListItem
                            key={item.id}
                            onClick={() => { selectItem(item.id); setShow(false) }}
                        >
                            <Typography size="16px" lineHeight="20px">{item.name}</Typography>
                        </ListItem>
                    )}
                </List>
            }
        </AutoCompliteWrapper>
    );
};

export default CitiesComplite;