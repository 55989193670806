import { combineReducers } from "redux";
import officesReducer from "./officesReducer";
import userReducer from "./userReducer";
import bookingsReducer from './bookingReducer'
import notificationReducer from './notificationReducer'

export const rootReducer = combineReducers({
    user: userReducer,
    offices: officesReducer,
    bookings: bookingsReducer,
    notification: notificationReducer
});

export type RootState = ReturnType<typeof rootReducer>