import styled from 'styled-components'
import { Flex } from '../../../../../components/common/Styled/Flex'
import { COLORS } from '../../../../../utils/constants/colors'

export const PriceCategoryPopup = styled.div`
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    width: 696px;
    background: ${COLORS.cream};

    input {
        background: ${COLORS.white};
    }
`

export const ColorWrapper = styled(Flex)`
    input {
        width: 50px;
        cursor: pointer;
        padding: 0 5px;
    }
`