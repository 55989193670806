import styled from "styled-components";
import { InputWrapper } from "../../../components/common/FormikElements/Input/style";
import {Flex} from "../../../components/common/Styled/Flex";
import {Typography} from "../../../components/common/Styled/Typography";
import { COLORS } from "../../../utils/constants/colors";

const Container = styled(Flex)`
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    padding: 0 0 20px 0;
    ${Typography} {
        max-width: 280px;
    };

`;

const SetPasswordWrapper = styled.div`
    padding: 0 20px;
    ${InputWrapper} {
        width: 460px;
        margin-bottom: 15px;
    }
    label{
        width: 100%;
        margin-bottom: 7px; 
    }

    input {
        background: ${COLORS.white};
    }

    @media (max-width: 500px) {
        width: 100%;

        ${InputWrapper} {
            width: 100%;
        }
    }
`

const Title = styled(Flex)`
    width: 100%;
    border-bottom: 1px solid ${COLORS.brown};
`

export {Container, SetPasswordWrapper, Title};