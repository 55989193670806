import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { COLORS } from '../../../../utils/constants/colors'

const CardWrapper = styled(Flex)<{status: boolean, active?: boolean}>`
    position: relative;
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    background: ${({active}) => active ? COLORS.cream : COLORS.logout};
    min-height: 100px;
    width: 100%;
    transition: background .2s linear;
    cursor: pointer;
    &::before {
        content: '';
        position: absolute;
        top: 5px;
        right: 5px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: ${({status}) => status ? COLORS.green : COLORS.red};
    }

    &:hover {
        background: ${COLORS.cream};
    }
`

export {CardWrapper}