import styled from 'styled-components'
import { Flex } from '../../../components/common/Styled/Flex'
import { Typography } from '../../../components/common/Styled/Typography'
import { BodyWrapper } from '../../../components/Modal/style'
import { COLORS } from '../../../utils/constants/colors'

const Tab = styled(Typography)<{active: boolean}>`
    ${({active}) => active && `color: ${COLORS.black};`};
    cursor: pointer;
    &:hover {
        color: ${COLORS.black};
    }
`

const UsersWrapper = styled(Flex)`
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
    justify-content: flex-start;
    input {
        width: 400px;
    }

    ${BodyWrapper} {
        overflow-y: auto;
        overflow-x: hidden!important;

        input {
            width: 100%;
        }
    }

    `

const UsersCardWrapper = styled(Flex)<{height: string}>`
    max-height: ${({height}) => height};
    overflow-y: auto;
    & > div {
        margin-bottom: 4px;
    }
    & > div:last-child {
        margin-bottom: 0px;
    }
`

export { Tab, UsersWrapper, UsersCardWrapper }