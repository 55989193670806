import React from 'react';
import { Button } from '../../../../components/common/Styled/Button';
import { Typography } from '../../../../components/common/Styled/Typography';
import { VoidFuncType } from '../../../../types/common';
import { CloseModalPopupWrapper } from './style';

const CloseModalPopup = ({closeModal, closeParentModal}: {closeModal: VoidFuncType, closeParentModal: VoidFuncType}) => {
    return (
        <CloseModalPopupWrapper>
            <Typography align="center" weight="600">
                При закрытии окна все поля очистятся. <br />
                Вы уверены?
            </Typography>
            <Button
                onClick={() => {closeParentModal(); closeModal()}}
                theme="grey"
                width="155px"
                height="40px"
                radius="21px"
                weight="600"
                lineHeight="17px"
                margin="15px 0 0 0"
            >
                Да, я уверен(а).
            </Button>
        </CloseModalPopupWrapper>
    );
};

export default CloseModalPopup;