import styled from 'styled-components'
import { COLORS } from '../../../../../utils/constants/colors'

export const CityPopup = styled.div`
    width: 348px;
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    background: ${COLORS.cream};

    input {
        background: ${COLORS.white};
    }
`