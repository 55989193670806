import styled from 'styled-components'
import { FilterWrapper } from '../common/Styled/Filters'

const DateWrapper = styled(FilterWrapper)`
    cursor: pointer;
    user-select: none;

    input{
        width: 145px;
        text-align: center;
    }

    @media (max-width: 355px) {
        input {
            width: 100%;
        }
    }
`

const TimeWrapper = styled(FilterWrapper)`
    cursor: pointer;
    user-select: none;

    input {
        width: 60px;
        margin-right: 5px;
        text-align: center;
    }
`

export { DateWrapper, TimeWrapper }