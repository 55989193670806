import styled from 'styled-components';
import {COLORS} from "../../utils/constants/colors";

const MenuWrapper = styled.div<{manyIcons: boolean}>`
    display: flex;
    align-items: ${({manyIcons}) => manyIcons ? 'flex-start' : 'center'};;
    margin: ${({manyIcons}) => manyIcons && '20px 0 0 0'};
    width: 100%;
    min-height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
    overflow-y: auto;
    
    svg {
        width: 40px;
        height: 40px;
    }
    
    @media (max-width: 1000px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: 90px;
        height: 90px;
        box-shadow: 0px 0px 10px rgba(198, 184, 166, 0.58);
        border-radius: 15px 15px 0px 0px;
        z-index: 99;
        background: ${COLORS.brown};

        svg {
            width: 35px;
            height: 35px;
        }
    }
    @media (max-width: 350px) {
        p{
        font-size: 10px;
        }
        
        svg {
            width: 30px;
            height: 30px;
        }

        height: 75px;
        min-height: 75px;
    }

    &::-webkit-scrollbar{
        width: 0px;
    }

`;

const ListItem = styled.li`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    cursor: pointer;
    
    svg {
        transition: all .2s linear;
        path {
            fill: rgba(61, 50, 33, 0.38);
        };
    }

    &:hover {
        p {
            color: ${COLORS.lightBlack} !important;
        }
        svg {
            path {
                fill: ${COLORS.lightBlack};
            }; 
        }
    }

    @media (max-width: 1000px) {
        margin-bottom: 0;
    }

`;


const List = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100px;
    padding: 0 10px;
    
    .active_menu_link {
        ${ListItem} {
            p {
               color: ${COLORS.lightBlack} !important;
            }
            svg {
               path {
                   fill: ${COLORS.lightBlack};
               }; 
            }
        };
    };

    @media (max-width: 1000px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
`;

const UnReadedCount = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-shadow: 0px 1px 1px rgba(161, 147, 126, 0.55);
    border-radius: 50%;
    background: ${COLORS.red};
    width: 16px;
    height: 16px;
    top: 0;
    right: 20px;
`

export { MenuWrapper, List, ListItem, UnReadedCount }