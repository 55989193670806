import React, { useEffect } from "react";
import {Typography} from "../../../../components/common/Styled/Typography";
import {Formik, Form, Field, useFormikContext} from "formik";
import * as Yup from "yup";
import FormikInput from "../../../../components/common/FormikElements/Input";
import {EMAIL, REQUIRED_STRING, validationMessages} from "../../../../utils/formik/validation";
import {Button} from "../../../../components/common/Styled/Button";
import {SubmitButtonWrapper} from "../../style";
import {useDispatch} from "react-redux";
import {SetStateType, VoidFuncType} from "../../../../types/common";
import {login, setLogged} from "../../../../store/reducers/userReducer";
import useResolution from "../../../../hooks/useResolution";

interface IProps {
    openRecovery: VoidFuncType
    changeStep: SetStateType<'login' | 'recovery' | 'create' | 'agreement'>,
    setError: SetStateType<string>
}

const Auth = ({openRecovery, changeStep, setError}: IProps) => {
    const dispatch = useDispatch();
    const isMobile = useResolution(625)
    const initialValues = {
        email: '',
        password: ''
    }

    const FormikContext = () => {
        const {values, setFieldValue} = useFormikContext()
        useEffect(() => {
            setFieldValue('email', (values as typeof initialValues).email.trim())
        }, [values])

        return null
    }

    const successLogin = (password: string, isChanged: boolean) => {
        if (isChanged) dispatch(setLogged(true))

        else {
            sessionStorage.setItem('user_temporary_password', password)
            changeStep('create');
        }
    }

    const handleSubmit = (values: typeof initialValues) => {
        dispatch(login({
            ...values,
            email: values.email.trim().toLowerCase(),
            errorCallback: (err: string) => setError(err),
            successCallback: (isChanged: boolean) => successLogin(values.password, isChanged)
        }))
    }


    const schema = Yup.object({
        email: EMAIL,
        password: REQUIRED_STRING.min(6, 'Минимальная длина пароля 6 символов').max(128, validationMessages.max128)
    })

    return (
        <>
            <Typography weight={700} size='36px' lineHeight="44px" margin="0 0 60px" fontFamily="Montserrat">Добро пожаловать в Psy Office</Typography>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => handleSubmit(values)}
                validationSchema={schema}
            >
                <Form>
                   <Field name="email" component={FormikInput} label="Email" theme="underline" labelWidth="90px"/>
                   <Field margin="20px 0 0 0 " name="password" type="password" component={FormikInput} label="Пароль" theme="underline" labelWidth="90px"/>
                   <Button
                       type="button"
                       fontFamily="Montserrat"
                       color="rgba(36, 36, 36, 0.5)"
                       fontSize="13px"
                       weight="500"
                       lineHeight="24px"
                       onClick={openRecovery}
                       margin="5px 0 55px 21.5%"
                       hover={{color: 'rgba(36, 36, 36)'}}
                   >
                       Восстановить пароль
                   </Button>
                   <SubmitButtonWrapper>
                      <Button
                          type="submit"
                          theme="grey"
                          radius="6px"
                          shadow={true}
                          weight={800}
                          margin="0 0 0 auto"
                          fontSize="16px"
                          width={isMobile ? '100%' : '155px'}
                          height="40px"
                      >
                          ВОЙТИ
                      </Button>
                      <FormikContext />
                   </SubmitButtonWrapper>
                </Form>
            </Formik>
        </>
    )
}

export default Auth;