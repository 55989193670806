import styled from 'styled-components'
import { Flex } from '../../components/common/Styled/Flex'

const NoticationsWrapper = styled(Flex)<{height: string}>`
    max-height: ${({height}) => height};
    overflow-y: auto;

    @media(max-width: 1000px) {
        max-height: calc(100vh - 250px);
    }

    
    @media(max-width: 900px) {
        max-height: calc(100vh - 170px);
    }
`

const NotificationPage = styled(Flex)`
    @media(max-width: 1050px) {
        padding: 135px 50px 0 50px;
    }

    @media(max-width: 900px) {
        padding: 50px 50px 0 50px;
    }

    @media(max-width: 530px) {
        padding: 50px 15px 0 15px;
    }

`

const NotificationHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 29px;
    font-weight: 800;
    color: #242424;

    @media(max-width: 800px) {
        font-size: 18px;
    } 
    @media(max-width: 343px) {
        font-size: 15px;
    } 
`

export {NoticationsWrapper, NotificationPage, NotificationHeader}