import React, { useLayoutEffect, useRef, useState} from 'react'
import { CalendarChevron } from '../../../assets/icons'
import { Button } from '../../../components/common/Styled/Button'
import { Flex } from '../../../components/common/Styled/Flex'
import { Typography } from '../../../components/common/Styled/Typography'
import useResolution from '../../../hooks/useResolution'
import { useWrapperSize } from '../../../hooks/useWrapperSize'
import { IProduct } from '../../../types/interfaces/product'
import { buyHours, buySubscription } from '../../../utils/api/routes'
import { COLORS } from '../../../utils/constants/colors'
import { fixString } from '../../Profile/utils'
import { ArrowDown, ProductItem, ProductItems, SubscriptionsItemWrapper, Title } from './style'

function SubscriptionsItem({data} : {data: IProduct}) {
    const isMobile = useResolution(500)
    const isMobile1000 = useResolution(1000)
    const ref = useRef<HTMLDivElement>(null)
    const {size} = useWrapperSize(ref, isMobile1000, [isMobile1000])
    const [hoursCounter, setHoursCounter] = useState(0)
    const [arrowDown, setArrowDown] = useState(false)
    const [active, setActive] = useState(false)

    const buy = (id: number, type = 1 as 1 | 2) => {
        type === 2 ? 
        buySubscription({subscription: id}).then(res => {
            window.location.href = res.data.PaymentURL
        })
        :
        buyHours({price_category: id, count: hoursCounter}).then(res => {
            window.location.href = res.data.PaymentURL
        })
    }
    
    useLayoutEffect(() => {
        const resizeHandler = () => {
            if(!ref.current) return
            // if(isMobile1000 || isMobile) {
            //     setArrowDown(false)
            // }
            if(ref.current.scrollHeight - 30 > ref.current.offsetHeight) {
                return setArrowDown(true)
            }
            setArrowDown(false)
        }
        resizeHandler()
        window.addEventListener('resize', resizeHandler)

        return () => window.removeEventListener('resize', resizeHandler)
    }, [ref, size, isMobile, isMobile1000, data])

    const scrollToDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        ref.current && ref.current.scrollTo({top: parseInt(size), left: 0, behavior: 'smooth'})
    }

    const scrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.offsetHeight) < 10) {
                return setArrowDown(false)
            }
            
            ref.current && ref.current.scrollHeight - 30 > ref.current.offsetHeight && setArrowDown(true)
    }

    return (
        <SubscriptionsItemWrapper isMobile={isMobile}>
            <Title padding="0 20px 13px 20px" align="flex-start" direction="column">
                <Typography margin="0 0 15px 0" weight="600" size="17px" lineHeight="19px">{data.name} {data.is_group ? '(групповая)': ''}</Typography>
                {!isMobile && <Typography weight="800" size="24px" lineHeight="29px">Баланс: {fixString(data.balance ? data.balance : 0)}</Typography>}
                {isMobile && 
                    <Flex width="100%" justify="space-between">
                        <Flex justify="flex-start" direction="column" align="flex-start">
                            <Typography weight="800" size="25px" lineHeight="30px">{fixString(data.balance ? data.balance : 0)}</Typography>
                            <Typography weight="700" lineHeight="17px">Баланс</Typography>
                        </Flex>
                        <Button 
                            theme="green"
                            width="146px"
                            height="34px"
                            radius="6px"
                            weight="700"
                            onClick={() => setActive(!active)}
                        >
                            {active ? 'Скрыть' : 'Пополнить'}
                        </Button>
                    </Flex>
                }
            </Title>
            {(active || !isMobile) && 
                <ProductItems onScroll={(e) => scrollHandler(e)} justify="flex-start" size={size} isMobile={isMobile} ref={ref} direction="column">
                    <ProductItem direction="column" width="100%">
                        <Flex width="100%" justify="space-between">
                            <Typography weight="700" size="16px" lineHeight="19px">{data.price}₽&nbsp;/&nbsp;час</Typography>
                            <Flex>
                                <Button
                                    background={COLORS.beige}
                                    color="black"
                                    width="24px"
                                    height="24px"
                                    radius="50%"
                                    onClick={() => hoursCounter !== 0 && setHoursCounter(prevState => --prevState)}
                                >
                                    -
                                </Button>
                                <Flex width="40px">
                                    <Typography weight="700" size="16px" lineHeight="19px" margin="0 16px">{hoursCounter}</Typography>
                                </Flex>
                                <Button
                                    background={COLORS.beige}
                                    color="black"
                                    width="24px"
                                    height="24px"
                                    radius="50%"
                                    onClick={() => hoursCounter < 10 && setHoursCounter(prevState => ++prevState)}
                                >
                                    +
                                </Button>
                            </Flex>
                        </Flex>
                        <Flex margin="15px 0 0 0" width="100%" justify="flex-end">
                            <Button 
                                theme="green"
                                width="90px"
                                height="30px"
                                radius="6px"
                                weight="700"
                                onClick={() => hoursCounter && buy(data.id)}
                                disabled={!hoursCounter}
                            >
                                Купить
                            </Button>
                        </Flex>
                    </ProductItem>
                    {data.subs.map(item => 
                        <ProductItem key={item.id} align="flex-start" direction="column" width="100%">
                            <Flex width="100%" height="38px" justify="space-between">
                                <Typography weight="700" size="16px" lineHeight="19px">{item.name}</Typography>
                                <Typography weight="700" size="16px" lineHeight="19px">{item.hours_all}₽</Typography>     
                            </Flex>
                            <Flex width="100%" justify="flex-start">
                                <Typography weight="500" color={COLORS.steel}>{item.hour_one}₽&nbsp;/&nbsp;час</Typography>  
                            </Flex> 
                            <Flex margin="15px 0 0 0" width="100%" justify="flex-end">
                                <Button 
                                    theme="green"
                                    width="90px"
                                    height="30px"
                                    radius="6px"
                                    weight="700"
                                    onClick={() => buy(item.id, 2)}
                                >
                                    Купить
                                </Button>
                            </Flex>
                        </ProductItem>
                        )}
                </ProductItems>
            }
            {arrowDown && !isMobile && !isMobile1000 && 
                <ArrowDown onClick={(e) => scrollToDown(e)}>
                    <CalendarChevron />
                </ArrowDown>
            }
        </SubscriptionsItemWrapper>
    )
}

export default SubscriptionsItem
