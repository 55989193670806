import React, { useState, useEffect } from 'react'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Ceil, CeilHeader, Table } from '../../../../components/common/Styled/Table'
import loupeIcon from '../../../../assets/icons/admin/loupeIcon.svg'
import { Button } from '../../../../components/common/Styled/Button'
import { Typography } from '../../../../components/common/Styled/Typography'
import { getSelectParams } from '../../../../utils/api/routes'
import { DeleteIcon } from '../../../../assets/icons'
import Modal from '../../../../components/Modal'
import AddCityPopup from './AddCityPopup'
import DeleteCityConfirmPopup from './DleteCityConfirmPopup'
import { DebouncedInput } from '../../../../components/common/FormElements/DebouncedInput/DebouncedInput'

function Cities() {
    const [cities, setCities] = useState<Array<{ id: number, name: string }>>([])
    const [showModal, setShowModal] = useState(false)
    const [showModalСonfirm, setShowModalСonfirm] = useState(false)
    const [activeCity, setActiveCity] = useState<number | null>(null)

    useEffect(() => {
        getSelectParams('cities').then(res => setCities(res.data))
    }, [])

    const updateComponent = (searchString: string) => {
        getSelectParams('cities', `?search=${searchString}`).then(res => setCities(res.data))
    }

    return (
        <Flex margin="40px 0 0 0" align="flex-start" width="100%" direction="column">
            <Flex width="100%" justify="space-between">
                <Flex width="30%">
                    <DebouncedInput
                        searchHandler={(searchString: string) => getSelectParams('cities', `?search=${searchString}`).then(res => setCities(res.data))}
                        placeholder="Поиск"
                        icon={loupeIcon}
                    />
                </Flex>
                <Button
                    radius="6px"
                    theme="grey"
                    width="217px"
                    height="36px"
                    weight="600"
                    lineHeight="17px"
                    onClick={() => setShowModal(true)}
                >
                    Добавить город
                </Button>
            </Flex>
            <Table margin="25px 0 0 0" w="30%" columns="60% 40%">
                <CeilHeader borderRadius="first">
                    <Typography weight="700" lineHeight="18px">Город</Typography>
                </CeilHeader>
                <CeilHeader borderRadius="last">
                    <Typography weight="700" lineHeight="18px">Удалить</Typography>
                </CeilHeader>
                {cities.map((item, index) =>
                    <React.Fragment key={item.id}>
                        <Ceil borderRadius="first" isLastRow={index === cities.length - 1}>
                            <Typography lineHeight="18px">{item.name}</Typography>
                        </Ceil>
                        <Ceil borderRadius="last" isLastRow={index === cities.length - 1}>
                            <DeleteIcon onClick={() => {
                                setActiveCity(item.id)
                                setShowModalСonfirm(true)
                            }} />
                        </Ceil>
                    </React.Fragment>
                )}
            </Table>
            {showModal &&
                <Modal
                    haveCloseBtn
                    component={AddCityPopup}
                    closeModal={() => setShowModal(false)}
                    parameters={{
                        updateComponent
                    }}
                />
            }
            {showModalСonfirm && activeCity &&
                <Modal
                    haveCloseBtn
                    component={DeleteCityConfirmPopup}
                    closeModal={() => {
                        setShowModalСonfirm(false)
                        setActiveCity(null)
                    }}
                    parameters={{
                        updateComponent,
                        id: activeCity
                    }}
                />
            }
        </Flex>
    )
}

export default Cities
