import React, { useEffect, useState } from 'react'
import { Button } from '../../../../../components/common/Styled/Button'
import { Flex } from '../../../../../components/common/Styled/Flex'
import { Typography } from '../../../../../components/common/Styled/Typography'
import ErrorHandler from '../../../../../components/Error'
import { useErrorHandler } from '../../../../../hooks/useErrorHandler'
import { VoidFuncType } from '../../../../../types/common'
import { deleteMetro, deleteOffice, getCbinetsOfMetro } from '../../../../../utils/api/routes'
import { showAlert } from '../../../../../utils/showAlert'
import { DeleteMetroConfirmPopupWrapper } from './style'

interface IProps {
    id: number,
    updateComponent: (arg: string) => void,
    closeModal: VoidFuncType
}

function DeleteMetroConfirmPopup({ id, updateComponent, closeModal }: IProps) {
    const [ids, setIds] = useState<Array<number>>([])
    const { active, hidden, text } = useErrorHandler()

    useEffect(() => {
        getCbinetsOfMetro(id).then(res => setIds(res.data.map(item => item.pk)))
    }, [])

    const deleteMetroById = async () => {
        await Promise.all(ids.map(item => deleteOffice(item)))
        deleteMetro(id).then(() => {
            updateComponent('')
            closeModal()
            showAlert('success', 'Метро успешно удалено')
        }).catch((e) => showAlert('error', 'Не удалось удалить метро'))
    }

    return (
        <DeleteMetroConfirmPopupWrapper>
            <ErrorHandler active={active} hidden={hidden} text={text} />
            <Flex padding="10px 0" border="1px solid #DECEB7">
                <Typography weight="700" size="18px" lineHeight="21px">Удалить метро</Typography>
            </Flex>
            <Flex padding="10px" direction="column">
                <Typography size="18px" lineHeight="21px" align="center">Вместе с метро удалятся все кабинеты, связанные с ним. <br /> Вы уверены?</Typography>
                <Button
                    margin="15px"
                    onClick={deleteMetroById}
                    theme="grey"
                    width="155px"
                    height="40px"
                    radius="21px"
                    weight="600"
                    lineHeight="17px"
                >
                    Да, я уверен(a)
                </Button>
            </Flex>
        </DeleteMetroConfirmPopupWrapper>
    )
}

export default DeleteMetroConfirmPopup
