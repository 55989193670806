import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Slider, { Settings } from 'react-slick'
import { AlaramIcon, BankIcon, BoundingIcon, CardIcon, CarIcon, NextIcon, PeopleIcon, PrevIcon } from '../../../../assets/icons'
import { Button } from '../../../../components/common/Styled/Button'
import { Flex } from '../../../../components/common/Styled/Flex'
import { SliderWrapper, ImgModalWrapper, ModalSliderWrapper } from '../../../../components/common/Styled/SliderWrapper'
import { Typography } from '../../../../components/common/Styled/Typography'
import Modal from '../../../../components/Modal'
import SliderModalComponent from '../../../../components/SliderModal'
import { VoidFuncType } from '../../../../types/common'
import { IOffice } from '../../../../types/interfaces/office'
import { deleteOffice } from '../../../../utils/api/routes'
import { COLORS } from '../../../../utils/constants/colors'
import DeleteCabinetPopup from '../DeleteCabinetPopup'
import { CabinetInfoWrapper, Photo, PhotoWrapper, Status, IconWrapper } from './style'

interface IProps {
    updateComponent: VoidFuncType
    office: IOffice
}

const CabinetInfo = ({ office, updateComponent }: IProps) => {
    const history = useHistory()
    const [showRoadPhoto, setShowRoadPhoto] = useState(false)
    const [showPhoto, setShowPhoto] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const deleteOfficeById = async () => {
        await deleteOffice(office.id).then(() => updateComponent())
    }

    const ModalSlider = ({ arr }: { arr: Array<{ id: number, photo: string }> }) => {
        const sliderSettings: Settings = {
            infinite: true,
            speed: 400,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: <PrevIcon />,
            nextArrow: <NextIcon />,
            dots: true,
            dotsClass: 'dots-modal-preview',
            customPaging: (i: number) => {
                return (
                    <a>
                        <img src={arr[i].photo} alt="Preview" />
                    </a>
                );
            },
        }
        return (
            <ModalSliderWrapper width="900px">
                <Slider {...sliderSettings}>
                    {arr.map(item => (
                        <ImgModalWrapper key={item.id}>
                            <img src={item.photo} alt="Фото кабинета" />
                        </ImgModalWrapper>
                    ))
                    }
                </Slider>
            </ModalSliderWrapper>
        )
    }

    return (
        <CabinetInfoWrapper align="flex-start" padding="25px" direction="column">
            <Typography weight="700" size="22px" lineHeight="26px">{office.name}</Typography>
            <Typography
                fontFamily="Montserrat"
                weight="500"
                size="16px"
                lineHeight="28px"
                margin="5px 0 0 0"
            >
                г. {office.city?.name}, {office.address}
            </Typography>
            <Flex justify="flex-start" width="100%" padding="0 0 22px 0" border="1px solid #DECEB7" margin="25px 0 0 0">
                <Flex>
                    <CardIcon />
                    <Typography margin="0 0 0 20px" weight="500" size="18px" lineHeight="21px">{office.price_category.name}</Typography>
                </Flex>
                <Flex margin="0 0 0 25%">
                    <BankIcon />
                    <Typography margin="0 0 0 20px" weight="500" size="18px" lineHeight="21px">{office.payment_system?.name}</Typography>
                </Flex>
            </Flex>
            <Flex width="100%" align="flex-start" direction="column" border="1px solid #DECEB7" padding="20px 0">
                <Typography weight="700" size="22px" lineHeight="26px">О кабинете</Typography>
                <IconWrapper margin="20px 0 25px 0" direction="column" align="flex-start" width="100%">
                    <Flex justify="flex-start" width="100%">
                        <Flex justify="flex-start" width="200px">
                            <PeopleIcon />
                            <Typography weight="500" size="18px" lineHeight="21px">{office.max_peoples} чел.</Typography>
                        </Flex>
                        <Flex>
                            <CarIcon />
                            <Typography weight="500" size="18px" lineHeight="21px">{office.parking} парковка</Typography>
                        </Flex>
                    </Flex>
                    <Flex justify="flex-start" width="100%" margin="25px 0 0 0">
                        <Flex justify="flex-start" width="200px">
                            <BoundingIcon />
                            <Typography weight="500" size="18px" lineHeight="21px">{office.metric_area} м<sup>2</sup></Typography>
                        </Flex>
                        <Flex>
                            <AlaramIcon />
                            <Typography weight="500" size="18px" lineHeight="21px">Мин {office.occupation_step === 1 ? '1 час' : '30 минут'}</Typography>
                        </Flex>
                    </Flex>
                </IconWrapper>
                <Typography whiteSpace="pre-line" fontFamily="Montserrat" size="16px" lineHeight="20px">{office.description}</Typography>
            </Flex>
            <Flex width="100%" align="flex-start" padding="20px 0" border="1px solid #DECEB7" direction="column">
                <Typography weight="700" size="22px" lineHeight="26px">Фото кабинета</Typography>
                <PhotoWrapper justify="flex-start" margin="10px 0 0 0" gap="5px">
                    {office.photos.map(item => <Photo onClick={() => setShowPhoto(true)} key={item.id}><img src={item.photo} alt="Фото кабинета" /></Photo>)}
                </PhotoWrapper>
            </Flex>
            <Flex width="100%" align="flex-start" padding="20px 0 0 0" direction="column">
                <Typography weight="700" size="22px" lineHeight="26px">Как добраться</Typography>
                <PhotoWrapper justify="flex-start" margin="10px 0 0 0" gap="5px">
                    {office.photos_get.map(item => <Photo onClick={() => setShowRoadPhoto(true)} key={item.id}><img src={item.photo} alt="Фото кабинета" /></Photo>)}
                </PhotoWrapper>
            </Flex>
            <Flex width="100%" margin="40px 0 0 0" justify="space-between">
                <Button
                    theme="darkRed"
                    width="155px"
                    height="40px"
                    weight="600"
                    lineHeight="17px"
                    radius="21px"
                    onClick={() => setShowModal(true)}
                >
                    Удалить
                </Button>
                <Button
                    theme="grey"
                    width="155px"
                    height="40px"
                    weight="600"
                    lineHeight="17px"
                    radius="21px"
                    onClick={() => history.push(`/offices/change/${office.id}`)}
                >
                    Редактировать
                </Button>
            </Flex>
            <Status status={office.status}>
                <Typography color={COLORS.steel} weight="600" size="13px" lineHeight="16px">{office.status ? 'Доступен' : 'Не доступен'}</Typography>
            </Status>
            {(showPhoto || showRoadPhoto) &&
                <Modal
                    component={() =>
                        <SliderModalComponent
                            alt={showRoadPhoto ? 'Фото пути' : 'Фото кабинета'}
                            arr={(showRoadPhoto ? office.photos_get : office.photos).map(item => ({ id: item.id, photo: item.photo }))}
                        />
                    }
                    closeModal={() => { setShowPhoto(false); setShowRoadPhoto(false) }}
                    sliderModal
                />
            }
            {showModal &&
                <Modal
                    component={DeleteCabinetPopup}
                    parameters={{
                        confirmFunction: deleteOfficeById
                    }}
                    haveCloseBtn
                    closeModal={() => setShowModal(false)}
                />
            }
        </CabinetInfoWrapper>
    )
}

export default CabinetInfo