import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'

export const CloseModalPopupWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 250px;
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    padding: 25px;
    background: ${COLORS.cream};
`