import React from 'react';
import Slider, { Settings } from 'react-slick';
import { PrevIcon, NextIcon } from '../../assets/icons';
import { ModalSliderWrapper, ImgModalWrapper } from '../common/Styled/SliderWrapper';


interface IProps {
    alt: string
    arr: Array<{id: number, photo: string}>
    infinite?: boolean
}

const SliderModalComponent = ({alt, arr, infinite = true}: IProps) => {
    const sliderSettings: Settings = {
        infinite,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <PrevIcon />,
        nextArrow: <NextIcon />,
        dots: true,
        dotsClass: 'dots-modal-preview',
        customPaging: (i: number) => {
            return (
                <a>
                    <img src={arr[i].photo} alt="Preview" />
                </a>
            );
          },
    };
    return (
        <ModalSliderWrapper width="900px">
            <Slider {...sliderSettings}>
                {arr.map(item => (
                    <ImgModalWrapper key={item.id}>
                        <img src={item.photo} alt={alt} />
                    </ImgModalWrapper>
                    ))
                }
            </Slider>
        </ModalSliderWrapper>
    );
};

export default SliderModalComponent;