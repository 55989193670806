import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ArrowBackIcon } from '../../assets/icons';
import { Flex } from '../../components/common/Styled/Flex';
import { Tab } from '../../components/common/Styled/Tab';
import useResolution from '../../hooks/useResolution';
import { RootState } from '../../store/reducers/rootReducer';
import CabinetsCatalog from './CabinetsCatalog';
import CabinetsSchedule from './CabinetsSchedule';
import { ArrowBack, CabinetsWrapper } from './style';

const Cabinets = () => {
    const [active, setActive] = useState(true)
    const { offices, count } = useSelector((state: RootState) => state.offices)
    const [showCalendar, setShowCalendar] = useState(false)
    const { path } = useRouteMatch();
    const { pathname } = useLocation();

    const isMobile = useResolution(1000)
    useEffect(() => {
        !isMobile && setShowCalendar(false)
    }, [isMobile])

    return (
        <CabinetsWrapper justify="flex-start" padding={isMobile ? '10px 15px 0 15px' : '60px 60px 0 60px'} align="flex-start" direction="column">
            {!showCalendar &&
                <Flex width={isMobile ? '100%' : 'auto'} gap="10px" justify="space-between" margin="0 0 30px">
                    <NavLink to={path}><Tab onClick={() => setActive(true)} active={pathname !== '/offices/catalog'} margin="0 10px 0 0" weight="600" size="15px" lineHeight="24px">Расписание</Tab></NavLink>
                    <NavLink to={`${path}/catalog`}><Tab onClick={() => setActive(false)} active={pathname === '/offices/catalog'} weight="600" size="15px" lineHeight="24px">Каталог кабинетов</Tab></NavLink>
                </Flex>
            }
            {showCalendar && 
                <ArrowBack padding="0 0 30px 0" align="flex-start">
                    <ArrowBackIcon onClick={() => setShowCalendar(false)} />
                </ArrowBack>
            }
            <Switch>
                <Route exact path={path}><CabinetsSchedule showCalendar={showCalendar} setShowCalendar={setShowCalendar} isMobile={isMobile} count={count} offices={offices} /></Route>
                <Route exact path={`${path}/catalog`}><CabinetsCatalog isMobile={isMobile} count={count} offices={offices}/></Route>
            </Switch>
            
            {/* {active && <CabinetsCatalog isMobile={isMobile} count={count} offices={offices}  />} */}
        </CabinetsWrapper>
    );
};

export default Cabinets;