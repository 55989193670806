import React from "react";
import {REQUIRED_STRING, validationMessages} from "../../../../utils/formik/validation";
import {Typography} from "../../../../components/common/Styled/Typography";
import {Field, Form, Formik} from "formik";
import FormikInput from "../../../../components/common/FormikElements/Input";
import {Button} from "../../../../components/common/Styled/Button";
import {SubmitButtonWrapper} from "../../style";
import * as Yup from "yup";
import {SetStateType} from "../../../../types/common";
import {recoveryPassword} from "../../../../utils/api/routes";

interface IProps {
    changeStep: SetStateType<'login' | 'recovery' | 'create' | 'agreement'>
    uid: string
    token: string
}

const PasswordRecovery = ({changeStep, token, uid}: IProps) => {
    
    const initialValues = {
        token: token,
        uid: uid,
        new_password: '',
        re_new_password: ''
    }

    const handleSubmit = (values: typeof initialValues) => {
        recoveryPassword(values)
            .then(() => changeStep('login'))
    }


    const schema = Yup.object({
        new_password: REQUIRED_STRING.max(128, validationMessages.max128),
        re_new_password: REQUIRED_STRING.oneOf([Yup.ref('new_password')], 'Пароль не совпадает!').max(128, validationMessages.max128)
    })

    return (
        <>
            <Typography weight={700} size='36px' lineHeight="44px" margin="0 0 60px" fontFamily="Montserrat">Восстановление пароля</Typography>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => handleSubmit(values)}
                validationSchema={schema}
            >
                <Form>
                    <Field name="new_password" type="password" component={FormikInput} label="Новый пароль" theme="underline" labelWidth="145px"/>
                    <Field margin="20px 0 0 0" name="re_new_password" type="password" component={FormikInput} label="Повторите пароль" theme="underline" labelWidth="145px"/>
                    <SubmitButtonWrapper>
                        <Button
                            type="submit"
                            theme="grey"
                            radius="6px"
                            shadow={true}
                            weight={800}
                            margin="80px 0 0 auto"
                            fontSize="16px"
                            width="155px"
                            height="40px"
                        >
                            СОХРАНИТЬ
                        </Button>
                    </SubmitButtonWrapper>
                </Form>
            </Formik>
        </>
    )
}

export default PasswordRecovery;