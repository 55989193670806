import React, { useState, UIEvent } from 'react';
import { SetStateType } from '../types/common';

export interface IReturnPaginationProps {
    scroll: (e: UIEvent<HTMLDivElement>) => void
    fetching: boolean
    setFetching: SetStateType<boolean>
}

export const usePagination = (
    full: boolean
) => {
    const [fetching, setFetching] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const finalyCallback = () => setFetching(false)

    const successCallback = () => setCurrentPage(prevPage => prevPage + 1)

    const resetCallback = () => setCurrentPage(1)

    const personalChenging = (page: number) => setCurrentPage(page)

    const scroll = (e: UIEvent<HTMLDivElement>) => {
        const scrollBottom = (Math.abs(e.currentTarget.scrollTop +
            e.currentTarget.offsetHeight - e.currentTarget.scrollHeight)) < 100

        if (scrollBottom && !full) {
            setFetching(true)
        }
    }

    return { fetching, scroll, finalyCallback, successCallback, currentPage, resetCallback, personalChenging }
};
