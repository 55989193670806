import styled from "styled-components";
import {Flex} from "../common/Styled/Flex";

const Container = styled(Flex)`
    flex: 1;
    width: 100%;
    overflow-x: hidden;
    @media (max-width: 1000px) {
        flex: none;
    }
`;

export {Container};