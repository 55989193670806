import Axios from 'axios';
import Cookies from 'js-cookie';
import { showAlert, stackErrors } from '../showAlert';
import { refresh } from './routes';

export const instance = Axios.create({
    baseURL: `${window.location.origin}/api/v1`,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
})

const removeTokens = () => {
    Cookies.remove('refresh')
    Cookies.remove('access')
    localStorage.removeItem('persist:rootStorage')
}

instance.interceptors.request.use((config) => {
    if (Cookies.get('access')) {
        config.headers.Authorization = 'Bearer ' + Cookies.get('access')
    }
    return config
})

instance.interceptors.response.use(
    function (response) {
        return response
    },
    async function (error) {

        // error?.response?.status !== 500 && showAlert('error', stackErrors(error))

        if (error?.response?.status === 429) {
            showAlert('error', 'Очень много запросов на сервер. Пожалуйста, подождите')
            return error.response
        }
        const originalRequest = error.config
        const refreshToken = Cookies.get('refresh') ?? ''

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true


            if (refreshToken) {
                try {
                    const response = await refresh({ refresh: refreshToken })
                    if (response.status === 200) {
                        const { access } = response.data
                        Cookies.set('access', access)
                        instance.defaults.headers.common['Authorization'] = 'Bearer ' + access
                        return instance(originalRequest)

                    } else {
                        removeTokens()
                    }

                } catch (e) {
                    removeTokens()
                }

            } else {
                removeTokens()
            }
        }

        return Promise.reject(error.response)
    },
)

