import moment from 'moment'
import React from 'react'
import { Typography } from '../../../../components/common/Styled/Typography'
import { IWithdrawal } from '../../../../types/interfaces/withdrawal'
import { COLORS } from '../../../../utils/constants/colors'
import { fixString } from '../../utils'
import { PurchaseHistoryItemWrapper, InfoWrapper } from './style'

function PurchaseHistoryItem({data}: {data: IWithdrawal}) {

    return (
        <PurchaseHistoryItemWrapper>
            <Typography fontFamily="Montserrat" weight="500" size="12px" lineHeight="24px" color={COLORS.steel}>{moment(data.created).format('DD.MM.YYYY HH:mm')}</Typography>
            <InfoWrapper margin="1px 0 0 0" width="100%" justify="space-between">
                <Typography weight="600" size="16px" lineHeight="18px">
                    {data.balance.subscription ? `Абонемент ${data.balance.subscription.name}` : data.balance.price_category.is_group ? `${data.balance.price_category.name} (групповая)` : data.balance.price_category.name }
                </Typography>
                <Typography weight="600" size="16px" lineHeight="18px">-{fixString(data.count)}</Typography>
            </InfoWrapper>
            <Typography margin="4px 0 0 0" lineHeight="18px">{data.office.name}, {data.office.city.name} {data.office.address}</Typography>
        </PurchaseHistoryItemWrapper>
    )
}

export default PurchaseHistoryItem
