import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { COLORS } from '../../../../utils/constants/colors'

const CabinetInfoWrapper = styled(Flex)`
    position: relative;
    width: 100%;
    background: ${COLORS.cream};
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 12px;
`

const PhotoWrapper = styled(Flex)`
    max-width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 8px;
    }
`

const Photo = styled.div`
    min-width: 120px;
    max-width: 120px;
    height: 80px;
    border-radius: 5px;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 5px;
    }
`

const Status = styled(Flex)<{status: boolean}>`
    position: absolute;
    top: 25px;
    right: 25px;
    background: ${({status}) => status ? COLORS.green : COLORS.red};
    border-radius: 9px;
    width: 118px;
    height: 30px;
`

const IconWrapper = styled(Flex)`
    path {
        fill: ${COLORS.lightBrown};
    }
    svg {
        margin: 0 20px 0 0;
        width: 23px;
        height: 23px;
    }
`

export {CabinetInfoWrapper, PhotoWrapper, Photo, Status, IconWrapper}