import styled from 'styled-components'
import { FilterWrapper } from '../../../components/common/Styled/Filters'
import { Flex } from '../../../components/common/Styled/Flex'

const CabinetsWrapper = styled(Flex)`
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
    justify-content: flex-start;
    input {
        width: 210px;
        margin-right: 10px;
    }
    ${FilterWrapper} {
        margin: 0 0 0 10px!important;
        width: 210px;
        input {
            min-width: 115px;
        }
    }
`

const OfficesWrapper = styled(Flex) <{ height: string }>`
    max-height: ${({ height }) => height};
    overflow-y: auto;

    & > div:not(:last-child) {
        margin-bottom: 4px;
    }
`

export { CabinetsWrapper, OfficesWrapper }