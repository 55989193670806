import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { COLORS } from '../../../../utils/constants/colors'

export const CabinetCardWrapper = styled(Flex) <{ status: boolean, active: boolean }>`
    position: relative;
    width: 100%;
    height: fit-content;
    background: ${({ active }) => active ? COLORS.cream : COLORS.logout};
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    cursor: pointer;
    transition: background .2s linear;
    svg path{
        fill: #A99A85;
    }
    &::before {
        content: '';
        position: absolute;
        top: 5px;
        right: 5px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: ${({ status }) => status ? COLORS.green : COLORS.red};
    }

    &:hover {
        background: ${COLORS.cream};
    }
`
