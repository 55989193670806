import moment from 'moment';
import React, { useState } from 'react';
import { MapIcon } from '../../../../assets/icons';
import { Button } from '../../../../components/common/Styled/Button';
import { Flex } from '../../../../components/common/Styled/Flex';
import { Typography } from '../../../../components/common/Styled/Typography';
import Modal from '../../../../components/Modal';
import { IBooking } from '../../../../types/interfaces/booking';
import { COLORS } from '../../../../utils/constants/colors';
import ReportPopup from '../ReportPopup';
import { Row } from '../style';
import { Address } from './style';

const PastItem = ({item} : {item: IBooking}) => {
    const { after_hours, before_hours, date, office } = item
    const [modalShow, setModalShow] = useState(false)
    return (
        <>
            <Row>
                <Flex justify="space-between">
                    <Flex width="70%" align="flex-start" direction="column">
                        <Typography
                            fontFamily="Montserrat"
                            weight="600"
                            size="12px"
                            lineHeight="24px"
                            color={COLORS.steel}
                        >
                            {moment(date).format('DD MMMM')}, {before_hours.slice(0, 5)} – {after_hours.slice(0, 5)}
                        </Typography>

                        <Flex gap="10px" width="100%">
                            <Typography
                                // width="60px"
                                weight="700"
                                size="20px"
                                lineHeight="18px"
                                whiteSpace="nowrap"
                                margin="0 14px 0 0"
                            >
                                {office?.name}
                            </Typography>

                            <Address
                                fontFamily="Montserrat"
                                weight="600"
                                size="12px"
                                lineHeight="24px"
                                color={COLORS.steel}
                            >
                                <MapIcon />
                                &nbsp;
                                {office?.address}
                            </Address>
                        </Flex>
                    </Flex>
                    <Button
                        theme="red"
                        radius="5px"
                        weight="500"
                        fontSize="13px"
                        lineHeight="16px"
                        width="118px"
                        height="26px"
                        padding="5px"
                        onClick={() => setModalShow(true)}
                    >
                        Пожаловаться
                    </Button>
                </Flex>
            </Row>
            {modalShow && 
                <Modal 
                    closeModal={() => setModalShow(false)}
                    haveCloseBtn
                    component={ReportPopup}
                    parameters={{
                        groupBooking: item.id,
                        office: office.id
                    }}
                />
            }
        </>
    );
};

export default PastItem;