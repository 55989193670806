import styled from 'styled-components';
import { Button } from '../../../components/common/Styled/Button';
import { COLORS } from '../../../utils/constants/colors';

export const Row = styled.div`
    width: 100%;
    border-bottom: 1px solid ${COLORS.brown};
    padding: 10px 20px;

    @media (max-width: 355px) {
        ${Button} {
            width: 100px;
            font-size: 10px;
        }
    }
`