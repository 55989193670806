import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors';
import { DateDropdown } from '../../FormElements/DateFilter/style';
import { Flex } from '../../Styled/Flex'
import { Typography } from '../../Styled/Typography';

const Label = styled.label<{width?: string}>`
    width: ${({width}) => width || 'auto'};
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 7px;   
`;

const InputDateFilter = styled(Flex)`
    position: relative;

    input {
        background: ${COLORS.white};
    }

    ${Typography} {
        cursor: pointer;
    }

    ${DateDropdown} {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 340px;

        .react-datepicker__day--disabled {
            color: ${COLORS.steel};
        }

        .react-datepicker__day--today.react-datepicker__day--disabled {
            color: ${COLORS.steel}!important;
        }
    }
`
export { Label, InputDateFilter}