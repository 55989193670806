import { Formik, Field } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '../../../../components/common/FormElements/Checkbox';
import FormikInput from '../../../../components/common/FormikElements/Input';
import FormikTextArea from '../../../../components/common/FormikElements/TextArea';
import { Button } from '../../../../components/common/Styled/Button';
import { Flex } from '../../../../components/common/Styled/Flex';
import { Typography } from '../../../../components/common/Styled/Typography';
import { RootState } from '../../../../store/reducers/rootReducer';
import { VoidFuncType } from '../../../../types/common';
import { createReport } from '../../../../utils/api/routes';
import { Container, StyledForm, Title } from './style';
import * as Yup from 'yup'
import { REQUIRED_STRING, validationMessages } from '../../../../utils/formik/validation';
import { COLORS } from '../../../../utils/constants/colors';

interface IProps {
    groupBooking: number,
    office: number,
    closeModal: VoidFuncType
}

const ReportPopup = ({groupBooking, office, closeModal}: IProps) => {
    const {id} = useSelector((state: RootState) => state.user.profile)
    const [reportParams, setReportParams] = useState<{[key: string]: boolean}>({
        trash: false,
        notConsumables: false,
        delay: false
    })
    const [error, setError] = useState(false)
    const [showMore, setShowMore] = useState(false)

    const initial = {
        other: '',
        comment: ''
    }

    const schema = Yup.object({
        comment: Yup.string().max(1500, validationMessages.max1500),
    })

    const sendReport = (values : typeof initial) => {
        if(!(reportParams.trash || reportParams.notConsumables || reportParams.delay || showMore)) {
            setError(true)
            return
        } 
        createReport({
            garbage: reportParams.trash,
            consumables: reportParams.notConsumables,
            vacated_late: reportParams.delay,
            office,
            groupbooking: groupBooking,
            psychologist: id,
            ...values
        }).then(() => closeModal())
        
    }

    const reasons = [
        {id: 1, text: 'Мусор', name: 'trash'},
        {id: 2, text: 'Отсутствие расходников', name: 'notConsumables'},
        {id: 3, text: 'Поздно освободили кабинет', name: 'delay'}
    ] as const

    const select = (key: string) => {
        setReportParams({...reportParams, [key]: !reportParams[key]})
        setError(false)
    }

    return (
        <Container>
            <Title>
                <Typography
                    weight="700"
                    size="18px"
                    lineHeight="24px"
                >
                    Отправить жалобу
                </Typography>
            </Title>
            <Formik
                onSubmit={(values) => sendReport(values)}
                initialValues={initial}
                validationSchema={schema}
            >
                <StyledForm>
                    {reasons.map(item => 
                        (
                            <Flex justify="flex-start" key={item.id} onClick={() => select(item.name)}>
                                <Checkbox className="checkbox" value={reportParams[item.name]} onChange={() => {}}/>
                                <Typography weight="500" lineHeight="24px">{item.text}</Typography>
                            </Flex>
                        )
                    )}
                    <Flex justify="flex-start" onClick={() => setShowMore(!showMore)}>
                        <Checkbox className="checkbox" value={showMore} onChange={() => {setShowMore(!showMore); setError(false)}}/>
                        <Typography weight="500" lineHeight="24px">Другое</Typography>
                    </Flex>
                    {showMore && 
                        <Flex margin="0 0 0 25px">
                            <Field placeholder="Подробнее..." name="other" component={FormikInput}/>
                        </Flex>
                    }
                    <Flex width="100%" justify="flex-start" margin="20px 0 0 0">
                        <Field 
                            placeholder="Комментарий"
                            name="comment"
                            height="95px"
                            component={FormikTextArea}
                        />
                    </Flex>
                    <Typography align="center" color={COLORS.red} weight="600">{error && 'Ни одно поле не заполнено'}</Typography>
                    <Flex justify="flex-end" margin="20px 0 0 0">
                        <Button
                            type="submit"
                            theme="grey"
                            weight="600"
                            lineHeight="17px"
                            width="155px"
                            height="40px"
                            radius="21px"
                        >
                            Отправить
                        </Button>
                    </Flex>
                </StyledForm>
            </Formik>
        </Container>
    );
};

export default ReportPopup;