import styled from "styled-components";
import {COLORS} from "../../utils/constants/colors";
import {Flex} from "../../components/common/Styled/Flex";

const Container = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
    display: flex;
    flex: 1;
    background: ${COLORS.cream};
    & > div {
        flex: 1;
    };
    
    img {
        width: 100%;
        height: 100%;
        max-height: 100vh;
        object-fit: cover;

        @media (max-width: 625px) {
            height: 240px;
        }
    };

    @media (max-width: 625px) {
        & > div {
            flex: 0;
        };
        flex-direction: column;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }
`;

const LoginContainer = styled(Flex)`
    @media (max-width: 625px) {
        justify-content: flex-start;
        padding: 70px 20px 20px 20px;
        border-radius: 15px 15px 0px 0px!important;
    }
`

const ErrorMessage = styled.div`
    background: #F7F1E5;
    padding: 23px 30px;
    margin-bottom: 45px;
    max-width: 420px;
`;

const FormWrapper = styled.div`
    max-width: 420px;
    margin: 0 auto;
    ${Flex} {
        &:first-of-type {
            margin-bottom: 30px;
        };
    };
`;

const SubmitButtonWrapper = styled.div`
    display: flex;
    width: 100%;
`;

export {Container, ErrorMessage, FormWrapper, SubmitButtonWrapper, LoginContainer};