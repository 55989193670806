import { Form } from 'formik'
import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { COLORS } from '../../../../utils/constants/colors'

const Container = styled.div`
    box-shadow: 0 0 4px #E1D7C9;
    border-radius: 6px;
    background: ${COLORS.cream};
    width: 350px;
    
    @media (max-width: 500px) {
        width: 100%;
    }

    .checkbox {
        margin-right: 10px;
    }

`

const Title = styled(Flex)`
    padding: 10px 20px;
    border-bottom: 1px solid ${COLORS.brown};
`

const StyledForm = styled(Form)`
    padding: 20px 20px 10px 20px;
`

export {Container, Title, StyledForm}