import React, { useEffect, useMemo, useRef, useState } from "react";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { usePagination } from "../../../../hooks/usePagination";
import { SetStateType, VoidFuncType } from "../../../../types/common";
import { DebouncedInput } from "../DebouncedInput/DebouncedInput";
import { getUsers } from "../../../../utils/api/routes";
import { RootState } from "../../../../store/reducers/rootReducer";
import { useSelector } from "react-redux";
import { LoaderIcon } from "../../../../assets/icons";
import {
  OptionsWrapper,
  SelectWrapper,
  Option,
  Container,
  OptionsSelectWrapper
} from "../Select/style";

import Checkbox from '../Checkbox'

type ValueType = string | number | boolean
export type OptionType = { id: number, name: string, active: boolean }

interface ISelectProps {
  options: Array<{ value: ValueType, name: string }> | ValueType[]
  value: ValueType
  onChange: (value: ValueType) => void,
  setOptionsMany: React.Dispatch<React.SetStateAction<OptionType[]>>
  defaultValue?: string
  totalCount?: number
  currentLength?: number
  request?: (pageSize: number, finalyCallback: VoidFuncType, setAllActive?: (arr: OptionType[], setArray: Function) => void, isGetAll?: boolean) => void
  many?: boolean
  optionsMany?: OptionType[]
  onChangeAll?: (id: number, isActive?: boolean) => void
  manySelected?: string
  selectAll?: boolean
  all?: boolean
  setAll?: SetStateType<boolean>
  isLoading?: boolean
  getAllUsers?: Function
}

const SelectWithSearch = ({
  value,
  options,
  onChange,
  defaultValue,
  totalCount,
  currentLength,
  request,
  many,
  optionsMany,
  onChangeAll,
  manySelected,
  selectAll,
  setOptionsMany,
  isLoading,
  getAllUsers,
}: ISelectProps) => {

  const ref = useRef<HTMLDivElement>(null);
  const refOptions = useRef<HTMLDivElement>(null);
  const { scroll, fetching, finalyCallback } = usePagination(totalCount === currentLength)
  useClickOutside(ref, () => toggleOptions(false))

  const { profile } = useSelector((state: RootState) => state.user)

  const [isShow, toggleOptions] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchedUsers, setSearchedUsers] = useState<{ id: number, active: boolean, name: string }[]>([])

  const getOption = (option: ValueType | { value: ValueType, name: string }, prop: 'value' | 'name'): ValueType =>
    typeof (option as { value: ValueType, name: string }).value ? (option as { value: ValueType, name: string })[prop] : option as ValueType

  const isAllActive = useMemo(() => optionsMany?.every(option => option.active), [optionsMany])

  const searchHandler = (str: string) => {
    setSearchValue(str)
    getUsers(`search=${str}&page=1&page_size=15&is_psychologist=true&is_superuser=false${!profile.is_superuser && !profile.notifications_all ? `&staff=${profile.id}` : ''}`)
      .then(res => setSearchedUsers(res.data.results.map(user => (
        {
          id: user.id,
          name: `${user.first_name} ${user.middle_name} ${user.last_name}`,
          active: optionsMany?.find(option => option.id === user.id)?.active || false
        }))))
  }

  const currentValue = useMemo(() => {
    if (!value || options.length === 0) return ''
    else {
      return (options[0] as { value: ValueType, name: string }).value
        ? (options as { value: ValueType, name: string }[]).find(item => item.value === +value)?.name
        : (options as ValueType[]).find(item => item === value)
    }
  }, [value, options.length])

  const toTop = useMemo(() => {
    if (ref.current) {
      const bottomPoint = ref.current.getBoundingClientRect().bottom
      if (window.innerHeight - bottomPoint <= 200) return true
    }
    return false
  }, [ref, isShow])

  const checkAll = () => {
    if (currentLength && totalCount && currentLength < totalCount && !isAllActive) {
      getAllUsers?.(totalCount, finalyCallback,
        (arr: Array<{ id: number, active: boolean, name: string }>, setArray: Function) => {
          setArray(arr.map(item => ({ ...item, active: true })))
        }
      )
    }
    if (!isAllActive)
      setOptionsMany(state => state.map(option => ({ ...option, active: true })))
    if (isAllActive)
      setOptionsMany(state => state.map(option => ({ ...option, active: false })))
  }

  useEffect(() => {
    if (fetching && totalCount && currentLength && totalCount > currentLength)
      request && request(15, finalyCallback)
  }, [fetching])

  return (
    <Container ref={ref}>
      <SelectWrapper isShow={isShow} onClick={() => toggleOptions(!isShow)}>
        {(many && manySelected) ?
          <span>{manySelected}</span>
          :
          <span>{currentValue || defaultValue || ''}</span>
        }
        <DebouncedInput
          searchHandler={searchHandler}
          placeholder={'Поиск'}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            toggleOptions(true)
          }}
        />
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.24701 7.14L0.451011 1.658C-0.114989 1.013 0.345011 3.67706e-07 1.20401 3.67706e-07H10.796C10.9883 -0.000164459 11.1765 0.0550878 11.3381 0.159141C11.4998 0.263194 11.628 0.411637 11.7075 0.586693C11.7869 0.761749 11.8142 0.955998 11.7861 1.14618C11.758 1.33636 11.6757 1.51441 11.549 1.659L6.75301 7.139C6.65915 7.24641 6.5434 7.3325 6.41352 7.39148C6.28364 7.45046 6.14265 7.48098 6.00001 7.48098C5.85737 7.48098 5.71638 7.45046 5.5865 7.39148C5.45663 7.3325 5.34087 7.24641 5.24701 7.139V7.14Z" fill="#DECEB7" />
        </svg>
      </SelectWrapper>
      {isShow && (
        <OptionsWrapper
          style={{ transform: `${toTop ? 'translateY(-100%)' : 'translateY(calc(100% + 15px))'}` }}
          ref={refOptions} toTop={toTop}
          onScroll={totalCount ? scroll : () => { }}
          isShow={isShow}
        >
          {
            isLoading
              ? <LoaderIcon />
              :
              (many && optionsMany && onChangeAll)
                ?
                <>
                  {selectAll && !searchValue &&
                    < OptionsSelectWrapper
                      padding="5px"
                      gap="5px"
                      onClick={checkAll}
                    >
                      <Checkbox
                        onChange={() => { }}
                        value={isAllActive}
                      />
                      <Option>
                        Все
                      </Option>
                    </OptionsSelectWrapper>
                  }
                  {
                    ((searchValue) ? searchedUsers : optionsMany).map((option, index) => (
                      <OptionsSelectWrapper
                        padding="5px"
                        gap="5px"
                        key={option.id}
                        onClick={() => {
                          onChangeAll(option.id)
                          searchedUsers.length && setSearchedUsers(state => state.map(user => {
                            if (user.id === option.id)
                              return { ...user, active: !user.active }
                            return user
                          }))
                        }}
                      >
                        <Checkbox
                          onChange={() => onChangeAll(option.id)}
                          value={option.active}
                        />
                        <Option key={index} >
                          {option.name}
                        </Option>
                      </OptionsSelectWrapper>
                    ))
                  }
                </>
                :
                options.map((option, index) => (
                  <Option key={index} onClick={() => {
                    onChange(getOption(option, 'value'));
                    toggleOptions(false);
                  }}>
                    {getOption(option, 'name')}
                  </Option>
                ))
          }
        </OptionsWrapper>
      )
      }
    </Container >
  )
}

export default SelectWithSearch;