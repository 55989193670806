import React from 'react'
import { PlusIcon } from '../../../../assets/icons'
import { ChangeType } from '../../../../types/common'
import { FileInputWrapper, StyledInput } from './style'

interface IProps {
    changeFunction: (e: ChangeType) => void,
    fieldId: string
    inputRef?: React.MutableRefObject<HTMLInputElement | null>
}

const FileInput = ({ changeFunction, fieldId, inputRef }: IProps) => (
    <FileInputWrapper align="flex-start" direction="column">
        <StyledInput htmlFor={fieldId}><PlusIcon /></StyledInput>
        <input
            onClick={e => (e.target as HTMLInputElement).value = ''}
            onChange={(e) => changeFunction(e)}
            id={fieldId}
            ref={inputRef}
            accept="image/png,image/jpg,image/jpeg,image/gif,image/webp,image/avif,image/apng,image/jpe"
            type="file" />
    </FileInputWrapper>
)


export default FileInput