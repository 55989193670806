import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import Input from '../../../components/common/FormElements/Input'
import { Flex } from '../../../components/common/Styled/Flex'
import { Ceil, CeilHeader, Table } from '../../../components/common/Styled/Table'
import { Typography } from '../../../components/common/Styled/Typography'
import { ChangeType } from '../../../types/common'
import loupeIcon from '../../../assets/icons/admin/loupeIcon.svg'
import { IHistory } from '../../../types/interfaces/history'
import { getAllHistory } from '../../../utils/api/routes'
import moment from 'moment'
import paginationHoc, { PaginationComponentProps } from '../../../hocs/Pagination.hoc'
import { DateSelectWrapper } from './style'
import DateFilter from '../../../components/common/FormElements/DateFilter'
import { debounce } from '../../../utils/debounce'

function History({ currentPage, setCurrentPage, paginationComponet }: PaginationComponentProps) {
    const [search, setSearch] = useState('')
    const [history, setHistory] = useState<Array<IHistory> | null>(null)
    const [totalCount, setTotalCount] = useState(0)
    const [dateInputValue, setDateInputValue] = useState('')
    const [showDateSelect, setShowDateSelect] = useState(false)
    const [dates, setDates] = useState<{ startDate: Date | null, endDate?: Date | null }>({ startDate: null, endDate: null })
    const [filterString, setFilterString] = useState('')
    const [pageSize, setPageSize] = useState(0)
    const ref = useRef<HTMLDivElement>(null)

    const tableHeader = [
        { id: 1, text: 'Дата и время', borderRadius: 'first' as 'first' },
        { id: 2, text: 'Автор изменения' },
        { id: 3, text: 'Психолог, которому изменили часы' },
        { id: 4, text: 'Операция и кол-во часов', borderRadius: 'last' as 'last' },
    ]

    const debouncedSearch = useRef(debounce((
        search: string,
        currentPage: number,
        filterString: string,
        pageSize: number
    ) => {
        getAllHistory(`?page=${currentPage}&page_size=${pageSize}&search=${search}${filterString}`)
            .then(res => {
                setHistory(res.data.results)
                setTotalCount(res.data.count)
            })
    }, 400))

    useLayoutEffect(() => {
        if (ref.current) {
            setPageSize(Math.floor((window.innerHeight - ref.current.getBoundingClientRect().top - 100) / 40))
        }
    }, [ref, history?.length])

    useEffect(() => {
        let queryString = ''
        if (dates.startDate) {
            queryString += `&created_before=${moment(dates.endDate).format('YYYY-MM-DD')} 00:00:00`
        }
        if (dates.endDate) {
            queryString += `&created_after=${moment(dates.startDate).format('YYYY-MM-DD')} 00:00:00`
        }
        if ((dates.endDate && dates.startDate) || (dates.endDate === null && dates.startDate === null)) {
            setCurrentPage(1)
            setFilterString(queryString)
        }
    }, [dates])

    useEffect(() => {
        debouncedSearch.current(search, currentPage, filterString, pageSize)
    }, [currentPage, filterString, pageSize])

    useEffect(() => {
        setCurrentPage(1)
        debouncedSearch.current(search, currentPage, filterString, pageSize)
    }, [search])

    return (
        <Flex padding="100px 60px 0 60px" overflow="hidden" align="flex-start" direction="column" height='100%' justify='flex-start'>
            <Flex width="35%">
                <Input
                    value={search}
                    onChange={(e: ChangeType) => setSearch(e.target.value)}
                    placeholder="ФИО / Email / Телефон"
                    icon={loupeIcon}
                />
            </Flex>
            <Flex width="20%" margin="15px 0 0 0">
                <DateSelectWrapper>
                    <Input
                        value={dateInputValue}
                        onChange={() => { }}
                        onFocus={() => setShowDateSelect(true)}
                        placeholder="Период бронирования"
                    />
                    {showDateSelect &&
                        <DateFilter
                            setShow={setShowDateSelect}
                            dates={dates}
                            setDates={setDates}
                            select
                            showInputs
                            setTitle={setDateInputValue}
                        />
                    }
                </DateSelectWrapper>
            </Flex>
            {history?.length
                ? (
                    <Table ref={ref} w="70%" columns="repeat(4, calc(100% / 4))" margin="25px 0 0 0">
                        {tableHeader.map(item =>
                            <CeilHeader key={item.id} title={item.text} borderRadius={item.borderRadius && item.borderRadius}>
                                <Typography weight="700" lineHeight="18px">{item.text}</Typography>
                            </CeilHeader>
                        )}
                        {history.map((item, index) =>
                            <React.Fragment key={item.id}>
                                <Ceil borderRadius="first" isLastRow={index === history.length - 1}>
                                    <Typography lineHeight="18px">{moment(item.created).format('DD.MM.YYYY HH:mm:ss')}</Typography>
                                </Ceil>
                                <Ceil>
                                    <Typography lineHeight="18px">{item.author.last_name} {item.author.first_name} {item.author.middle_name}</Typography>
                                </Ceil>
                                <Ceil>
                                    <Typography lineHeight="18px">{item.psychologist.last_name} {item.psychologist.first_name} {item.psychologist.middle_name}</Typography>
                                </Ceil>
                                <Ceil borderRadius="last" isLastRow={index === history.length - 1}>
                                    <Typography lineHeight="18px">{item.amount > 0 ? `+${item.amount}` : item.amount}</Typography>
                                </Ceil>
                            </React.Fragment>

                        )}
                    </Table>
                )
                : ''
            }
            {
                !history?.length && history !== null &&
                (
                    <Flex direction='column' margin='250px 0 0 0' justify='center' width='100%'>
                        <Typography lineHeight="18px" weight='500' size='28'>По вашему запросу ничего не найдено...</Typography>
                    </Flex>
                )
            }
            <Flex width="100%" margin="25px 0 0 0">
                {paginationComponet(totalCount, pageSize)}
            </Flex>
        </Flex>
    )
}

export default paginationHoc(History)
