import moment from "moment"
import { IBooking } from "../types/interfaces/booking"

export const isActiveBookingStarted = (booking: IBooking) => {
  const bookingDate = moment(booking?.date).format('YYYY-MM-DD')
  const startTime = moment(bookingDate + ' ' + booking!.before_hours, 'YYYY-MM-DD HH:mm:ss')
  const endTime = moment(bookingDate + ' ' + booking!.after_hours, 'YYYY-MM-DD HH:mm:ss')
  const nowTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'))
  if (booking && nowTime.isAfter(startTime) && nowTime.isBefore(endTime)) {
    return true
  }
  return false
}