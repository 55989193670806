import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex } from '../../../components/common/Styled/Flex';
import { Grid } from '../../../components/common/Styled/Grid';
import { Typography } from '../../../components/common/Styled/Typography';
import paginationHoc, { PaginationComponentProps } from '../../../hocs/Pagination.hoc';
import { RootState } from '../../../store/reducers/rootReducer';
import { IBooking } from '../../../types/interfaces/booking';
import { getBookings } from '../../../utils/api/routes';
import { Message } from '../BookingSchedule/style';
import { Container } from '../style';
import CanceledItem from './CanceledItem';

const BookingCanceled = ({ currentPage, paginationComponet, isMobile }: PaginationComponentProps) => {
    const { id } = useSelector((state: RootState) => state.user.profile)
    const [bookings, setBookings] = useState<Array<IBooking>>([])
    const [pageSize, setPageSize] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (ref.current) {
            const wrapperTop = ref.current.getBoundingClientRect().top
            const pageSize = Math.floor(((window.innerHeight - (wrapperTop + 50)) / 79) * (isMobile ? 1 : 2) - (isMobile ? 1 : 0))
            setPageSize(pageSize % 2 === 0 || isMobile ? pageSize : pageSize - 1)

        }
    }, [isMobile, ref, totalCount])

    useEffect(() => {
        const queryString = `&page=${currentPage}&user=${id}&canceled=True`
        getBookings(queryString, pageSize)
            .then(res => {
                res.data.count !== totalCount && setTotalCount(res.data.count)
                setBookings(res.data.results)
            })
    }, [currentPage, pageSize])

    return (
        <Flex justify="flex-start" height="100%" width="100%" direction="column">
            <Grid h="100%" ref={ref} w="100%" columns={isMobile || !bookings.length ? '100%' : '45% 45%'} gap="5%">
                {
                    bookings.length
                        ?
                        <>
                            <Container width="100%">
                                {!isMobile && pageSize > 1 && bookings.slice(0, pageSize / (isMobile ? 1 : 2)).map(item => <CanceledItem key={item.id} item={item} />)}
                                {isMobile && bookings.map(item => <CanceledItem key={item.id} item={item} />)}
                            </Container>
                            {bookings.length > pageSize / (isMobile ? 1 : 2) && !isMobile &&
                                <Container width="100%">
                                    {bookings.slice(pageSize / (isMobile ? 1 : 2)).map(item =>
                                        <CanceledItem key={item.id} item={item} />
                                    )}
                                </Container>
                            }
                        </>
                        :
                        <Message align="center" width="100%">
                            <Typography weight="700" size="18px" align="center">Здесь будут видны Ваши отмененные бронирования</Typography>
                        </Message>
                }
            </Grid>
            <Flex margin="25px" width="100%" justify="flex-end">
                {() => paginationComponet(totalCount, pageSize)}
            </Flex>
        </Flex>
    );
};

export default paginationHoc(BookingCanceled);