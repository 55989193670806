import React, { useEffect, useRef, useState } from 'react';
import { ITotalProps } from '..';
import { useAdaptive } from '../../../hooks/useAdaptive';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { ChangeType } from '../../../types/common';
import { COLORS } from '../../../utils/constants/colors';
import Input from '../../common/FormElements/Input';
import { Button } from '../../common/Styled/Button';
import { DropDown } from '../../common/Styled/DropDown';
import { Flex } from '../../common/Styled/Flex';
import { Typography } from '../../common/Styled/Typography';
import { NumberPeopleWrapper } from './style';

const NumberPepole = ({setTotalState}: ITotalProps) => {
    const [value, setValue] = useState('0')
    const [title, setTitle] = useState('Кол-во человек')
    const [active, setActive] = useState(false)
    const [show, setShow] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if(+value <= 0) return setTotalState(prevState => ({...prevState, numberPepole: ''}))
        setActive(true)
        setTotalState(prevState => ({...prevState, numberPepole: value}))
    }, [value, setActive, setTotalState])

    const verificationStr = '234'

    const changeNumber = (e: ChangeType) => {
        if(Number.isNaN(+e.target.value)) return
        setValue(e.target.value.trim())
    }

    const dicrement = () => {
        if(+value === 0) return
        setValue(prevState => `${+prevState - 1}`)
    }
    const increment = () => {
        if(+value === 50) return
        setValue(prevState => `${+prevState + 1}`)
    }

    const save = () => {
        setShow(false)
        if(+value <= 0) {
            setTitle('Кол-во человек')
            return setActive(false)
        }
        setTitle(value)
    }

    useClickOutside(ref, () => {
        setShow(false)
        if(+value <= 0) {
            setTitle('Кол-во человек')
            setActive(false)
        }
    })
    const resize = useAdaptive(ref)

    useEffect(() => {
        resize()
    }, [resize])

    const titleHandler = (value: string) => {
        return `${value} ${verificationStr.includes(value[value.length - 1]) && (+value < 10 || +value > 20) ? 'человека' : 'человек'}`
    }

    return (
        <NumberPeopleWrapper ref={ref} active={active}>
            <Typography
                onClick={() => setShow(!show)}
                color={active ? COLORS.white : COLORS.black} 
                fontFamily="Montserrat" 
                lineHeight="24px" 
                weight="700" 
                size="12px"
                align="center"
                padding="0 25px"
            >
                {+value > 0 ? titleHandler(value) : title}
            </Typography>
            {show && 
                <DropDown padding="15px 10px">
                    <Flex width="157px" justify="space-between">
                        <Button
                            onClick={dicrement}
                            radius="50%"
                            background={COLORS.lightBrown}
                            color={COLORS.white}
                            width="22px"
                            height="22px"
                            weight="700"
                        >
                            -
                        </Button>
                        <Flex width="60px">
                            <Input value={value} onChange={(e : ChangeType) => +e.target.value <= 50 && changeNumber(e)} />
                        </Flex>
                        <Button
                            onClick={increment}
                            radius="50%"
                            background={COLORS.lightBrown}
                            color={COLORS.white}
                            width="22px"
                            height="22px"
                            weight="700"
                        >
                            +
                        </Button>
                    </Flex>
                    <Flex margin="20px 0 0 0">
                        <Button
                            onClick={save}
                            radius="27px"
                            theme="green"
                            width="119px"
                            height="34px"
                            weight="600"
                            lineHeight="17px"
                        >
                            Сохранить
                        </Button>
                    </Flex>
                </DropDown>
            }
        </NumberPeopleWrapper>
    );
};

export default NumberPepole;