import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import DateFilter from '../../../../components/common/FormElements/DateFilter';
import Input from '../../../../components/common/FormElements/Input';
import Select from '../../../../components/common/FormElements/Select';
import TimeFilter from '../../../../components/common/FormElements/TimeFilter';
import { Flex } from '../../../../components/common/Styled/Flex';
import { Grid } from '../../../../components/common/Styled/Grid';
import { Ceil, CeilHeader, Table } from '../../../../components/common/Styled/Table';
import { Typography } from '../../../../components/common/Styled/Typography';
import paginationHoc, { PaginationComponentProps } from '../../../../hocs/Pagination.hoc';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { RootState } from '../../../../store/reducers/rootReducer';
import { VoidFuncType } from '../../../../types/common';
import { IOfficeStatistics } from '../../../../types/interfaces/statistics';
import { getAllOffices, getSelectParams, getOfficeStatistics, getStatisticsSummary } from '../../../../utils/api/routes';
import { Card } from '../style';
import { CabinetStatisticsWrapper, TimeSelectWrapper } from './style';

type OptionType = { value: number, name: string }
type SelectOptionType = { id: number, active: boolean, name: string }

const CabinetStatistics = ({ currentPage, setCurrentPage, paginationComponet }: PaginationComponentProps) => {
    const { profile } = useSelector((state: RootState) => state.user)
    const [cities, setCities] = useState<Array<OptionType>>([])
    const [metros, setMetros] = useState<Array<SelectOptionType>>([])
    const [offices, setOffices] = useState<Array<SelectOptionType>>([])
    const [cityId, setCityId] = useState<number | null>(null)
    const [activeOffices, setActiveOffices] = useState('')
    const [activeMetros, setActiveMetros] = useState('')
    const [status, setStatus] = useState<number | null>(null)
    const [currentOfficePage, setCurrentOfficePage] = useState(2)
    const [totalOfficeCount, setTotalOfficeCount] = useState(0)
    const [queryString, setQueryString] = useState('')
    const [statistics, setStatistics] = useState<IOfficeStatistics[]>([])
    const [statisticsSummary, setStatisticsSummary] = useState({ hours: 0, count: 0 })
    const [totalStatisticsCount, setTotalStatisticsCount] = useState(0)
    const [dates, setDates] = useState<{ startDate: Date | null, endDate?: Date | null }>({ startDate: null, endDate: null })
    const [showTimeSelect, setShowTimeSelect] = useState(false)
    const [dateInputValue, setDateInputValue] = useState('')
    const [timesQuery, setTimesQuery] = useState('')
    const [pageSize, setPageSize] = useState(0)
    const ref = useRef<HTMLDivElement>(null)
    const timeFilterRef = useRef<HTMLDivElement>(null)
    useClickOutside(timeFilterRef, () => setShowTimeSelect(false))
    const tableHeader = [
        { id: 1, text: 'Кабинет', borderRadius: 'first' as 'first' },
        { id: 2, text: 'Психолог' },
        { id: 3, text: 'Дата брони' },
        { id: 4, text: 'Время начала' },
        { id: 5, text: 'Время конца', borderRadius: 'last' as 'last' },
    ]

    useEffect(() => {
        if (ref.current) {
            setPageSize(Math.floor((window.innerHeight - ref.current.getBoundingClientRect().top - 100) / 40))
        }
    }, [ref])

    const request = (finalyCallback: VoidFuncType) => {
        getAllOffices(currentOfficePage, 15, queryString)
            .then(res => {
                const newData = res.data.results.map(item => ({ id: item.id, name: item.name, active: false }))
                setOffices([...offices, ...newData])
                setCurrentOfficePage(prevState => prevState + 1)
            })
            .finally(() => finalyCallback())
    }

    useEffect(() => {
        let fillterString = `${!profile.is_superuser && !profile.cabinets_all ? `&user=${profile.id}` : ''}`
        const city = cities.find(item => item.value === cityId)
        if (city) {
            fillterString += `&city=${city.name}`
        }

        fillterString += `&metro__in=${activeMetros}`

        setQueryString(fillterString)
    }, [cityId, activeMetros])

    useEffect(() => {
        let queryString = ''
        if (dates.startDate) {
            queryString += `&after_date=${moment(dates.startDate).format('YYYY-MM-DD')}`
        }
        if (dates.endDate) {
            queryString += `&before_date=${moment(dates.endDate).format('YYYY-MM-DD')}`
        }
        if (status && status > 0) {
            queryString += `${status === 1 ? '&visited=true&canceled=false' : '&visited=false&canceled=true'}`
        }
        if ((dates.endDate && dates.startDate) || (dates.endDate === null && dates.startDate === null)) {
            setCurrentPage(1)
            setTimesQuery(queryString)
        }
    }, [dates, status])

    useEffect(() => {
        if (activeOffices) {
            getOfficeStatistics(`?page=${currentPage}&page_size=${pageSize}&office_name=${activeOffices}${timesQuery}`)
                .then(res => {
                    setTotalStatisticsCount(res.data.count)
                    setStatistics(res.data.results)
                })
            getStatisticsSummary(`?office_name=${activeOffices}${timesQuery}`, 'offices_summary')
                .then(res => setStatisticsSummary({ hours: res.data.hours || 0, count: res.data.count }))
            return
        }
        setStatisticsSummary({ hours: 0, count: 0 })
        setStatistics([])
        setTotalStatisticsCount(0)

    }, [activeOffices, currentPage, timesQuery])

    useEffect(() => {
        getAllOffices(1, 15, queryString)
            .then(res => {
                setCurrentOfficePage(2)
                setOffices(res.data.results.map(item => ({ id: item.id, name: item.name, active: false })))
                setTotalOfficeCount(res.data.count)
            })
    }, [queryString])

    useEffect(() => {
        getSelectParams('cities').then(res => setCities([{ value: -1, name: 'Все' }, ...res.data.map(item => ({ value: item.id, name: item.name }))]))
    }, [])

    useEffect(() => {
        if (cityId) {
            getSelectParams('metros', `?city=${cityId}`).then(res => setMetros(res.data.map(item => ({ id: item.id, name: item.name, active: false }))))
            return
        }
        setMetros([])
    }, [cityId])


    useEffect(() => {
        const activeArr = metros.reduce((acum: string[], item) => {
            if (item.active) {
                acum.push(item.name)
            }
            return acum
        }, []).join(',')
        setActiveMetros(activeArr)
    }, [metros])

    useEffect(() => {
        const activeArr = offices.reduce((acum: string[], item) => {
            if (item.active) {
                acum.push(item.name)
            }
            return acum
        }, []).join(',')
        setActiveOffices(activeArr)
    }, [offices])

    const selectMetro = (id: number) => setMetros(metros.map(item => {
        if (item.id === id) {
            return { ...item, active: !item.active }
        }
        return item
    }))
    const selectOffice = (id: number) => setOffices(offices.map(item => {
        if (item.id === id) {
            return { ...item, active: !item.active }
        }
        return item
    }))

    return (
        <CabinetStatisticsWrapper>
            <Grid gap="10px" columns="repeat(4, 1fr) 1.2fr">
                <Select
                    onChange={(value) => setCityId(value as number)}
                    options={cities}
                    value={cityId || ''}
                    defaultValue="Город"
                />
                <Select
                    onChange={() => { }}
                    options={[]}
                    value=""
                    many
                    optionsMany={metros}
                    onChangeAll={(id: number) => selectMetro(id)}
                    manySelected={activeMetros}
                    defaultValue="Метро"
                />
                <Select
                    onChange={() => { }}
                    options={[]}
                    value=""
                    many
                    optionsMany={offices}
                    onChangeAll={(id: number) => selectOffice(id)}
                    manySelected={activeOffices}
                    totalCount={totalOfficeCount}
                    request={request}
                    currentLength={offices.length}
                    defaultValue="Кабинет"
                />
                <Select
                    onChange={(value) => setStatus(value as number)}
                    options={[
                        { value: -1, name: 'Все' },
                        { value: 1, name: 'Завершенные брони' },
                        { value: 2, name: 'Отмененные брони' },
                    ]}
                    value={status || ''}
                    defaultValue="Статус брони"
                />
                <TimeSelectWrapper ref={timeFilterRef}>
                    <Input
                        onChange={() => { }}
                        value={dateInputValue}
                        onFocus={() => setShowTimeSelect(true)}
                        placeholder="Период бронирования"
                    />
                    {showTimeSelect &&
                        <DateFilter
                            setShow={setShowTimeSelect}
                            dates={dates}
                            setDates={setDates}
                            select
                            showInputs
                            setTitle={setDateInputValue}
                        />
                    }
                </TimeSelectWrapper>
            </Grid>
            <Grid margin="25px 0 0 0" columns="70% 25%" gap="5%">
                <Table ref={ref} columns="15% 30% repeat(3, 18%)">
                    {tableHeader.map(item =>
                        <CeilHeader borderRadius={item.borderRadius && item.borderRadius} key={item.id}>
                            <Typography weight="700" lineHeight="18px">{item.text}</Typography>
                        </CeilHeader>
                    )}
                    {pageSize > 0 && statistics.map((item, index) =>
                        <React.Fragment key={item.id}>
                            <Ceil borderRadius="first" isLastRow={index === statistics.length - 1}>
                                <Typography lineHeight="18px">{item.office.name}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{item.user ? item.user.get_full_name : item.unknown_user}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{moment(item.date).format('DD.MM.YYYY')}</Typography>
                            </Ceil>
                            <Ceil>
                                <Typography lineHeight="18px">{item.before_hours.slice(0, 5)}</Typography>
                            </Ceil>
                            <Ceil borderRadius="last" isLastRow={index === statistics.length - 1}>
                                <Typography lineHeight="18px">{item.after_hours.slice(0, 5)}</Typography>
                            </Ceil>
                        </React.Fragment>
                    )}
                </Table>
                <Flex direction="column" width="100%">
                    <Card>
                        <Typography fontFamily="Montserrat" weight="600" size="18px" lineHeight="22px">Итого часов</Typography>
                        <Typography weight="800" size="72px" lineHeight="86px">{statisticsSummary.hours}</Typography>
                    </Card>
                    <Card>
                        <Typography fontFamily="Montserrat" weight="600" size="18px" lineHeight="22px">Выбрано броней</Typography>
                        <Typography weight="800" size="72px" lineHeight="86px">{statisticsSummary.count}</Typography>
                    </Card>
                </Flex>
            </Grid>
            <Flex margin="25px 0 0 0">
                {paginationComponet(totalStatisticsCount, pageSize)}
            </Flex>
        </CabinetStatisticsWrapper>
    );
};

export default paginationHoc(CabinetStatistics);