import styled, { keyframes } from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'
import { Button } from '../../Styled/Button'
import { DropDown } from '../../Styled/DropDown'
import { Flex } from '../../Styled/Flex'

const open = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const DateDropdown = styled(DropDown)<{disabledDays: boolean, padding?: string}>`
    padding: ${p => p.padding || '0'};
    left: 0;
    animation: ${open} .2s linear;
    
    .react-datepicker__day--selected {
        border-radius: 50%;
        background: ${COLORS.brown}!important;
        color: ${COLORS.white}!important;
    }

    .react-datepicker{
        font-family: SF Pro Display;
        border: none;
        width: 100%;
    }

    .react-datepicker__day{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        margin: 0;
        width: 14.28%;
        height: 48px;
        padding: 2px 0;
        transition: color .2s linear;
        color: ${p => p.disabledDays ? COLORS.steel + '!important' : COLORS.black};
        border-radius: 50%;
    }

    .react-datepicker__day--today{
        background: ${COLORS.lightBrown}!important;
        color: ${COLORS.white};
        border-radius: 50%;
    }

    .react-datepicker__month {
        margin: 0;
    }

    .react-datepicker__day--outside-month{
        visibility: hidden;
    }
    
    .react-datepicker__day--keyboard-selected{
        border-radius: 50%;
        color: ${COLORS.white}!important;
        background: ${COLORS.brown};
    }

    .react-datepicker__day--in-range{
        background: ${COLORS.brown};
        color: ${COLORS.black}!important;
        border-radius: 0!important;
    }
    .react-datepicker__month-container {
        width: 100%;
    }
    
    .react-datepicker__day--in-selecting-range{
        background: ${COLORS.brown}!important;
        border-radius: 0!important;
        color: ${COLORS.black}!important;
    }

    .react-datepicker__header, .react-datepicker__navigation {
        display: none;
    }
    
    .react-datepicker__day--in-range {
        background: ${COLORS.brown}!important;
        border-radius: 0!important;
        color: ${COLORS.black}!important;
    }

    .react-datepicker__day--range-end, .react-datepicker__day--selecting-range-end {
        border-radius: 0 50% 50% 0!important;
        color: ${COLORS.white}!important;
    }
    
    .react-datepicker__day--range-start, .react-datepicker__day--selecting-range-start {
        border-radius: 50% 0 0 50%!important;
        color: ${COLORS.white}!important;
    }

    .react-datepicker__week {
        display: flex;
        margin-bottom: 5px;
    }

`

const WeekWrapper = styled(Flex)`
    border-bottom: 1px solid ${COLORS.brown};
` 

const InputWrapper = styled(Flex)`
    /* gap: 10px;
    -webkit-gap: 10px; */

    input:first-child {
        margin-right: 10px;
    }
    @media (max-width: 400px) {
        padding: 15px 10px 0 10px;
    }
    @media (max-width: 355px) {
        width: 250px;
    }
`
const ButtonWrapper = styled(Flex)`
    @media (max-width: 400px) {
        padding: 15px;
    }
`
const Chevron = styled(Button)<{isPrev?: boolean}>`
    padding: 5px;
    transform: ${({isPrev}) => !isPrev && 'rotate(180deg)'};
`

export { DateDropdown, WeekWrapper, InputWrapper, ButtonWrapper, Chevron }