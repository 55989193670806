import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VoidFuncType } from "../../../types/common";
import { IOffice } from "../../../types/interfaces/office";
import { getAllOffices } from "../../../utils/api/routes";

interface IGetOfficeType {
    filtration?: boolean,
    page: number,
    pageSize: number,
    successCallback: VoidFuncType,
    finalyCallback: VoidFuncType,
    queryString: string
}

const initialState = {
    offices: [] as IOffice[],
    count: 0
}

export const getOffices = createAsyncThunk<object, IGetOfficeType>(
    'offices/getOficces',
    async (payload, { dispatch, rejectWithValue }) => {
        const { filtration, page, pageSize, successCallback, finalyCallback, queryString } = payload
        try {
            await getAllOffices(page, pageSize, queryString).then(res => {

                !!filtration ? dispatch(setOffices(res.data.results)) : dispatch(addOffices(res.data.results))
                successCallback()
                dispatch(setCount(res.data.count))

            }).finally(finalyCallback)

        } catch (err) {
            finalyCallback()
            return rejectWithValue(err.response.data)
        }
    }
)

const officeSlice = createSlice({
    name: 'office',
    initialState,
    reducers: {
        setOffices: (state, action: PayloadAction<IOffice[]>) => {
            state.offices = action.payload
        },
        addOffices: (state, action: PayloadAction<IOffice[]>) => {
            state.offices.push(...action.payload)
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload
        }
    }
})

const { setOffices, addOffices, setCount } = officeSlice.actions

export { setOffices, addOffices, setCount }

export default officeSlice.reducer