import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '../../../components/common/Styled/Flex';
import { Grid } from '../../../components/common/Styled/Grid';
import Filters from '../../../components/Filters';
import { usePagination } from '../../../hooks/usePagination';
import { useWrapperSize } from '../../../hooks/useWrapperSize';
import { getOffices } from '../../../store/reducers/officesReducer';
import { IOffice } from '../../../types/interfaces/office';
import { getSelectParams } from '../../../utils/api/routes';
import CabinetsCatalogPreview from './CabinetsCatalogPreview';
import { CatalogWrapper, CatalogOffices } from './style';

type FliterParamsState = {id: number, name: string, city?: number, active?: boolean}[]

interface IProps{
    offices: IOffice[]
    count: number
    isMobile: boolean
}

const CabinetsCatalog = ({offices, count, isMobile} : IProps) => {
    const [queryString, setQueryString] = useState('')
    const dispatch = useDispatch()
    const {scroll, fetching, successCallback, finalyCallback, personalChenging, currentPage} = usePagination(count === offices.length)
    const ref = useRef<HTMLDivElement>(null)

    const {size} = useWrapperSize(ref, isMobile, [queryString])

    const filtretingSuccess = () => personalChenging(2)

    useEffect(() => {
        queryString && dispatch(getOffices({filtration: true, page: 1, successCallback: filtretingSuccess, finalyCallback, pageSize: 15, queryString: queryString + '&status=true'}))
    }, [queryString])

    useEffect(() => {
        fetching && dispatch(getOffices({page: currentPage, successCallback, finalyCallback, pageSize: 15, queryString: queryString + '&status=true'}))
    }, [fetching])


    return (
        <CatalogWrapper>
            <Grid columns="100%" rows="minmax(50px, max-content) 1fr">
                <Flex justify="flex-start" >
                    <Filters 
                        show={{
                            city: true,
                            metro: true
                        }}
                        setQueryString={setQueryString}
                    />
                </Flex>
                <CatalogOffices ref={ref} height={size} onScroll={scroll} margin="20px 0 0 0" columns='repeat(auto-fill, 23%)' gap="20px">
                    {offices.map(item => <CabinetsCatalogPreview key={item.id} data={item}/>)}
                </CatalogOffices>
            </Grid>  
        </CatalogWrapper>
    );
};

export default CabinetsCatalog;