import styled from "styled-components";
import {Flex} from "../common/Styled/Flex";
import {COLORS} from "../../utils/constants/colors";
import { Typography } from "../common/Styled/Typography";

const CalendarWrapper = styled.div`
    flex: 1;
    position: relative;
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
    width: 100%;
    padding: 0 10px;
`

const Hour = styled(Typography)`
    font-size: 16px;

    @media (max-width: 680px) {
        font-size: 12px;
    }
    @media (max-height: 750px) {
        font-size: 12px;
    }
`
const Minut = styled(Typography)`
    font-size: 8px;

    @media (max-width: 680px) {
        font-size: 7px;
    }
    @media (max-height: 750px) {
        font-size: 7px;
    }
`

const DateWrapper = styled(Flex)`
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`

const Header = styled(Flex)`
    position: relative;
    
    @media (max-width: 640px) {
        ${Typography} {
            font-size: 12px;
        }
    }
    @media (max-width: 470px) {
        ${Typography} {
            text-align: center;
            font-size: 10px;
        }
    }
`;

const Chevron = styled.button<{isPrev: boolean}>`
    position: absolute;
    top: 50%;
    ${({isPrev}) => isPrev ? 'left: -10px' : 'right: -10px'};
    transform: translateY(-50%) ${({isPrev}) => !isPrev && 'rotate(180deg)'};
    user-select: none;
    @media (max-width: 700px) {
        &:first-child {
            left: -10px;
        }
        &:last-child {
            right: -5px;
        }
    }
`;

const DayBorder = styled(Flex)<{active?: boolean}>`
    ${({active}) => active && `background: ${COLORS.lightBrown}; ${Typography} {color ${COLORS.white};}`};
    border-radius: 6px;
    ${({active}) => active && 'padding: 5px 8px;'};
`;

const Grid = styled.div<{view: 7 | 1, margin?: string, rows?: number}>`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(${({view}) => view}, 1fr);
    grid-template-rows: ${({rows}) => rows ? `repeat(${rows}, 1fr)` : 'auto'};
    position: relative;
    border-bottom: 1px solid #DDDBDE;
    user-select: none;
    /* border-right: 1px solid #DDDBDE; */
    margin: ${({margin}) => margin || 0};
    /* &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background: transparent;
        height: 100%;
        width: 1px;
    }; */

    &:hover {
        cursor: pointer;
    }
`;

const GridItem = styled.div<{view?: 1 | 7, border: boolean}>`
    flex: 1;
    border-top: 1px solid #DDDBDE;
    user-select: none;
    border-right: ${ p => p.view === 1 ? 'none' : p.border ? `1px solid #DDDBDE` : 'none'};
`;

export {
    CalendarWrapper,
    Chevron,
    Header,
    Grid,
    GridItem,
    Wrapper,
    Hour,
    Minut,
    DateWrapper,
    DayBorder,
};