import React, { useEffect, useState } from 'react'
import { Button } from '../../../../../components/common/Styled/Button'
import { Flex } from '../../../../../components/common/Styled/Flex'
import { Typography } from '../../../../../components/common/Styled/Typography'
import ErrorHandler from '../../../../../components/Error'
import { useErrorHandler } from '../../../../../hooks/useErrorHandler'
import { VoidFuncType } from '../../../../../types/common'
import { deleteCity, deleteMetro, deleteOffice, getCbinetsOfCity, getSelectParams } from '../../../../../utils/api/routes'
import { DeleteCityConfirmPopupWrapper } from './style'

interface IProps {
    id: number, 
    updateComponent: (arg: string) => void, 
    closeModal: VoidFuncType
}

function DeleteCityConfirmPopup({id, updateComponent, closeModal}: IProps) {
    const [officeIds, setOfficeIds] = useState<Array<number>>([])
    const [metrosIds, setMetrosIds] = useState<Array<{id: number, city?: number, name: string}>>([])
    const {show, active, hidden, text} = useErrorHandler()
    useEffect(() => {
        getCbinetsOfCity(id).then(res => setOfficeIds(res.data.map(item => item.pk)))
        getSelectParams('metros')
        .then(res => setMetrosIds(res.data.filter(item => item.city === id)))
    }, [])

    const deleteCityById = async () => {
        await Promise.all([...officeIds.map(item => deleteOffice(item)), ...metrosIds.map(item => deleteMetro(item.id))])
        deleteCity(id).then(() => {
            updateComponent('')
            closeModal()
        }).catch((e) => show(e))
    }

    return (
        <DeleteCityConfirmPopupWrapper>
            <ErrorHandler active={active} hidden={hidden} text={text} />
            <Flex padding="10px 0" border="1px solid #DECEB7">
                <Typography weight="700" size="18px" lineHeight="21px">Удалить город</Typography>
            </Flex>
            <Flex padding="10px" direction="column">
                <Typography size="18px" lineHeight="21px" align="center">Вместе с городом удалятся все кабинты и метро, связанные с ним. <br /> Вы уверены?</Typography>
                <Button
                    margin="15px"
                    onClick={deleteCityById}
                    theme="grey"
                    width="155px"
                    height="40px"
                    radius="21px"
                    weight="600"
                    lineHeight="17px"
                >
                    Да, я уверен(a)
                </Button>
            </Flex>
        </DeleteCityConfirmPopupWrapper>
    )
}

export default DeleteCityConfirmPopup
