import styled, {CSSObject} from "styled-components";

interface IFlexProps {
    wraping?: boolean,
    align?: CSSObject['alignItems'],
    justify?: CSSObject['justifyContent'],
    direction?: CSSObject['flexDirection'],
    margin?: string,
    padding?: string
    background?: string
    width?: string
    height?: string
    gap?: string
    column?: string
    border?: string
    cursor?: CSSObject['cursor']
    maxHeight?: string
    overflow?: CSSObject['overflow']
}

export const Flex = styled.div<IFlexProps>`
    display: flex;
    align-items: ${p => p.align || 'center'};
    justify-content: ${p => p.justify || 'center'};
    flex-wrap: ${p => p.wraping ? 'wrap' : 'nowrap'};
    flex-direction: ${p => p.direction || 'row'};
    margin: ${p => p.margin || '0'};
    height: ${p => p.height ? p.height : 'auto'};
    padding: ${p => p.padding || '0'};
    background: ${p => p.background || 'none'};
    width: ${p => p.width ? p.width : 'auto'};
    border-bottom: ${p => p.border || 'none'};
    cursor: ${p => p.cursor || 'default'};
    
    & > p, & > div, & > input, & > span, & > a  {
        ${(p) => p.gap ? 'margin-right: ' + p.gap : ''};
        ${(p) => p.column ? 'margin-bottom: ' + p.column : ''};
    }
    & > p:last-child, & > div:last-child, & > input:last-child, & > span:last-child, & > a:last-child{
        ${(p) => p.gap && `margin-right: 0`};
    }

    ${({maxHeight}) => `max-height: ${maxHeight};`};
    ${({overflow}) => `overflow-y: ${overflow};`}
`;