import styled from 'styled-components'
import { COLORS } from '../../../utils/constants/colors'
import { FilterWrapper } from '../../common/Styled/Filters'

export const AutoCompliteWrapper = styled(FilterWrapper)<{width?: string}>`
    width: ${({width}) => width || 'auto'};
    height: 32px;
    .metros-checkbox:checked {
        background: ${COLORS.brown};
    }
`