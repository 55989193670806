import styled from 'styled-components'
import { Button } from '../../../components/common/Styled/Button'
import { CalendarWrapper } from '../../../components/common/Styled/CalendarWrapper'
import { Flex } from '../../../components/common/Styled/Flex'
import { Grid } from '../../../components/common/Styled/Grid'
import { Typography } from '../../../components/common/Styled/Typography'

const List = styled.div<{height: string}>`
    width: 100%;
    max-height: ${({height}) => height};
    overflow-y: auto;

    @media (max-width: 1000px) {
        &::-webkit-scrollbar {
            width: 8px;
        }
    }
`

const ButtonsList = styled(Flex)`
    @media(max-width: 500px) {
        ${Button} {
            width: 90px;
            font-size: 10px;
            height: 35px;
        }
    }
` 

const CalendarTitle = styled(Flex)`
    border-bottom: 1px solid #DECEB7;
    @media (max-width: 510px) {
        ${Typography} {
            font-size: 20px;
        }
    }
    @media (max-width: 440px) {
        ${Typography} {
            font-size: 18px;
        }
    }
    @media (max-width: 370px) {
        ${Typography} {
            font-size: 16px;
        }
    }
    @media (max-height: 700px) {
        padding: 10px;
        ${Typography} {
            font-size: 14px;
        }
    }
    @media (max-height: 670px) {
        ${Typography} {
            font-size: 12px;
        }
    }
`
const CabinetWrapper = styled(Flex)`
    ${CalendarWrapper} {
        @media (max-width: 1000px) {
            position: fixed;
            top: 44px;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 100;
            border-radius: 12px 12px 0 0;
        } 
    }

    ${Grid} {
        @media (max-width: 1100px) {
            grid-template-columns: 30% 72%;
            gap: 20px;
        }

        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
        }

        @media (max-height: 950px) and (min-width: 1000px) {
            margin: 10px 0 0 0;
        }
    }
`

const PaddingWrapper = styled(Flex)`   
    @media (max-height: 640px) {
        padding: 15px;
    }
`

export { List, CalendarTitle, CabinetWrapper, PaddingWrapper, ButtonsList }