import React, {useEffect} from "react";
import {REQUIRED_STRING, validationMessages} from "../../../../utils/formik/validation";
import {Typography} from "../../../../components/common/Styled/Typography";
import {Field, Form, Formik} from "formik";
import FormikInput from "../../../../components/common/FormikElements/Input";
import {Button} from "../../../../components/common/Styled/Button";
import {SubmitButtonWrapper} from "../../style";
import * as Yup from "yup";
import {SetStateType} from "../../../../types/common";
import {createPassword} from "../../../../utils/api/routes";
import useResolution from "../../../../hooks/useResolution";

interface IProps {
    changeStep: SetStateType<'login' | 'recovery' | 'create' | 'agreement'>
}

const PasswordCreation = ({changeStep}: IProps) => {
    const isMobile = useResolution(625)
    const initialValues = {
        new_password: '',
        re_new_password: '',
        current_password: sessionStorage.getItem('user_temporary_password') as string
    }

    useEffect(() => {
        return () => {
            sessionStorage.removeItem('user_temporary_password')
        }
    }, [])

    const handleSubmit = (values: typeof initialValues) => {
        createPassword(values)
            .then(() => changeStep('agreement'))
    }


    const schema = Yup.object({
        new_password: REQUIRED_STRING.max(128, validationMessages.max128),
        re_new_password: REQUIRED_STRING.oneOf([Yup.ref('new_password')], 'Пароль не совпадает!').max(128, validationMessages.max128)
    })

    return (
        <>
            <Typography weight={700} size='36px' lineHeight="44px" margin="0 0 60px" fontFamily="Montserrat">Создание пароля</Typography>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => handleSubmit(values)}
                validationSchema={schema}
            >
                <Form>
                    <Field name="new_password" type="password" component={FormikInput} label="Новый пароль" theme="underline" labelWidth="145px"/>
                    <Field margin="20px 0 0 0" name="re_new_password" type="password" component={FormikInput} label="Повторите пароль" theme="underline" labelWidth="145px"/>
                    <SubmitButtonWrapper>
                        <Button
                            type="submit"
                            theme="grey"
                            radius="6px"
                            shadow={true}
                            weight={800}
                            margin="80px 0 0 auto"
                            fontSize="16px"
                            width={isMobile ? '100%' : '155px'}
                            height="40px"
                        >
                            СОХРАНИТЬ
                        </Button>
                    </SubmitButtonWrapper>
                </Form>
            </Formik>
        </>
    )
}

export default PasswordCreation;