import styled from 'styled-components';
import {COLORS} from "../../../../utils/constants/colors";
import {Flex} from "../../Styled/Flex";

const CheckboxContainer = styled.div`
	display: inline-block;
	vertical-align: middle;
`

const Icon = styled.svg``;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	-webkit-clippath: inset(50%);
	-moz-clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`

const StyledCheckbox = styled(Flex).attrs({ className: 'checkbox-body' }) <{ checked?: boolean }>`
	cursor: pointer;
	width: 16px;
	height: 16px;
	background: ${p => p.checked ? COLORS.lightBrown : 'none'};
	border: ${p => !p.checked ? `1px solid ${COLORS.lightBrown}` : 'none'};
	border-radius: 2px;
	transition: all 150ms;

	${Icon} {
		visibility: ${p => p.checked ? 'visible' : 'hidden'};
	}
`

export {CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox};