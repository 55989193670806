import styled, {CSSObject} from "styled-components";
import {COLORS} from "../../../../utils/constants/colors";

interface ITypographyProps {
    color?: string
    weight?: string | number
    size?: string
    lineHeight?: string
    fontFamily?: string
    margin?: string
    padding?: string
    align?: CSSObject['textAlign']
    decoration?: CSSObject['textDecoration'] 
    whiteSpace?: CSSObject['whiteSpace']
}

export const Typography = styled.p<ITypographyProps>`
    font-size: ${p => p.size || '14px'};
	line-height: ${p => p.lineHeight || '120%'};
	font-weight: ${p => p.weight || 'normal'};
	font-family: ${p => p.fontFamily || 'SF Pro Display'}, sans-serif;
	color: ${p => p.color || COLORS.black};
	padding: ${p => p.padding || 0};
	margin: ${p => p.margin || 0};
	text-align: ${p => p.align || 'start'};
    text-decoration: ${p => p.decoration ? p.decoration : 'none'};
    white-space: ${({whiteSpace}) => whiteSpace || 'normal'};
`;


export const BaseLabel = styled.label`
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 7px;   
`;