import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/common/Styled/Button'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Table, CeilHeader, Ceil } from '../../../../components/common/Styled/Table'
import { Typography } from '../../../../components/common/Styled/Typography'
import { ChangeType } from '../../../../types/common'
import loupeIcon from '../../../../assets/icons/admin/loupeIcon.svg'
import { getPriceCategories, getSubscriptions, deletePriceCategory } from '../../../../utils/api/routes'
import { IPriceCategory } from '../../../../types/interfaces/priceCategories'
import Modal from '../../../../components/Modal'
import AddPriceCategoryPopup from './AddPiceCategoryPopup'
// import DeletePriceCategoryPopup from './DeletePriceCategoryPopup'
import { ISubscription } from '../../../../types/interfaces/subscription'
import { DeleteIcon, PenIcon } from '../../../../assets/icons'
import { DebouncedInput } from '../../../../components/common/FormElements/DebouncedInput/DebouncedInput'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'


function PriceCategory() {
    const { show } = useErrorHandler()
    const [priceCategories, setPriceCategories] = useState<Array<IPriceCategory>>([])
    const [subscriptions, setSubscriptions] = useState<Array<ISubscription>>([])
    const [activePriceCategory, seActivePriceCategory] = useState<IPriceCategory | null>(null)
    const [addCategoryModal, setAddCategoryModal] = useState(false)
    // const [deleteCategoryModal, setDeleteCategoryModal] = useState(false)

    const tableHeader = [
        { id: 1, text: 'Название категории', borderRadius: 'first' as 'first' },
        { id: 2, text: 'Город' },
        { id: 3, text: 'Платежная система' },
        { id: 4, text: 'Список абонементов' },
        { id: 5, text: 'Редактировать', borderRadius: 'last' as 'last' },
        // { id: 6, text: 'Удалить', borderRadius: 'last' as 'last' },
    ]
    const updateComponent = (searchString?: string) =>
        getPriceCategories(searchString && `?search=${searchString}`).then(res => setPriceCategories(res.data))

    const getSubs = (id: number) => {
        if (!subscriptions.length) return
        return subscriptions.reduce((acum, subscription) => {
            if (subscription.price_category?.id === id) {
                acum += `${subscription.name} `
            }
            return acum
        }, '')
    }

    useEffect(() => {
        getSubscriptions().then(res => setSubscriptions(res.data))
        getPriceCategories().then(res => setPriceCategories(res.data))
    }, [])

    return (
        <Flex margin="40px 0 0 0" direction="column" align="flex-start" width="100%">
            <Flex width="100%" justify="space-between">
                <Flex width="30%">
                    <DebouncedInput
                        searchHandler={((searchString: string) => updateComponent(searchString))}
                        icon={loupeIcon}
                        placeholder="Поиск"
                    />
                </Flex>
                <Button
                    theme="grey"
                    width="286px"
                    height="36px"
                    radius="6px"
                    weight="600"
                    lineHeight="17px"
                    onClick={() => setAddCategoryModal(true)}
                >
                    Добавить ценовую категорию
                </Button>
            </Flex>
            <Table margin="25px 0 0 0" w="80%" columns="repeat(5, 20%)">
                {tableHeader.map(item =>
                    <CeilHeader key={item.id} borderRadius={item.borderRadius && item.borderRadius}>
                        <Typography weight="700" lineHeight="18px">{item.text}</Typography>
                    </CeilHeader>
                )}
                {priceCategories.map((item, index) =>
                    <React.Fragment key={item.id}>
                        <Ceil borderRadius="first" isLastRow={index === priceCategories.length - 1}>
                            <Typography lineHeight="18px">{item.name} {item.is_group && '(групповая)'}</Typography>
                        </Ceil>
                        <Ceil>
                            <Typography lineHeight="18px">{item.city.name}</Typography>
                        </Ceil>
                        <Ceil>
                            <Typography lineHeight="18px">{item.payment_system.name}</Typography>
                        </Ceil>
                        <Ceil>
                            <Typography title={getSubs(item.id)} lineHeight="18px">
                                {getSubs(item.id)}
                            </Typography>
                        </Ceil>
                        <Ceil borderRadius="last" isLastRow={index === priceCategories.length - 1}>
                            <PenIcon onClick={() => {
                                seActivePriceCategory(item)
                                setAddCategoryModal(true)
                            }} />
                        </Ceil>
                        {/* <Ceil borderRadius="last" isLastRow={index === priceCategories.length - 1}>
                            <DeleteIcon onClick={() => {
                                seActivePriceCategory(item)
                                setDeleteCategoryModal(true)
                            }} />
                        </Ceil> */}
                    </React.Fragment>

                )}
            </Table>
            {addCategoryModal &&
                <Modal
                    component={AddPriceCategoryPopup}
                    haveCloseBtn={false}
                    closeModal={() => { setAddCategoryModal(false); seActivePriceCategory(null) }}
                    parameters={{
                        updateComponent,
                        activePriceCategory
                    }}
                />
            }
            {/* {deleteCategoryModal &&
                <Modal
                    component={DeletePriceCategoryPopup}
                    haveCloseBtn={true}
                    closeModal={() => { setDeleteCategoryModal(false); seActivePriceCategory(null) }}
                    parameters={{
                        updateComponent,
                        activePriceCategory
                    }}
                />
            } */}
        </Flex>
    )
}

export default PriceCategory
