import React, {HTMLAttributes} from "react";
import {ChangeType} from "../../../../types/common";
import {StyledInput} from "./style";

export interface IInputProps extends HTMLAttributes<HTMLInputElement>{
    value: string,
    onChange: (event: ChangeType) => void,
    theme?: 'default' | 'underline',
    classname?: string
    name?: string
    maxLength?: number
    pattern?: string
    type?: string
    icon?: string
}

const Input = ({value, icon, onChange, theme = 'default', classname, name, maxLength, pattern, type, ...props}: IInputProps & {disabled?: boolean, autoComplete?: string}) => {
    return (
            <StyledInput
                value={value}
                onChange={onChange}
                theme={theme}
                className={classname}
                name={name}
                maxLength={maxLength}
                pattern={pattern}
                type={type}
                icon={icon}
                {...props}
            />
    )
}

export default Input;