import styled, { CSSObject } from 'styled-components';

interface IProp {
    margin?: string,
    padding?: string,
    columns?: CSSObject['gridTemplateColumns'],
    rows?: CSSObject['gridTemplateRows'],
    justify?: CSSObject['justifyItems'],
    align?: CSSObject['alignItems'],
    w?: string,
    h?: string, 
    gap?: string,
}

export const Grid = styled.div<IProp>`
    display: grid;
    grid-template-columns: ${p => p.columns || 'none'};
    grid-template-rows: ${p => p.rows || 'none'};
    justify-items: ${p => p.justify || 'left'};
    align-items: ${p => p.align || 'start'};
    margin: ${p => p.margin || '0'};
    padding: ${p => p.padding || '0'};
    width: ${p => p.w || '100%'};
    height: ${p => p.h || 'auto'};
    gap: ${p => p.gap ||'0'};
`