import React, { useEffect, useRef, useState } from 'react'
import { usePagination } from '../../../hooks/usePagination'
import useResolution from '../../../hooks/useResolution'
import { useWrapperSize } from '../../../hooks/useWrapperSize'
import { IWithdrawal } from '../../../types/interfaces/withdrawal'
import { getHistoryBalance } from '../../../utils/api/routes'
import PurchaseHistoryItem from './PurchaseHistoryItem'
import { PurchaseHistoryWrapper, Wrapper } from './style'

function PurchaseHistory() {
    const [withdrawal, setWithdrawal] = useState<Array<IWithdrawal>>([])
    const [count, setCount] = useState(0)
    const {scroll, currentPage, finalyCallback, successCallback, resetCallback, fetching} = usePagination(count <= withdrawal.length)
    const isMobile = useResolution(1000)
    const ref = useRef<HTMLDivElement>(null)
    const {size} = useWrapperSize(ref, isMobile)
    useEffect(() => {
        resetCallback()
        getHistoryBalance('?page=1&page_size=15').then(res => {
            setWithdrawal(res.data.results)
            setCount(res.data.count)
            successCallback()
        })
    }, [setWithdrawal, setCount])

    useEffect(() => {
        fetching && getHistoryBalance(`?page=${currentPage}&page_size=15`).then(res => {
            setWithdrawal([...withdrawal, ...res.data.results])
            setCount(res.data.count)
            successCallback()
        }).finally(finalyCallback)
    }, [fetching])

    return (
        <Wrapper ref={ref} height={size}>
            <PurchaseHistoryWrapper onScroll={scroll}>
                {withdrawal.map(item => 
                    <PurchaseHistoryItem key={item.id} data={item} />
                )}
            </PurchaseHistoryWrapper>
        </Wrapper>    
    )
}

export default PurchaseHistory
