import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { usePagination } from '../../hooks/usePagination'
import { useWrapperSize } from '../../hooks/useWrapperSize'
import { getUnReadedNotificationCount } from '../../store/reducers/notificationReducer'
import { INotification } from '../../types/interfaces/notification'
import { getNotifications, readNotification } from '../../utils/api/routes'
import { NoticationsWrapper, NotificationHeader, NotificationPage } from './style'
import { Typography } from '../../components/common/Styled/Typography'
import { Flex } from '../../components/common/Styled/Flex'

import NotificationCard from './NotificationCard'
import useResolution from '../../hooks/useResolution'

function Notifications() {
    const dispatch = useDispatch()
    const isMobile = useResolution(1000)

    const ref = useRef<HTMLDivElement>(null)

    const [notification, setNotification] = useState<Array<INotification>>([])
    const [count, setCount] = useState(0)

    const { size } = useWrapperSize(ref, isMobile)
    const {
        scroll,
        currentPage,
        resetCallback,
        finalyCallback,
        successCallback,
        fetching
    } = usePagination(count <= notification.length)

    useEffect(() => {
        resetCallback()
        getNotifications('?page=1&page_size=10').then(res => {
            setNotification(res.data.results)
            setCount(res.data.count)
            successCallback()
        })
    }, [])

    useEffect(() => {
        const recipientList = notification.reduce((acum: Array<number>, item) => {
            if (!item.readed) {
                acum.push(item.id)
            }
            return acum
        }, [])

        recipientList.length && readNotification({ recipient_list: recipientList }).then(() => {
            dispatch(getUnReadedNotificationCount())
        })
    }, [notification])

    useEffect(() => {
        fetching && getNotifications(`?page=${currentPage}&page_size=10`).then(res => {
            setNotification([...notification, ...res.data.results])
            successCallback()
        }).finally(finalyCallback)
    }, [fetching])

    return (
        <NotificationPage padding="50px 60px 0 60px">
            <Flex direction='column' width='100%'>
                <NotificationHeader>Уведомления</NotificationHeader>
                <NoticationsWrapper height={size} ref={ref} onScroll={scroll} justify="flex-start" direction="column" width="100%">
                    {notification.map(item =>
                        <NotificationCard key={item.id} data={item} />
                    )}
                    {
                        !notification.length &&
                        <Flex height="80%">
                            <Typography weight="300" size='18px'>
                                Для Вас сейчас нет уведомлений
                            </Typography>
                        </Flex>
                    }
                </NoticationsWrapper>
            </Flex>
        </NotificationPage >
    )
}

export default Notifications
