import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getNotificatinsUnreaded } from "../../../utils/api/routes"


const initialState = {
    unReadedCount: 0
}

export const getUnReadedNotificationCount = createAsyncThunk<object>(
    'notification/getUnReadedCount',
    async (payload, { dispatch }) => {
        try {
            const response = await getNotificatinsUnreaded()
            if (response.status === 200) {
                dispatch(setUnReadedCount(response.data.count))
            }
        } catch (error) { }
    }
)


const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setUnReadedCount(state, action: PayloadAction<number>) {
            state.unReadedCount = action.payload
        },
    }
})

const { setUnReadedCount } = notificationSlice.actions

export { setUnReadedCount }

export default notificationSlice.reducer