import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { COLORS } from '../../../../utils/constants/colors'

const NotificationCardWrapper = styled.div`
    width: 100%;
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    background: ${COLORS.cream};
    padding: 10px;
`

const PhotoWrapper = styled(Flex)`
    max-width: 100%;
    height: 65px;
    overflow-x: auto;
    &::-webkit-scrollbar {
        height: 4px;
    }
`

const Photo = styled.div`
    min-width: 69px;
    width: 69px;
    height: 100%;
    border-radius: 6px;
    background: url(.jpg), #C4C4C4;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }
`

export {NotificationCardWrapper, PhotoWrapper, Photo}