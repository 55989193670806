import React, { useEffect, useState } from 'react';

export const useWrapperSize = <T extends HTMLElement = HTMLElement>(
    ref: React.RefObject<T>, isMobile?: boolean, args = [] as Array<unknown>, coeff = 0) => {
    const [size, setSize] = useState('0')
    const [coefficient, setCoefficient] = useState(100)

    useEffect(() => {
        window.addEventListener('resize', resize)
        
        resize()
        return () => window.removeEventListener('resize', resize)
    }, [coefficient, ...args])

    useEffect(() => {
        isMobile && setCoefficient(100)
        !isMobile && setCoefficient(30)
    }, [isMobile])
    
    const resize = () => {
        if(ref.current) {
            setSize(`${window.innerHeight - ref.current.getBoundingClientRect().top - coefficient}px`)
        }
    }

    return {size, resize}
}