import React, { useEffect, useRef, useState } from 'react';
import { BalanceWrapper, FormWrapper, Tabs, TabsWrapper } from './style';
import { Grid } from '../../components/common/Styled/Grid';
import { Typography } from '../../components/common/Styled/Typography';
import ProfileForm from './ProfileForm';
import useResolution from '../../hooks/useResolution';
import { COLORS } from '../../utils/constants/colors';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { IBalance } from '../../types/interfaces/balance';
import { Flex } from '../../components/common/Styled/Flex';
import { useWrapperSize } from '../../hooks/useWrapperSize';
import PurchaseHistory from './PurchaseHistory';

const Profile = () => {
    const {profile} = useSelector((state: RootState) => state.user)
    const [balances, setBalances] = useState<Array<IBalance>>([])
    const isMobile = useResolution(1000)
    const [showForm, setShowForm] = useState(true)
    const ref = useRef<HTMLDivElement>(null)
    const {size} = useWrapperSize(ref, isMobile)

    useEffect(() => {
        
    }, [])

    return (
        <>
            {!isMobile &&
                <Grid 
                    columns={'50% 40%'} 
                    padding={'0 90px'} 
                    margin={'50px 0 0 0'} 
                    gap={'125px'}
                >
                    <>
                        <FormWrapper>
                            <Typography
                                size={'24px'}
                                weight={'800'}
                                lineHeight={'29px'}
                                margin={'0 0 20px 0'}
                            >
                                Личные данные
                            </Typography>
                            <ProfileForm isMobile={isMobile}  />
                        </FormWrapper>
                        <Flex direction="column" align="flex-start" width="100%">
                            <Typography
                                size={'24px'}
                                weight={'800'}
                                lineHeight={'29px'}
                                margin={'0 0 20px 0'}
                            >
                                История покупок
                            </Typography>
                            <PurchaseHistory />
                        </Flex>
                    </>
                </Grid>  
            }
            {isMobile &&
                <TabsWrapper direction="column" padding="35px 15px 0 15px">
                    <Tabs justify="space-between">
                        <Typography
                            size={'18px'}
                            weight={'800'}
                            lineHeight={'21px'}
                            margin={'0 0 20px 0'}
                            color={showForm ? COLORS.black : COLORS.steel}
                            onClick={() => setShowForm(true)}
                        >
                            Личные данные
                        </Typography>
                        <Typography
                            size={'18px'}
                            weight={'800'}
                            lineHeight={'21px'}
                            margin={'0 0 20px 0'}
                            color={showForm ? COLORS.steel : COLORS.black}
                            onClick={() => setShowForm(false)}
                        >
                            История покупок
                        </Typography>
                    </Tabs>
                    {showForm && 
                        <FormWrapper>
                            <ProfileForm isMobile={isMobile} />
                        </FormWrapper>
                    }
                    {!showForm && 
                        <PurchaseHistory />
                    }

                </TabsWrapper>
            }
        </>
    );
};

export default Profile;