import React from 'react';
import { Formik, Field, Form } from 'formik';
import FormikInput from '../../../components/common/FormikElements/Input';
import { Button } from '../../../components/common/Styled/Button';
import { Flex } from '../../../components/common/Styled/Flex';
import { Typography } from '../../../components/common/Styled/Typography';
import { COLORS } from '../../../utils/constants/colors';
import { CONFIRM, REQUIRED_STRING, validationMessages } from '../../../utils/formik/validation';
import { Container, SetPasswordWrapper, Title } from './style';
import * as Yup from 'yup';
import { VoidFuncType } from '../../../types/common';
import { createPassword } from '../../../utils/api/routes';
import ErrorHandler from '../../../components/Error';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { showAlert } from '../../../utils/showAlert';

const ChangePopup = ({ closeModal }: { closeModal: VoidFuncType }) => {
    const { show, active, hidden, text } = useErrorHandler()
    const passwordSubmit = (value: typeof initial) => {
        createPassword(value)
            .then(() => closeModal()).catch((e) => show(e))

    }

    const initial = {
        current_password: '',
        new_password: '',
        re_new_password: ''
    }

    const schema = Yup.object({
        current_password: REQUIRED_STRING.max(128, validationMessages.max128),
        new_password: REQUIRED_STRING.max(128, validationMessages.max128),
        re_new_password: CONFIRM
    })

    const inputs = [
        { id: 1, name: 'current_password', label: 'Старый пароль' },
        { id: 2, name: 'new_password', label: 'Новый пароль' },
        { id: 3, name: 're_new_password', label: 'Повторите пароль' },
    ]

    return (
        <Container background={COLORS.cream} direction="column">
            <ErrorHandler active={active} hidden={hidden} text={text} />
            <Title margin="0 0 25px 0" padding="10px 0">
                <Typography weight="700" size='18px' align="center" lineHeight="21px">Сменить пароль</Typography>
            </Title>
            <Formik
                initialValues={initial}
                onSubmit={(value) => passwordSubmit(value)}
                validationSchema={schema}
            >
                <SetPasswordWrapper>
                    <Form>
                        {inputs.map(item =>
                            <Field
                                className="aaa"
                                direction="column"
                                type="password"
                                name={item.name}
                                component={FormikInput}
                                label={item.label}
                                key={item.id}
                                schema={schema}
                            />
                        )}
                        <Flex justify="space-between">
                            <Button
                                type="button"
                                theme="beige"
                                fontSize="14px"
                                lineHeight="17px"
                                radius="21px"
                                weight="600"
                                width="155px"
                                height="40px"
                                onClick={closeModal}
                            >
                                Отменить
                            </Button>
                            <Button
                                type="submit"
                                theme="green"
                                fontSize="14px"
                                lineHeight="17px"
                                radius="21px"
                                weight={600}
                                width="155px"
                                height="40px"
                            >
                                Сохранить
                            </Button>
                        </Flex>
                    </Form>
                </SetPasswordWrapper>
            </Formik>
        </Container>
    );
};

export default ChangePopup;