import React from "react";
import {Flex} from "../../Styled/Flex";
import {FieldProps} from "formik";
import {Label, InputWrapper} from "./style";
import {getNestedValue} from "../../../../utils/formik/getNestedValue";
import Input from "../../FormElements/Input";
import {Typography} from "../../Styled/Typography";
import {COLORS} from "../../../../utils/constants/colors";
import { CSSObject } from "styled-components";
import { formatPhoneNumber } from "../../../../utils/formik/formatPhoneNumber";
import { getCorrectFieldLabel } from "../../../../utils/formik/getCorrectFieldLabel";

interface IInputProps extends FieldProps {
    label?: string
    labelWidth?: string
    direction?: CSSObject['flexDirection']
    margin?: string
    description?: string
    schema: Record<string, any>
}

const FormikInput = ({
                   label,
                   labelWidth,
                   field: {value, onChange, ...field},
                   form: {setFieldValue, errors, touched},
                   direction = 'row',
                   margin = '0',
                   description,
                   schema,
                   ...props
}: IInputProps) => {

    const fieldError = getNestedValue(errors, field.name)
    const fieldTouched = getNestedValue(touched, field.name)

    return (
        <Flex margin={margin} width="100%" direction={direction} align="flex-start" justify="flex-start">
            {label && <Label width={labelWidth}>{getCorrectFieldLabel(label, schema, field.name)}</Label>}
            <InputWrapper>
                <Input value={field.name === 'phone' ? formatPhoneNumber(value) : value} onChange={onChange} {...field} {...props} />
                {fieldError && fieldTouched && <Typography color={COLORS.red} margin="5px 0" size="12px">{fieldError}</Typography>}
                {description && <Typography whiteSpace="pre-line" color={COLORS.gray} margin="5px 0" size="12px">{description}</Typography>}
            </InputWrapper>
        </Flex>
    )
}

// export default React.memo(FormikInput, (prevProps, nextProps) => {
//     return prevProps.field.value === nextProps.field.value &&
//         getNestedValue(prevProps.form.errors, prevProps.field.name) === getNestedValue(nextProps.form.errors, nextProps.field.name)
// });

export default FormikInput;