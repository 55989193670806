import styled from 'styled-components'
import { Flex } from '../../components/common/Styled/Flex'

const CabinetsWrapper = styled(Flex)`
    @media (min-width: 1000px) and (max-height: 950px) {
        padding: 30px 60px 0 60px;
    }
`
const ArrowBack = styled(Flex)`
    position: fixed;
    top: 10px;
    left: 15px;
`

export {CabinetsWrapper, ArrowBack}