import styled from 'styled-components'
import { Flex } from '../../../components/common/Styled/Flex'
import { Typography } from '../../../components/common/Styled/Typography'
import { COLORS } from '../../../utils/constants/colors'

const NotificationCardWrapper = styled.div`
    padding-top: 20px;
    width: 100%;
    max-width: 530px;
    height: auto;
    background: ${COLORS.logout};
    border-radius: 7px;
    cursor: pointer;
    transition: all .2s linear;
    margin-bottom: 10px;
    &:hover {
        background: ${COLORS.cream};
    }
`

const Address = styled(Typography)`
    word-break: break-word;
`

const PhotoWrapper = styled(Flex)<{showMore: boolean}>`
    max-width: 100%;
    height: ${({showMore}) => showMore ? '200px' : '65px'};
    transition: height .2s linear;
    overflow: auto;
    &::-webkit-scrollbar {
        height: 4px;
    }
`

const Photo = styled.div<{showMore: boolean}>`
    min-width: ${({showMore}) => showMore ? '200px' : '65px'};
    width: ${({showMore}) => showMore ? '200px' : '65px'};
    height: 100%;
    border-radius: 6px;
    background: #C4C4C4;
    transition: width .2s linear,
                min-width .2s linear;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }
`

const ShowMore = styled(Flex)<{active: boolean}>`
    border-top: 1px solid ${COLORS.brown};
    cursor: pointer;
    transition: background .2s linear;

    &:hover {
        background: ${COLORS.logout};
    }

    svg {
        transition: transform .2s linear;
        transform: ${({active}) => active ? 'rotate(180deg);' : 'rotate(0);'};
    }
`

export {NotificationCardWrapper, PhotoWrapper, Photo, Address, ShowMore}