import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Typography } from '../../../../components/common/Styled/Typography'
import { COLORS } from '../../../../utils/constants/colors'

const PurchaseHistoryItemWrapper = styled.div`
    padding: 10px 20px 20px;
    border-bottom: 1px solid ${COLORS.brown};
`

const InfoWrapper = styled(Flex)`

    @media(max-width: 1060px) {
        ${Typography} {
            font-size: 14px;
        }
    }

    @media(max-width: 1000px) {
        ${Typography} {
            font-size: 16px;
        }
    }

    @media(max-width: 350px) {
        ${Typography} {
            font-size: 14px;
        }
    }
`

export {PurchaseHistoryItemWrapper, InfoWrapper}