import styled, { keyframes } from 'styled-components'
import { Label } from '../../../../components/common/FormikElements/Input/style'
import { Flex } from '../../../../components/common/Styled/Flex'
import { Typography } from '../../../../components/common/Styled/Typography'
import { COLORS } from '../../../../utils/constants/colors'

const open = keyframes`
    0% {
        opacity: 0; 
    }
    100% {
        opacity: 1;
    }
`

const HeaderWrapper = styled(Flex)`
    position: relative;
    animation: ${open} .1s linear;
    input, span {
        font-weight: 700;
        font-size: 18px;
    }

    @media (max-width: 1080px) {
        height: auto;
        ${Typography} {
            &:nth-child(2n + 1) {
                font-size: 18px;
            }
        }
    }
    @media (max-width: 605px) {
        margin: 0 0 15px 0;
        ${Flex} {
            &:last-child{
                margin: 0;
            }
        }
        input, span {
            font-weight: 700;
            font-size: 14px;
        }
        ${Label} {
            width: 100%;
            font-size: 12px;
            margin: 10px 0 0 0;
        }
    }

    @media (max-width: 489px) {
        ${Typography} {
            &:nth-child(2n + 1) {
                font-size: 16px;
            }
        }
    }

    @media (max-height: 950px) and (min-width: 1000px) {
        margin: 0 0 10px 0;

        ${Label} {
            margin: 3px 0 0 15px;
        }
    }

    @media (max-height: 950px) {
        margin: 0 0 10px 0;
    }

`

const ImgWrapper = styled.div`
    border-radius: 9px;
    width: 250px;
    height: 100%;
    max-height: 130px;
    img{
        width: 100%;
        height: 100%;
        max-height: 130px;
        object-fit: cover;
        border-radius: 9px;
    }

    @media (max-width: 530px) {
        width: 110px;
        height: 84px;
        margin: 0;
    }

    @media (max-height: 950px) and (min-width: 1000px) {
        height: 100px;
    }
`

const Date = styled(Flex)`
    @media (max-height: 950px){
        margin: 10px 0 0 0;
    }
`

const Name = styled(Typography)`
    width: calc(100% - 100px);
`

const Address = styled(Typography)`
    @media (max-height: 950px){
        margin: 0;
    }
`

const Status = styled(Flex)<{status: 1 | 2 | 3}>`
    position: absolute;
    top: 0;
    right: 0;
    min-width: 120px;
    height: 30px;
    border-radius: 9px;
    background: ${p => p.status === 1 ? COLORS.green : p.status === 2 ? COLORS.orange : COLORS.red};
    padding: 0 5px;
    @media (max-width: 1080px) {
        min-width: 100px;
        ${Typography} {
            font-size: 13px!important;
        }
    }
    @media (max-width: 593px) {
        min-width: 90px;
        height: 20px;
        ${Typography} {
            font-size: 10px!important;
        }
    }
    @media (max-width: 360px) {
        min-width: 70px;
        ${Typography} {
            font-size: 8px!important;
        }
    }
`
const HeaderInfo = styled(Flex)`
    @media(max-width: 500px) {
        margin: 10px 0 0 15px;
        ${Flex} {
            width: 100%;
        }
    }
`

export { HeaderWrapper, ImgWrapper, Status, HeaderInfo, Date, Address, Name }