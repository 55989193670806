import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { ITotalProps } from '../..';
import { useAdaptive } from '../../../../hooks/useAdaptive';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { getSelectParams } from '../../../../utils/api/routes';
import { COLORS } from '../../../../utils/constants/colors';
import Checkbox from '../../../common/FormElements/Checkbox';
import { Typography } from '../../../common/Styled/Typography';
import { List, ListItem, MetroWrapper } from './style';

type ListType = Array<{id: number, name: string, city?: number, active?: boolean}>

const MetrosComplite = ({setTotalState, cityId} : ITotalProps & {cityId: number | null}) => {
    const [list, setList] = useState<ListType>([])
    const [value, setValue] = useState('Метро')
    const [show, setShow] = useState(false)
    const [selected, setSelected] = useState('')
    const [active, setActive] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const listRef = useRef<HTMLUListElement>(null)
    const resize = useAdaptive(listRef)
    
    useEffect(() => {
        const queryString = cityId ? `?city=${cityId}` : ''
        cityId && getSelectParams('metros', queryString)
            .then(res => res.data.map(item => ({...item, active: false})))
            .then(data => setList(data))
    }, [cityId])

    useEffect(() => {
        resize(100)
    }, [show, resize, selected])

    useEffect(() => {
        setSelected('')
        setList(list.map(item => ({...item, active: false})))
    }, [cityId])


    const selectItem = (id: number) => {    
        setList(
            list.map(item => {
                if(item.id === id) {
                    return {...item, active: !item.active}
                }
                return item
            })  
        )
    }

    useEffect(() => {
        if(list.length) {
            const metrosString = list.reduce((acum, item) => {
                if(item.active) {
                    acum += item.name + ','
                }
                return acum
            }, '')
            setSelected(metrosString)
        }
    }, [list])

    useEffect(() => {
        setTotalState(prevState => ({...prevState, metro: selected}))
        if(selected) {
            setValue(`Выбрано: ${list.filter(item => item.active).length}`)
            setActive(true)
            return
        }
        setTotalState(prevState => ({...prevState, metro: ''}))
        setValue('Метро')
        setActive(false)
    }, [selected])

    useClickOutside(ref, () => setShow(false))

    return (
        <MetroWrapper ref={ref} active={active}>
            <Typography
                color={active ? COLORS.white : COLORS.black}
                fontFamily="Montserrat" 
                lineHeight="24px" 
                weight="700" 
                size="12px"
                align="center"
                padding="0 25px"
                onClick={() => setShow(!show)} 
            >
                {value}
            </Typography>
            {show &&
                <List active={active} ref={listRef}>
                {cityId ? 
                        list.map((item) => 
                            <ListItem 
                                key={item.id}
                                data-id={item.id}
                                onClick={() => selectItem(item.id)} 
                            >
                                <Checkbox className="metros-checkbox" value={item.active} onChange={() => {}}/>
                                <Typography size="16px" lineHeight="20px">{item.name}</Typography>
                            </ListItem>
                        )
                        :
                        <ListItem>
                            <Typography size="16px" lineHeight="20px">Город не выбран</Typography>
                        </ListItem>
                    }
                </List>
            }
        </MetroWrapper>
    );
};

export default MetrosComplite;