import styled from "styled-components";
import { COLORS } from "../../../../utils/constants/colors";
import { Flex } from "../../Styled/Flex";


const Container = styled.div`
  width: 100%;
  min-height: 36px;
  position: relative;
  cursor: pointer;
`;

const SelectWrapper = styled.div<{ isShow: boolean }>`
  border: 1px solid ${COLORS.brown};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  color: ${COLORS.black}; 
  background: ${COLORS.white};
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  font-size: 16px;
  padding: 8px 14px 8px 10px; 
  min-height: 36px;
  line-height: 20px;
  width: 100%;
  ${({ isShow }) => isShow && 'z-index: 11'};
  svg {
    transition: all .3s linear;
    transform: ${({ isShow }) => isShow ? 'rotate(180deg)' : 'rotate(0)'};
  };

  span {
    text-overflow: ellipsis;
    width: calc(100% - 20px);
    white-space: nowrap;
    overflow:hidden; 
  }
`;

const OptionsWrapper = styled.div<{ isShow: boolean, toTop: boolean }>`
    position: absolute;
    z-index: 1005;
    max-height: 200px;
    overflow: auto;
    left: 0;
    border: 1px solid ${COLORS.brown};
    width: 100%;
    ${({ toTop }) => toTop ? 'top: 0;' : 'bottom: -2px;'};
    transform: ${({ toTop }) => toTop ? 'translateY(-100%)' : 'translateY(calc(100% - 5px))'}; 
    background: ${COLORS.white};
    border-radius: ${({ toTop }) => toTop ? '6px 6px 0 0' : '0 0 6px 6px'};
    ${({ isShow, toTop }) => isShow && (toTop ? 'border-bottom: none !important' : 'border-top: none !important')};

    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: ${COLORS.brown};
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }    
`;

const Option = styled.div`
   background: ${COLORS.white};
   color: ${COLORS.black};
   cursor: pointer;
   width: 100%;
   font-size: 16px;
   line-height: 20px;
   padding: 8px 10px;
   hyphens: auto;
   &:hover, &:active {
      background: ${COLORS.brown}; 
   };
   &:first-of-type {
        padding: 13px 10px 8px !important;
   }; 
   &:last-of-type {
       border-radius: 0 0 6px 6px;
   }; 
`;

const OptionsSelectWrapper = styled(Flex)`
	&:hover {
      background: ${COLORS.brown}; 
	};
	   
	${Option} {
		background: transparent; 
		&:hover, &:active {
      		background: transparent; 
   		};
	}
`


export { SelectWrapper, OptionsWrapper, Option, Container, OptionsSelectWrapper }