import styled from 'styled-components';
import { Button } from '../../../components/common/Styled/Button';
import { Flex } from '../../../components/common/Styled/Flex';
import { COLORS } from '../../../utils/constants/colors';

const FormCard = styled.div`
    width: 100%;
    background: ${COLORS.cream};
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 12px;

    input{
        cursor: text;
    }

    @media (max-width: 1280px) {
        label {
            width: 100px;
        }

        input, ${Button} {
            font-size: 12px;
        }

        ${Button}{
            width: 150px;
        }
    }

    @media (max-width: 434px) {

        ${Button} {
            font-size: 9px;
            width: 110px;
        }

    }
    @media (max-height: 700px) {
        .field {
            margin: 0 0 20px 0;
        }
    }
    @media (max-height: 560px) {
        ${Button} {
            height: 30px;
        }
    }
    @media (max-height: 490px) {
        .wrapper {
            padding: 10px 15px 0 15px;
        }
    }
`;

const Logout = styled(Flex)`
    width: 100%;
    margin-top: 40px;
    background: ${COLORS.logout};
    box-shadow: inset 0px 1px 0px #E1D7C9;
    border-radius: 0px 0px 12px 12px;
    cursor: pointer;
    transition: background .2s linear;
    &:hover {
        background: #E1D7C9;
    }

    @media (max-height: 700px) {
        margin-top: 20px;
    }
    @media (max-width: 510px) {
        margin: 0;
    }
`

export { FormCard, Logout }