import styled, { keyframes } from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'
import { DropDown } from '../../Styled/DropDown'
import { Flex } from '../../Styled/Flex'

const open = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const TimeWrapper = styled(DropDown)`
    animation: ${open} .2s linear;
    left: 0;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: SF Pro Display;
        font-size: 16px;
        font-weight: 600;
        width: 100px!important;
        height: 30px;
        margin-right: 5px;
        border: 1px solid #DECEB7;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
    }
    .react-datepicker__time-list-item  {
        font-family: SF Pro Display;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .react-datepicker__time-list-item--selected {
        background: ${COLORS.brown}!important;
    }
    .react-datepicker__time-list::-webkit-scrollbar {
      width: 5px;
    }

    .react-datepicker__time-list-item--disabled {
        display: none;
    }

    .react-datepicker__time-list-item {
        font-size: 14px;
        font-weight: 600;
    }

    @media(max-width: 320px) {
        width: 250px;
        span {
            width: 80px!important;
        }
    }

`

const SelectWrapper = styled(Flex)`
    position: relative;
    
    & > div {
        position: absolute;
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
    }
`

export { TimeWrapper, SelectWrapper }