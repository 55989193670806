import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'
import { Typography } from '../Typography'

export const Tab = styled(Typography)<{active?: boolean}>`
    cursor: pointer;
    color: ${p => p.active ? COLORS.black : COLORS.steel};
    transition: color .2s linear;

    &:hover {
        color: ${p => !p.active && COLORS.lightBlack};
    }
`