import moment from 'moment';
import React from 'react';
import { MapIcon } from '../../../../assets/icons';
import { Button } from '../../../../components/common/Styled/Button';
import { Flex } from '../../../../components/common/Styled/Flex';
import { Typography } from '../../../../components/common/Styled/Typography';
import { IBooking } from '../../../../types/interfaces/booking';
import { COLORS } from '../../../../utils/constants/colors';
import { Address } from '../../BookingPast/PastItem/style';
import { Row } from '../../BookingPast/style';

const CanceledItem = ({item} : {item: IBooking}) => {
    const {after_hours, before_hours, date, penalty, office} = item

    return (
        <Row>
            <Flex justify="space-between">
                <Flex width="100%" align="flex-start" direction="column">
                    <Typography
                        fontFamily="Montserrat"
                        weight="600"
                        size="12px"
                        lineHeight="24px"
                        color={COLORS.steel}
                    >
                        {moment(date).format('DD MMMM')}, {before_hours.slice(0, 5)} – {after_hours.slice(0, 5)}
                    </Typography>

                    <Flex gap="10px" width="100%">
                        <Typography
                            // width="60px"
                            weight="700"
                            size="20px"
                            lineHeight="18px"
                            margin="0 14px 0 0"
                            whiteSpace="nowrap"
                        >
                            {office?.name}
                        </Typography>

                        <Address
                            fontFamily="Montserrat"
                            weight="600"
                            size="12px"
                            lineHeight="24px"
                            color={COLORS.steel}
                        >
                            <MapIcon />
                            &nbsp;
                            {office?.address}
                        </Address>
                    </Flex>
                </Flex>
                {!!penalty && +penalty > 0 &&
                    <Button
                        fontSize="13px"
                        lineHeight="16px"
                        weight="600"
                        width="130px"
                        height="30px"
                        radius="9px"
                        theme="red"
                    >
                        Штраф {penalty} часа
                    </Button>

                }
            </Flex>    
        </Row>
    );
};

export default CanceledItem;