import styled from 'styled-components'
import { COLORS } from '../../../utils/constants/colors'

export const Card = styled.div`
    width: 100%;
    height: 133px;
    box-shadow: 0px 0px 20px #E1D7C9;
    border-radius: 8px;
    background: ${COLORS.white};
    padding: 20px;
    margin-bottom: 20px;
`
