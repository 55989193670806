import styled from 'styled-components'
import { COLORS } from '../../../../utils/constants/colors'

export const CalendarWrapper = styled.div<{padding?: string, notScroll?: boolean}>`
    width: 100%;
    background: ${COLORS.cream};
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 12px;
    padding: ${p => p.padding ? p.padding : '0'};
    transition: transform .2s linear;
    ${({notScroll}) => !notScroll && 'overflow-y: auto; overflow-x: hidden;'};
    &::-webkit-scrollbar {
        width: 1px;
    }
`