import React, { useCallback, useEffect, useRef } from "react";
import { BodyWrapper, CloseButton, ModalWrapper, ModalWrapperContent } from "./style";
import { CloseIcon, CloseSliderIcon } from "../../assets/icons";
import { ComponentType, VoidFuncType } from "../../types/common";
import { useClickOutside } from "../../hooks/useClickOutside";


interface IProps<T> {
    haveCloseBtn?: boolean
    component: ComponentType
    closeModal: VoidFuncType
    parameters?: T
    sliderModal?: boolean
}

const Modal = <T extends object>({ haveCloseBtn, component: Component, closeModal, parameters, sliderModal }: IProps<T>) => {
    const ref = useRef<HTMLDivElement>(null);
    useClickOutside(ref, closeModal);

    const onEscPress = useCallback((e: KeyboardEvent) => {
        e.key === 'Escape' && closeModal()
    }, [closeModal]);

    useEffect(() => {
        document.addEventListener('keydown', onEscPress);
        window.document.querySelector('body')!.classList.add('scroll-hidden');
        return () => {
            window.document.querySelector('body')!.classList.remove('scroll-hidden');
            document.removeEventListener('keydown', onEscPress);
        }
    }, [onEscPress]);

    return (
        <ModalWrapper>
            <ModalWrapperContent>
                <BodyWrapper className="modal_body_wrapper" ref={ref}>
                    {haveCloseBtn && <CloseButton onClick={() => closeModal()}><CloseIcon /></CloseButton>}
                    {sliderModal && <CloseButton sliderModal={!!sliderModal} onClick={() => closeModal()}><CloseSliderIcon /></CloseButton>}
                    <Component {...parameters} closeModal={closeModal} />
                </BodyWrapper>
            </ModalWrapperContent>
        </ModalWrapper>
    )
}

export default Modal;