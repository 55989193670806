import React, { useEffect } from "react";
import {Switch, useLocation} from "react-router-dom";
import Login from "../pages/Login";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/reducers/rootReducer";
import PrivateRoute from "./PrivateRoute";
import MainRouter from "./MainRouter";
import Cookies from "js-cookie";
import { getProfile, logout } from "../store/reducers/userReducer";

const App = () => {
  const {isLogged} = useSelector((state: RootState) => state.user);
  const {pathname} = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    pathname !== '/login' && dispatch(getProfile({}));
    if(!Cookies.get('access') && !Cookies.get('refresh')) {
      dispatch(logout())
    }
  }, [pathname, dispatch])

  
  return (
    <>
        <Switch>
          <PrivateRoute exact path="/login" component={Login} redirect="/offices" access={!isLogged}/>
          <PrivateRoute path="/" component={MainRouter} redirect="/login" access={isLogged} />
        </Switch>
    </>
  );
}

export default App;
