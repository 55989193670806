import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './assets/index.scss';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import {persistor, store} from "./store/store";
import {PersistGate} from 'redux-persist/integration/react';
import moment from "moment";
import 'moment/locale/ru';

moment.locale('ru');

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
