export const COLORS = {
    black: '#242424',
    lightBlack: '#3D3221',
    white: '#FFFFFF',
    brown: '#DECEB7',
    beige: '#EDE8DA',
    cream: '#FFFBF2',
    red: '#F3817E',
    darkRed: '#B16767',
    darkGreen: '#7A896E',
    green: '#B2DD91',
    steel: 'rgba(36, 36, 36, 0.49)',
    darkSteel: 'rgba(36, 36, 36, 0.8)',
    hoverLightBlack: '#1C160E',
    placeholder: '#C4C4C4',
    lightBrown: '#A99A85',
    logout: '#F7F1E5',
    gray: '#6D7269',
    orange: '#F9C68B'
}