import { Form } from 'formik'
import styled from 'styled-components'
import { Flex } from '../../../../components/common/Styled/Flex'
import { COLORS } from '../../../../utils/constants/colors'

const PopupWrapper = styled(Flex)`
    box-shadow: 0px 0px 4px #E1D7C9;
    border-radius: 6px;
    background: ${COLORS.cream};
    width: 500px;

    input {
        background: ${COLORS.white};
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
  }

  &::-webkit-scrollbar {
      width: 12px;
  }

  &::-webkit-scrollbar-track {
      background: #EDE8DA;
  }
`

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;

    padding: 10px 20px 25px 20px;

    & >  div:not(:first-child), & > p {
        margin-top: 15px;
    }
`

const Tab = styled(Flex)<{active: boolean, isNext?: boolean}>`
    border: 1px solid #DECEB7;
    ${({isNext}) => isNext ? 'border-left: none;' : 'boreder-right: none;'};
    border-radius: ${({isNext}) => isNext ? '0 6px 6px 0' : '6px 0 0 6px'};
    background: ${({active}) => active ? COLORS.lightBrown : COLORS.white};
    padding: 10px;
    cursor: pointer;
    transition: background .2s linear;

    &:hover {
        background: ${({active}) => active ? COLORS.lightBrown : COLORS.brown};
    }
`

export { PopupWrapper, StyledForm, Tab }