import styled from 'styled-components';
import { Flex } from '../../components/common/Styled/Flex';

const FormWrapper = styled.div`
    width: 100%;
`;

const BalanceWrapper = styled.div<{height: string}>`
    width: 100%;
    max-height: ${({height}) => height};
    overflow-y: auto;
`;

const Tabs = styled(Flex)`
    width: 100%;
    p {
        cursor: pointer;
    }
`

const TabsWrapper = styled(Flex)`
    @media (max-height: 545px) {
        padding: 15px 15px 0 15px;
    }
`

export { FormWrapper, BalanceWrapper, Tabs, TabsWrapper }

