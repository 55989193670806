import React from 'react';
import { ArrowWrapper, CabinetsPreviewWrapper, PhotoWrapper } from './style';
import { Typography } from '../../../../components/common/Styled/Typography';
import { Flex } from '../../../../components/common/Styled/Flex';
import { COLORS } from '../../../../utils/constants/colors';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { IOffice } from '../../../../types/interfaces/office';
import Slider, { Settings } from 'react-slick';
import { NextIcon, PrevIcon } from '../../../../assets/icons';

const CabinersCatalogPreview = ({data} : {data: IOffice}) => {
    const { id, name, address, price_category, photos } = data
    const { path } = useRouteMatch(); 
    const sliderSettings: Settings = {
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        nextArrow: <ArrowWrapper><NextIcon /></ArrowWrapper>,
        prevArrow: <ArrowWrapper isPrev><PrevIcon /></ArrowWrapper>
    }
    return (
        <CabinetsPreviewWrapper>
            <NavLink to={`${path}/${id}`}>
                <Slider {...sliderSettings}>
                    {photos.map(item => 
                        <PhotoWrapper key={item.id}>
                            <img src={item.photo} alt="Фото кабинета" />
                        </PhotoWrapper>
                    )}
                </Slider>
                <Flex align="flex-start" direction="column" padding="10px 20px 20px 15px">
                    <Flex cursor="pointer" justify="flex-start">
                        <Typography margin="0 10px 0 0" weight="700" size="18px" lineHeight="21px">{name}</Typography>
                        <Typography size="12px" weight="600" lineHeight="15px" fontFamily="Montserrat">{address}</Typography>
                    </Flex>
                    <Flex cursor="pointer" gap="5px" width="100%" justify="space-between">
                        <Typography size="12px" color={COLORS.steel}  weight="600" lineHeight="15px" fontFamily="Montserrat">{price_category.name}</Typography>
                        <Typography weight="700" size="18px" lineHeight="21px">{price_category.price}₽&nbsp;/&nbsp;час</Typography>
                    </Flex>
                </Flex>
            </NavLink>
        </CabinetsPreviewWrapper>
    );
};

export default CabinersCatalogPreview;