import React, { useEffect, useRef, useState } from 'react';
import { ArrowBackIcon } from '../../assets/icons';
import { Flex } from '../../components/common/Styled/Flex';
import { Tab } from '../../components/common/Styled/Tab';
import useResolution from '../../hooks/useResolution';
import BookingCanceled from './BookingCanceled';
import BookingPast from './BookingPast';
import BookingSchedule from './BookingSchedule';
import { MainWrapper, TabWrapper, ArrowBack } from './style';

enum Pages {
  Scheduled,
  Past,
  Canceled
}

const Bookings = () => {
  const [activePage, setActivePage] = useState<Pages>(Pages.Scheduled)
  const ref = useRef<HTMLDivElement>(null)
  const [showCalendar, setShowCalendar] = useState(false)
  const tabs = [
    { id: 1, text: 'Запланированные', changeFunc: () => setActivePage(Pages.Scheduled) },
    { id: 2, text: 'Прошедшие', changeFunc: () => setActivePage(Pages.Past) },
    { id: 3, text: 'Отмененные', changeFunc: () => setActivePage(Pages.Canceled) },
  ] as const

  const isMobile = useResolution(1000)


  useEffect(() => {
    !isMobile && setShowCalendar(false)
  }, [isMobile])

  return (
    <MainWrapper showCalendar={showCalendar} direction="column" align="flex-start" padding="70px 60px 0 60px">
      {!showCalendar ?
        <TabWrapper justify="flex-start" margin="0 0 40px 0">
          {tabs.map((item, index) =>
            <Tab
              key={item.id}
              fontFamily="Montserrat"
              weight="600"
              size="15px"
              lineHeight="24px"
              onClick={item.changeFunc}
              active={index === activePage}
              margin="0 35px 0 0"
            >
              {item.text}
            </Tab>
          )}
        </TabWrapper>
        :
        <ArrowBack padding="10px" onClick={() => setShowCalendar(false)}>
          <ArrowBackIcon />
        </ArrowBack>
      }
      <Flex
        align="flex-start"
        ref={ref}
        height={ref.current ? `${window.innerHeight - 30 - (isMobile ? 100 : 0) - ref.current.getBoundingClientRect().top}px` : 'auto'}
        justify="flex-start" width="100%"
      >
        {activePage === 0 && <BookingSchedule showCalendar={showCalendar} setShowCalendar={setShowCalendar}
          isMobile={isMobile} />}
        {activePage === 1 && <BookingPast isMobile={isMobile} />}
        {activePage === 2 && <BookingCanceled isMobile={isMobile} />}
      </Flex>
    </MainWrapper>
  );
};

export default Bookings;