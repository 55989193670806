import React, { useEffect, useRef, useState } from 'react';
import Input from '../Input';
import { Flex } from '../../Styled/Flex';
import { Typography } from '../../Styled/Typography';
import { DateDropdown, WeekWrapper, InputWrapper, ButtonWrapper, Chevron } from './style';
import DatePicker from "react-datepicker";
import ru from 'date-fns/locale/ru'
import moment from 'moment';
import { COLORS } from '../../../../utils/constants/colors';
import { SetStateType } from '../../../../types/common';
import { DateType } from '../../../Filters';
import { useAdaptive } from '../../../../hooks/useAdaptive';
import { CalendarChevron } from '../../../../assets/icons';
import { Button } from '../../Styled/Button';
import { useClickOutside } from '../../../../hooks/useClickOutside';

interface IProps {
    setShow: SetStateType<boolean>
    setTitle?: SetStateType<string>
    setActive?: SetStateType<boolean>
    setDisabledDays?: SetStateType<boolean>
    showInputs?: boolean
    select?: boolean
    dates: { startDate: DateType, endDate?: DateType }
    setDates: SetStateType<{ startDate: DateType, endDate?: DateType }>
    disabledDays?: boolean,
    excludeDates?: Array<Date>
    dontShowPrevDays?: boolean
    setGlobalMonth?: SetStateType<Date | null>
}

const DateFilter = ({
    setShow,
    setTitle,
    setActive,
    showInputs,
    select,
    dates,
    setDates,
    disabledDays,
    setDisabledDays,
    excludeDates,
    dontShowPrevDays,
    setGlobalMonth
}: IProps) => {
    const [month, setMonth] = useState(dates.startDate || moment().toDate())
    const ref = useRef<HTMLDivElement>(null);

    const week = [
        { id: 2, name: 'пн' },
        { id: 3, name: 'вт' },
        { id: 4, name: 'ср' },
        { id: 5, name: 'чт' },
        { id: 6, name: 'пт' },
        { id: 7, name: 'сб' },
        { id: 1, name: 'вс' }
    ] as const

    const resize = useAdaptive(ref)
    useClickOutside(ref, () => {
        if ((dates.endDate && dates.startDate) || (dates.endDate === null && dates.startDate === null)) {
            setShow(false)
            ready()
            return
        }

        cancel()
    })

    useEffect(() => {
        resize()
    }, [])

    useEffect(() => {
        setGlobalMonth && setGlobalMonth(month)
    }, [month])

    const formatDate = (date: Date | null) => {
        if (date) {
            const day = moment(date).format('DD')
            const month = moment(date).format('MMMM')
            const year = moment(date).format('YYYY')
            return `${day} ${month.slice(0, 3)}. ${year}г.`
        }
        return ''
    }

    const nextMonth = () => {
        setMonth(moment(month).add(1, 'month').toDate())
    }

    const prevMonth = () => {
        setMonth(moment(month).add(-1, 'month').toDate())
    }

    const cancel = () => {
        setDates({ startDate: null, endDate: null })
        setMonth(moment().toDate())
        setShow(false)
        setTitle && setTitle('Дата')
        setActive && setActive(false)
        setDisabledDays && setDisabledDays(false)
        setGlobalMonth && setGlobalMonth(null)
    }

    const ready = () => {
        if (!dates.startDate) return cancel()
        setShow(false)
        setTitle && setTitle(`${formatDate(dates.startDate)} ${dates.endDate ? '– ' + formatDate(dates.endDate) : ''}`)
        setActive && setActive(true)
        setGlobalMonth && setGlobalMonth(null)
    }

    const changeDates = (dates: [Date, Date]) => {
        const [start, end] = dates
        setDates({ startDate: start, endDate: end })
        if (end) {
            return setDisabledDays && setDisabledDays(true)
        }
        setDisabledDays && setDisabledDays(false)
    }

    return (
        <DateDropdown padding={showInputs ? '0' : '30px 0 0'} ref={ref} disabledDays={!!disabledDays}>
            {showInputs &&
                <InputWrapper padding="30px 15px 0 15px" margin="0 0 30px 0" justify="space-between">
                    <Input
                        name="startDate"
                        onChange={() => { }}
                        value={formatDate(dates.startDate)}
                        theme="underline"
                        disabled
                    />
                    <Input
                        name="endDate"
                        value={formatDate(dates.endDate || null)}
                        onChange={() => { }}
                        theme="underline"
                        disabled
                    />
                </InputWrapper>
            }
            <Flex margin="0 0 25px 0">
                {(!dontShowPrevDays || moment(month).isAfter(moment(), 'month')) &&
                    <Chevron type="button" onClick={prevMonth} isPrev={true}>
                        <CalendarChevron />
                    </Chevron>
                }
                <Typography
                    weight="800"
                    color={COLORS.steel}
                    size="14px"
                    lineHeight="17px"
                    padding="0 30px"
                >
                    {moment(month).format('MMMM YYYY').toUpperCase()}
                </Typography>
                <Chevron type="button" onClick={nextMonth}>
                    <CalendarChevron />
                </Chevron>
            </Flex>
            <WeekWrapper justify="space-between" padding={`${showInputs ? '0' : '30px'} 20px 15px 20px`} margin="0 0 15px 0">
                {week.map(item => <Typography key={item.id} weight="800">{item.name}</Typography>)}
            </WeekWrapper>
            <DatePicker
                locale={ru}
                inline
                onChange={(dates: [Date, Date] | Date) => select ? changeDates(dates as [Date, Date]) : setDates({ startDate: dates as Date, endDate: null })}
                startDate={dates.startDate}
                endDate={dates.endDate}
                selectsRange={select}
                selected={month}
                excludeDates={excludeDates ? excludeDates : []}
                minDate={!!dontShowPrevDays ? moment().isAfter(moment(`${moment().format('YYYY-MM-DD')} 22:00:00`), 'hour') ? moment().add(1, 'day').toDate() : new Date() : undefined}
            />
            <ButtonWrapper padding="20px" justify="flex-end">
                <Button
                    onClick={cancel}
                    margin="0 20px 0"
                    type="button"
                >
                    <Typography color={COLORS.steel} weight="800" size="14px" lineHeight="17px">ОТМЕНА</Typography>
                </Button>
                <Button
                    onClick={ready}
                    type="button"
                >
                    <Typography color={COLORS.gray} weight="800" size="14px" lineHeight="17px">ГОТОВО</Typography>
                </Button>
            </ButtonWrapper>
        </DateDropdown>
    );
};

export default DateFilter;