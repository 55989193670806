import styled from "styled-components";
import {COLORS} from "../../../../utils/constants/colors";

interface IProps {
    theme: 'default' | 'underline',
    icon?: string
}

const StyledInput = styled.input<IProps>`
    position: relative;
    font-family: Montserrat, sans-serif;
    width: 100%;
    font-size: 16px;
    background: none;
    ${p => p.theme === 'underline' 
        ? `
           color: ${COLORS.black};
           border: none;
           border-bottom: 1px solid ${COLORS.brown};
           font-weight: 500;
           line-height: 24px;
           padding-bottom: 3px;
        `
        : `
            border: 1px solid ${COLORS.brown};
            border-radius: 6px;
            line-height: 20px;
            padding: 8px 10px;
        `
    }
    ${({icon}) => icon && 
        `
            background: url(${icon}) no-repeat scroll 7px 10px, #fff;
            padding-left:30px;

        `
        
    }
`;

export {StyledInput};