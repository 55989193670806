import alertify from "alertifyjs";
alertify.set('notifier', 'position', 'top-right');

export const showAlert = (status: 'success' | 'error', text: string) => {	
	alertify[status](text)
}

export const stackErrors = (err: any) => {
	return Object.keys(err.response.data).reduce((acum, item) => acum + err.response.data[item] + '\n', '')
}
