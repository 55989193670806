import React, {useState} from "react";
import {COLORS} from "../../../utils/constants/colors";
import {Typography} from "../../../components/common/Styled/Typography";
import {Formik, Form, Field, FormikValues} from "formik";
import FormikInput from "../../../components/common/FormikElements/Input";
import {Button} from "../../../components/common/Styled/Button";
import {Container} from "./style";
import {SubmitButtonWrapper} from "../style";
import {resetPassword} from "../../../utils/api/routes";
import {EMAIL} from "../../../utils/formik/validation";
import * as Yup from "yup";
import useResolution from "../../../hooks/useResolution";

interface IProps {
    closeModal: VoidFunction
}

const RecoveryPopup = ({closeModal}: IProps) => {
    const isMobile = useResolution(700)
    const [state, setState] = useState({
        message: '',
        success: false
    })

    const handleSubmit = (values: FormikValues) => {
        resetPassword(values.email.trim().toLowerCase())
            .then(() => setState({message: 'На указанный email была отправлена для восстановления пароля', success: true}))
            .catch(() => setState({message: 'Пользователя с таким email не существует', success: false}))
    }

    const schema = Yup.object({
        email: EMAIL
    })

    return (
        <Container background={COLORS.cream} padding="36px 50px 33px" direction="column">
            <Typography weight={700} size='24px' lineHeight="29px" margin={`0 0 ${state.message ? '25px' : '45px'}`}>Восстановление пароля</Typography>
            {state.message && <Typography weight={500} lineHeight="22px" align="center" size='16px' margin="0 0 25px" color={!state.success ? COLORS.red : COLORS.black}>{state.message}</Typography>}
            <Formik
                initialValues={{email: ''}}
                onSubmit={(values) => handleSubmit(values)}
                validationSchema={schema}
            >
                <Form>
                    <Field direction={isMobile ? 'column' : 'row'} name="email" component={FormikInput} label="Email" labelWidth="90px" theme="underline"/>
                    <SubmitButtonWrapper>
                        <Button
                            type="submit"
                            theme="grey"
                            radius="6px"
                            shadow={true}
                            weight={800}
                            margin="40px auto 0"
                            fontSize="16px"
                            width="215px"
                            height="40px"
                        >
                            ВОССТАНОВИТЬ
                        </Button>
                    </SubmitButtonWrapper>
                </Form>
            </Formik>
        </Container>
    )
}

export default RecoveryPopup